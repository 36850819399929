import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { SubscriberCdrService } from './subscriber-cdr.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SearchService } from '../../../services/search.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { Subscription } from 'rxjs';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver/FileSaver';
import { ToastrService } from 'ngx-toastr';
declare let $;
import { SearchAutocompleteComponent } from '../../../components/search-autocomplete/search-autocomplete.component';
@Component({
  selector: 'app-subscriber-cdr',
  templateUrl: './subscriber-cdr.component.html',
  styleUrls: ['./subscriber-cdr.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriberCdrComponent extends OldAbstractBaseListComponent implements OnInit, OnDestroy {

  @ViewChild(SearchAutocompleteComponent, { static: false }) clearUsernameRef: SearchAutocompleteComponent
  @ViewChild("editUserName", { static: false }) editUserNameRef: TemplateRef<any>;
  subscriberCdrDataForGrid: any = { content: [] };
  subscriberCdrGridSettings: any = {};
  tableKeyEnum = TableKeyEnum;
  filtercheckflag: boolean = true;
  requestType: any[] = [{ username: '' }]
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  searchFlag: boolean = false;
  gridSetting: any;
  recordFound: boolean;
  userName: string;
  UsernameList: string;
  customerId: number;
  maxDate: any;
  minDate: any;
  currentMonthDate: any;
  mind: any;
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredSubscriberCDRDataForGrid: any = [];

  searchSubscriberCdr = new FormGroup({
    requestType: new FormControl(null),
    userName: new FormControl(null),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    serviceAreas: new FormControl(null),
    nasipaddress: new FormControl(''),
    framedipaddress: new FormControl(''),
    acctsessionid: new FormControl(''),
  })

  constructor(
    private subscriberCdrService: SubscriberCdrService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private datepipe: DatePipe,
    private toaster: ToastrService
  ) { super(searchService); }

  ngOnInit() {
    this.setDate();
    this.setDefaultPageSize(this.tableKeyEnum.SUBSCRIBER_CDR_LIST)
    //this.initPaginationSort();
    this.setGridSetting();

    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.setUsername();
    this.setDateFromDefault();
    this.subscriptions.push(filterSubscription);
  }
  setDate() {
    var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var startDate = this.datepipe.transform(createStartdate, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.searchSubscriberCdr.patchValue({ "startDate": startDate, "endDate": endDate });
  }
  setDateFromDefault(event?) {
    var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.currentMonthDate = new Date();
    const Mindate = new Date(2022, 0, 1);
    this.mind = Mindate;
    if (event && event.target) {
      this.minDate = new Date(event.target.value.getFullYear(), event.target.value.getMonth(), 1);
      this.maxDate = new Date(event.target.value.getFullYear(), event.target.value.getMonth() + 1, 0);
      this.searchSubscriberCdr.get('endDate').setValue(this.maxDate)
      this.searchSubscriberCdr.get('endDate').updateValueAndValidity();
    }
    else {
      this.minDate = new Date(createStartdate.getFullYear(), createStartdate.getMonth(), 1);
      this.maxDate = new Date(createStartdate.getFullYear(), createStartdate.getMonth() + 1, 0);
    }
  }

  getSubscriberCdrData(gridSetting: any) {
    this.gridSetting = gridSetting;
    let fetchSettingData = this.subscriberCdrGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    if (this.searchFlag === false) {
      let data = this.searchSubscriberCdr.value;
      params.endDate = this.datepipe.transform(data.endDate, "yyyy-MM-dd");
      params.startDate = this.datepipe.transform(data.startDate, "yyyy-MM-dd");
      params.filterCheckFlag = this.filtercheckflag;
    }
    //params.filterCheckFlag = true
    if (this.searchFlag === true) {
      let tempPageNumber = Number(params.page)
      let apiInputData = this.searchSubscriberCdr.value;
      params = this.transformApiInputData(apiInputData, params);
    }
    this.subscriberCdrService.getSubscriberCdrDataWithPageing(params).subscribe((result) => {
      if (result.content.length == 0) {
        this.toaster.error('No Record Found');
      }
      this.subscriberCdrDataForGrid = result;
      this.filteredSubscriberCDRDataForGrid = JSON.parse(JSON.stringify(this.subscriberCdrDataForGrid.content));
      this.refreshDataSource(this.subscriberCdrDataForGrid.content);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.subscriberCdrGridSettings.pageTotalLength = this.totalRecords;
    },
      (error) => {
        if (error.status !== 200) {
          this.toaster.error(error.ERROR);
          this.refreshDataSource(null);
        }
      });
  }
  onExportToExcel() {
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = (today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds()).toString();
    let apiInputData = this.searchSubscriberCdr.value;
    let createStartdate = this.datepipe.transform(apiInputData.startDate, 'yyyy-MM-dd');
    let createEnddate = this.datepipe.transform(apiInputData.endDate, 'yyyy-MM-dd');
    let ddmmyyyyStartDate = new Date(createStartdate);
    let ddmmyyyyEndDate = new Date(createEnddate);
    if (ddmmyyyyStartDate > ddmmyyyyEndDate) {
      this.toaster.error("Start date should be less then End date.");
    } else {
      let formatedData = this.transformApiInputData(apiInputData);
      this.subscriberCdrService.excelServiceData(formatedData).subscribe(blob => {
        saveAs(blob, "[subscriber cdr report " + date.toString() + " " + "(" + time + ")" + "]" + ".xls");
      })
    }
  }
  getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    ////console.log("params1", this.orderingData);
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getSubscriberCdrData(config);
  }

  setUsername() {
    this.UsernameList = "subscriber/searchNew?s="
  }

  getUsername(data: any) {
    if (data.event) {
      let userName = data.event.username;
      this.searchSubscriberCdr.patchValue({ "userName": userName });
    } else {
      this.userName = "";
      this.searchSubscriberCdr.patchValue({ "userName": null });
      this.clearUsernameRef.autocomplete.clearAllText;
      this.clearUsernameRef.dataList = [];
    }
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      { colKey: 'index', default: 'ID', rowKey: 'id', width: '3%', isFilterAllow: false, isNumber: true },


      {
        colKey: 'acctstatustype',
        default: 'Acct Request Type',
        rowKey: 'acctstatustype',
        width: '5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'username',
        default: 'Username',
        rowKey: 'username',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'acctsessionid',
        default: 'Session ID',
        rowKey: 'acctsessionid',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'createdate',
        default: 'Session Start Time',
        rowKey: 'createdate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'endSessionTime',
        default: 'Session End Time',
        rowKey: 'endSessionTime',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'usage_data',
        default: 'Usage-Volume',
        rowKey: 'usage_data',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'usage_time',
        default: 'Usage-Time',
        rowKey: 'usage_time',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'framedipaddress',
        default: 'IP Address',
        rowKey: 'framedipaddress',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'nasipaddress',
        default: 'NAS IP',
        rowKey: 'nasipaddress',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'callingstationid',
        default: 'MAC ID',
        rowKey: 'callingstationid',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'framedinterfaceid',
        default: 'FramedInterface Id',
        rowKey: 'framedinterfaceid',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'framedipv6prefix',
        default: 'Framed IPv6 Prefix',
        rowKey: 'framedipv6prefix',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },


      {
        colKey: 'acctterminatecause',
        default: 'Termination cause',
        rowKey: 'acctterminatecause',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'nasportid',
        default: 'Nas port',
        rowKey: 'nasportid',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      }

    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    // this.searchSettings = [
    //   {
    //     display_field: 'Record Date',
    //     value_field: 'CREATE_DATE',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.DATE,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.DATE,
    //   },
    //   {
    //     display_field: 'Acct Request Type',
    //     value_field: 'acctstatustype',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.DATE,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.DATE,
    //   },
    //   {
    //     display_field: 'Username',
    //     value_field: 'username',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.TEXT,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.STRING,
    //   },
    //   {
    //     display_field: 'Session Time',
    //     value_field: 'acctsessiontime',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.NUMBER,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.NUMBER,
    //   },
    //   {
    //     display_field: 'Input Octate',
    //     value_field: 'acctinputoctets',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.NUMBER,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.NUMBER,
    //   },
    //   {
    //     display_field: 'Output Octate',
    //     value_field: 'acctoutputoctets',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.NUMBER,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.NUMBER,
    //   },
    //   {
    //     display_field: 'NAS IP Address',
    //     value_field: 'nasipaddress',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.TEXT,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.STRING,
    //   },
    //   {
    //     display_field: 'Gateway',
    //     value_field: 'connectinfo',
    //     control_setting: [
    //       {
    //         control_type: SearchControlTypeEnum.TEXT,
    //         display_style: '',
    //       },
    //     ],
    //     data_type: SearchDataTypeEnum.STRING,
    //   },
    // ];

    this.subscriberCdrGridSettings = {

      tableId: this.tableKeyEnum.SUBSCRIBER_CDR_LIST,

      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      perPage: this.paginationData.rowsPerPage,
      pageNumber: this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      showFilter: false,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageTotalLength: 0,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
    if (this.subscriberCdrGridSettings.serverSideSortPagination && this.subscriberCdrGridSettings.perPage) {
      this.setPaginationPerPage(this.subscriberCdrGridSettings.perPage);
    }
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.subscriberCdrDataForGrid.content));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(
        filterObj.filters,
        this.subscriberCdrDataForGrid.content
      );
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getSubscriberCdrData(config);
  }
  SearhSubscriberCdr() {
    this.searchFlag = true
    this.filtercheckflag = true;
    let apiInputData = this.searchSubscriberCdr.value;
    let createStartdate = this.datepipe.transform(apiInputData.startDate, 'yyyy-MM-dd');
    let createEnddate = this.datepipe.transform(apiInputData.endDate, 'yyyy-MM-dd');
    let ddmmyyyyStartDate = new Date(createStartdate);
    let ddmmyyyyEndDate = new Date(createEnddate);
    if (ddmmyyyyStartDate > ddmmyyyyEndDate) {
      this.toaster.error("Start date should be less then End date.");
    } else {
      let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
      this.getSubscriberCdrData(config);
      // this.searchFlag = true;
      // let apiInputData = this.searchSubscriberCdr.value;
      // let formatedApiInputData = this.transformApiInputData(apiInputData);
      // formatedApiInputData['page']=1; //Default Page
      // ////console.log("formatedApiInputData-->"+formatedApiInputData)

      // this.subscriberCdrService.getSubscriberCdrDataWithPageing(formatedApiInputData).subscribe(result => {
      //   if (result && result.dbcdrprocessinglist.length == 0) {
      //     this.subscriberCdrDataForGrid.content = [];
      //     this.recordFound = false;
      //     this.hideShowRecordStatusMessage();
      //   } else {
      //     this.recordFound = true;
      //     this.subscriberCdrDataForGrid.content = result.dbcdrprocessinglist;
      //     this.hideShowRecordStatusMessage();
      //   }
      //   this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      //   this.subscriberCdrGridSettings.pageTotalLength = this.totalRecords;
      //   this.refreshDataSource(this.subscriberCdrDataForGrid.content);
      //   
      // })
      // // this.reset();
    }

  }
  transformApiInputData(data, params?) {
    let formatedInputData: any = {};
    if (params) {
      formatedInputData = Object.assign(params);
    }
    let apiInuputData = data;
    if (apiInuputData.userName != null && apiInuputData.userName != "") {
      formatedInputData.username = data.userName;
    }
    if (apiInuputData.requestType != null && apiInuputData.requestType != "") {
      formatedInputData.requestType = data.requestType;
    }
    formatedInputData.filterCheckFlag = this.filtercheckflag;
    // formatedInputData.page = this.gridSetting.page.pageNumber;
    // formatedInputData.pageSize = this.gridSetting.page.rowsPerPage;
    formatedInputData.startDate = this.datepipe.transform(data.startDate, "yyyy-MM-dd");
    formatedInputData.endDate = this.datepipe.transform(data.endDate, "yyyy-MM-dd");
    if (data.nasipaddress != '' && data.nasipaddress != null) {
      formatedInputData.nasipaddress = data.nasipaddress;
    }
    if (data.framedipaddress != '' && data.framedipaddress != null) {
      formatedInputData.framedipaddress = data.framedipaddress;
    }
    if (data.acctsessionid != '' && data.acctsessionid != null) {
      formatedInputData.acctsessionid = data.acctsessionid;
    }
    return formatedInputData;
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
    else {
      this.dataSource = new MatTableDataSource();
    }
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'username') {
        col.customControls = [this.editUserNameRef];
      }
    });
  }

  onEdit(event) {
    let self = this;
    this.subscriberCdrService.getUserdetailByUserName(event.username).subscribe(res => {
      this.customerId = res.customers.id;
    })
    setTimeout(function () {
      self.viewCustomerdetail();
    }, 500);


    // if(event.custid){
    //   this.router.navigate(['masters/subscriber/details',event.custid]);
    // }else{
    //   this.toaster.error('User Not Found.')
    // }
  }
  viewCustomerdetail() {
    this.router.navigate(['masters/subscriber/details', this.customerId]);
  }
  reset() {
    this.searchSubscriberCdr.reset();
    this.setDate();
    this.setDateFromDefault();
    this.searchFlag = false;
    this.recordFound = true;
    this.filtercheckflag = true;
    this.clearUsernameRef.autocomplete.clearModel();
    this.clearUsernameRef.dataList = [];
    // this.getListWithParams();
    this.refreshDataSource([]);
    this.hideShowRecordStatusMessage();
    this.totalRecords = 0;
    this.subscriberCdrGridSettings.pageTotalLength = 0;
  }
  hideShowRecordStatusMessage() {
    $('#record').remove();
    if (!this.recordFound) {
      $('.custom-mat-page').before
        ("<div id='record' style='margin-left:400px;margin-top:3px;'><strong>Record Not Found</strong></div>");
    }
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}

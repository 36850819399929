import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { SubscriberDetailsService } from '../subscriber-details.service';

@Component({
  selector: 'app-active-contract',
  templateUrl: './active-contract.component.html',
  styleUrls: ['./active-contract.component.scss']
})
export class ActiveContractComponent implements OnInit {

  @ViewChild('desc', { static: true }) descRef:  TemplateRef<any>;
  @ViewChild('dateWrap', { static: true }) dateRef:  TemplateRef<any>;
  @ViewChild('enddateWrap', { static: true }) enddateRef:  TemplateRef<any>;
  @ViewChild('statusWrap', { static: true }) statusWrapRef:  TemplateRef<any>;

  @Input() dataList;

  custId : any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  activeContractNeoGridSettings = {};
  subscriptions: Subscription[] = [];

  activePlanTableData : any;
  planMapId: string;
  tenantId: any;

  constructor(
    private modalService: NgbModal,
    private dashboardservice: SubscriberDetailsService,
    private tosterService: ToastrService
  ) { }

  ngOnInit() {
    this.tenantId = localStorage.getItem('custUserId');
    this.setGridSetting();
    //this.custId = localStorage.getItem('custUserId')
    this.getActivePlanData();
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'RECNTXT') {
        col.customControls = [this.descRef];
      }
      if(col.colKey === 'RECNBEG'){
        col.customControls = [this.dateRef];
      }
      if(col.colKey === 'RECNENDABS'){
        col.customControls = [this.enddateRef];
      }
      if(col.colKey === 'STATUS'){
        col.customControls = [this.statusWrapRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'RECNNR',
        default: 'Contract Number',
        rowKey: 'RECNNR',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'RECNBEG',
        default: 'Contract Begin DATE',
        rowKey: 'RECNBEG',
        width: '25%',
        isNumber: false,
        isFilterAllow: false,
        enableSort: false,
      },
      {
        colKey: 'RECNENDABS',
        default: 'Contract End Date',
        rowKey: 'RECNENDABS',
        width: '25%',
        isNumber: false,
        isFilterAllow: false,
        enableSort: false,
      },
      // {
      //   colKey: 'STATUS',
      //   default: 'Status',
      //   rowKey: 'STATUS',
      //   width: '15%',
      //   isFilterAllow: false,
      //   isNumber: false,
      // },
      {
        colKey: 'RECNTXT',
        default: 'Description',
        rowKey: 'RECNTXT',
        width: '15%',
        isFilterAllow: false,
        isNumber: false,
      },
    ];

    this.activeContractNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.CONTRACT_HISTORY,
      perPage: 10,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      //tableHeight:600,
      // verticalScroll:true,
      // headerSticky:true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getActivePlanData() {
    // let dataList: any = [];
    // if(this.tenantId){
    //   this.dashboardservice.getBusinessPartnerContractData(this.tenantId).subscribe(res =>{
    //     if(res.responseCode == 200){
    //       dataList = res.data.ET_CONTRACT.item;
          this.refreshDataSource(this.dataList);
    //     }else{
    //       this.tosterService.error(res.responseMessage);
    //     }
    //   },error => {
    //     if (error.responseCode !== 200) {
    //       this.tosterService.error(error.error.responseMessage);
    //     }
    //   }
    //   )
    // }
  }

  open(paydet, data: any) {
    this.modalService.open(paydet, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    this.planMapId = data.planmapid;
  }
}

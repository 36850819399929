import { Component,OnInit, ChangeDetectorRef, TemplateRef, ViewChild, AfterViewInit, OnDestroy,} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import _ from 'underscore';
import { SearchService } from '../../../services/search.service';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../core/constant/response-code';
import { CustomModalService } from '../../../components/custom-modal/custom-modal.service';
import { SubscriberDetailsService } from '../subscriber-details/subscriber-details.service';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { AdvanceSearchComponent } from '../../../components/advance-search/advance-search.component';
import { TenantList } from '../model/machineData';
import { SubscriberService } from '../subscriber/subscriber.service';

@Component({
  selector: 'app-tenats-list',
  templateUrl: './tenats-list.component.html',
  styleUrls: ['./tenats-list.component.scss']
})
export class TenatsListComponent extends OldAbstractBaseListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('connectivity', { static: false }) livestatusRef: TemplateRef<any>;
  @ViewChild('downgradespeed', { static: false }) downgradeRef: TemplateRef<any>;
  @ViewChild ('editUserName',{static:false}) editUserNameRef:TemplateRef<any>;
  @ViewChild ('outstanding',{static:false}) outstandingWrapperRef:TemplateRef<any>;
  @ViewChild ('addressWrapper',{static:false}) addressWrapperReff:TemplateRef<any>;
  @ViewChild ('macWrapper',{static:false}) macWrapperReff:TemplateRef<any>;
  @ViewChild ('firstPayment',{static:false}) firstPaymentRef:TemplateRef<any>;
  @ViewChild(AdvanceSearchComponent, { static: false }) advanceSearch: AdvanceSearchComponent;

  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  openChangePlanPopup: boolean;
  custPassDetails: any;
  passwordDetailsName: any;
  custId: any;
  partnerId: number;
  service: string;
  partnerNameInput: string;
  subscriberBasicDetails: any = {
    macAddressModelList: [],
    planList: []
  }
  subscriberDataForGrid: any = { content: [] };
  subscriberGridSettings: any = {};
  public countryList: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  selectForUpdate: any;
  show_update: any = false;
filterObj:any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  subscriberNeoGridSettings: any = {};
  searchSettings = [];
  subscriptions: Subscription[] = [];
  public loginService: LoginService;
  advSearchSetting = [];

  editUnameFlag:boolean=false;
  username:string;
  isChangePlanOpen: boolean;
  selectedCustomer: any;
  ui_id: string;
  multipleOptionsList = [];
  filters=[];
  value:any;
  constructor(
    private subscriberService: SubscriberService,
    private router: Router,
    private searchService: SearchService,
    private tosterservice:ToastrService,
    loginservice: LoginService,
    private sharingdataService:DataSharingServiceService
  ) {
    super(searchService);
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.searchService.setSearchFilterSettings('subscriber');
    this.setDefaultPageSize(this.tableKeyEnum.SUBSCRIBER_LIST)
    this.setGridSetting();
    if(this.sharingdataService.filterStatusName){
      this.getMethodValue();
      this.sharingdataService.resetSubscriberStatus();
    }else{
      this.initPaginationSort();
  }
  const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
   this.setFilterObjForAdvanceSearch(obj);
   });
   this.subscriptions.push(filterSubscription);  
    }

  setFilterObjForAdvanceSearch(obj){
    let temp= {
      filters:obj
    }
    this.applyFilter(temp);
  } 

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
        if(this.multipleOptionsList.length == 0){
          col.isMultipleOption = false
        }
      }
      if (col.colKey == 'connectivity') {
        col.customControls = [this.livestatusRef];
      }
      if (col.colKey == 'downgradespeed') {
        col.customControls = [this.downgradeRef];
      }
      if(col.colKey== 'username'){
        col.customControls=[this.editUserNameRef];
       
      }
      if(col.colKey== 'outstanding'){
        col.customControls=[this.outstandingWrapperRef];
       
      }
      if(col.colKey== 'outstanding'){
        col.customControls=[this.outstandingWrapperRef];
       
      }
      if(col.colKey== 'fulladdress'){
        col.customControls=[this.addressWrapperReff];
       
      }
      if(col.colKey== 'macaddress'){
        col.customControls=[this.macWrapperReff];
      }
      if(col.colKey== 'firstPayment'){
        col.customControls=[this.firstPaymentRef];
      }
    });
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '20px',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'connectivity',
        default: 'Live',
        rowKey: 'connectivity',
        width: '25px',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        sortKey: 'name',
        width: '15%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'username',
        default: 'Username',
        rowKey: 'username',
        sortKey: 'username',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'email',
        default: 'Email',
        rowKey: 'email',
        sortKey: 'email',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'mobile',
        default: 'Mobile',
        rowKey: 'mobile',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'fulladdress',
        default: 'Address',
        rowKey: 'fulladdress',
        width: '0.5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'createdate',
        default: 'Created Date',
        rowKey: 'createdate',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: false,
        enableDelete: false,
        enableSort: false,
      },
      {
        colKey: 'createdby',
        default: 'Created by',
        rowKey: 'createdby',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: false,
        enableDelete: false,
        enableSort: false,
      },
      {
        colKey: 'lastmodifieddate',
        default: 'Lastmodified date',
        rowKey: 'lastmodifieddate',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: false,
        enableDelete: false,
        enableSort: false,
      },
      {
        colKey: 'lastmodifiedby',
        default: 'Lastmodified by',
        rowKey: 'lastmodifiedby',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: false,
        enableDelete: false,
        enableSort: false,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        sortKey: 'status',
        width: '20%',
        customValue: (status) => {
          if (status != 'Suspend') {
            if (status === 'Active') {
              return 'Active';
            } else if (status === 'New Activation') {
              return 'New Activation';
            } else {
              return 'In Active';
            }
          } else {
            return 'Suspend';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '8%',
        isStickyEnd: true,
        isMultipleOption: true
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });

    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Username',
        value_field: 'username',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Email',
        value_field: 'email',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Mobile',
        value_field: 'mobile',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Address',
        value_field: 'address',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Account No',
        value_field: 'acctno',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Partner',
        value_field: 'partner',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
      {
        display_field: 'Plan',
        value_field: 'plan',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'ExpiryDate',
        value_field: 'expirydate',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Mac Address',
        value_field: 'macaddress',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'DID No',
        value_field: 'didno',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'IP Address',
        value_field: 'ipaddress',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field:'Service Area',
        value_field:'serviceArea',
        control_setting:[
          {
            control_type:SearchControlTypeEnum.TEXT,
            display_style:'',
          },
        ],
        data_type:SearchDataTypeEnum.STRING,
      },
      {
        display_field:'OLT',
        value_field:'serviceNetwork',
        control_setting:[
          {
            control_type:SearchControlTypeEnum.TEXT,
            display_style:'',
          },
        ],
        data_type:SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'ONU-Id',
        value_field: 'onuid',
        control_setting: [
          { 
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type:SearchDataTypeEnum.STRING,
      },
      {
        display_field:'Sales Representative',
        value_field:'salesRepresentative',
        control_setting:[
          {
            control_type:SearchControlTypeEnum.TEXT,
            display_style:'',
          },
        ],
        data_type:SearchDataTypeEnum.STRING,
      }
    ];


    this.subscriberNeoGridSettings = {
      showAdvanceSearch: true,
      showFooter: false,
      showPagination: true,
      showFilters: false,
      tableId: this.tableKeyEnum.SUBSCRIBER_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageTotalLength: 0,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageNumber:this.paginationData.pageNumber - 1 ,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Tenant ?',
      deleteMsgBoxDetail: 'Subscriber Name : ${name} ',
    };
    if (this.subscriberNeoGridSettings.serverSideSortPagination && this.subscriberNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.subscriberNeoGridSettings.perPage);
    }
  }

  getSubscriberData(gridSetting: any) {
    let fetchSettingData = this.subscriberNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    if(this.filterObj){
      params["filters"] = this.filterObj.filters
    }
     this.subscriberService.getSubscriberList(params).subscribe((result) => {
      this.subscriberDataForGrid = TenantList;
      this.refreshDataSource(this.subscriberDataForGrid);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.subscriberNeoGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.subscriberDataForGrid));
    if (filterObj && filteredDataForGrid) {
      this.filterObj = filterObj
       console.log("FilterObject",this.filterObj)
       
       filterObj.pageSize =  this.paginationData.rowsPerPage;
       filterObj.page =  this.paginationData.pageNumber;
      this.subscriberService.getAdvanceFilter(filterObj).subscribe((res) => {
        console.warn("res of Adv",res.content)
        this.refreshDataSource(res.content);
        this.totalRecords = res.pageDetails ? res.pageDetails.totalRecords : 0;
        this.subscriberNeoGridSettings.pageTotalLength = this.totalRecords;
      });
    }
    else {
       this.filterObj =null
       this.getListWithParams();
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  setPagination(event) {
    this.paginationData = event;
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getSubscriberData(config);
      }

  onEdit(event) {
    this.setUpdateValueInForm(event);
  }

  onDelete(event) {
    let self = this;
    if (event) {
      this.commonDeleteMsgBox(this.subscriberNeoGridSettings,event,this.delete.bind(this));
    }
  }

  delete(objEvent) {
    let self = this;
    let selected_subscriber_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.subscriberService.deleteSubscriber(selected_subscriber_data.subscriber_id).subscribe((result) => {
        if(result.status === ResponseCode.OK){
          this.tosterservice.success('Tenant is deleted successfully');
          this.show_update = false;
          self.subscriberDataForGrid.forEach(function (item, index) {
            if (item.subscriber_id === selected_subscriber_data.subscriber_id) {
              self.subscriberDataForGrid.splice(index, 1);
            }
          });
          self.refreshDataSource(self.subscriberDataForGrid);
        }
      },
      err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/masters/tenant/details',rowData.id]);
  }

  onAddSubscriberClick() {
    this.router.navigate(['/masters/subscriber/add_subscriber']);

  }

  onAddQuickSubscriberClick() {
    this.router.navigate(['/masters/subscriber/quick_add_subscriber']);
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getSubscriberData(config);
  }

 
  saveUname(udata ,i){
    this.setUpdateValueInForm(udata);
  }

  ngOnDestroy() {
   if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
    this.searchService.serverFilterObj = null;
  }

  closePopUpRefreshData()
  {
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getSubscriberData(config);
  }

getMethodValue(){
  let myobj= {
    filters:[
      {
        filterColumn: "status",
        filterDataType: "boolean",
        filterOperator: "equalto",
        filterValue: this.sharingdataService.filterStatusName
     }
    ]
  }
  this.applyFilter(myobj);
  }
}
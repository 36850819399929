import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { ChangeStatusService } from './change-status.service';
import * as alertify from 'alertifyjs';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../../../../../../src/app/auth/token-storage-service';
import { ActivePlanListService } from '../subscriber-tables/active-plan/active-plan.service';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss']
})
export class ChangeStatusComponent implements OnInit {
  
  @Input()custId : any;
  changeStatus:any = [{
    text:'',
    value:''
  }]
  insertChangeStatusForm = new FormGroup({
    changedStatus : new FormControl('', [Validators.required]),
    currentStatus : new FormControl(''),
    remarks : new FormControl('', [Validators.required])
  });
  public changeStatusData : any;
  currentStatus : any;
  customerStatus:string;
  submitBtnDisable:boolean=false;
  activePlanTableData:any;
  constructor(private customModalService: CustomModalService,
    private changeStatusService : ChangeStatusService,
    private cdRef : ChangeDetectorRef,
    private tosterservice : ToastrService,
    private tokenservice:TokenStorageService,
    private activePlanListService:ActivePlanListService

    ) { 
      // this.customerStatus =  JSON.parse(this.tokenservice.getCustomerStatus());
      // this.submitBtnDisable= (this.customerStatus == "NewActivation") ? true : false ;
    }

  ngOnInit() {
    this.getChangeStatus();
    this.getActivePlanData();
  }

  getChangeStatus() {
    this.changeStatusService.getChnageStatus(this.custId).subscribe(result => {
      this.changeStatusData = result.data;
      this.currentStatus = result.data.currentStatus;
      this.changeStatus = result.data.changedStatus;
    })
  }
  onClickSaveForm() {
    if (this.insertChangeStatusForm.valid === true) {
      let self = this;
      alertify.confirm("Change Status","Are You Sure You want to Change Status ?",
      function() {
        self.insertChangeStatusOnDb();
      },
      function() {
        
      });
    } else {
      this.insertChangeStatusForm.markAllAsTouched();
    }
  }

  insertChangeStatusOnDb() {
    if (this.insertChangeStatusForm.valid === true) {
      let formatedInputData:any = this.insertChangeStatusForm.value;
      formatedInputData.custId = this.custId;
      this.changeStatusService.insertChangestatus(formatedInputData).subscribe(result => {
          this.cdRef.detectChanges();
          if (result.responseCode !== 200 && result.responseMessage) {
            this.tosterservice.error(result.responseMessage);
          } else {
            this.tosterservice.success('Change Status has been Updated successfully');
            this.customModalService.close('change-status',result);
          }
        },
        err => {
          this.tosterservice.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  closeModal(id:string){
    this.customModalService.close(id);
    this.insertChangeStatusForm.reset();
  }

  getActivePlanData() {
    let obj=    {
      custId: this.custId,
      purchaseType: "plan"
    }
    this.activePlanListService.getPlanListForActivation(obj).subscribe(result => {
      this.activePlanTableData = result.data;
      
      if(this.activePlanTableData.length > 0) {
        this.submitBtnDisable= true; 
      }else{
        this.submitBtnDisable= false; 
      }
      
    });
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { Autosize } from 'ng-autosize';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { ErrorComponent } from './pages/error/error.component';
import { AppInterceptor } from './app.interceptor';
import { LoginService } from './pages/login/login.service';
import { AppGuard } from './app.guard';
import { HttpModule } from '@angular/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap';
import { EnvServiceProvider } from '../env.service.provider';
import  *  as  version  from  './layout/sidebar/version.json';
import { CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

const APP_PROVIDERS = [AppConfig, LoginService, AppGuard];

import {
  MatDatepickerModule,
  MatNativeDateModule,
  DateAdapter,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
  MatDialogRef,
  MatSnackBarModule,
  MatSnackBar,
  MatTabGroup,
  MatTabsModule,
  MatFormFieldModule
} from "@angular/material";

 //import {  } from '@angular/material/tabs'; 


import { DateFormat } from "./services/date-format";
import { AddStateComponent } from './pages/masters/add-state/add-state.component';
import { CityComponent } from './pages/masters/city/city.component';
import { GridComponent } from './components/grid/grid.component';
import { AddCityComponent } from './pages/masters/add-city/add-city.component';
import { MasterFormsModule } from './pages/masters/master_forms.module';
import { PlanManagementModule } from './pages/plan_management/plan_management.module';
import { RadiusModule } from './pages/radius/radius.module';
import { NetworkManagementModule } from './pages/network-management/network-management.module';
import { CustomerCareModule } from './pages/customer-care/customer-care.module';
import { HRModule } from './pages/hr/hr.module';

import { PaymentMidpageComponent } from './components/payment-midpage/payment-midpage.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';

import { environment } from '../environments/environment';
import { TokenStorageService } from './auth/token-storage-service';
import { OperationsModule } from './pages/operations/operations.module';
// import { GenericQuickcaseComponent } from './components/generic-quickcase/generic-quickcase.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule,OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";



@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, ErrorComponent, Autosize, PaymentMidpageComponent,PaymentStatusComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule, 
    HttpClientModule,
    HttpModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
    NgbModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule, 
    CommonModule,
    RadiusModule,
    MasterFormsModule,
    PlanManagementModule,
    NetworkManagementModule,
    CustomerCareModule,
    HRModule,
    OperationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
   ], 
  entryComponents: [],
  providers: [
    APP_PROVIDERS,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor, 
      multi: true,
    },
    { provide: DateAdapter, useClass: DateFormat },
    {
      provide: MatDialogRef, //New Obj Add
      useValue: {}
    },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' },
    EnvServiceProvider
    ],
  exports:[],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
}


import { AfterViewInit, ViewEncapsulation, Component, ElementRef, OnInit, Input, ViewChild, Optional, Inject,TemplateRef,} from '@angular/core';
import { CustomModalService } from '../../../components/custom-modal';
import { SubscriberDetailsService } from "./subscriber-details.service";
import _ from 'underscore';
import { TroubleTicketsDetailsService } from './subscriber-tables/trouble-tickets-details/trouble-tickets-details.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import * as alertify from 'alertifyjs';
  import { animate, state, style, transition, trigger } from '@angular/animations';
  import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
  import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
  import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivePlanComponent } from './subscriber-tables/active-plan/active-plan.component';
import { PlanHistoryComponent } from './subscriber-tables/plan-history/plan-history.component';
import { PurchasesComponent } from './subscriber-tables/purchases/purchases.component';
import { ChargesListComponent } from './subscriber-tables/charges-list/charges-list.component';
import { PaymentsComponent } from './subscriber-tables/payments/payments.component';
import { FuturePlansComponent } from './subscriber-tables/future-plans/future-plans.component';
import { ChangePlanComponent } from './change-plan/change-plan.component';
import { ReversePaymentComponent } from './reverse-payment/reverse-payment.component';
import { ToastrService } from 'ngx-toastr';
import { ValueAddedServicesComponent } from './subscriber-tables/value-added-services/value-added-services.component';


export interface DialogPassData {
  id: number;
  moduleName:string
}

import { NetworkSessionComponent } from './subscriber-tables/network-session/network-session.component';
import { HostListener } from '@angular/core';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { AuditComponent } from '../../radius/audit/audit.component';
import { AppConfig } from '../../../app.config';
import { PurchasePlanComponent } from './purchase-plan/purchase-plan.component';
import { PurchaseAddonComponent } from './purchase-addon/purchase-addon.component';
import { TokenStorageService } from '../../../../../src/app/auth/token-storage-service';
import { PaginationData } from '../../../core/modal/pagination-data';



declare let jQuery: any;
declare let $:any;


// import { Input } from 'hammerjs';
@Component({
  selector: 'app-subscriber-details',
  templateUrl: './subscriber-details.component.html',
  styleUrls: ['./subscriber-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})



export class SubscriberDetailsComponent implements OnInit, AfterViewInit {
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  navTabActiveFlag: boolean = false;
  @ViewChild(ActivePlanComponent, { static: false }) activePlan: ActivePlanComponent;
  @ViewChild(FuturePlansComponent, { static: false }) future: FuturePlansComponent;
  @ViewChild(PlanHistoryComponent, { static: false }) planHistory: PlanHistoryComponent;
  @ViewChild(PurchasesComponent, { static: false }) purchases: PurchasesComponent;
  @ViewChild(ChargesListComponent, { static: false }) charge: ChargesListComponent;
  @ViewChild(PaymentsComponent, { static: false }) payment: PaymentsComponent;
  @ViewChild(ChangePlanComponent, { static: false }) changePlan: ChangePlanComponent;
  @ViewChild(PurchasePlanComponent, { static: false }) purchasePlan: PurchasePlanComponent;
  @ViewChild(PurchaseAddonComponent, { static: false }) purchaseAddOnPlan: PurchaseAddonComponent;
  @ViewChild(ValueAddedServicesComponent, { static: false }) valueAdded: ValueAddedServicesComponent;
  @ViewChild(ReversePaymentComponent, { static: false }) rollbackPayment: ReversePaymentComponent;
  @ViewChild(NetworkSessionComponent, { static: false }) networkSession: NetworkSessionComponent;
  @ViewChild(AuditComponent, { static: false }) audit: AuditComponent;
  @ViewChild(ValueAddedServicesComponent, { static: false }) ValueAddedServicesref: ValueAddedServicesComponent
  @ViewChild('lastserviceReqNo',{static: false}) lastserviceReqNoRef: TemplateRef<any>;
  @ViewChild('statusWrapper',{static: false}) statusWrapperRef: TemplateRef<any>;

  dataSource = new MatTableDataSource();
  $menuSection: any;
  backEndImage:string;
  operation_type: any;
  el: ElementRef;
  private ngbRef: NgbModalRef;
  public paginationData: PaginationData;
  ui_id: string;
  mobileOtp: any = null;
  tableKeyEnum = TableKeyEnum;
  currentFocus: any;
  openChangeQosPopup: any = false;
  openNetworkDetailsPopup: any = false;
  caseDetail: any
  custId: any;
  allColumns: any[] = [];
  auditId: any;
  rad
  disconnectRemark: string;
  service: string;
  submtted: boolean = false;
  partnerNameInput: string;
  collectorName: string;

  partnerId: number;
  aadhaar_OTP:number
  neogridsetting = {}
  client_id :string
  cust_id:string;
  aadharverified:boolean = false;
  default_pincode_value:string;
  isImage:boolean=false;
  imagePath:string;
  backendImageUrl:string;
  searchData:string;

  insertsubscriberdetails = new FormGroup({
    aadhar: new FormControl(''),
  });
 
 
  

  public sub: any = null;
  operationTypeList=[
    {
      label:'Basic Details'
        
  },
    
    {label:'Service Request Details'},

    {label :'Machine Details'}
   

    
  ]

  rotateOnuFlag: boolean = false;
  openChangeExpiryPopup: boolean;
  openChangeStatusPopup: boolean;
  openChangeVoiceDetailsPopup: boolean;
  openBasicDetailsPopup: boolean;
  openAddressDetailsPopup: boolean;
  openContectDetailsPopup: boolean;
  openReverseChargePopup: boolean;
  openApplyChargePopup: boolean;
  openRecordPaymentPopup: boolean;
  openChangePasswordPopup: boolean;
  openResetPasswordPopup: boolean;
  openCPEPasswrdPopup: boolean;
  openCPEResetPasswordPopup: boolean;
  liveUserStatus: boolean;
  subscriberBasicDetails: any = {
    macAddressModelList: [],
    planList: [],
    outstanding:''
  };
  openResetMacPopup: boolean;
  openManageMacPopup: boolean;
  openManageVlanPopup: boolean;
  openReversePaymentPopup: boolean;
  openUpdatePaymentPopup: boolean;
  openChangeQuotaPopup: boolean;
  openActivePalns: boolean;
  openTroubleTicketsDetails: boolean;
  openFuturePlans: boolean;
  openPalnHistory: boolean;
  openPurchases: boolean;
  openPayments: boolean;
  openCharges: boolean;
  openValueAddded: boolean;
  openOtherDetails: boolean;
  openCustomerDocumentDetails: boolean;
  openCustomerDocumentPopup: boolean;
  openChangePlanPopup: boolean;
  openPurchasePlanPopup: boolean;
  openPurchaseIpPopup: boolean;
  openPurchaseValueAddedChargePopup: boolean;
  openIptvPopup: boolean;
  openAllocationIpPopup: boolean;
  openChangeOtherDetailsPopup: boolean;
  openAdjustPaymentDetailsPopup: boolean;
  openReplaceIPAddressPopup: boolean;
  openRollbackIPAddressPopup: boolean;
  openChangeIPExpiryPopup: boolean;
  openReleaseIPAddressPopup: boolean;
  openPurchaseAddOnPlan: boolean;
  currentOutstanding: any;
  macAddressTooltip: string = '-';
  firstMacAddress: string = '-';
  activePlanNameTooltip: string = '-';
  firstActivePlanName: string = '-';
  docId: any;
  public loginService: LoginService;
  openOtherUpdates: boolean;
  openotherCommunication: boolean;
  openRadiusAudit: boolean;
  openLocationDetailPopup: boolean;
  openChangePartnerPopup: boolean;
  openChangeRepPopup: boolean;
  openChangePayCollectorPopup: boolean;
  openSuspensionPopup:boolean;
  openBlockStatusPopup:boolean;
  state: string = 'default';
  stateOnu: string = 'default';
  voiceProvisionDetailsFrom: FormGroup;
  custPassDetails: any;
  activeuserflag: boolean;
  Connectivity: boolean;
  isDisabled:boolean=false;
  salesrepNameInput: any;
  salesrepId: any;
  name: any;
  updatetabDisable:boolean=false;
  backbutton :boolean=false;
  logdata:any;
  iptvScreenFrom: string;
  dataForCustomer: any;

  customerPropertyData = [ 
    {
      cutid:'39848',
      RECNNR:'A-102',
      RECNBEG:'Orian Square',
      RECNENDABS: 'UAE',
      RECNTXT: 'Owned',
      usagetype: 'Office',
      area: '50000',
      contract:'CT-ORS-1001'
    },{
      cutid:'39848',
      RECNNR:'Y-513',
      RECNBEG:'Gutron Parks',
      RECNENDABS: 'UAE',
      RECNTXT: 'Leased',
      usagetype: 'Office',
      area: '10000',
      contract:'CT-GP-1117'
    },{
      cutid:'39844',
      RECNNR:'E-1208',
      RECNBEG:'Serenity Heights',
      RECNENDABS: 'Saudi Arabia',
      RECNTXT: 'Leased',
      usagetype: 'House',
      area: '18000',
      contract:'CT-PW-2222'
    },{
      cutid:'39844',
      RECNNR:'305/Y',
      RECNBEG:'Legacy Manor',
      RECNENDABS: 'Saudi Arabia',
      RECNTXT: 'Owned',
      usagetype: 'House',
      area: '30000',
      contract:'CT-ORS-1001'
    }
  ]
  constructor(el: ElementRef,
    private ngbModalService: NgbModal,
    private customModalService: CustomModalService,
    private subscriberDetailsService: SubscriberDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    loginservice: LoginService,
    private tosterservice: ToastrService,
    private sharingdataService:DataSharingServiceService,
     private tokenService:TokenStorageService,
    // private loadingService: LoadingService,
    private appConfig: AppConfig,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<SubscriberDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,

    private troubleTicketsDetailsService: TroubleTicketsDetailsService
  ) 
  {
    this.el = el;
    this.loginService = loginservice;
    this.logdata=null;  
    this.sharingdataService.getInfo().subscribe(info => {
    if(info){
      this.logdata =  info;
    }else{
      this.logdata=null;
    }
    
     })
     this.backendImageUrl=this.appConfig.config.serverUrl;
  }
  

 ismobileback():boolean{
  let w = document.documentElement.clientWidth;
  let breakpoint = 992;
  if(w<breakpoint) return true
  else return false;
 }
 onddlchange(){
    // $(function(){
    //     $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
    //       console.log('aaa=>', $(this));
    //       if (!$(this).next().hasClass('show')) {
    //         $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    //       }
    //       var $subMenu = $(this).next(".dropdown-menu");
    //       $subMenu.toggleClass('show'); 			// appliqué au ul
    //       $(this).parent().toggleClass('show'); 	// appliqué au li parent
      
    //       $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
    //         $('.dropdown-submenu .show').removeClass('show'); 	// appliqué au ul
    //         $('.dropdown-submenu.show').removeClass('show'); 		// appliqué au li parent
    //       });
    //       return false;
    //     });
    // });
  }
    

 

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.isDisabled=false;
      this.updatetabDisable=true;
      this.custId = id;
      this.getSubscriberBasicDetails();
    }
    if(this.data && this.data.id){
      this.isDisabled=true;
      this.custId = this.data.id;
      this.getSubscriberBasicDetails();
    }
    this.operation_type = this.operationTypeList.find(res=>res.label == 'BasicDetails')
    this.onOperationTypeChange('Basic Details')
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_DOCUMENT_VIEW,AclConstants.OPERATION_CUSTOMER_ALL))
    {
      this.navTabActiveFlag = true;
    }
    else{
      this.navTabActiveFlag = false;
      this.openotherCommunication = true
    }

    if(this.custId){
      this.dataForCustomer = this.customerPropertyData.filter(res => res.cutid == this.custId)
    }
   
    this.voiceProvisionDetailsFrom = new FormGroup({
      voiceProvisionFlag: new FormControl('', [Validators.required]),
      voiceProvision: new FormControl(''),
      custId: new FormControl(''),
      remark: new FormControl('', [Validators.required])
    });

   
    
    this.filedSetCollapse();
    this.getTroubleTicketsDetailsData();
    
    this.backbutton = this.ismobileback();
    window.onresize = ()=>{
      this.backbutton = this.ismobileback()
    }
   
  }
  popupNameList: any = [
    {
      ui_id: 'change-expiry',
      key_name: 'openChangeExpiryPopup'
    },
    {
      ui_id: 'basic-details',
      key_name: 'openBasicDetailsPopup'
    },
    {
      ui_id: 'address-details',
      key_name: 'openAddressDetailsPopup'
    },
    {
      ui_id: 'contect-details',
      key_name: 'openContectDetailsPopup'
    },
    {
      ui_id: 'change-voice-details',
      key_name: 'openChangeVoiceDetailsPopup'
    },
    {
      ui_id: 'change-status',
      key_name: 'openChangeStatusPopup'
    },
    {
      ui_id: 'change-plan',
      key_name: 'openChangePlanPopup'
    },
    {
      ui_id: 'purchase-plan',
      key_name: 'openPurchasePlanPopup'
    },
    {
      ui_id: 'change-qos',
      key_name: 'openChangeQosPopup'
    },
    {
      ui_id: 'reset-mac',
      key_name: 'openResetMacPopup'
    },
    {
      ui_id: 'manage-mac',
      key_name: 'openManageMacPopup'
    },
    {
      ui_id: 'manage-vlan',
      key_name: 'openManageVlanPopup'
    },
    {
      ui_id: 'network-details',
      key_name: 'openNetworkDetailsPopup'
    },
    {
      ui_id: 'apply-charge',
      key_name: 'openApplyChargePopup'
    },
    {
      ui_id: 'reverse-charge',
      key_name: 'openReverseChargePopup'
    },
    {
      ui_id: 'record-payment',
      key_name: 'openRecordPaymentPopup'
    },
    {
      ui_id: 'reverse-payment',
      key_name: 'openReversePaymentPopup'
    },
    {
      ui_id: 'update-payment',
      key_name: 'openUpdatePaymentPopup'
    },
    {
      ui_id: 'change-quota',
      key_name: 'openChangeQuotaPopup'
    },
    {
      ui_id: 'change-password',
      key_name: 'openChangePasswordPopup'
    },
    {
      ui_id: 'reset-password',
      key_name: 'openResetPasswordPopup'
    },
    {
      ui_id: 'cpe-password',
      key_name: 'openCPEPasswrdPopup'
    },
    {
      ui_id: 'reset-cpe-password',
      key_name: 'openCPEResetPasswordPopup'
    },
    {
      ui_id: 'customer-document',
      key_name: 'openCustomerDocumentPopup'
    },
    {
      ui_id: 'purchase-ip',
      key_name: 'openPurchaseIpPopup'
    },
    {
      ui_id: 'value-added-charge',
      key_name: 'openPurchaseValueAddedChargePopup'
    },
    {
      ui_id: 'allocation-ip',
      key_name: 'openAllocationIpPopup'
    },
    {
      ui_id: 'iptv-details',
      key_name: 'openIptvPopup'
    },
    {
      ui_id: 'change-other-details',
      key_name: 'openChangeOtherDetailsPopup'
    },
    {
      ui_id: 'adjust-payment-details',
      key_name: 'openAdjustPaymentDetailsPopup'
    },
    {
      ui_id: 'replace-ip-address',
      key_name: 'openReplaceIPAddressPopup'
    },
    {
      ui_id: 'rollback-ip-address',
      key_name: 'openRollbackIPAddressPopup'
    },
    {
      ui_id: 'change-ip-expiry',
      key_name: 'openChangeIPExpiryPopup'
    },
    {
      ui_id: 'release-ip-address',
      key_name: 'openReleaseIPAddressPopup'
    },
    {
      ui_id: 'purchase-addon-plan',
      key_name: 'openPurchaseAddOnPlan',
    },
    {
      ui_id: 'location-datails',
      key_name: 'openLocationDetailPopup',
    },
    {
      ui_id: 'change-partner',
      key_name: 'openChangePartnerPopup',
    },
    {
      ui_id: 'change-representative',
      key_name:'openChangeRepPopup'
    },
    {
      ui_id: 'change-collector',
      key_name:'openChangePayCollectorPopup'
    },
    {
      ui_id: 'extend-suspension',
      key_name:'openSuspensionPopup'
    },
    {
      ui_id: 'block-subscriber',
      key_name:'openBlockStatusPopup'
    }

  ]
  

  voiceProvision: any = {
    voiceProvision: '',
    remark: ''
  }

  voiceProvisionDetailsList: any = [
    {
      voiceProvision: 'Yes',
      voiceProvisionFlag: true
    },
    {
      voiceProvision: 'No',
      voiceProvisionFlag: false
    }
  ];

  f() {
    return this.voiceProvisionDetailsFrom.controls;
  }

  openViewPasswordModel() {
    this.subscriberDetailsService.getCustomerPasswordDetails(this.custId).subscribe((res) => {
      if (res.responseCode == 200) {
        this.custPassDetails = res.data;
        $("#viewPasswordPopup").modal('show');
      }
    }, err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR);
    });
  }

  closePasswordPopup() {
    $("#viewPasswordPopup").modal('hide');
  }

  openVoiceProvisionModel() {
    this.subscriberDetailsService.getVoiceProvisionDetails(this.custId).subscribe((result) => {
      this.voiceProvisionDetailsFrom.get("voiceProvisionFlag").setValue(result.data.voiceProvisionFlag);
      this.voiceProvisionDetailsFrom.get("custId").setValue(result.data.custId);
    });
    $("#voiceProvisionDetailsPopup").modal('show');

  }

  openCreateTicketModel() {

  }

  validateForm() {
    if (!this.voiceProvisionDetailsFrom.valid) {
      this.tosterservice.error("Please fill all the details!");
      Object.keys(this.voiceProvisionDetailsFrom.controls).forEach(field => {
        const control = this.voiceProvisionDetailsFrom.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      this.saveVoiceProvisionDetails();
    }
  }

  saveVoiceProvisionDetails() {
    if (this.voiceProvisionDetailsFrom.valid === true) {
      this.closeForm();
      $("#voiceProvisionDetailsPopup").modal('hide');
      this.subscriberDetailsService.saveVoiceProvisionDetails(this.voiceProvisionDetailsFrom.value).subscribe((res) => {
        this.tosterservice.success("Voice provision details saved successfully");

      });
    } else {

      this.tosterservice.error("Form is not valid!");
    }
  }

  closeForm() {
    this.voiceProvisionDetailsFrom.reset();
  }

  showVerificationPopup(verified) {
    this.submtted = false;
    this.subscriberDetailsService.MobileVerification(this.custId).subscribe(res => {
      if (res.responseCode !== 200) {
        this.tosterservice.error(res.responseMessage);
      } else {
        if (res.data == 'success') {
          this.tosterservice.success(res.responseMessage);
          this.ngbModalService.open(verified, { size: 'md' });
        }
      }
    })
  }
VerifiedOTP() {
    this.submtted = true;
    if (this.mobileOtp) {
      this.subscriberDetailsService.OTPverifiedService(this.custId, this.mobileOtp).subscribe(res => {
        if (res.responseCode === 200) {
          if (res.data === 'success') {
            this.mobileOtp = null;
            this.tosterservice.success(res.responseMessage);
            this.ngOnInit();
            this.ngbModalService.dismissAll();
          }
        } else {
          this.tosterservice.error(res.responseMessage);
        }
      })
    } else {
      this.tosterservice.error("OTP is required");
    }
  }

  AadharshowVerificationPopup(aadharverified) {
    this.submtted = false;
    this.subscriberDetailsService.getAadhaarOTP(this.subscriberBasicDetails.aadhar).subscribe((res)=>{
      if (res.responseCode == 200) {
        this.tosterservice.success(res.responseMessage);
        this.client_id = res.data.client_id
        this.ngbModalService.open(aadharverified, { size: 'md' });
      } else {
        this.tosterservice.error(res.responseMessage);
      }
    })
  }

  getAadharOTP(id?){
    if(id){
      this.customModalService.open(id);
    }
    this.subscriberDetailsService.getAadhaarOTP(this.subscriberBasicDetails.aadhar).subscribe((res)=>{
      ////console.log("Aadhaar otp res",res);
      this.aadhaar_OTP = null
      if(res.responseCode !== 200){
        if(res.responseMessage==null){
          this.tosterservice.error("Try again later")
        }
        else{
          this.tosterservice.error(res.responseMessage)
        }
      }
      else{
       
        this.client_id = res.data.client_id;
        this.tosterservice.success("OTP Sent Successfully")
      }
    });
  }

  VerifyaadharOtp(id){
    console.log("demo",this.aadhaar_OTP, this.client_id,this.subscriberBasicDetails.id)
  
    if(this.aadhaar_OTP && this.client_id && this.subscriberBasicDetails.id){
      let obj = {
        "client_id": this.client_id,
        "customer_id": this.subscriberBasicDetails.id,
        "mobile_number" : "",
         "otp" : this.aadhaar_OTP
      }
     
      this.subscriberDetailsService.verifyOtpAadhaar(obj).subscribe((res)=>{
       if(res.responseCode !== 200){
        this.tosterservice.error(res.responseMessage)
       }
       else{
        
        if(res.data.imageName){
          this.imagePath= this.backendImageUrl + 'alticonImages/CustAadhaarImage/' + res.data.imageName;
          
        }
        else{
          
          this.imagePath = "../../../../assets/img/no_image.jpg"
        }
        this.aadharverified = true;
        this.tosterservice.success("Aadhar Verrified Successfully")
        this.ngbModalService.dismissAll();
       this.getSubscriberBasicDetails();
       }
      });
    }
    else{
      this.tosterservice.error("Please Enter OTP")
    }
  }
  CloseVerify(id:string){
    this.customModalService.close(id, { close_from: "close_button" });
  }


  closeModal() {
    this.submtted = false;
    this.mobileOtp = "";
    this.ngbModalService.dismissAll();
  }
  openModal(id: string) {
    this.popupNameList.forEach(element => {
      if (id === element.ui_id) {
        this[element.key_name] = true;
        this.ui_id = element.ui_id;
      } else {
        this[element.key_name] = false;
      }
    });

    setTimeout(() => {
      this.customModalService.open(id);
      if(id === 'change-plan')
      {
        this.changePlan.ngOnInit();
      }
      if (id === 'purchase-plan') {
        //cthis.purchasePlan.ngOnInit();
      }
      if(id === 'purchase-addon-plan')
      {
        this.purchaseAddOnPlan.ngOnInit();
      }
      if (id === 'reverse-paymenchange-plan') {
        this.ngOnInit()
        this.rollbackPayment.ngOnInit();
      }
    }, 500);
  }

  openPurchaseNewScreen()
  {
    this.router.navigate(['/masters/subscriber/details/purchase-plan', this.custId]);
  }

  openPurchaseAddonPlanNewScreen()
  {
    this.router.navigate(['/masters/subscriber/details/purchase-addon-plan', this.custId]);
  }

  closeChangeQosPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangeQosPopup = false;
  }

  closeNetworkDetailsPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openNetworkDetailsPopup = false;
  }
  closeApplyChargePopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openApplyChargePopup = false;
  }

  closeReverseChargePopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openReverseChargePopup = false;
  }

  closeChangeExpiryPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangeExpiryPopup = false;
  }

  closeRecordPaymentPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openRecordPaymentPopup = false;
  }

  closeReversePaymentPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openReversePaymentPopup = false;
  }

  closeUpdatePaymentPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openUpdatePaymentPopup = false;
  }

  closeChangeQuotaPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangeQuotaPopup = false;
  }

  closeResetMacPopup(data: any) {
    this.getSubscriberBasicDetails();
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openResetMacPopup = false;
  }

  closeChabgeVoicDetailPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangeVoiceDetailsPopup = false;
  }

  closeManageMacPopup(data: any) {
    this.getSubscriberBasicDetails();
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openManageMacPopup = false;
  }

  closeManageVlanPopup(data: any) {
    this.getSubscriberBasicDetails();
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openManageVlanPopup = false;
  }

  closeChangePasswordPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangePasswordPopup = false;
  }

  closeResetPasswordPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openResetPasswordPopup = false;
  }

  closeCPEPasswrdPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openCPEPasswrdPopup = false;
  }

  closeCPEResetPasswordPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openCPEResetPasswordPopup = false;
  }

  closeContectDetailsPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openContectDetailsPopup = false;
  }

  closeBasicDetailsPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    if(data.data.aadharVerified){
      
      this.imagePath= this.backendImageUrl + 'alticonImages/CustAadhaarImage/' +data.data.aadhar +".jpeg";
    }
    else
    {
      
      this.imagePath = "../../../../assets/img/no_image.jpg";
    }
    this.openBasicDetailsPopup = false;
  }

  closeAddressDetailsPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openAddressDetailsPopup = false;
  }

  closeChabgeStatusPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangeStatusPopup = false;
  }

  closeCustomerDocumentPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openCustomerDocumentPopup = false;
  }

  closeChangePlanPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangePlanPopup = false;
  }
  
  closePurchasePlanPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openPurchasePlanPopup = false;
  }

  closePurchaseIpPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openPurchaseIpPopup = false;
  }

  closePurchaseValueAddedService(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openPurchaseValueAddedChargePopup = false;
  }
  
  closeAllocationIpPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openAllocationIpPopup = false;
  }

  closeIptvPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openIptvPopup = false;
    this.iptvScreenFrom = undefined;
  }

  closeChangeOtherDetailsPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangeOtherDetailsPopup = false;
  }

  closeAdjustPaymentDetailsPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openAdjustPaymentDetailsPopup = false;
  }

  closeReplaceIPAddressPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openReplaceIPAddressPopup = false;
  }

  closeRollbackIPAddressPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openRollbackIPAddressPopup = false;
  }

  closeChangeIPExpiryPopup(data: any) {
    ////console.log("change ip method::", data)
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangeIPExpiryPopup = false;
  }

  closeReleaseIPAddressPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openReleaseIPAddressPopup = false;
  }

  closePurchaseAddOnPlanPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openPurchaseAddOnPlan = false;
  }

  closeLocationDetailsPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openLocationDetailPopup = false;
  }

  closeChangePartnerPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangePartnerPopup = false;
  }

  closeChangeRepPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangeRepPopup = false;
  }

  closeChangePayCollectorPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openChangePayCollectorPopup = false;
  }
  
  closeSuspensionPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openSuspensionPopup = false;
  }

  closeBlockstatusPopup(data: any) {
    this.setSubscriberBasicDetailsOnPopupClose(data);
    this.openBlockStatusPopup = false;
  }

  setSubscriberBasicDetailsOnPopupClose(data: any) {
    
    if (data && data.close_from === "close_button") {
     
    } else {
      if (data.data && data.data.basicDetails) {
        this.subscriberBasicDetails = data.data.basicDetails;
      } else if (data.data && data.data.customersBasicDetails) {
        this.subscriberBasicDetails = data.data.customersBasicDetails;
      }
      else {
        this.subscriberBasicDetails = data.data;
      }
      this.partnerNameInput = this.subscriberBasicDetails.partnerName;
      this.collectorName = this.subscriberBasicDetails.collectorname;
      ////console.log("subscriberBasicDetails", this.subscriberBasicDetails);
    }
  }


  onLedger() {
    this.router.navigate(['/masters/subscriber/details/ledger', this.custId]);
  }
  onActiveTab() {
    this.activePlan.ngOnInit();
  }
  onFutureTab() {
    this.future.ngOnInit();
  }
  onPlanHistoryTab() {
    this.planHistory.ngOnInit();
  }
  onPurchasesTab() {
    this.purchases.ngOnInit();
  }
  onPaymentTab() {
    this.payment.ngOnInit();

  }
  onChargesTab() {
    this.charge.ngOnInit();
  }
  onValueAddedService() {
    this.valueAdded.ngOnInit();
  }

  onFuturePlansClick() {
    this.openFuturePlans = true;
  }
  onPlanHistoryClick() {
    this.openPalnHistory = true;
  }
  onPurchasesClick() {
    this.openPurchases = true;
  }
  onPaymentsClick() {
    this.openPayments = true;
  }
  onChargesClick() {
    this.openCharges = true;
  }
  onValueAddedClick() {
    this.openValueAddded = true;
  }

  onOtherCommunicationClick() {
    this.openotherCommunication = true;
  }
  OnNetworkSessionsclick() {
    // this.networkSession.ngOnInit(); /**Extra call Remove:Prod */
  }
  OnRadiusAudit(){
    this.openRadiusAudit=true;
  }
  onOperationTypeChange(event){
    
    this.operation_type = event;
    //console.log("operationtype:",this.operation_type)
    
    if(this.operation_type == 'customer Document'){
      this.openOtherDetails = true;
    }
  }
  scroll(prm) {
    let el=document.getElementById(prm);
    console.log(el);
    el.scrollIntoView({ behavior: 'smooth' });
  }  

  reciveDocId(event) {
    if (event != null) {
      this.docId = event;
      this.openCustomerDocumentDetails = true
      this.openOtherDetails = false;
    } else {
      this.docId = null;
      this.openCustomerDocumentDetails = false;
      this.openOtherDetails = true;
    }
  }

  recivedMessage(event) {
    if (event === "Close") {
      this.openCustomerDocumentDetails = false;
      this.openOtherDetails = true;
    } else if (event != "Close") {
      this.tosterservice.success(event);
      this.openCustomerDocumentDetails = false;
      this.openOtherDetails = true;
    } else {
      this.openOtherDetails = false;
      this.openCustomerDocumentDetails = true;
    }
  }

  onAddCustomerDocumentClick() {
    this.docId = null;
    this.openCustomerDocumentDetails = true
    this.openOtherDetails = false;
  }

  onBackToCustomerDocment() {
    this.docId = null;
    this.openCustomerDocumentDetails = false;
    this.openOtherDetails = true;
  }


  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.type == 'email' && ctrlValue.which === 32) {
      ctrlValue.preventDefault();
    }
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }

  ngAfterViewInit() {
          
    this.allColumns.forEach((col) => {
      if (col.colKey === 'lastserviceReqNo') {
        col.customControls = [this.lastserviceReqNoRef];
      } 
      if (col.colKey === 'status') {
        col.customControls = [this.statusWrapperRef];
      }
    });
    // let menu_list = [
    //   {
    //     ui_id: 'change-expiry',
    //     title: 'Change Expiry',
    //   },
    //   {
    //     ui_id: 'basic-details',
    //     title: 'Basic Details'
    //   },
    //   {
    //     ui_id: 'address-details',
    //     title: 'Address Details',
    //   },
    //   {
    //     ui_id: 'contect-details',
    //     title: 'Contact Details'
    //   },
    //   {
    //     ui_id: 'change-voice-details',
    //     title: 'Change Voice Details'
    //   },
    //   {
    //     ui_id: 'change-status',
    //     title: 'Change Status'
    //   },
    //   {
    //     ui_id: 'change-plan',
    //     title: 'Change Plan'
    //   },
    //   {
    //     ui_id: 'purchase-plan',
    //     title: 'Purchase Plan'
    //   },
    //   {
    //     ui_id: 'change-qos',
    //     title: 'Change Qos'
    //   },
    //   {
    //     ui_id: 'reset-mac',
    //     title: 'Reset mac'
    //   },
    //   {
    //     ui_id: 'manage-mac',
    //     title: 'Manage Mac'
    //   },
    //   {
    //     ui_id: 'network-details',
    //     title: 'Network Details'
    //   },
    //   {
    //     ui_id: 'apply-charge',
    //     title: 'Apply Charge'
    //   },
    //   {
    //     ui_id: 'reverse-charge',
    //     title: 'Reverse Charge'
    //   },
    //   {
    //     ui_id: 'record-payment',
    //     title: 'Record Payment'
    //   },
    //   {
    //     ui_id: 'reverse-payment',
    //     title: 'Reverse Payment'
    //   },
    //   {
    //     ui_id: 'update-payment',
    //     title: 'Update Payment'
    //   },
    //   {
    //     ui_id: 'change-quota',
    //     title: 'Change Quota'
    //   },
    //   {
    //     ui_id: 'change-password',
    //     title: 'Change Password'
    //   },
    //   {
    //     ui_id: 'reset-password',
    //     title: 'Reset Password'
    //   },
    //   {
    //     ui_id: 'cpe-password',
    //     title: 'CPE Password'
    //   },
    //   {
    //     ui_id: 'reset-cpe-password',
    //     title: 'Reset CPE Password'
    //   },
    //   {
    //     ui_id: 'customer-document',
    //     title: 'Customer Document',
    //   },
    //   {
    //     ui_id: 'purchase-ip',
    //     title: 'Purchase IP'
    //   },
    //   {
    //     ui_id: 'allocation-ip',
    //     title: 'Allocation IP'
    //   },
    //   {
    //     ui_id: 'iptv-details',
    //     title: 'Iptv Details'
    //   },
    //   {
    //     ui_id: 'change-other-details',
    //     title: 'Other Details'
    //   },
    //   {
    //     ui_id: 'adjust-payment-details',
    //     title: 'Adjustments Payment Details'
    //   },
    //   {
    //     ui_id: 'replace-ip-address',
    //     title: 'Replace IP Adresss'
    //   },
    //   {
    //     ui_id: 'rollback-ip-address',
    //     title: 'Rollback IP Address',
    //   },
    //   {
    //     ui_id: 'change-ip-expiry',
    //     title: 'Change Plan Expiry'
    //   },
    //   {
    //     ui_id: 'release-ip-address',
    //     title: 'Release IP',
    //   },
    //   {
    //     ui_id: 'purchase-addon-plan',
    //     title: 'Purchase add on Plan',
    //   },
    //   {
    //     ui_id: 'change-partner',
    //     title: 'Change Partner',
    //   }
    // ];


    // let find = this.createAutocomplete(document.getElementById("txtSearchMenuAutoComplete"), menu_list);
 //console.log("find--",find)
    
    

  }

  getSubscriberBasicDetails() {

    let subscriber_id = this.custId;
    
    this.subscriberDetailsService.getSubscriberBasicDetails(subscriber_id).subscribe((result) => {
      if (result.responseCode !== 200 && result.responseMessage) {
        this.tosterservice.error(result.responseMessage);
      } else {
        //console.log(result);
        if(result.data.aadharVerified){
          this.imagePath= this.backendImageUrl + 'alticonImages/CustAadhaarImage/' +result.data.aadhar +".jpeg";
        }
        else
        {
          this.imagePath = "../../../../assets/img/no_image.jpg";
        }
        this.activeuserflag = result.data.activeuserFlag; 
        this.Connectivity = result.data.connectivity;
        this.partnerId = result.data.partnerId;
        localStorage.setItem('Partner_Type',result.data.partnerCommisionType)
        if (result.data.activePlanName && result.data.activePlanName.length) {
          //key change :activePlanName from result.data.planList && planstage=’ACTIVE’
          // this.service = result.data.planList.service; //old 
           this.service = result.data.service; //New 
          this.partnerNameInput = result.data.partnerName;
          this.collectorName = result.data.collectorname;
          this.salesrepNameInput = result.data.salesRepName;
          this.partnerId = result.data.partnerId;
          this.salesrepId = result.data.salesRepId;
          
          this.firstActivePlanName=result.data.activePlanName ? result.data.activePlanName: '-' //change below code to single key
          this.activePlanNameTooltip=result.data.activePlanName ? result.data.activePlanName: '-'
          
          /** Changed Key Replace -Remove after Testing*/

          // let findActivePlan: any = _.where(result.data.planList, { planstage: 'ACTIVE' });
          // if (findActivePlan && findActivePlan.length) {
          //  let activePlans = _.pluck(findActivePlan, 'planName');
          //  this.activePlanNameTooltip = activePlans.join(',');
           
          //  console.log("active",activePlans)
          //  console.log("activeNmaetooltip",this.activePlanNameTooltip)
          //  this.firstActivePlanName = _.first(activePlans);
          // } else {
          //  this.firstActivePlanName = '-';
          // }


          //findActivePlan.
        }
            this.firstMacAddress=result.data.macAddress;
            this.macAddressTooltip=result.data.macAddress;
            //**Key Change :remove after Testing*/
       // if (result.data.macAddressModelList && result.data.macAddressModelList.length) {
        //   let macAddress = _.pluck(result.data.macAddressModelList, "macAddress");
        //   this.macAddressTooltip = macAddress.join(",");
        //   this.firstMacAddress = _.first(macAddress);
        // } else {
        //   this.macAddressTooltip = '-';
        //   this.firstMacAddress = '-';
        // }

        this.name = result.data.name;
        this.subscriberBasicDetails = result.data;
        this.currentOutstanding = this.subscriberBasicDetails.outStanding;
        //outstanding old vlaue- affected 10 places
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
      }
    });
  }
 

  filedSetCollapse() {
    (function ($) {
      $.fn.collapsible = function (options) {
        var settings = $.extend(
          {
            collapsed: false,
            animation: true,
            speed: 'medium',
          },
          options
        );

        this.each(function () {
          var $fieldset = $(this);
          var $legend = $fieldset.children('legend');
          var isCollapsed = $fieldset.hasClass('collapsed');

          $legend.click(function () {
            collapse($fieldset, settings, !isCollapsed);
            isCollapsed = !isCollapsed;
          });

          // Perform initial collapse.
          // Don't use animation to close for initial collapse.
          if (isCollapsed) {
            collapse($fieldset, { animation: false }, isCollapsed);
          } else {
            collapse($fieldset, settings, isCollapsed);
          }
        });
      };
       /**
       * Collapse/uncollapse the specified fieldset.
       * @param {object} $fieldset
       * @param {object} options
       * @param {boolean} collapse
       */
        function collapse($fieldset, options, doCollapse) {
          let $container = $fieldset.find('div');
          if (doCollapse) {
            if (options.animation) {
              $container.slideUp(options.speed);
            } else {
              $container.hide();
            }
            $fieldset.removeClass('expanded').addClass('collapsed');
          } else {
            $container.show();
          }
          $fieldset.removeClass('collapsed').addClass('expanded');
        }
      })($);
    
    $('fieldset.collapsible').collapsible();
    }

  createAutocomplete(inp: any, arr: any) {
    //console.log("inp--->",inp)
    //console.log("arr--->",arr)
    
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/

    /*execute a function when someone writes in the text field:*/
    let self = this;
    inp.addEventListener("input", function (e) {
      var a, b, i, val = this.value;
      /*close any already open lists of autocompleted values*/
      self.closeAllLists();
      if (!val) {
        $(".autocomplete-items").remove();
        return false;
      }
      self.currentFocus = -1;

      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        var pos = arr[i].title.toUpperCase().indexOf(val.toUpperCase());
        /*check if the item starts with the same letters as the text field value:*/
        if (pos > -1) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.innerHTML = arr[i].title.substr(0, pos);
          b.innerHTML += "<strong>" + arr[i].title.substr(pos, val.length) + "</strong>";
          b.innerHTML += arr[i].title.substr(pos + val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + arr[i].title + "'>";
           if(arr[i].title){
            b.innerHTML += "<input type='hidden' value='" + null + "'>";
           }
          /*execute a function when someone clicks on the item value (DIV element):*/
          let findUiId = arr[i].ui_id;
          b.addEventListener("click", function (e) {
            /*insert the value for the autocomplete text field:*/
            let item_name = this.getElementsByTagName("input")[0].value;
            inp.value = item_name;
            
            setTimeout(() => {
              inp.value=null
            }, 500);
            // open modal by ui id
            self.openModal(findUiId);
            /*close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
            self.closeAllLists(null, inp);
            // let link_id = item_name.toLowerCase().replace(/ /g, "_") + "_menu_link";
            // let menu_item_el = $("#ip_address_menu_link");
            // if (menu_item_el) {
            //   $("#ip_address_menu_link")[0].click();
            // }
          });
          a.appendChild(b);
        }

      }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (e) {
      
      // var autocomplete_list_el = document.getElementById(this.id + "autocomplete-list");
      let autocomplete_list_el1 = document.getElementById(this.id + "autocomplete-list");
      let autocomplete_list_el: any;
      if (autocomplete_list_el1) {
        autocomplete_list_el = autocomplete_list_el1.getElementsByTagName("div");
      }
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        self.currentFocus++;
        /*and and make the current item more visible:*/
        self.addActive(autocomplete_list_el);
      } else if (e.keyCode == 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        self.currentFocus--;
        /*and and make the current item more visible:*/
        self.addActive(autocomplete_list_el);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (self.currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (autocomplete_list_el) autocomplete_list_el[self.currentFocus].click();
          
        }
      }
    });

    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
      self.closeAllLists(e.target, inp);      
    });

  }

  removeActive(autocomplete_list_el) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (let i = 0; i < autocomplete_list_el.length; i++) {
      autocomplete_list_el[i].classList.remove("autocomplete-active");
    }
  }

  addActive(autocomplete_list_el) {
    /*a function to classify an item as "active":*/
    if (!autocomplete_list_el) return false;
    /*start by removing the "active" class on all items:*/
    this.removeActive(autocomplete_list_el);
    if (this.currentFocus >= autocomplete_list_el.length) { this.currentFocus = 0; }
    if (this.currentFocus < 0) { this.currentFocus = (autocomplete_list_el.length - 1); }
    /*add class "autocomplete-active":*/
    autocomplete_list_el[this.currentFocus].classList.add("autocomplete-active");
  }

  closeAllLists(elmnt?: any, inp?: any) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    
    let autocomplete_items_el = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < autocomplete_items_el.length; i++) {
      
      if (elmnt != autocomplete_items_el[i] && elmnt != inp) {
        autocomplete_items_el[i].parentNode.removeChild(autocomplete_items_el[i]);
      }
    }
  }

  rotate() {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
  }
  Onupower: any
  lastUpdated: any
  rotateOnu() {
    this.rotateOnuFlag = true;
    this.stateOnu = (this.stateOnu === 'default' ? 'rotated' : 'default');
    let obj = {
      custid: this.custId,
      serialnumber: this.subscriberBasicDetails.onuId
      //this.subscriberBasicDetails.onuid old
    }
    this.subscriberDetailsService.Refreshonu(obj).subscribe((res) => {
      this.Onupower = res.data.power;
      this.lastUpdated = res.data.lastModifiedDate
    });
  }

  rotatedvas(){
    this.ValueAddedServicesref.getValueAddedService();
  }
  rotatedcharges(){
    this.charge.getChargesListData();
  }
  rotatepayment(){
    this.payment.getPaymentsData(null);
  }
  rotatepurchase(){
    this.purchases.getPurchasesData();
  }
  rotateplanhistory(){
    this.planHistory.getFuturePlansData();
  }
  rotatefuture(){
    this.future.getFuturePlansData();
  }
  rotateactive(){
    this.activePlan.getActivePlanData();
  }


  onDissconnectSub(Disconnect) {
    this.ngbModalService.open(Disconnect, { size: 'lg' });
  }
  getTroubleTicketsDetailsData() {
    this.troubleTicketsDetailsService.getTroubleTicketsDetailsList(this.custId).subscribe(result => {
      this.caseDetail = result.data;
    });
  }
  onDisconnectSave() {
    let reqObj = {
      userId: this.subscriberBasicDetails.id,
      remark: this.disconnectRemark,
      reqType: 'username'
    }
    this.subscriberDetailsService.disconnectUser(reqObj).subscribe((res) => {

      if (res.responseCode === 200) {
        this.tosterservice.success("User is Successfully Disconnected from now")
      }
    });
    this.disconnectRemark = ''
    this.ngbModalService.dismissAll();
  }
  onComonentReload() {

  }
  onNetworkClick() {
    alertify.confirm("Fetch ONU status", "Are you sure you want fetch ONU status ?",
      function () {

      },
      function () {

      });
  }
  backToView() {
   
    // this.router.navigate(['/masters/subscriber']); //Orignal Code
   if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
     // this.sharingdataService.setInfo(this.logdata);/**Removed:-*/
    }else{
      this.router.navigate(['/masters/subscriber']);
    }
  }

  OnCaseRefresh(data) {
    this.caseDetail = data
  }

  closeDialog(){
    console.log("You are clear text")
    this.dialogRef.close();
  }
   
  PrintCustomerApplicationDoc(content)
  {
    this.ngbRef = this.ngbModalService.open(content, { windowClass: 'xxlModal', size:'xl' });
  }

  onOpenIPTVDetails(id: string){
    this.iptvScreenFrom= 'subscriber';
    this.openModal(id);
  }
  ReceivedData(data){
    if(data){
      this.getSubscriberBasicDetails();
    //  this.tokenService.setCustomerStatus(this.subscriberBasicDetails.status);
    }
  }
 
 

  }

import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { TokenStorageService } from '../../../../auth/token-storage-service';
import { SubscriberDetailsService } from '../subscriber-details.service';

@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss']
})
export class UnitListComponent implements OnInit {
  @ViewChild('unitNo', { static: false }) unitNoWrapperRef: TemplateRef<any>;
  @ViewChild('BUILDING', { static: false }) BUILDINGWrapperRef: TemplateRef<any>;
  @ViewChild('UNITTYPE', { static: false }) UNITTYPEWrapperRef: TemplateRef<any>;
  @ViewChild('USAGETYPE', { static: false }) USAGETYPEWrapperRef: TemplateRef<any>;

  @Input() dataList;

  custId: any
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  unitsNeoGridSettings = {};
  subscriptions: Subscription[] = [];
  configPath;
  baseURL;
  purchasesTableData : any;
  planMapId: string;

  // dataList = [{
  //   unitNo: 1,
  //   building: 'Building 1',
  //   contractNo: '0000180910000',
  //   area: 'Area 1',
  //   unitType: 'Rent',
  //   usageType: '-'
  // }]

  constructor(
    private tokenStorageService:TokenStorageService,
    private modalService: NgbModal,
    private dashboardservice: SubscriberDetailsService,
    private tosterService: ToastrService,
  ) {  }

  ngOnInit() {
    this.setGridSetting();
    this.getPurchasesData();
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'SMENR') {
          col.customControls = [this.unitNoWrapperRef];
      }
      if (col.colKey === 'SGENR') {
        col.customControls = [this.BUILDINGWrapperRef];
      }
      if (col.colKey === 'SUPPLEMENTINFO') {
        col.customControls = [this.UNITTYPEWrapperRef];
      }
      if (col.colKey === 'XMBEZ') {
        col.customControls = [this.USAGETYPEWrapperRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'SMENR',
        default: 'Unit No.',
        rowKey: 'SMENR',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'SGENR',
        default: 'Building',
        rowKey: 'SGENR',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'RECNNR',
        default: 'Contract No.',
        rowKey: 'RECNNR',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
       {
        colKey: 'MEASVALUE',
        default: 'Area',
        rowKey: 'MEASVALUE',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'SUPPLEMENTINFO',
        default: 'Unit Type',
        rowKey: 'SUPPLEMENTINFO',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'XMBEZ',
        default: 'Usage Type',
        rowKey: 'XMBEZ',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
    ];

    this.unitsNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.PURCHASESLIST_LIST,
      perPage: 10,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getPurchasesData() {
    // let dataList: any = [];
    // if(this.custId){
    //   this.dashboardservice.getBusinessPartnerContractData(this.custId).subscribe(res =>{
    //     if(res.responseCode == 200){
    //       dataList = res.data.ET_UNIT.item;
          this.refreshDataSource(this.dataList);
    //     }else{
    //       this.tosterService.error(res.responseMessage);
    //     }
    //   },error => {
    //     if (error.responseCode !== 200) {
    //       this.tosterService.error(error.error.responseMessage);
    //     }
    //   }
    //   )
    // }
  }

  open(paydet, data: any) {
    this.modalService.open(paydet, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    this.planMapId = data.planmapid;
  }
}

import { Component, OnInit, TemplateRef, ViewChild ,Optional,Inject} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../services/ibase-service';
import { Customer, Project } from '../model/Customer';
import { ToastrService } from 'ngx-toastr';
import { SearchService } from '../../../services/search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SubscriberLeasedServiceService } from '../subscriber-leased-line/service/subscriber-leased-service.service';
import { TroubleTicketDetailsService } from './trouble-ticket-details/trouble-ticket-details.service';
import { ServiceAreaServices } from '../../../services/service_area/service_area.service';
import { MatDialog ,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DataSharingServiceService } from '../../../../../src/app/pages/data-sharing-service.service';


export interface DialogPassData {
id: number;
}


import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material';
import { GenericContactDetailsComponent } from '../generic-contact-details/generic-contact-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { LoginService } from '../../login/login.service';
import { ProjectList } from '../model/machineData';
@Component({
  selector: 'app-subscriber-leased-line-details',
  templateUrl: './subscriber-leased-line-details.component.html',
  styleUrls: ['./subscriber-leased-line-details.component.scss']
})
export class SubscriberLeasedLineDetailsComponent extends AbstractBaseAddUpdateComponent<Customer> implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  //@ViewChild(GenericContactDetailsComponent, { static: false })  genericContactDetails: GenericContactDetailsComponent;
  @ViewChild(GenericContactDetailsComponent, { static: false })
  private genericContactDetails: GenericContactDetailsComponent;
  neoTableSetting: any = {};
  updatebtnHideInDoc:Boolean=false;

  custId: any;
  dataGrid: any[] = [];
  subleasedlineneogridsetting: any = {};
  searchSettings: any = {};
  customer: any = {};
  allColumns: any[] = [];
  leasedLineId: number;
  dataSource = new MatTableDataSource([]);
  caseDetail: any;
  subscriptions: Subscription[] = [];
  //caseDetail: any;
  name: any;
  tablekeyEnum = TableKeyEnum;
  req_message: string = "This field is required";
  subscriberLeasedForm: FormGroup;
  openTroubleTicketsDetails: boolean;
  isDisabled:boolean=false;
  public serviceAreaList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  contactlist: any[] = [];
  AclClassConstants = AclClassConstants;
	AclConstants = AclConstants;

  constructor(
    private toaster: ToastrService,
    private searchService: SearchService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private subscriberLeasedService: SubscriberLeasedServiceService,
    private troubleTicketDetailsService: TroubleTicketDetailsService,
    private serviceAreaServices: ServiceAreaServices,
    private genericDataSharingService: DataSharingServiceService,
    public dialog:MatDialog,
    public ngbService:NgbModal,
    public loginService: LoginService,
    public dialogRef: MatDialogRef<SubscriberLeasedLineDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,

  ) {

    super(toaster, router)
  }

  ngOnInit() {
    this.init();
    
    this.leasedLineId = this.rowId;
    this.getTroubleTicketsDetailsData();
    this.getServiceAreaList();
    const filterSubscription = this.subscriberLeasedService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
    
  }



  applyFilter(filterObj: any) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filteredDataForGrid) {
      this.subscriberLeasedService.getAdvanceFilterSearchData(filterObj).subscribe((res) => {
        this.refreshDataSource(res.TechnicalContactList);
      });
    }
  }

  refreshDataSource(data: any) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  init() {
    let id = +this.activatedRoute.snapshot.paramMap.get('id');
   
    if (id) {
      this.isDisabled=false;
      this.isUpdateComponent = true;
      let catModal = this.getNewModal();
      catModal.id = id;
      this.rowId = id;
      this.getById(id);
      localStorage.setItem("leasedId", this.rowId);
      this.setData(this.getNewModal());
      this.setValidation();
      
      return;
    }
    if(this.data && this.data.id){
      this.isDisabled=true; 
      this.isUpdateComponent = true;
      let catModal = this.getNewModal();
      catModal.id = this.data.id;
      this.rowId = this.data.id;
      this.getById(this.data.id);
      localStorage.setItem("leasedId", this.rowId);
      this.setData(this.getNewModal());
      this.setValidation();
      return;
    }

    this.authRole();
    this.setData(this.getNewModal());
    this.setValidation();

  }


  // getTableColumn() {
  //   let self = this;

  //   this.allColumns = [
  //     {
  //       colKey: 'index',
  //       default: 'ID',
  //       rowKey: 'id',
  //       width: '1%',
  //       isFilterAllow: false,
  //       isNumber: true,
  //     },
  //     {
  //       colKey: 'techName',
  //       default: 'Technical Contact Name',
  //       rowKey: 'techName',
  //       width: '10%',
  //       isNumber: false,
  //       isFilterAllow: true,
  //       enableEdit: true,
  //       enableDelete: true,
  //     },
  //     {
  //       colKey: 'techNumber',
  //       default: 'Technical Contact Phone',
  //       rowKey: 'techNumber',
  //       width: '10%',
  //       isNumber: false,
  //       isFilterAllow: true,
  //       enableEdit: true,
  //       enableDelete: true,
  //     },
  //     {
  //       colKey: 'actionCol',
  //       default: 'Action',
  //       rowKey: 'id',
  //       enableEdit: false,
  //       enableDelete: false,
  //       isNumber: false,
  //       width: '1%',
  //       isStickyEnd: true,
  //     }
  //   ];
  //   this.allColumns.forEach(element => {
  //     element.isFilterAllow = false;
  //   });
  //   this.getTableSetting();
  // }
  // getTableSetting() {

  //   let subleasedlineneogridsetting = {
  //     showFooter: false,
  //     showPagination: false,
  //     // perPage: 10,
  //     // pageSizeOptions: [5, 10, 15, 20, 50, 100],
  //     // sort: true,
  //     // serverSideSortPagination: true,
  //     // pageTotalLength: 0,
  //     // serverSideFilter: true,
  //     //defaultSortCol: '',
  //     //defaultSortDirection: 'desc',
  //     tableId:this.tablekeyEnum.LEASE_LINE_CONTACT_DETAIS,
  //     enableMultiDelete: true,
  //     columns: this.allColumns,
  
  //   };
  //   this.subleasedlineneogridsetting = subleasedlineneogridsetting
  //   return subleasedlineneogridsetting
  // }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
     
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      
    });
  }

  getServiceAreaList() {
    this.serviceAreaServices.getServiceAreaList().subscribe((result) => {
      this.serviceAreaList = result.dataList;
    });
  }
  getTroubleTicketsDetailsData() {
    let caseData:any = {};
    caseData.id = this.leasedLineId;
    this.troubleTicketDetailsService.getTroubleTicketsDetailsList(caseData).subscribe(result => {
      this.caseDetail = result.data;
      //console.log("leased data",this.caseDetail);
    });
    ////console.log(this.caseDetail);
  }
  getService(): IBaseService<Customer> {
    return this.subscriberLeasedService;
  }
  setData(data: Customer) {
    let id = +this.activatedRoute.snapshot.paramMap.get('id');
    let project = ProjectList.filter(x=>x.id == id)[0];
    if(project){
      this.customer = project;
      this.name = this.customer.projectName;
    }
    let temp:any={};
    //this.contactlist = this.customer.leaslinedContactDetail;
    if(this.customer.leaslinedContactDetail){
      //myObj.map(({ id }) => id);
      // 
      //console.log("Leaseline contact data",this.customer.leaslinedContactDetail);
      
      // this.customer.leaslinedContactDetail.forEach((res) =>{
      
      //   if(res){
      //     temp.techconname = res.techconname;
      //     temp.techconphone = res.techconphone;
      //     this.contactlist.push(temp);
      //     //console.log("contactdtaa inside",this.contactlist);
      //   }
      // })
      
      this.contactlist = this.customer.leaslinedContactDetail;
      if(this.contactlist.length>0)
      this.genericContactDetails.refreshDataSource(this.contactlist)
    }

    //this.genericContactDetails.refreshDataSource(this.contactlist);
  }
  getData(): Customer {
    return this.customer;
  }
  getModuleHomeUrl() {
    
if(this.isUpdateComponent){
  return '/masters/suscriberLeased';
}
if(!this.isUpdateComponent){
  return '/masters/suscriberLeased-details/'+  this.responseData.data.id ;
}
  
  }
  onEditUrl() {
    this.router.navigate(['/masters/suscriberLeased-details/', this.responseData.data.id]);
  }
  getActiveRoute() {
    return this.activatedRoute;
  }
  getNewModal() {
    return new Customer();
  }
  getFormGroup(): FormGroup {
    return this.subscriberLeasedForm;
  }
  setValidation() {
    this.subscriberLeasedForm = new FormGroup({
      projectName: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      latitude: new FormControl('',  Validators.required),
      longitude: new FormControl('',  Validators.required),
      address: new FormControl('', Validators.required),
      projectType: new FormControl('', Validators.required),
      totalBlocks: new FormControl('', Validators.required),
      totalUnits: new FormControl('', Validators.required),
      ownerOfTheProject: new FormControl('', Validators.required),
      blueprintDocument: new FormControl('', Validators.required),
      amenitiesInfo: new FormControl('', Validators.required),
      contactperson: new FormControl('', Validators.required),
      contactnumber: new FormControl(''),
    
      smsflag: new FormControl(true),
      emailflag: new FormControl(true),
      area:new FormControl(null),
    })
  }
  get f() {
    return this.subscriberLeasedForm.controls;
  }
  getId(): any {
    return this.customer.id;
  }

  OnCaseRefresh(data) {
    this.caseDetail = data
  }

  formSubmit() {
    
    if(this.genericContactDetails.TechnicalContactList)
    //console.log('aaaa =>', this.genericContactDetails.TechnicalContactList);
    this.customer.leaslinedContactDetail = this.genericContactDetails.TechnicalContactList;
    if (this.validateForm()) {
      this.onSubmit();
    }
  }
  onBack() {
   // this.router.navigate(['masters/suscriberLeased']);
   if(this.genericDataSharingService.lastAuditUrl == this.router.url){
    this.router.navigate(['/operations/audit']);
    this.genericDataSharingService.lastAuditUrl = undefined;
    }else{
    this.router.navigate(['masters/suscriberLeased']);
    }

  }

  onTroubleTicketDetails() {
    this.openTroubleTicketsDetails = true;
  }

  onAddSlotClick() {
    this.router.navigate(['/masters/subscriberLeased/property-detail', this.rowId]);
  }
  numberOnly(event: { which: any; keyCode: any; }): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  changeSMS(event: { target: { checked: any; }; }) {
    if (event.target.checked) {
      this.subscriberLeasedForm.get('smsflag').setValue(true);
    } else {
      this.subscriberLeasedForm.get('smsflag').setValue(false);
    }
  }
  changeEmail(event: { target: { checked: any; }; }) {
    if (event.target.checked) {
      this.subscriberLeasedForm.get('emailflag').setValue(true);
    } else {
      this.subscriberLeasedForm.get('emailflag').setValue(false);
    }
  }
  closeDialog(){
    this.dialogRef.close();
    }

  openDocument(docUpload) {
    this.ngbService.open(docUpload,{ size: 'lg' });
  }

  getGenericDataService(){
    return this.genericDataSharingService
    }

  tabClick(event){
    if(event.index===3){
      this.updatebtnHideInDoc=true;
    }else{
      this.updatebtnHideInDoc=false;
    }
  }
}


import { Route } from '@angular/compiler/src/core';
import { Component, OnInit, TemplateRef, ViewChild,Inject, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../../src/app/core/enum/searchType.enum';
import { NotificationService } from '../notification/notification.service';
import { TableKeyEnum } from '../../../../app/core/enum/table-key.enum';
import {MatTableDataSource, throwMatDialogContentAlreadyAttachedError} from '@angular/material';
import { OldAbstractBaseListComponent } from '../../../../app/components/base/old-abstract-base-list-component';
import { AppConfig } from '../../../app.config';
import { SearchService } from '../../../services/search.service';
import { MatDialog ,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DataSharingServiceService } from '../../data-sharing-service.service';

export interface DialogPassData {
  id: number;
}


@Component({
  selector: 'app-add-notification-group',
  templateUrl: './add-notification-group.component.html',
  styleUrls: ['./add-notification-group.component.scss']
})
export class AddNotificationGroupComponent extends OldAbstractBaseListComponent  implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  allColumns: any[] = [];
  smsproviderList=[];
  sort:boolean=false;
  searchSettings=[];
  NotificationGridSettings: any  = {};
  req_message = "This field is required.";
  tableKeyEnum = TableKeyEnum;
  dataSource = new MatTableDataSource([]);
  notiGroupForm:FormGroup;
  statusList=[];
  notifiGroupId:any;
  show_update:boolean=false;
  selected:any;
  groupData:any;
  selectedProvider:any;
  statusListSelect:any;
  notigroupid:any;
  isDisabled:boolean=false;

  constructor(private router:Router,
    private route : ActivatedRoute,
  private notificationservice:NotificationService,
  private toastrService:ToastrService,
  appConfig: AppConfig,
  private searchService:SearchService,
  private sharingdataService: DataSharingServiceService,
  public dialog:MatDialog,
  public dialogRef: MatDialogRef<AddNotificationGroupComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) {

    super(searchService)
   }

  ngOnInit() {
  this.setDefaultPageSize(this.tableKeyEnum.GROUP_NOTIFICATION)
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.isDisabled=false;
     this.notifiGroupId = id;
     localStorage.setItem('ngId',this.notifiGroupId)
     this.show_update = true;
     this.NotificationGroupgetDataByID(this.notifiGroupId);
     this.getListWithParams()
    }

    // if (this.data && this.data.id) {
       
    //     this.notifiGroupId = this.data.id;
    //     localStorage.setItem('ngId',this.notifiGroupId)
    //     this.show_update = true;
    //     this.isDisabled=true;
    //     this.NotificationGroupgetDataByID(this.notifiGroupId);
    //     this.getListWithParams()
    //     //this.notiGroupForm.disable();
    //    }
    



   
    this.formLoad();
    this.getGroupStatus();
    this.setGridSetting();
    this.getSMSProviderData();
     
  }

  formLoad(){
    this.notiGroupForm = new FormGroup({
      name: new FormControl('', Validators.required),
      status: new FormControl('',Validators.required),
      smsProvider:new FormControl('',Validators.required)
    })
  }
  getGroupStatus(){
    this.notificationservice.commonStatus().subscribe(
      result=> {
        this.statusList=result.dataList;
      }
    )
  }

  getSMSProviderData(){
    this.notificationservice.getSMSProvider().subscribe(
      result=> {
        this.smsproviderList=result.dataList;
      }
    )
  }
  
  validateForm(){
    ////console.log("form Data-->"+JSON.stringify(this.notiGroupForm.value))
    if (!this.notiGroupForm.valid) {
      this.toastrService.error("Please fill all the details!");
      Object.keys(this.notiGroupForm.controls).forEach(field => {
        const control = this.notiGroupForm.get(field);            
        if (control instanceof FormControl) {             
            control.markAsTouched({ onlySelf: true });
        } 
      });
    }else {
        if (this.notifiGroupId === undefined) {
        this.insertGroupNotification();
      } else {
        this.updateGroupNotification();
      }
    }
  }

  backToViewList() {
   // this.router.navigate(['/operations/notification-group']);/**Old Code */
   if(this.sharingdataService.lastAuditUrl == this.router.url){
    this.router.navigate(['/operations/audit']);
    this.sharingdataService.lastAuditUrl = undefined;
    }else{
    this.router.navigate(['/operations/notification-group']);
    }

  }
  
  insertGroupNotification(){
    let formdata=this.notiGroupForm.value;
    this.show_update=false;
    this.notificationservice.saveGroupNotification(formdata).subscribe(
      result=>{

        if(result.responseCode === 200){
          this.show_update=true;
          this.toastrService.success('Data Saved successfully.', 'Success');
          this.notifiGroupId=result.data.id;
          localStorage.setItem('ngId',result.data.id);
          this.router.navigate(['/operations/notification-group/notification-group-add',result.data.id]);
        }
        else{
          this.toastrService.error(result.responseMessage);
        }
        
        
      }
    )
  }

  
  updateGroupNotification(){
    if(this.notiGroupForm.valid ===true){
      let formData=this.notiGroupForm.value;
      formData['id']= this.notifiGroupId;
      this.notificationservice.updateGroupNotificationData(formData).subscribe(
        result=>{
          if(result.responseCode === 200){
            this.toastrService.success('Data Updated successfully.', 'Success');
            this.router.navigate(['/operations/notification-group']);
            localStorage.removeItem('ngId');
          }else{
            this.toastrService.error(result.responseMessage);
          }
        }
      )
    }
  }
  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.NotificationGroupgetDataByParamID(config);
 }
  NotificationGroupgetDataByID(notificationGroupId){
    this.notificationservice.NotificationGroupgetDataByID(notificationGroupId).subscribe(
      result=>{
        this.notiGroupForm.get('name').setValue(result.data.name);
        this.notiGroupForm.get('status').setValue(result.data.status);
        this.notiGroupForm.get('smsProvider').setValue(result.data.smsProvider);
      }
    )
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        // enableSort: true,
      },
      {
        colKey: 'event',
        default: 'event',
        rowKey: 'event_name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        // enableSort: true, 
      },
      {
        colKey: 'Category',
        default: 'Category',
        rowKey: 'category',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        // enableSort: true, 
      },

      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        customValue: (status) => {
          if (status.equalsIgnoreCase() === 'Active') {
            return 'Active';
          } else {
            return 'Inactive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        // enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        // enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: true,
        enableDelete: true,
        isFilterAllow: false,
        // enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Template Name',
        value_field: 'templatename',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Template Type',
        value_field: 'templatetype',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'Active',
            value_field: 'Y',
          },
          {
            display_field: 'In Active',
            value_field: 'N',
          },
        ],
      },
    ];

    this.NotificationGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.GROUP_NOTIFICATION,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Notification ?',
      deleteMsgBoxDetail: 'Notification Name :${name} ',
    };
    
  }

  onAddNotificationClick(){
  localStorage.setItem('notificationGroupId',this.notifiGroupId);
  this.router.navigate(['/operations/notification/add-notification']);
  }

  NotificationGroupgetDataByParamID(gridSetting:any){
    let fetchSettingData = this.NotificationGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
   params['notifiGroupId']= this.notifiGroupId 
    this.notificationservice.NotificationGroupgetDataByParamID(params).subscribe(result=>{
      this.groupData=result.dataList;
      this.refreshDataSource(this.groupData);
      this.totalRecords = result.totalRecords;
      this.NotificationGridSettings.pageTotalLength = this.totalRecords;
   
    })
  }
  refreshDataSource(data) {
    if (data) {
       this.dataSource = new MatTableDataSource(data);
     }
   }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
    });
  }

setPagination(event){
    let config = { page: event, order: this.orderingData, search: this.searchData};
    this.NotificationGroupgetDataByParamID(config);
  }

  onDelete(event){
   this.notificationservice.NotificationdeleteID(event.RowData).subscribe(result=>
      {
        if(result.responseCode === 200){
          this.toastrService.error("Notification Deleted Successfully","Success");
          this.NotificationGroupgetDataByParamID(this.notifiGroupId);
        }else if(result.responseCode !==200){
          this.toastrService.error(result.responseMessage)
        }
      })

  }
  onEdit(eventObj){
    localStorage.setItem('notificationGroupId',this.notigroupid);
    this.router.navigate(['operations/notification/add-notification',eventObj.RowData.id]);
  }

  closeDialog(){
    this.dialogRef.close();
   }


}

import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource, SELECT_PANEL_VIEWPORT_PADDING } from '@angular/material';
import { Subscription } from 'rxjs';
import { CustomModalService } from '../../../../../../../src/app/components/custom-modal/custom-modal.service';
import { TableKeyEnum } from '../../../../../../../src/app/core/enum/table-key.enum';
import { LoginService } from '../../../../../../../src/app/pages/login/login.service';
import { ActivePlanListService } from '../active-plan/active-plan.service';
import { AclConstants } from '../../../../aclOperationConstants';
import { AclClassConstants } from '../../../../aclClassConstants';
import { ActivePlanComponent } from '../active-plan/active-plan.component';
import { BasicDetailsComponent } from '../../basic-details/basic-details.component';
import { BasicDetailsService } from '../../basic-details/basic-details.service';
import { SubscriberDetailsComponent } from '../../subscriber-details.component';
import { ToastrService } from 'ngx-toastr';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-plan-activation',
  templateUrl: './plan-activation.component.html',
  styleUrls: ['./plan-activation.component.scss']
})
export class PlanActivationComponent implements OnInit {
  // @ViewChild('activateWrapper', { static: false }) activeWrapperRef: TemplateRef<any>;
  @ViewChild('planActivationWrapper', { static: false }) planActivationWraRef: TemplateRef<any>;
  @ViewChild('createdate', { static: false }) createDateWrapperRef: TemplateRef<any>;
  @ViewChild('planPrice', { static: false }) planPriceWrapperRef: TemplateRef<any>; 
  @ViewChild('vas', { static: false }) vasWrapperRef: TemplateRef<any>;   
  @ViewChild('SubscriberDetailsComponent', { static: false }) subscriberPlanRef: SubscriberDetailsComponent;
  @ViewChild(ActivePlanComponent, { static: false }) activePlan: ActivePlanComponent;
  @Input() custId: any;
  @Output() getData = new EventEmitter<any>();
  @Output() statusData: any = new EventEmitter();
	
  
  planId: any;
  custplanId: any;
  planType: any = "active";
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  activationPlanNeoGridSettings = { };
  subscriptions: Subscription[] = [];
  openCancelPlanPopup: boolean;
  activePlanTableData: any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  constructor(
    private activePlanListService: ActivePlanListService,
    private toaster:ToastrService,
    public loginService: LoginService,
    ) {
   
     }

  ngOnInit() {
    this.setGridSetting();
    this.getActivePlanData();
  }
  
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
    
      if (col.colKey === 'planactivation') {
        col.customControls = [this.planActivationWraRef];
      }
      if (col.colKey === 'createdate') {
        col.customControls = [this.createDateWrapperRef];
      }
              if (col.colKey === 'planPrice') {
        col.customControls = [this.planPriceWrapperRef];
      } 

      if (col.colKey === 'selectedVasDTOs') {
        col.customControls = [this.vasWrapperRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'entityValue',
        default: 'Plan Name',
        rowKey: 'entityValue',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'purchaseType',
        default: 'Purchase Type ',
        rowKey: 'purchaseType',
        width: '8%',
        isNumber: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'partnerName',
        default: 'partner Name ',
        rowKey: 'partnerName',
        width: '10%',
        isNumber: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'planPrice',
        default: 'Plan Price (Inc.Tax)',
        rowKey: 'planPrice',
        width: '15%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.planPriceWrapperRef],
      }, 
       {
        colKey: 'createdate',
        default: 'Purchase Date',
        rowKey: 'createdate',
        width: '13%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.createDateWrapperRef],
      },  
      {
        colKey: 'selectedVasDTOs',
        default: 'Vas',
        rowKey: 'selectedVasDTOs',
        width: '5%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.vasWrapperRef],
      },
      {
        colKey: 'createdByName',
        default: 'createdBy Name',
        rowKey: 'createdByName',
        width: '12%',
        isNumber: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
     
       {
        colKey: 'planactivation',
        default: 'Action',
        rowKey: 'planactivation',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls: [this.planActivationWraRef],
      }
    ];

    this.activationPlanNeoGridSettings = {
      showFilter:false,
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      pageNumber:1,
      tableId: this.tableKeyEnum.PLAN_ACTIVATION,
      perPage: 5,
      tableHeight:null,
      headerSticky:true,
      verticalScroll:true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getActivePlanData() {
    let obj=    {
      custId: this.custId,
      purchaseType: "plan"
    }
    this.activePlanListService.getPlanListForActivation(obj).subscribe(result => {
      this.activePlanTableData = result.data;
      this.refreshDataSource(this.activePlanTableData);
    });
  }
  
  getcustomerPurchaseActionData(objdata) {
    let obj=    {
      customerId: objdata.customerId,
      activateId: objdata.id, 
      planId: objdata.planId
    }

    let self = this;
      alertify.confirm("Plan Activation", "Are you sure you want to Activate this Plan?",
        function () {
          self.Saveactivationapicall(obj);
        },
        function () {

        });
  
  }

  Saveactivationapicall(obj){
   console.log("obj----",obj)
    this.activePlanListService.getcustomerPurchaseAction(obj).subscribe(result => {
      if(result.responseCode===200){
        this.statusData.emit('Active');
        this.activePlanTableData = result.dataList;
      }else{
        this.toaster.error(result.responseMessage)
      }
    });
  }

}

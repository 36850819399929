import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';

@Component({
  selector: 'app-machine-details',
  templateUrl: './machine-details.component.html',
  styleUrls: ['./machine-details.component.scss']
})
export class MachineDetailsComponent implements OnInit {
  @ViewChild('lastserviceReqNo',{static: false}) lastserviceReqNoRef: TemplateRef<any>;
  @ViewChild('statusWrapper',{static: false}) statusWrapperRef: TemplateRef<any>;
  
  allColumns: any[] = [];
  tableKeyEnum = TableKeyEnum;
  dataSource = new MatTableDataSource([]);
  neogridsetting = {};
  editviewId:any;
  custId:number;

  constructor(private ngbService: NgbModal,
    private CommonListServicesService: CommonListServicesService,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.custId = Number(this.route.snapshot.paramMap.get('id'))
    this.getTableColumn();
  }

  getTableColumn() {
    let self = this;

    let allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'serialNo',
        default: 'Serial No.',
        rowKey: 'serialNo',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
      },
      {
        colKey: 'brand',
        default: 'Equipment Types',
        rowKey: 'brand',
        width: '10%',
        isNumber: false,
      },
      {
        colKey: 'currentCustomerAllocationDate',
        default: 'allocation Date',
        rowKey: 'currentCustomerAllocationDate',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: false,
      },
      // {
      //   colKey: 'status',
      //   default: 'status',
      //   rowKey: 'status',
      //   width: '10%',
      //   isNumber: false,
      // },
      {
        colKey: 'Warranty',
        default: 'Under Warrenty',
        rowKey: 'Warranty',
        width: '10%',
        isNumber: false,
      },
      {
        colKey: 'warrentyStartDate',
        default: 'Warrenty Start Date',
        rowKey: 'warrentyStartDate',
        width: '10%',
        isNumber: false,
      },
      {
        colKey: 'warrentyEndDate',
        default: 'Warrenty End Date',
        rowKey: 'warrentyEndDate',
        width: '10%',
        isNumber: false,
      },
      // {
      //   colKey: 'underContact',
      //   default: 'under Contract',
      //   rowKey: 'underContact',
      //   width: '1%',
      //   isNumber: false,
      // },
      // {
      //   colKey: 'startDtae',
      //   default: 'Contract Start Date',
      //   rowKey: 'startDtae',
      //   width: '10%',
      //   isNumber: false,
      // },
      // {
      //   colKey: 'endDate',
      //   default: 'Contract End Date',
      //   rowKey: 'endDate',
      //   width: '10%',
      //   isNumber: false,
      // },
      // {
      //   colKey: 'currentCustomerReleaseDate',
      //   default: 'allocation End Date',
      //   rowKey: 'currentCustomerReleaseDate',
      //   width: '20%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: false,
      // },
      {
        colKey: 'lastServiceDate',
        default: 'Last Service Date',
        rowKey: 'lastServiceDate',
        width: '10%',
        isNumber: false,
      },
      {
        colKey: 'lastServiceBy',
        default: 'Last Service By',
        rowKey: 'lastServiceBy',
        width: '10%',
        isNumber: false,
      },
      {
        colKey: 'serDueDate',
        default: 'service Due Date',
        rowKey: 'serDueDate',
        width: '10%',
        isNumber: false,
      },
      {
        colKey: 'lastserviceReqNo',
        default: 'Service ReqNo.',
        rowKey: 'lastserviceReqNo',
        width: '10%',
        isNumber: false,
      },
    ];


    this.allColumns = allColumns;

      this.neogridsetting = {
        showAdvanceSearch: false,
        showFooter: false,
        showPagination: true,
        tableId: this.tableKeyEnum.MACHINE_LIST,
        perPage: 10,
        pageNumber:1,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        sort: true,
        defaultSortCol: '',
        defaultSortDirection: 'asc',
        // enableMultiDelete: true,
        columns: this.allColumns,
        deleteMsgBoxTitle: 'Are you sure to delete this Service Area ?',
        deleteMsgBoxDetail: 'Service Area Name :${name} ',
      };

      this.setTableData()
  }

  ngAfterViewInit() {  
    this.allColumns.forEach((col) => {
      if (col.colKey === 'lastserviceReqNo') {
        col.customControls = [this.lastserviceReqNoRef];
      } 
      if (col.colKey === 'status') {
        col.customControls = [this.statusWrapperRef];
      }
    });
  }

  onEdit(event,editviewmode) {
    this.editviewId =  event.caseId
    this.ngbService.open(editviewmode, { size: 'xl' });
  }

  setTableData(){
    // let data = [{
    //   id:85134,
    //   brand:'Lutronic',
    //   name:'eCO2',
    //   installationdate:'7-5-2022',
    //   iswarrenty:'Yes',
    //   warrentyStartDate:'7-5-2023',
    //   warrentyEndDate:'7-5-2029',
    //   underContact:'Yes',
    //   startDtae:'7-5-2022',
    //   endDate:'7-5-2035',
    //   lastServiceDate:'6-10-2022',
    //   lastServiceBy:'Raj Malhotra',
    //   lastserviceReqNo:'TKT-81022',
    //   status:'Not Installed',
    //   serialNo:'SRL-JAN-2023-1',
    //   serDueDate:'5-10-2023'
    // },
    // {
    //   id:85137,
    //   brand:'Ultherapy',
    //   name:'Ultherapy',
    //   installationdate:'2-7-2020',
    //   iswarrenty:'Yes',
    //   warrentyStartDate:'2-7-2020',
    //   warrentyEndDate:'2-7-2025',
    //   underContact:'Yes',
    //   startDtae:'2-8-2022',
    //   endDate:'2-8-2030',
    //   lastServiceDate:'12-1-2023',
    //   lastServiceBy:'yash Patel',
    //   lastserviceReqNo:'TKT-81025',
    //   status:'Installed',
    //   serialNo:'SRL-JAN-2023-48',
    //   serDueDate:'11-12-2023'
    // },{
    //   id:85138,
    //   brand:'Brera',
    //   name:'MED 400',
    //   installationdate:'7-5-2022',
    //   iswarrenty:'Yes',
    //   warrentyStartDate:'4-2-2023',
    //   warrentyEndDate:'5-2-2024',
    //   underContact:'No',
    //   startDtae:'-',
    //   endDate:'-',
    //   lastServiceDate:'4-2-2023',
    //   lastServiceBy:'Rakesh Pandya',
    //   lastserviceReqNo:'TKT-81026',
    //   status:'Installed',
    //   serialNo:'SRL-JAN-2023-56',
    //   serDueDate:'1-2-2023'
    // },{
    //   id:85139,
    //   brand:'Cutera',
    //   name:'Excel V',
    //   installationdate:'-',
    //   iswarrenty:'Yes',
    //   warrentyStartDate:'6-1-2023',
    //   warrentyEndDate:'7-2-2024',
    //   underContact:'No',
    //   startDtae:'5-5-2022',
    //   endDate:'6-5-2023',
    //   lastServiceDate:'4-5-2023',
    //   lastServiceBy:'Harsh Panchal',
    //   lastserviceReqNo:'TKT-81027',
    //   status:'Not Installed',
    //   serialNo:'SRL-JAN-2023-24',
    //   serDueDate:'11-12-2024'
    // },{
    //   id:85143,
    //   brand:'Ballancer',
    //   name:'Ballancer',
    //   installationdate:'12-2-2023',
    //   iswarrenty:'No',
    //   warrentyStartDate:'-',
    //   warrentyEndDate:'-',
    //   underContact:'Yes',
    //   startDtae:'5-5-2022',
    //   endDate:'6-5-2023',
    //   lastServiceDate:'-',
    //   lastServiceBy:'-',
    //   lastserviceReqNo:'TKT-81031',
    //   status:'Not Installed',
    //   serialNo:'SRL-JAN-2023-8',
    //   serDueDate:'1-1-2023'
    // }]
    let data = this.CommonListServicesService.machineData
    let filtereddata = data.filter(ls=>ls.customerId == this.custId)
    this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(filtereddata)));
  }

}

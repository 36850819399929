export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = '';
  public serverUrl = '';
  public inventoryUrl = '';
  // Whether or not to enable debug mode
  public enableDebug = true;
  //production mode variable
  public productionMode = '';
  public prdctnModeForSideBar = '';

  constructor() {
  }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2TableModule } from 'ng2-table';

import { UtilsModule } from '../../layout/utils/utils.module';
import { JqSparklineModule } from '../../components/sparkline/sparkline.module';

import { NgSelectModule } from '@ng-select/ng-select';

import {SharedModule} from '../../layout/shared.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { AlertModule, TooltipModule } from 'ngx-bootstrap';
import { ButtonsModule, BsDropdownModule, PaginationModule } from 'ngx-bootstrap';

import { WidgetModule } from '../../layout/widget/widget.module';
import { TextMaskModule } from 'angular2-text-mask';

/* tslint:disable */
import { BootstrapWizardModule } from '../../components/wizard/wizard.module';
import { DropzoneDemoDirective } from '../../components/dropzone/dropzone.directive';
import { EditorModule } from '@tinymce/tinymce-angular';

import { CaseReasonAddUpdateComponent } from './case-reason/case-reason-add-update/case-reason-add-update.component';
import { CaseReasonListComponent } from './case-reason/case-reason-list/case-reason-list.component';
import { CasesListComponent } from './cases/cases-list/cases-list.component';
import { CasesAddUpdateComponent } from './cases/cases-add-update/cases-add-update.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule ,OWL_DATE_TIME_FORMATS} from 'ng-pick-datetime';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import {NgbTabsetModule, NgbCollapseModule, NgbDropdownItem, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NeoTableLibModule } from 'n-neo-table-lib';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { Ng2CompleterModule } from "ng2-completer";
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  DateAdapter,
  MatTabsModule
} from "@angular/material";
import { AppGuard } from '../../app.guard';
import { AclConstants } from '../aclOperationConstants';
import { AclClassConstants } from '../aclClassConstants';
import { CasesModule } from './cases/cases.module';
import { DateFormat } from '../../services/date-format';
import { CaseUpdatePopupComponent } from './cases/case-update-popup/case-update-popup.component';
import { InventoryModule } from '../inventory/inventory/inventory.module';
import { BulkCaseUpdatePopupComponent } from './cases/bulk-case-update-popup/bulk-case-update-popup.component';
// import { RequestedItemsComponent } from './requested-items/requested-items.component';
import { ServiceRequestScheduleComponent } from './cases/service-request-schedule/service-request-schedule.component';
import { CalendarModule, DateAdapter as da } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SlaListComponent } from './sla-list/sla-list.component';
import { SlaAddUpdateComponent } from './sla-add-update/sla-add-update.component';

export const MY_NATIVE_FORMATS = {
  fullPickerInput: {day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'},
  datePickerInput: {day: 'numeric', month: 'numeric', year: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {day: 'numeric',month: 'long', year: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

export const routes = [

  {
    path: 'caseReason',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_CASE_REASON_VIEW,
        classId:AclClassConstants.ACL_CASE_REASON,
        accessIdForAllOpreation :AclConstants.OPERATION_CASE_REASON_ALL,
        operation:'case reason'
    },
    component: CaseReasonListComponent
  },
  {
    path: 'caseReason/add-caseReason',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_CASE_REASON_ADD,
        classId:AclClassConstants.ACL_CASE_REASON,
        accessIdForAllOpreation :AclConstants.OPERATION_CASE_REASON_ALL,
        operation:'Add new case reason'
    },
    component: CaseReasonAddUpdateComponent
  },
  {
    path: 'caseReason/add-caseReason/:id',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_CASE_REASON_EDIT,
        classId:AclClassConstants.ACL_CASE_REASON,
        accessIdForAllOpreation :AclConstants.OPERATION_CASE_REASON_ALL,
        operation:'Edit case reason'
    },
    component: CaseReasonAddUpdateComponent
  },
  {
    path: 'cases',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_CASE_VIEW,
        classId:AclClassConstants.ACL_CASES,
        accessIdForAllOpreation :AclConstants.OPERATION_CASE_ALL,
        operation:'cases'
    },
    component:CasesListComponent
  },
  {
    path: 'service-request-schedule',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_CASE_VIEW,
        classId:AclClassConstants.ACL_CASES,
        accessIdForAllOpreation :AclConstants.OPERATION_CASE_ALL,
        operation:'cases'
    },
    component:ServiceRequestScheduleComponent
  },
  {
    path: 'casesInternal',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_INTERNAL_CASE_VIEW,
        classId:AclClassConstants.ACL_INTERNALCASE,
        accessIdForAllOpreation :AclConstants.OPERATION_INTERNAL_CASE_ALL,
        operation:'cases Internal'
    },
    component:CasesListComponent
  },
  {
    path: 'cases/add-cases',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_CASE_ADD,
        classId:AclClassConstants.ACL_CASES,
        accessIdForAllOpreation :AclConstants.OPERATION_CASE_ALL,
        operation:'add new cases '
    },
    component: CasesAddUpdateComponent
  },
  {
    path: 'cases/add-casesInternal',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_INTERNAL_CASE_ADD,
        classId:AclClassConstants.ACL_INTERNALCASE,
        accessIdForAllOpreation :AclConstants.OPERATION_INTERNAL_CASE_ALL,
        operation:'add new cases Internal'
    },
    component: CasesAddUpdateComponent
  },
  {
    path: 'cases/add-cases/:id',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_CASE_EDIT,
        classId:AclClassConstants.ACL_CASES,
        accessIdForAllOpreation :AclConstants.OPERATION_CASE_ALL,
        operation:'Edit case'
    },
    component: CasesAddUpdateComponent
  },
  {
    path: 'cases/add-casesInternal/:id',
    canActivate: [AppGuard],
    data: {
        operationId: AclConstants.OPERATION_INTERNAL_CASE_EDIT,
        classId:AclClassConstants.ACL_INTERNALCASE,
        accessIdForAllOpreation :AclConstants.OPERATION_INTERNAL_CASE_ALL,
        operation:'Edit Internal case'
    },
    component: CasesAddUpdateComponent
  },
  {
    path: 'sla',
    canActivate: [AppGuard],
    component:SlaListComponent
  },
  {
    path: 'sla/addUpdate',
    canActivate: [AppGuard],
    component:SlaAddUpdateComponent
  },
  {
    path: 'sla/addUpdate/:id',
    canActivate: [AppGuard],
    component:SlaAddUpdateComponent
  },
];
@NgModule({
  declarations: [
    CaseReasonAddUpdateComponent,
    CaseReasonListComponent,
    CasesListComponent,
    CasesAddUpdateComponent,
    CaseUpdatePopupComponent,
    BulkCaseUpdatePopupComponent,
    SlaListComponent,
    SlaAddUpdateComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    WidgetModule,
    BootstrapWizardModule,
    RouterModule.forChild(routes),
    EditorModule,
    JqSparklineModule,
    PaginationModule.forRoot(),
    UtilsModule,
    Ng2TableModule,
    NgxDatatableModule,
    NgSelectModule,
    OwlDateTimeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OwlNativeDateTimeModule,
    JwBootstrapSwitchNg2Module,
    NgbTabsetModule,
    NgbCollapseModule,
    NeoTableLibModule,
    Ng2SmartTableModule,
    Ng2CompleterModule,
    NgbAccordionModule,
    NgxMatTimepickerModule,
    MatExpansionModule,
    NgbModule,
    MatButtonModule,
    MatButtonToggleModule,
    CasesModule,
    MatSlideToggleModule,
    InventoryModule,
    MatTabsModule,
    CalendarModule.forRoot({
      provide: da,
      useFactory: adapterFactory,
    }),
  ],
  exports:[
    CaseUpdatePopupComponent,
    CasesAddUpdateComponent
  ],
  entryComponents:[
    CaseUpdatePopupComponent,
     CasesAddUpdateComponent,
     BulkCaseUpdatePopupComponent
  ],
  providers: [
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS},
    { provide: DateAdapter, useClass: DateFormat }
    ,AppGuard
  ],
})
export class CustomerCareModule {
  static routes = routes;
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale("en-in"); // DD/MM/YYYY
    
    // Date.prototype.getToJSON = function(){
    //     const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
    //     this.setHours(hoursDiff);
    //     return this.toISOString();
    // };
  }
}

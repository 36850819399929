import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { OtherCommunicationService } from './other-communication.service';
import { AppConfig } from '../../../../app.config';
import { FormControl, FormGroup } from '@angular/forms';
import _ from 'underscore';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { createThis } from 'typescript';
import { OldAbstractBaseListComponent } from '../../../../components/base/old-abstract-base-list-component';
declare let $: any;
import { SearchService } from '../../../../services/search.service';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild ('bodyWrapper',{static:false}) bodyWrapperReff:TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @Input() custId: any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  otherUpdatesNeoGridSettings: any = {};
  gridSetting: any;
  subscriptions: Subscription[] = [];
  configPath;
  eventdatalist: any;
  baseURL;
  event: any[] = [];
  otherUpdatesTableData: any;
  searchOtherCommForm = new FormGroup({
    from_date: new FormControl(''),
    to_date: new FormControl(''),
    event: new FormControl(null)
  });

  constructor(
    private otherCommunicationService: OtherCommunicationService,
    appConfig: AppConfig,
    private tosterservice: ToastrService,
    private searchService: SearchService,
    public datepipe: DatePipe,

  ) {
    super(searchService)
    this.configPath = appConfig.getConfig();
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.OTHER_COMMUNICATION)
    this.getStartDateEndDate();
    this.setGridSetting();
    this.initPaginationSort();
    // this.getListWithParams();

    this.baseURL = this.configPath.baseURLApi;

    this.getEventData();

  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if(col.colKey== 'body'){
        col.customControls=[this.bodyWrapperReff];
      }
      if (col.colKey == 'is_sended') {
        col.customControls = [this.statusWrapperRef];
      }
    });
  }
  public getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.gridSetting = config;

    this.searchOtherCommunication();

  }

  getStartDateEndDate() {
    var createStartdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    var startDate = this.datepipe.transform(createStartdate, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.searchOtherCommForm.patchValue({ "from_date": startDate, "to_date": endDate });

  }
 
  getEventData() {
    this.otherCommunicationService.getEvents().subscribe(res => {
      this.event = res.data;
      this.setEventData();
    })
  }
  setEventData() {
    this.eventdatalist = Object.keys(this.event).map((field_value) => {
      return { field_value: field_value, value_field: field_value }
    })
  }
  

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'createdate',
        default: 'Date',
        rowKey: 'createdate',
        width: '5%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'entity',
        default: 'Mobile/email',
        rowKey: 'entity',
        width: '5%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'module',
        default: 'Event',
        rowKey: 'module',
        width: '6%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'channel',
        default: 'Channel',
        rowKey: 'channel',
        width: '1%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'body',
        default: 'Body',
        rowKey: 'body',
        width: '5%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'is_sended',
        default: 'Status',
        rowKey: 'is_sended',
        width: '5%',
        customControls: [this.statusWrapperRef],
        customValue: (is_sended) => {
          if (is_sended === true) {
            return 'Success';
          } else {
            return 'Fail';
          }
        },
        isNumber: false,
        isFilterAllow: false,
      },

    ];

    this.otherUpdatesNeoGridSettings = {
      serverSideSortPagination: true,
      showAdvanceSearch: false,
      showFooter: false,
      showFilter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.OTHER_COMMUNICATION,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: 'Date',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
    if (this.otherUpdatesNeoGridSettings.serverSideSortPagination && this.otherUpdatesNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.otherUpdatesNeoGridSettings.perPage);
    }
  }


  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }


  searchOtherCommunication() {
    let formatedInputData: any = {};
    let apiInputData = this.searchOtherCommForm.value;
    let START_DATE = new DatePipe('en-US').transform(apiInputData.from_date, 'yyyy-MM-dd')
    let END_DATE = new DatePipe('en-US').transform(apiInputData.to_date, 'yyyy-MM-dd')
    let fetchSettingData = this.otherUpdatesNeoGridSettings.serverSideSortPagination ? this.gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);

    formatedInputData = Object.assign(params);
    formatedInputData.custId = this.custId;
    formatedInputData.startDate = START_DATE;
    formatedInputData.endDate = END_DATE;
    formatedInputData.eventName = apiInputData.event;

    if(START_DATE > END_DATE)
    {
      this.tosterservice.error("From Date should be less then To Date.");
      // this.tosterservice.error("To Date must be grater then From Date.");

    }
    else{
      this.otherCommunicationService.getCommunicationBySearch(formatedInputData).subscribe(result => {
        ////console.log("result", result);
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.otherUpdatesTableData = result.dataList;
          this.otherUpdatesNeoGridSettings.pageTotalLength = result && result.totalRecords > 0 ? result.totalRecords : 0
          this.refreshDataSource(this.otherUpdatesTableData);
        }
      });
    }
    
  }

  resetForm() {
    this.searchOtherCommForm.reset();
    this.getStartDateEndDate();
    setTimeout(() => {
      this.searchOtherCommunication();
    }, 700);
  }
}

import { BaseAddUpdateComponent } from './base-add-update-component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { Output, EventEmitter, Input, Optional, Inject } from '@angular/core';
import { IBaseService } from '../../services/ibase-service';
import { ResponseCode } from '../../core/constant/response-code';
import * as alertify from 'alertifyjs';
import { format } from 'url';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSharingServiceService } from '../../pages/data-sharing-service.service';
//import { AddClientGroupComponent } from 'src/app/pages/radius/client-grp/add-client-group/add-client-group.component';
// import { PincodeAddUpdateComponent } from 'src/app/pages/masters/pincode/pincode-add-update/pincode-add-update.component';

/**Need Above Component Id But it gives Error at Runtime  */

export interface DialogPassData{
  id:number
}

export abstract class AbstractBaseAddUpdateComponent<T> implements BaseAddUpdateComponent<T> {
  @Output('onSaved')
  public onSavedCallback: EventEmitter<T> = new EventEmitter<T>();

  @Input('isModelComponent')
  public isModelComponent: boolean = false;
  public isDisabled:boolean=false;

  public responseData:any;
  public isUpdateComponent: boolean = false;
  public submitted: boolean = false;
  public rowId:any;
  // public validationConfiguration = VALIDATION_CONFIGURATION;
  public isUserAllow: boolean;
  constructor(private toastr: ToastrService, private routerSrv: Router,
  
  // public dialog:MatDialog,  
  // public dialogRef: MatDialogRef<>,
  // @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
    ) { }

  // all abstract methods for ADD-UPDATE component
  abstract getService(): IBaseService<T>;
  abstract getGenericDataService();
  abstract setData(T);
  abstract getData(): T;
  abstract getModuleHomeUrl();
  abstract getActiveRoute();
  abstract getNewModal();
  abstract getFormGroup(): FormGroup;
  abstract setValidation();
  abstract getId(): number;

  init() {
    if (this.getActiveRoute() && this.getActiveRoute().queryParams) {
      this.getActiveRoute().queryParams.subscribe((param) => {
        let id = param['id'];
        if (id) {
          this.isUpdateComponent = true;
          let catModal = this.getNewModal();
          catModal.id = id;
          this.rowId=id;
          this.getById(id);
          return;
        }
           /** Dialog Open -Run Time Getting Error Because Extend Class*/

        //  if(this.data && this.data.id){
        //   this.isDisabled=true;
        //   this.isUpdateComponent = false;//false set
        //   let catModal = this.getNewModal();
        //   catModal.id = this.data.id;
        //   this.getById(this.data.id);
        //   return;
        // }
         
        this.setData(this.getNewModal());
      });
    }
    this.authRole();
    this.setData(this.getNewModal());
    this.setValidation();
  }

  save(modal: T) {
    // ////console.log(JSON.stringify(modal));
    this.getService()
      .save(modal)
      .subscribe(
        (response) => {
          if (response.responseCode != ResponseCode.OK) {
            this.onResponseError(response.responseMessage);
            return;
          }
          //this.setData(response.data);
          this.responseData=response;
          this.onSavedCallback.emit(response.data);
          this.onSaveSuccess();
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }

  onSave() {
    this.save(this.getData());
  }

  update(modal: T) {
    
    // ////console.log(JSON.stringify(modal));
    this.getService()
      .update(modal)
      .subscribe(
        (response) => {
          ////console.log(response.responseCode)
          
          if (response.responseCode != ResponseCode.OK) {
            this.onResponseError(response.responseMessage);
            return;
          }
          //this.setData(response.data);
          this.onSaveSuccess();
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }

  onUpdate() {
    this.update(this.getData());
  }

  getById(id: number) {
    this.getService()
      .getById(id)
      .subscribe(
        (response) => {
          if (response.responseCode != ResponseCode.OK) {
            this.onResponseError(response.responseMessage);
            return;
          }
          this.responseData=response;
          this.setData(response.data);
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }

  getErrorMsg(controlName: string) {
    const formGroup = this.getFormGroup();
    const formControl = formGroup.controls[controlName];
    if (formControl) {
      if (formControl.errors && formControl.errors.required) {
        return 'This field is required.';
      } else if (formControl.errors && formControl.errors.minlength) {
        return `Please enter atleast ${formControl.errors.minlength.requiredLength} characters.`;
      } else if (formControl.errors && formControl.errors.maxlength) {
        return `Please enter at max string with ${formControl.errors.maxlength.requiredLength} characters.`;
      } else if (formControl.errors && formControl.errors.min) {
        return `Please enter minimum ${formControl.errors.min.min} as value.`;
      } else if (formControl.errors && formControl.errors.max) {
        return `Please enter value upto ${formControl.errors.max.max}.`;
      }
      return '';
    }
    return '';
  }

  validateForm() {

    this.submitted = true;
    if (this.getFormGroup().invalid) {
      return false;
    }
    return true;
  }

  onSubmit() {
    if (this.isUpdateComponent) {
      
      this.onUpdate();
      return;
    }
    if (!this.isUpdateComponent) {
      this.onSave();
    }
    // if (confirm('Are you sure you want to save this record?')) {
    //   this.onSave();
    // }
  }

  onCancel() {
    this.goToModuleHome();
  }

  public onSaveSuccess() {
  
    if (!this.isModelComponent) {

      this.goToModuleHome();
    }
    this.toastr.success('Data has been created successfully.', 'Success');
    // this.toastr.success();
  }

  public onUpdateSuccess() {
     this.toastr.success('Data has been updated successfully.', 'Success');
    this.goToModuleHome();
  }

  public onResponseError(message: string) {
    this.toastr.error(message.replace('\n', '<br/>'), 'Error', { enableHtml: true });

  }
  public goToModuleHome() {

    if(this.getGenericDataService().lastAuditUrl == this.routerSrv.url){
      this.routerSrv.navigate(['/operations/audit']);
      this.getGenericDataService().lastAuditUrl = undefined;
      }else{
      this.routerSrv.navigate([this.getModuleHomeUrl()]);
    }
  }

  public getFile(): Array<File> {
    return;
  }

  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  public numberOnlyValidation = Validators.pattern(/^\d*(?:[.,]\d{1,4})?$/);

  public validateResponse(response, fun: Function) {
    if (response.responseCode !== ResponseCode.OK) {
      this.toastr.error(response.responseMessage, 'Error');
      return;
    }

    fun();
  }

  public authRole() {
    // Id 1 for admin, Id 2 for staff, Id 3 for SuperAdmin, Id 4 for other.
    const activeUser = JSON.parse(localStorage.getItem('activeUser'));
    if (activeUser && activeUser.role && activeUser.role.roleid) {
      if (activeUser.role.roleid === 1 || activeUser.role.roleid === 3) {
        this.isUserAllow = true;
      }
      if (activeUser.role.roleid === 2 || activeUser.role.roleid === 4) {
        this.isUserAllow = false;
      }
    }
  }

  /** Validation For First Character "Space" Remove*/
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  /**Remove Trailing and Leading Space from FormControl Value */
  getTrimFormControlValue(fieldvalue:any){
   if(fieldvalue !== null){
     return fieldvalue.trim()
     } 
  }

  setAutoSelectDropDownList(data){
    if(data && data.length == 1){
      return data[0];
    }
  }
}

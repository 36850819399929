import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import _ from "underscore";
import { TableKeyEnum } from "../../../core/enum/table-key.enum";
import { DateAdapter, MatTableDataSource } from "@angular/material";
import { PaginationData } from "../../../core/modal/pagination-data";
import { Subscription } from "rxjs";
import { SearchService } from "../../../services/search.service";
import {
  SearchControlTypeEnum,
  SearchDataTypeEnum,
} from "../../../core/enum/searchType.enum";
import { ServiceAreaService } from "../../network-management/service-area/service/service-area.service";
import { OldAbstractBaseListComponent } from "../../../components/base/old-abstract-base-list-component";
import { AclClassConstants } from "../../aclClassConstants";
import { AclConstants } from "../../aclOperationConstants";
import { LoginService } from "../../login/login.service";
import { ResponseCode } from "../../../core/constant/response-code";
import { ToastrService } from "ngx-toastr";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  AddBalance,
  ReverseBalance,
} from "../../masters/add-franchies/model/add-balance";
import { DatePipe } from "@angular/common";
import { PaymentModeService } from "../../../../app/services/payment_mode/payment_mode.service";
import { AbstractBaseListComponent } from "../../../components/base/abstract-base-list-component";
import { OrderingData } from "../../../core/modal/ordering-data";
import { IBaseService } from "../../../services/ibase-service";
import { machineData } from "../model/machineData";
import { CommonListServicesService } from "../../../services/common_list_service/common-list-services.service";
import { MachineInventoryService } from "./service/machine-inventory.service";

// export interface staticData {
//   SrNo: Number;
//   brand: String;
//   name: String;
//   status:String
//   currentCustomer: string;
//   ownershipType:String;
//   supplierDetails:String
//   startDate:Date;
//   endDate:Date;
  
// }
// const ELEMENT_DATA: 
@Component({
  selector: "app-machine-inventory",
  templateUrl: "./machine-inventory.component.html",
  styleUrls: ["./machine-inventory.component.scss"],
})
export class MachineInventoryComponent extends AbstractBaseListComponent<any> implements OnInit,AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('serviceare', { static: false }) serviceAreaReff: TemplateRef<any>;
  @ViewChild('serialNo', { static: false }) serialNo: TemplateRef<any>;
  @ViewChild('lastserviceReqNo',{static: false}) lastserviceReqNoRef: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  dataGrid = [];
  tableKeyEnum = TableKeyEnum;
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  // public entity: ServiceAreaModel = new ServiceAreaModel();
  public entity_id: any = null;
  form: FormGroup;
  passdata: any;
  editviewId:any;

  constructor(private entityService : MachineInventoryService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    public CommonListService: CommonListServicesService,
    private ngbModal: NgbModal,
    private dateAdapter: DateAdapter<Date>,
    loginService: LoginService) {
      super(toastrService,searchService);
      this.loginService = loginService;
      dateAdapter.setLocale("en-in");
    }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.EQUIPMENT_INVENTORY_LIST)
    this.init();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.serviceAreaReff];
      }
      if (col.colKey == 'serialNo') {
        col.customControls = [this.serialNo];
      }
      if (col.colKey === 'lastserviceReqNo') {
        col.customControls = [this.lastserviceReqNoRef];
      } 
    });
  }

  getService(): IBaseService<any> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;

    let allColumns = [
      {
        colKey: 'index',
        default: 'id',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'serialNo',
        default: 'Serial No.',
        rowKey: 'serialNo',
        width: '40%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'brand',
        default: 'Brand',
        rowKey: 'brand',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue:(status) => {
          if(status === 'Installed'){
            return 'Installed';
          } else {
            return 'Free';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'ownershipType',
        default: 'ownership Type',
        rowKey: 'ownershipType',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'currentCustomer',
        default: 'Current Customer',
        rowKey: 'currentCustomer',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'currentCustomerAllocationDate',
        default: 'allocation Date',
        rowKey: 'currentCustomerAllocationDate',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'category',
        default: 'category',
        rowKey: 'category',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'subCategory',
        default: 'sub Category',
        rowKey: 'subCategory',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'warrentyStartDate',
        default: 'Warrenty Start Date',
        rowKey: 'warrentyStartDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'warrentyEndDate',
        default: 'Warrenty End Date',
        rowKey: 'warrentyEndDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastserviceReqNo',
        default: 'Service ReqNo.',
        rowKey: 'lastserviceReqNo',
        width: '10%',
        isNumber: false,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns = allColumns;
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    return allColumns ? allColumns : [];
  }

  getTableSetting() {
    let serviceAreaNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.EQUIPMENT_INVENTORY_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      serverSideSortPagination: false,
      serverSideFilter: true,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
      deleteMsgBoxTitle: 'Are you sure to delete this Machine Inventory ?',
      deleteMsgBoxDetail: 'Machine Name :${name} ',
    };
    return serviceAreaNeoGridSettings;
  }

  getTableSearchSetting() {
    let searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
    return searchSettings;
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  // onEditClick(event) {
  //   ////console.log(event.RowData)
  //   this.setUpdateValueInForm(event.RowData);
  // }

  // onDeleteClick(event) {
  //   if (event.deletClicked) {
  //     this.delete(event.RowData);
  //   }
  // }

  onEditClick(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  onDeleteClick(event) {
     if (event) {
       this.commonDeleteMsgBox(this.getTableSetting(),event,this.afterDeleteConfirm.bind(this));
     }
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  setList(list: Array<any>) {
    this.dataGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filterObj.filters) {

      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.dataGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  onAddClick() {
    this.router.navigate(['/masters/machine-inventory/add_machine_inventory']);
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/masters/machine-inventory/add_machine_inventory',rowData.id]);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  openPopup(el,data?){
    this.ngbModal.open(el,{size:'lg'})
    if(data)
      this.passdata = data
  }

  closeModal(){
    this.ngbModal.dismissAll();
  }

  onEdit(event,editviewmode) {
    this.editviewId =  event.caseId
    this.ngbModal.open(editviewmode, { size: 'xl' });
  }
}

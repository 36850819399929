import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { Subscription } from "rxjs";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TableKeyEnum } from "../../../core/enum/table-key.enum";
import { AclClassConstants } from "../../aclClassConstants";
import { AclConstants } from "../../aclOperationConstants";
import { AppConfig } from "../../../app.config";
import { LoginService } from "../../login/login.service";
import { PurchasesListService } from "../../masters/subscriber-details/subscriber-tables/purchases/purchases.service";
import { Router } from "@angular/router";
import { CaseReasonService } from "../case-reason/service/case-reason.service";

@Component({
  selector: "app-sla-list",
  templateUrl: "./sla-list.component.html",
  styleUrls: ["./sla-list.component.scss"],
})
export class SlaListComponent implements OnInit {
  @ViewChild("downloadInvoice", { static: false })
  downloadInvoiceWrapperRef: TemplateRef<any>;
  @ViewChild("downloadCreditNote", { static: false })
  downloadCreditNoteWrapperRef: TemplateRef<any>;
  @ViewChild("amount", { static: false }) amountWrapperRef: TemplateRef<any>;
  @ViewChild("remark", { static: false }) remarkWrapperRef: TemplateRef<any>;
  @ViewChild("actionBtn", { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild("vasBtn", { static: false }) vasWrapperRef: TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  ngbRef: NgbModalRef;
  uuId: any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  NeoGridSettings = {};
  subscriptions: Subscription[] = [];
  configPath;
  baseURL;
  TableData: any;
  zoneList = [];
  tempDatalist = [];
  constructor(
    private purchasesService: PurchasesListService,
    appConfig: AppConfig,
    private modalService: NgbModal,
    public loginService: LoginService,
    private router: Router,
    private caseReasonService: CaseReasonService
  ) {
    this.configPath = appConfig.getConfig();
  }

  ngOnInit() {
    this.setGridSetting();
    this.getData();
    this.baseURL = this.configPath.baseURLApi;
  }

  onChangeZone(event) {
    if(!event || event.length == 0){
      this.refreshDataSource(this.tempDatalist);
      return
    }
    let idList = event.map((ls) => ls.name);
    this.refreshDataSource(this.getSLAsByServiceAreaIds(idList));
  }

  getSLAsByServiceAreaIds(serviceAreaIds: number[]): any[] {
    return this.tempDatalist.filter(sla => serviceAreaIds.includes(sla.serviceAreaName));
  }

  getServiceAreaList(){
    if(!this.zoneList || this.zoneList.length == 0){
      this.caseReasonService.getAllServiceArea().subscribe((res)=>{
        this.zoneList = res.dataList;
      });
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === "invoiceNo") {
        col.customControls = [this.downloadInvoiceWrapperRef];
      }
      if (col.colKey === "credId") {
        col.customControls = [this.downloadCreditNoteWrapperRef];
      }
      if (col.colKey === "amount") {
        col.customControls = [this.amountWrapperRef];
      }
      if (col.colKey === "remark") {
        col.customControls = [this.remarkWrapperRef];
      }
      if (col.colKey === "actionCol") {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey === "vas") {
        col.customControls = [this.vasWrapperRef];
      }
      if (col.colKey === "status") {
        col.customControls = [this.statusWrapperRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: "slaName",
        default: "SLA Name",
        rowKey: "slaName",
        width: "10%",
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: "serviceAreaName",
        default: "Zone",
        rowKey: "serviceAreaName",
        width: "10%",
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: "createdByName",
        default: "created By",
        rowKey: "createdByName",
        width: "10%",
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: "createdate",
        default: "Create DATE",
        rowKey: "createdate",
        width: "10%",
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: "lastModifiedByName",
        default: "last Modified By",
        rowKey: "lastModifiedByName",
        width: "10%",
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: "status",
        default: "Status",
        rowKey: "status",
        width: "5%",
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: "actionCol",
        default: "Action",
        rowKey: "id",
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: "1%",
        isStickyEnd: true,
      },
    ];

    this.NeoGridSettings = {
      showAdvanceSearch: false,
      showFilter: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.SLA_LIST,
      perPage: 10,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: "",
      defaultSortDirection: "asc",
      columns: this.allColumns,
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(
        JSON.parse(JSON.stringify(data))
      );
    }
  }

  getData() {
    this.purchasesService.getAllSLA().subscribe(result => {
    this.TableData = result.dataList;
    this.tempDatalist = result.dataList;
    this.refreshDataSource(this.TableData);
    });
  }

  open(paydet, data: any) {
    this.ngbRef = this.modalService.open(paydet, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });
    this.uuId = data.uuid;
  }

  onClose() {
    this.ngbRef.close();
  }

  onAddClick() {
    this.router.navigate(['customer-care/sla/addUpdate'])
  }

  onEditClick(data) {
    this.router.navigate(['/customer-care/sla/addUpdate', data.slaId]);
  }
  
  onDeleteClick(data) {
    if(confirm('Are You Sure You Want To Delete?')){
      this.caseReasonService.deleteSLA(data).subscribe((res) => {
        this.getData();
      })
    }
  }
}

import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomModalService } from '../../../../components/custom-modal';

import { PlanServices } from '../../../../services/plan_service/plan_service.service';

import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { LocationDetailsService } from './location-details.service';
import { ToastrService } from 'ngx-toastr';
import { AddSubscriberDetailsService } from '../../add-subscriber-details/add-subscriber-details.service';
@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent implements OnInit {
  @Input() custId: any;
  submitted:any = null;
  taxDetailsByPlanData:any;
  insertLocationDetailsForm = new FormGroup ({
    latitude : new FormControl(''),
    longitude : new FormControl(''),
    url : new FormControl(''),
    gis_code : new FormControl(''),
    feasibilityDistance:new FormControl('')
  });

  chargeAmount: any = "";
  taxAmount: any = "";
  finalAmount: any = "";

  locationDetailsData : any;
  feasibilityDistanceObj: any;
  bridgeTypeObj:any;
  urlObj:any;
  gisObj:any;
  latituteObj:any;
  longituteObj:any;
  dynamicrequiredArray: any;
  req_message = "This field is required.";
  constructor(
    private customModalService: CustomModalService,
    private locationDetailsService : LocationDetailsService,
    private planService: PlanServices,
    private tosterservice : ToastrService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,

  ) {
    this.getRequiredAllField();
   }

  ngOnInit() {
    this.getSubscriberCharges();
  }

  getSubscriberCharges() {
    this.locationDetailsService.getLocationDetails(this.custId).subscribe(
      result => {
        this.locationDetailsData = result.data;
        ////console.log(this.locationDetailsData);
        this.insertLocationDetailsForm.patchValue(this.locationDetailsData);
      }
    )
  }

  onClickSaveApplyChargeForm() {
    if (this.insertLocationDetailsForm.valid === true) {
      this.submitted = false;
      let self = this;
      alertify.confirm("Location Details","Are You Sure You want to Update Location Details ?",
      function() {
        self.saveApiCall();
      },
      function() {

      });
    } else {
      this.submitted = true;
      this.insertLocationDetailsForm.markAllAsTouched();
    }
  }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.insertLocationDetailsForm.value);
    let formatedInputData:any = apiInputData;
    formatedInputData.custId = this.custId;
    this.locationDetailsService.updateLocationDetails(formatedInputData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
         this.tosterservice.success('Location Details Update Successfully.');
         this.customModalService.close('location-datails',result);
        }
      },err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id:string){
    this.customModalService.close(id,{close_from:"close_button"});
    this.submitted = false;
  }

  getRequiredAllField() {
    this.addSubscriberDetailsService.getRequiredAllField().subscribe(result => {

      if (result.responseCode === 200) {
        
        this.dynamicrequiredArray = result.data;
        this.feasibilityDistanceObj = this.dynamicrequiredArray.find(e => e.fieldname === 'feasibilityDistance');
        // this.bridgeTypeObj = this.dynamicrequiredArray.find(e => e.fieldname === 'bridgeType');
        this.urlObj = this.dynamicrequiredArray.find(e => e.fieldname === 'url');
        this.gisObj = this.dynamicrequiredArray.find(e => e.fieldname === 'gis_code');
        this.latituteObj = this.dynamicrequiredArray.find(e => e.fieldname === 'latitude');
        this.longituteObj = this.dynamicrequiredArray.find(e => e.fieldname === 'longitude');
        


        if ((this.feasibilityDistanceObj !== null && this.feasibilityDistanceObj !== undefined) && this.feasibilityDistanceObj.isMandatory === true) {
          this.insertLocationDetailsForm.get('feasibilityDistance').setValidators(Validators.required)
          this.insertLocationDetailsForm.get('feasibilityDistance').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('feasibilityDistance').clearValidators()
          this.insertLocationDetailsForm.get('feasibilityDistance').updateValueAndValidity();
        }

        


        if ((this.urlObj !== null && this.urlObj !== undefined) && this.urlObj.isMandatory === true) {
          this.insertLocationDetailsForm.get('url').setValidators(Validators.required)
          this.insertLocationDetailsForm.get('url').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('url').clearValidators()
          this.insertLocationDetailsForm.get('url').updateValueAndValidity();
        }


        if ((this.gisObj !== null && this.gisObj !== undefined) && this.gisObj.isMandatory === true) {
          this.insertLocationDetailsForm.get('gis_code').setValidators(Validators.required)
          this.insertLocationDetailsForm.get('gis_code').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('gis_code').clearValidators()
          this.insertLocationDetailsForm.get('gis_code').updateValueAndValidity();
        }

        if ((this.latituteObj !== null && this.latituteObj !== undefined) && this.latituteObj.isMandatory === true) {
          this.insertLocationDetailsForm.get('latitude').setValidators(Validators.required)
          this.insertLocationDetailsForm.get('latitude').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('latitude').clearValidators()
          this.insertLocationDetailsForm.get('latitude').updateValueAndValidity();
        }

        if ((this.longituteObj !== null && this.longituteObj !== undefined) && this.longituteObj.isMandatory === true) {
          this.insertLocationDetailsForm.get('longitude').setValidators(Validators.required)
          this.insertLocationDetailsForm.get('longitude').updateValueAndValidity();
        }
        else {
          this.insertLocationDetailsForm.get('longitude').clearValidators()
          this.insertLocationDetailsForm.get('longitude').updateValueAndValidity();
        }
      
      }
    })
    
  }
}

import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { LoginService } from '../../login/login.service';
import { Router } from '@angular/router';
import { proprertyDocList } from '../model/machineData';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { IBaseService } from '../../../services/ibase-service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../../../auth/token-storage-service';
import { SearchService } from '../../../services/search.service';
import { DocumentPropertyService } from './document-property.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { OrderingData } from '../../../core/modal/ordering-data';
import * as alertify from 'alertifyjs';
import { PaginationData } from '../../../core/modal/pagination-data';

@Component({
  selector: 'app-document-property-list',
  templateUrl: './document-property-list.component.html',
  styleUrls: ['./document-property-list.component.scss']
})
export class DocumentPropertyListComponent  extends AbstractBaseListComponent<any> implements OnInit {
 
  
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('totaldocumentWrapper', { static: false }) totaldocumentWrapper: TemplateRef<any>;
  @ViewChild('subcategoryWrapper', { static: false }) subcategoryWrapper: TemplateRef<any>;
  @ViewChild('downloadInvoice', { static: false }) downloadInvoiceWrapperRef: TemplateRef<any>;
  @ViewChild('downloadCreditNote',{ static: false }) downloadCreditNoteWrapperRef: TemplateRef<any>;
  @ViewChild('amount', { static: false }) amountWrapperRef: TemplateRef<any>;
  @ViewChild('remark', { static: false }) remarkWrapperRef: TemplateRef<any>;
  @ViewChild('vasBtn', { static: false }) vasWrapperRef: TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  ngbRef:NgbModalRef
  uuId:any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataGrid: any[] = [];
  searchSettings = [];
  dataSource = new MatTableDataSource([]);
  purchasesNeoGridSettings = {};
  subscriptions: Subscription[] = [];
  purchasesTableData = proprertyDocList;
  planMapId: string;

  constructor(
    private entityService: DocumentPropertyService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    loginservice: LoginService,
    private tosterservice: ToastrService,
    private tokenSerivce:TokenStorageService
  ) {
    super(tosterservice, searchService);
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.DOCUMENT_LIST)
    this.init();
    this.getTableColumn()
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      this.applyFilter(obj);
      
    });
    this.subscriptions.push(filterSubscription);
  }
  getService(): IBaseService<any> {
    return this.entityService;
  }
  setList(list: Array<any>) {
    this.dataGrid = list;
    
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
    
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }
  getTableColumn() {
    let self = this;
      this.allColumns = [
        {
          colKey: 'index',
          default: 'ID',
          rowKey: 'id',
          width: '1%',
          isFilterAllow: false,
        },
        {
          colKey: 'name',
          default: 'Document Category Name',
          rowKey: 'name',
          width: '12%',
          isNumber: false,
          isFilterAllow: false,
        },
        {
          colKey: 'refNo',
          default: 'Refrence No.',
          rowKey: 'refNo',
          width: '10%',
          isNumber: false,
          isFilterAllow: false,
        },
        {
          colKey: 'totalDocuments',
          default: 'Total Document',
          rowKey: 'totalDocuments',
          width: '7%',
          isNumber: false,
          isFilterAllow: false,
        },
        {
          colKey: 'category',
          default: 'Category',
          rowKey: 'category',
          width: '10%',
          isNumber: false,
          isFilterAllow: false,
        },
        {
          colKey: 'subCategory',
          default: 'Sub Category',
          rowKey: 'subCategory',
          width: '10%',
          isNumber: false,
          isFilterAllow: false,
        },
        {
          colKey: 'status',
          default: 'Status',
          rowKey: 'status',
          width: '7%',
          isNumber: false,
          isFilterAllow: false,
        },
        {
          colKey: 'actionCol',
          default: 'Action',
          rowKey: 'id',
          enableEdit: false,
          enableDelete: false,
          isFilterAllow: false,
          enableSort: false,
          isExpandable: false,
          isNumber: false,
          width: '1%',
          isStickyEnd: true,
        },
      ];
   

    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });


    this.getTableSetting();
    this.getTableSearchSetting();

  }
  getTableSetting() {
    this.neoTableSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.DOCUMENT_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure want to delete this record ?',
      deleteMsgBoxDetail: 'Item Name :${name} ',
    };
  }
  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Country Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'STATUS',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
    ];
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey === 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey === 'totalDocuments') {
        col.customControls = [this.totaldocumentWrapper];
      }
      if (col.colKey === 'subCategory') {
        col.customControls = [this.subcategoryWrapper];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
      },
      {
        colKey: 'docType',
        default: 'Document Category Name',
        rowKey: 'docType',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'docSubType',
      //   default: 'Document Type',
      //   rowKey: 'docSubType',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      {
        colKey: 'refNo',
        default: 'Refrence No.',
        rowKey: 'refNo',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'totalDoc',
        default: 'Total Document',
        rowKey: 'totalDoc',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'status',
        default: 'status',
        rowKey: 'status',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];

    this.purchasesNeoGridSettings = {
      showAdvanceSearch: false,
      showFilter: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.DOC_PROPERTY_LIST,
      perPage: 10,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }


  onClose(){
    this.ngbRef.close();
  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;

    if (this.neoTableSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters,this.dataGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
  }
  onAddClick(){
    this.router.navigate(['/masters/document-property-list/document_update'])
  }

  onEditClick(event) {
    
    // let index = event.RowData;
     let index = event;
     this.setUpdateValueInForm(index);
   }
   setUpdateValueInForm(rowData) {
    
    this.router.navigate(['/masters/document-property-list/document_update/', rowData.id]);
  }
   onDeleteClick(event) {
    
    let self = this;
    
    alertify.confirm(`Are you sure want to delete this`+" record ?",
    function(){
      if (event) {
        
         self.delete(event);
       }},
    function(){
    })
  }
}

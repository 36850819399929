import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { EditorModule } from "@tinymce/tinymce-angular";
import { TextMaskModule } from "angular2-text-mask";
import { Ng2TableModule } from "ng2-table";
import { DateFormat } from "../../services/date-format";
import { AlertModule, BsDropdownModule, PaginationModule, TooltipModule } from "ngx-bootstrap";
import { JqSparklineModule } from "../../components/sparkline/sparkline.module";
import { BootstrapWizardModule } from "../../components/wizard/wizard.module";
import { SharedModule } from "../../layout/shared.module";
import { UtilsModule } from "../../layout/utils/utils.module";
import { WidgetModule } from "../../layout/widget/widget.module";
import { IpPoolConfigListComponent } from './ip-pool-config/ip-pool-config-list/ip-pool-config-list.component';
import { IpPoolConfigDetailComponent } from './ip-pool-config/ip-pool-config-detail/ip-pool-config-detail.component';
import { NetworkDevicesComponent } from './network-device/network-devices/network-devices.component';
import { AddNetworkDevicesComponent } from './network-device/add-network-devices/add-network-devices.component';
import { AddSlotComponent } from './network-device/add-network-devices/add-slot/add-slot.component';
import { ServiceAreaListComponent } from './service-area/service-area-list/service-area-list.component';
import { ServiceAreaAddUpdateComponent } from './service-area/service-area-add-update/service-area-add-update.component';
import { AppGuard } from '../../app.guard';
import { AclConstants } from '../aclOperationConstants';
import { AclClassConstants } from '../aclClassConstants';
import { SearchIpComponent } from './search-ip/search-ip.component';
import { NgbTabsetModule } from "@ng-bootstrap/ng-bootstrap";
import { MatButtonToggleModule } from "@angular/material";
import { MatDatepickerModule } from "@angular/material";
import { MatExpansionModule } from "@angular/material";
import { NetworkstatusComponent } from './networkstatus/networkstatus.component';
import { NeworkSearchComponent } from './nework-search/nework-search.component';
import { DateAdapter } from '@angular/material';
import { NetworkTreeComponent } from './network-tree/network-tree.component';
import { ViewFrameComponent } from "./view-frame/view-frame.component";
import { ViewSlotComponent } from './view-slot/view-slot.component';
import { ViewPortComponent } from './view-port/view-port.component';
import { IppoolDetailsComponent } from './ippool-details/ippool-details.component';
// import { ViewNetworkTreeComponent } from './network-tree/view-network-tree/view-network-tree.component';

export const routes = [
    // { path: '', redirectTo: 'ip_pool_list', pathMatch: 'full' },
    {
        path: 'ip_pool_list',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_IP_POOL_VIEW,
            classId: AclClassConstants.ACL_IP_POOL,
            accessIdForAllOpreation: AclConstants.OPERATION_IP_POOL_ALL,
            operation: 'ip pool list'
        },
        component: IpPoolConfigListComponent
    },
    {
        path: 'ip_pool_list/detail',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_IP_POOL_ADD,
            classId: AclClassConstants.ACL_IP_POOL,
            accessIdForAllOpreation: AclConstants.OPERATION_IP_POOL_ALL,
            operation: 'Add ip pool detail'
        },
        component: IpPoolConfigDetailComponent
    },
    {
        path: 'ip_pool_list/detail/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_IP_POOL_EDIT,
            classId: AclClassConstants.ACL_IP_POOL,
            accessIdForAllOpreation: AclConstants.OPERATION_IP_POOL_ALL,
            operation: 'Edit ip pool detail'
        },
        component: IpPoolConfigDetailComponent
    },
    {
        path: 'network',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_NETWORK_DEVICES_VIEW,
            classId: AclClassConstants.ACL_NETWORK_DEVICES,
            accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_DEVICES_ALL,
            operation: 'network'
        },
        component: NetworkDevicesComponent
    },
    {
        path: 'network/add_network',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_NETWORK_DEVICES_ADD,
            classId: AclClassConstants.ACL_NETWORK_DEVICES,
            accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_DEVICES_ALL,
            operation: 'Add new network'
        },
        component: AddNetworkDevicesComponent
    },
    {
        path: 'network/add_network/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_NETWORK_DEVICES_EDIT,
            classId: AclClassConstants.ACL_NETWORK_DEVICES,
            accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_DEVICES_ALL,
            operation: 'Edit network'
        },
        component: AddNetworkDevicesComponent
    },
    {
        path: 'network/add_network/add_slot/:networkId',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_NETWORK_DEVICES_EDIT,
            classId: AclClassConstants.ACL_NETWORK_DEVICES,
            operation: 'Add slot',
            accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_DEVICES_ALL,
        },
        component: AddSlotComponent
    },
    {
        path: 'network/add_network/add_slot/:networkId/:slot_id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_NETWORK_DEVICES_EDIT,
            classId: AclClassConstants.ACL_NETWORK_DEVICES,
            operation: 'Update slot',
            accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_DEVICES_ALL,
        },
        component: AddSlotComponent
    },
    {
        path: 'serviceArea',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_SERVICE_AREA_VIEW,
            classId: AclClassConstants.ACL_SERVICE_AREA,
            accessIdForAllOpreation: AclConstants.OPERATION_SERVICE_AREA_ALL,
            operation: 'service area'
        },
        component: ServiceAreaListComponent
    },
    {
        path: 'serviceArea/add-serviceArea/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_SERVICE_AREA_EDIT,
            classId: AclClassConstants.ACL_SERVICE_AREA,
            accessIdForAllOpreation: AclConstants.OPERATION_SERVICE_AREA_ALL,
            operation: 'Edit service area'
        },
        component: ServiceAreaAddUpdateComponent
    },
    {
        path: 'serviceArea/add-serviceArea',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_SERVICE_AREA_ADD,
            classId: AclClassConstants.ACL_SERVICE_AREA,
            accessIdForAllOpreation: AclConstants.OPERATION_SERVICE_AREA_ALL,
            operation: 'add new service area'
        },
        component: ServiceAreaAddUpdateComponent
    },
    {
        path: 'search-ip',
        component: SearchIpComponent
    },
    {
        path: 'networkstatus',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_NETWORK_STATUS_VIEW,
            classId: AclClassConstants.ACL_NETWORK_STATUS,
            accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_STATUS_VIEW,
            operation: 'add new service area'
        },
        component: NetworkstatusComponent
    },
    {
        path: 'network-search',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_NETWORK_SEARCH_VIEW,
            classId: AclClassConstants.ACL_NETWORK_SEARCH,
            accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_SEARCH_VIEW,
            operation: 'add new service area'
        },
        component: NeworkSearchComponent
    },
    {
        path: 'network-tree',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_NETWORK_TREE_VIEW,
            classId: AclClassConstants.ACL_NETWORK_TREE,
            accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_TREE_VIEW,
            operation: 'network-tree'
        },
        component: NetworkTreeComponent
    },
    {
        path: 'frame/:id',
        canActivate: [AppGuard],
        // data: {
        //     operationId: AclConstants.OPERATION_NETWORK_TREE_VIEW,
        //     classId: AclClassConstants.ACL_NETWORK_TREE,
        //     accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_TREE_VIEW,
        //     operation: 'network frame'
        // },
        component: ViewFrameComponent
    },
    {
        path: 'port/:id/:frame/:slot',
        canActivate: [AppGuard],
        // data: {
        //     operationId: AclConstants.OPERATION_NETWORK_TREE_VIEW,
        //     classId: AclClassConstants.ACL_NETWORK_TREE,
        //     accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_TREE_VIEW,
        //     operation: 'network frame'
        // },
        // component: ViewSlotComponent /**old compo*/
        component: ViewPortComponent
    },
    {
        path: 'port/:id/:slotid/:portid',
        canActivate: [AppGuard],
        // data: {
        //     operationId: AclConstants.OPERATION_NETWORK_TREE_VIEW,
        //     classId: AclClassConstants.ACL_NETWORK_TREE,
        //     accessIdForAllOpreation: AclConstants.OPERATION_NETWORK_TREE_VIEW,
        //     operation: 'network frame'
        // },
        component: ViewPortComponent
    },
    {
        path: 'olt/:id/:frame/:slot/:port',
        component: ViewSlotComponent
    }
];

@NgModule({
    declarations: [
        IpPoolConfigListComponent,
        IpPoolConfigDetailComponent,
        NetworkDevicesComponent,
        AddNetworkDevicesComponent,
        AddSlotComponent,
        ServiceAreaListComponent,
        ServiceAreaAddUpdateComponent,
        SearchIpComponent,
        NetworkstatusComponent,
        NeworkSearchComponent,
        NetworkTreeComponent,
        // ViewNetworkTreeComponent,
        ViewFrameComponent,
        ViewSlotComponent,
        ViewPortComponent,
        IppoolDetailsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule,
        SharedModule,
        TooltipModule.forRoot(),
        AlertModule.forRoot(),
        BsDropdownModule.forRoot(),
        WidgetModule,
        BootstrapWizardModule,
        RouterModule.forChild(routes),
        EditorModule,
        JqSparklineModule,
        PaginationModule.forRoot(),
        UtilsModule,
        Ng2TableModule,
        NgxDatatableModule,
        NgSelectModule,
        NgbTabsetModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatExpansionModule
    ],
    providers: [AppGuard, { provide: DateAdapter, useClass: DateFormat }],
})
export class NetworkManagementModule {
    constructor(private dateAdapter: DateAdapter<Date>) {
        dateAdapter.setLocale("en-in"); // DD/MM/YYYY
    }
    static routes = routes;
}
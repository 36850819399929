import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CasesAddUpdateComponent } from './cases-add-update/cases-add-update.component';
import { CasesListComponent } from './cases-list/cases-list.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {NgbAccordionModule} from '@ng-bootstrap/ng-bootstrap';
import {MatButtonToggleModule, MatExpansionModule} from '@angular/material';
import { ButtonsModule, BsDropdownModule, PaginationModule } from 'ngx-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2TableModule } from 'ng2-table';
import { CaseUpdatePopupComponent } from './case-update-popup/case-update-popup.component';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
} from "@angular/material";
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceRequestScheduleComponent } from './service-request-schedule/service-request-schedule.component';
import { CalendarModule, CalendarModule as da } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        Ng2SmartTableModule,
        NgbAccordionModule,
        MatButtonToggleModule,
        MatExpansionModule,
        PaginationModule.forRoot(),
        Ng2TableModule,
        NgxDatatableModule,
        MatDatepickerModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxMatTimepickerModule,
        NgbTabsetModule,
        CalendarModule.forRoot({
          provide: da,
          useFactory: adapterFactory,
        }),
    ],
    declarations: [ServiceRequestScheduleComponent],
})

export class CasesModule { }


import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-machine-inventory-detail',
  templateUrl: './machine-inventory-detail.component.html',
  styleUrls: ['./machine-inventory-detail.component.scss']
})
export class MachineInventoryDetailComponent implements OnInit {
  @Input() Data: any

  constructor(private ngbModal: NgbModal) { }

  ngOnInit() {
  }

  closeModal(){
    this.ngbModal.dismissAll();
  }

}

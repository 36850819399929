import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivityModel } from '../model/activity-model';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivityService } from '../service/activity.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSharingServiceService } from '../../../data-sharing-service.service';
import { IBaseService } from '../../../../services/ibase-service';

@Component({
  selector: 'app-activity-add-update',
  templateUrl: './activity-add-update.component.html',
  styleUrls: ['./activity-add-update.component.scss']
})
export class ActivityAddUpdateComponent extends AbstractBaseAddUpdateComponent<ActivityModel> implements OnInit{

  activityDetail: FormGroup;
  circuit = new ActivityModel();
  updatebutton:boolean=false
  activityType: any[] = [];
  isUpdateComponent: boolean = false;
  entity: any = {};
  urlHome: 'masters/activity-list';
  fileid: any;
  submittednf : boolean = false;
  constructor(
    private toaster: ToastrService,
    private router: Router,
    private ngbModalService:NgbModal,
    private activate: ActivatedRoute,
    private circuitService: ActivityService,
    private genericDataSharingService: DataSharingServiceService,
    private detectChangeRef: ChangeDetectorRef
  ) {
    super(toaster, router)
    this.setValidation()
  }

  ngOnInit() {
    this.init();
    let id = this.activate.snapshot.paramMap.get('id');

    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));

    }
    this.activityList()
    this.circuit=new ActivityModel();
    
  }
  public filterlist: any[];
  getService(): IBaseService<ActivityModel> {
    return this.circuitService;
  }
  getGenericDataService() {
    return this.genericDataSharingService
  }
  setData(data: ActivityModel) {
    this.circuit = data;
  }
  getData(): ActivityModel {
    return this.circuit;
  }
  getNewModal() {
    return new ActivityModel();
  }
  getFormGroup(): FormGroup {
    return this.activityDetail;
  }
 
  public getModuleHomeUrl(): string {

    return this.urlHome;
  }


  public getActiveRoute(): ActivatedRoute {
    return this.activate;
  }
  setValidation() {
    this.activityDetail = new FormGroup({
      activityName: new FormControl(''),
      description: new FormControl(''),
      activityType: new FormControl(null),
    })
  }
  getId(): number {
    return this.circuit.id;
  }
  get f() {
    return this.activityDetail.controls;
  }
  onCancel(){
    this.router.navigate(['/masters/activity-list']);
  }
  formSubmit() {
    let self = this;
   
      if (this.validateForm()) {
        this.onSaveAndContinue(this.circuit)
      }
    
    else {
      this.toaster.error("Please fill all the details!");
    }
  }
  formSubmit2() {
    let self = this;
   let obj={
    id:this.activate.snapshot.paramMap.get('id'),
    description:this.circuit.description,
    activityType:this.circuit.activityType,
    activityName:this.circuit.activityName
   }
      if (this.validateForm()) {
        
        this.circuitService.documentupdate(obj).subscribe((res)=>{
          if(res.responseCode==200){
            this.toaster.success('Data has been Updated successfully.', 'Success');
            this.router.navigate(['/masters/activity-list']);

          }
        })
      }
    
    else {
      this.toaster.error("Please fill all the details!");
    }
  }
  onSaveAndContinue(modal: ActivityModel) {
    this.getService()
      .save(modal)
      .subscribe(
        (response) => {
          this.validateResponse(response, () => {
            this.submitted = false;
            const responseParty = response.data;
            this.isUpdateComponent=true
            this.router.navigate(['/masters/activity-list/add_activity/' + responseParty.id]);

          });
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }

    closeModal(){
      this.ngbModalService.dismissAll();
      this.updatebutton=false
    }

    activityList() {
      this.circuitService.activityList().subscribe(response => {
        this.activityType = response.dataList;
      })
    }
   
    
}

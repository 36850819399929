import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { LoginService } from '../../../login/login.service';
import { ChangePlanService } from '../change-plan/change-plan.service';
import * as alertify from 'alertifyjs';
import { CustomModalService } from '../../../../components/custom-modal/custom-modal.service';
import _ from 'underscore';
import { RecordPaymentService } from '../record-payment/record-payment.service';
import { ApplyChargeService } from '../apply-charge/apply-charge.service';
import { PaymentModeService } from '../../../../services/payment_mode/payment_mode.service';
import { NPackagesService } from '../../../plan_management/packages/Npackages.service';
import { LoadingService } from '../../../../components/loading/loading.service';
import { ActivePlanListService } from '../subscriber-tables/active-plan/active-plan.service';

@Component({
  selector: 'app-purchase-plan',
  templateUrl: './purchase-plan.component.html',
  styleUrls: ['./purchase-plan.component.scss']
})
export class PurchasePlanComponent implements OnInit {

  @Input() custId: any;
  @Input() ui_id: string;
  @Input() service: string;
  @Input() partnerId: number;
  @Input() bal:number;

  form: FormGroup;
  vasprice: number = 0;

  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  refundOptionFlag: boolean = false;
  showCurrentPlanDetails: boolean = true;
  showExpiredPlanDetails: boolean = true;
  CurrentPlan: any
  isChecked: boolean = false;
  isIncludingTax: boolean = false;
  selectedPlan: any;
  isOverhead: boolean = false;
  taxDetailsByPlanData: number;
  planPrice: number = 0;
  displayMinPrice: number;
  displayMaxPrice: number;
  purchaseaddonprice: any;
  public planPurchaseTypeList: any;
  displayActivateDate: any;
  displayExpiryDate: any;
  purchaseType: string;
  showAdvRenewal: boolean = false;
  advanceRenewFlag: any;
  refundOption: any
  defaultRefundOptForNoACL: any;
  selectedDefaultRefundOption: any
  refundShow: boolean = false;
  subscriberCurrentPlanData: any;
  submitted: boolean = false;
  req_message = 'This field is required.';
  submitBtnDisable:boolean=false;
  activePlanTableData: any;
  btnSaveDisable:boolean=false;
  public advRenewalList: any[] = [
    {
        text: 'Yes',
        value: true,
    },
    {
        text: 'No',
        value: false,
    },
  ];
  plandataType:any;
  planTypeList:any
  planGroup:any;
  public planList: any;
  finalpaybal: number;
  isPriceTaxMin: boolean = false;
  isPriceTaxMax: boolean = false;
  minWithTax: any;
  maxWithTax: any;
  activatetionDate: boolean;
  addonStart: boolean;
  Partner_type: any
  isaddon: boolean;
  finalAmount: number;
  collectorFlag:boolean = true;
  tdsAmountValid: boolean;
  amountValid: boolean;
  paymentDateValid: boolean;
  remarksValid: boolean;
  referenceNoValid: boolean;
  paymentModeValid: boolean;
  public paymentModeList: any[] = [
    {
        text: '',
        value: '',
    },
  ];
  chequeDateValid: boolean;
  branchValid: boolean;
  chequeNoValid: boolean;
  bankNameValid: boolean;
  checkeligiblity: any;
  endDate: string;
  selectedPlanDisplay:any;
  custStatusList: any[] = 
  [{ 'text': 'TDS', 'value': 'TDS' },
  { 'text': 'Cash', 'value': 'Cash' },
  { 'text': 'Wallet', 'value': 'Wallet' }
  ];
  selected_payment_mode_value: any
  public tdsPendingPaymentsList: any[] = [
    {
        display_text: '',
        id: '',
    },
  ];
  selected_tds_deducted: any;
  collectorList: [];
  oldPurchasePrice: any;
  addonStartDate: any;
  purchaseEndDate: Date;
  ottServiceArcd = [];
  voiceServiceArcd = [];
  iptvSetrviceArcd = [];
  selectedVASIds = [];
  associatedVASDTOs = [];
  currentPlanDTO = [];
  normalPlanDtoList = [];
  currentVoice;
  currentOtt;
  currentIptv;
  currentplanId:string;
  isAllowVAS: boolean = true;
  isOpenedOnce: boolean = false;
  constructor(
    private packagesService: NPackagesService,
    private paymentModeService: PaymentModeService,
    private applyChargeService: ApplyChargeService,
    private recordPaymentService: RecordPaymentService,
    private customModalService: CustomModalService,
    private commonService: CommonListServicesService,
    private changePlanService: ChangePlanService,
    private tosterservice: ToastrService,
    private datePipe: DatePipe,
    private datepipe: DatePipe,
    public loginService: LoginService,
    public loadingService: LoadingService,
    private activePlanListService:ActivePlanListService,
    
  ) 
  { 
    this.loginService = loginService
  }

  ngOnInit() {
    this.vasprice = 0;
    this.planPrice = 0;
    this.taxDetailsByPlanData = 0;
    this.displayMinPrice = 0;
    this.displayMaxPrice = 0;
    this.finalpaybal = 0;
    this.finalAmount = 0;
    this.setValidation();
    this.vasprice = 0;
    if (this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER, AclConstants.OPERATION_CUSTOMER_REFUND_OPTION, AclConstants.OPERATION_CUSTOMER_ALL)) {
      this.refundOptionFlag = true;
    }
    else {
        this.refundOptionFlag = false;
    }

    this.commonService.getGenericCommonList('refundOptionType').subscribe((res) => {
      this.refundOption = res.dataList;
    });
    this.getPlanTypeList();
    this.getSubscriberCurrentPlan();    
    this.getStartDateEndDate();
    this.Partner_type = localStorage.getItem('Partner_Type');
    this.getPaymentCollectorList(this.custId);
    this.getPaymentModeListFromGenericApi();
    this.getActivePlanData();
  }


  getPaymentModeListFromGenericApi() {
    this.paymentModeService.getPaymentModeListFromGenericApi().subscribe(result => {
        this.paymentModeList = result.dataList;
    });
  }

  getPaymentCollectorList(custid){
    this.applyChargeService.getPaymentCollectorList(custid).subscribe(
      result => {
        this.collectorList = result.dataList;
      }
    )
  }

  setValidation()
  {
    this.form = new FormGroup({
        planId: new FormControl(null, [Validators.required]),
        advRenewal: new FormControl(null),
        isIncludeTax: new FormControl(false),
        isPriceOverride: new FormControl(false,Validators.required),
        sellPrice: new FormControl(''),
        planPurchaseType: new FormControl('', [Validators.required]),
        skip: new FormControl(),
        remarks: new FormControl('', [Validators.required]),
        isPaymentReceived: new FormControl('no', [Validators.required]),
        addonStartDate: new FormControl(new Date()),
        refundOption: new FormControl(null),
        vasRefurnd: new FormControl(null),
        planType: new FormControl(null),
        isBalanceUsed: new FormControl(false),
        balanceAmount: new FormControl(null),
        recordPaymentDTO: new FormGroup({
            amount: new FormControl(''),
            payment_date: new FormControl(''),
            payment_mode: new FormControl(''),
            referenceNo: new FormControl(''),
            chequeNo: new FormControl(''),
            bankName: new FormControl(''),
            chequeDate: new FormControl(''),
            branch: new FormControl(''),
            tdsDeducted: new FormControl(false),
            tdsAmount: new FormControl(''),
            remarks: new FormControl(''),
            credit_doc_id: new FormControl(''),
        })
    });
  }

  getPlanTypeList()
  {
    this.commonService.getGenericCommonList('planTypeForNew').subscribe((res)=>{
      this.planTypeList = res.dataList
      .filter((ls) => {
        if (ls.value === 'Renew' || ls.value === 'Registration') {
          return ls;
        }
      });
      this.planTypeSelection(this.planTypeList);
    })
  }

  planTypeSelection(planTypeList)
  {
    if(planTypeList && planTypeList.length > 0){
        let plan = planTypeList[1];
        if(plan.value){
            this.plandataType = plan.value;
        }
        this.onChangePlanType(plan)
    }
  }

  getStartDateEndDate() {
    let newdate = new Date();
    var currentDate = this.datepipe.transform(newdate, 'yyyy-MM-dd');
    this.form.get('recordPaymentDTO').get('payment_date').setValue(currentDate);
}

  onChangePlanType(event){
    this.planList = [];
    this.ottServiceArcd = [];
    this.voiceServiceArcd = [];
    this.iptvSetrviceArcd = [];
    this.form.get('planId').reset();
    this.planGroup = null;
    if(event && event.value){
        this.planGroup = event.value
        this.getSubscriberCurrentPlan()
    }
  }

  getSubscriberCurrentPlan() 
  {
    let subscriber_id = this.custId;
    this.changePlanService.getSubscriberCurrentPlan(subscriber_id).subscribe((result) => {
        this.selectedDefaultRefundOption = result.data.defaultRefundOptForActive;
        this.defaultRefundOptForNoACL = result.data.defaultRefundOptForNoACL;
        this.associatedVASDTOs = result.data.associatedVASDTOs;
        this.currentPlanDTO = result.data.currentPlanDTO;
        this.normalPlanDtoList = result.data.normalPlanDtoList;
        this.getPlanPurchaseType(this.currentPlanDTO);

        if(this.associatedVASDTOs && this.associatedVASDTOs.length > 0){
            this.associatedVASDTOs.forEach((ls)=> {
                if(ls.planType == 'VOICE')
                {
                    this.currentVoice = ls;
                };
                if(ls.planType == 'OTT')
                {
                    this.currentOtt = ls;
                };
                if(ls.planType == 'IPTV')
                {
                    this.currentIptv = ls;
                };
            })
        }

        setTimeout(() => {
            if (result && result.data) {
                this.subscriberCurrentPlanData = result.data;
                if (result.data.currentPlanDTO === null && result.data.expiredPlanDTO === null) {
    
                    this.showCurrentPlanDetails = false;
                    this.showExpiredPlanDetails = false;
    
                }
                if (!result.data.currentPlanDTO) {
                    if(this.subscriberCurrentPlanData.expiredPlanDTO){
                        this.CurrentPlan = this.subscriberCurrentPlanData.expiredPlanDTO
                    }
                    this.purchaseType = 'New';
    
                    if (this.planPurchaseTypeList && this.planPurchaseTypeList.length > 0) {
                        let newPlan = this.planPurchaseTypeList.find((ls) => {
                            if (ls.value.toLowerCase() === 'new') {
                                return ls;
                            }
                        });
                        this.form.get('planPurchaseType').setValue(this.purchaseType);
                        this.onChangePurchaseType(newPlan);
                    }
    
                }
                else {
                    if( this.subscriberCurrentPlanData.currentPlanDTO){
                        this.CurrentPlan = this.subscriberCurrentPlanData.currentPlanDTO[0]
                    }
                    this.purchaseType = 'Renew';
                    if (this.planPurchaseTypeList && this.planPurchaseTypeList.length > 0) {
                        let renew = this.planPurchaseTypeList.find((ls) => {
                            if (ls.value.toLowerCase() === 'renew') {
                                return ls;
                            }
                        });
    
                        this.form.get('planPurchaseType').setValue(this.purchaseType);
                        this.onChangePurchaseType(renew);
                    }
                    this.form.get('advRenewal').patchValue(true);
                    if (this.advRenewalList) {
                        let adv = this.advRenewalList.find((ls) => {
                            if (ls.value === 'true') {
                                return ls;
                            }
                        });
                        this.onChangeAdvRenewal(adv);
                    }
    
                }
                if (this.ui_id !== 'purchase-addon-plan') {
                    if(this.planGroup == 'Registration'){
                        this.planList = this.subscriberCurrentPlanData.registrationPlanDtoList;
                    }
                    else if(this.planGroup == 'Renew' || this.planGroup == null){
                        this.planList = this.subscriberCurrentPlanData.normalPlanDtoList;
                    } 
                    if (this.planList && this.planList.length > 0) {
                        let plan = this.planList.find((ls) => {
    
                            if (this.subscriberCurrentPlanData.currentPlanDTO) {
                                if (ls.planId === this.subscriberCurrentPlanData.currentPlanDTO[0].custCurrentPlanId) {
                                    return ls;
                                }
                            }
                            else if (this.subscriberCurrentPlanData.expiredPlanDTO) {
                                if (ls.planId === this.subscriberCurrentPlanData.expiredPlanDTO.custCurrentPlanId) {
                                    return ls;
                                }
                            }
                            else {
                                return null
                            }
    
    
                        });
                        if (this.subscriberCurrentPlanData.currentPlanDTO) {
    
                            this.form.get('planId').patchValue(plan ? this.subscriberCurrentPlanData.currentPlanDTO[0].custCurrentPlanId : null);
                            ////console.log("plan::",plan)
                            if (plan) {
                                this.onChangePlan(plan);
                            }
                        }
                        if (this.subscriberCurrentPlanData.expiredPlanDTO) {
                            this.form.get('planId').patchValue(plan ? this.subscriberCurrentPlanData.expiredPlanDTO.custCurrentPlanId : null);
                            if (plan) {
                                this.onChangePlan(plan);
                            }
                        }
    
    
                    }
                }
    
    
            }
        }, 100);
       

    });
  }

  getPlanPurchaseType(currentPlanDTO) {
    this.changePlanService.getPlanPurchaseType().subscribe((result) => {

        this.planPurchaseTypeList = result.dataList.filter((item: any) => {
            if (currentPlanDTO !== null && this.ui_id === 'purchase-plan') {
                if (item.value === 'Renew') {
                    return item.value == 'Renew';
                }
                if (item.value === 'Upgrade') {
                    return item.value == 'Upgrade';
                }
            } 
            if (currentPlanDTO == null && this.ui_id === 'purchase-plan') {
                if (item.value === 'New') {
                    return item.value == 'New';
                }
            }

        });
    });
}

    deselectRadio(id, i)
    {     
       if(this.isAllowVAS){
        if(id == 'iptv'){
            this.iptvSetrviceArcd.map((ls) => ls.selected = false);
            this.packagesService.checkForSetUpBox(this.custId).subscribe((res)=>{
                if(res.responseCode===200){
                    this.iptvSetrviceArcd[i].selected = true;
                }else{
                    this.iptvSetrviceArcd[i].selected = false;
                    let element = document.getElementById('iptv' + i);
                    element['checked'] = false;
                    this.tosterservice.error(res.responseMessage);
                }
               });
        }
        if(id == 'voice'){
            this.voiceServiceArcd.map((ls) => ls.selected = false);
            this.voiceServiceArcd[i].selected = true;
        }
        if(id == 'ott'){
            this.ottServiceArcd.map((ls) => ls.selected = false);
            this.ottServiceArcd[i].selected = true;
        }
        this.calculateVasPrice();
       } 
       
    }

    calculateVasPrice(){
        this.vasprice = 0;
        this.ottServiceArcd.forEach((ls) =>{
            if(ls.selected)
                this.vasprice += (ls.offerPrice ? ls.offerPrice : 0) + (ls.taxAmount ? ls.taxAmount : 0);
        });

        this.voiceServiceArcd.forEach((ls) =>{
            if(ls.selected)
                this.vasprice +=  (ls.offerPrice ? ls.offerPrice : 0) + (ls.taxAmount ? ls.taxAmount : 0);
        });

        setTimeout(() => {
            this.iptvSetrviceArcd.forEach((ls) =>{
                if(ls.selected)
                    this.vasprice +=  (ls.offerPrice ? ls.offerPrice : 0) + (ls.taxAmount ? ls.taxAmount : 0);
            });
        }, 200);
    }

  onChangePlan(planItem, fromScreen?:boolean) 
  {
    this.vasprice = 0;
    this.selectedPlanDisplay= planItem.planName;
    this.ottServiceArcd = [];
    this.voiceServiceArcd = [];
    this.iptvSetrviceArcd = [];
    this.isAllowVAS = planItem.allowVasSelection;
    if(planItem.vasdtos && planItem.vasdtos.length > 0){
        planItem.vasdtos.forEach((ls)=> {
            if(ls.type == 'VOICE')
            {
                ls.selected = this.isAllowVAS ? false : true;
                this.voiceServiceArcd.push(ls);
            };
            if(ls.type == 'OTT')
            {
                ls.selected = this.isAllowVAS ? false : true;
                this.ottServiceArcd.push(ls);
            };
            if(ls.type == 'IPTV')
            {
                ls.selected = this.isAllowVAS ? false : true;
                this.iptvSetrviceArcd.push(ls);
            };
        })
    }
    if(!this.isAllowVAS){
        this.calculateVasPrice();
    }
    if(this.associatedVASDTOs && this.associatedVASDTOs.length > 0 && !fromScreen){
        this.vasprice=0;
        for(let i=0; i< this.voiceServiceArcd.length; i++ ){
            for(let j=0; j<this.associatedVASDTOs.length; j++){
                if(this.voiceServiceArcd[i].id == this.associatedVASDTOs[j].custCurrentPlanId){
                this.voiceServiceArcd[i].selected = true;
                this.vasprice += (this.voiceServiceArcd[i].offerPrice + this.voiceServiceArcd[i].taxAmount);
                }
            }
        }

        for(let i=0; i< this.ottServiceArcd.length; i++ ){
            for(let j=0; j<this.associatedVASDTOs.length; j++){
                if(this.ottServiceArcd[i].id == this.associatedVASDTOs[j].custCurrentPlanId){
                this.ottServiceArcd[i].selected = true;
                this.vasprice += (this.ottServiceArcd[i].offerPrice + this.ottServiceArcd[i].taxAmount);
                }
            }
        }

        for(let i=0; i< this.iptvSetrviceArcd.length; i++ ){
            for(let j=0; j<this.associatedVASDTOs.length; j++){
                if(this.iptvSetrviceArcd[i].id == this.associatedVASDTOs[j].custCurrentPlanId){
                this.iptvSetrviceArcd[i].selected = true;
                this.vasprice += (this.iptvSetrviceArcd[i].offerPrice + this.iptvSetrviceArcd[i].taxAmount);
                }
            }
        }
        
    }

    this.form.get('isPaymentReceived').setValue('no');
    this.form.get('balanceAmount').reset();
    this.form.get('sellPrice').setValue(null);
    this.selectedPlan = planItem;

    if (this.selectedPlan && this.selectedPlan.minPrice !== null && this.selectedPlan.minPrice !== '') {
        this.isPriceTaxMin = false;
        if (this.isPriceTaxMin === false) {
            this.displayMinPrice = 0;
            this.displayMinPrice = this.selectedPlan.minPrice + this.selectedPlan.minPriceTax;
            this.isPriceTaxMin = true;
        }
        else {
            this.isPriceTaxMin = false;
            return
        }
    }
    else {
        this.selectedPlan.minPrice = null;
        this.selectedPlan.minPriceTax = null;
        this.displayMinPrice = null;
        this.maxWithTax = null;
    }
    if (this.selectedPlan.maxPrice !== null && this.selectedPlan.maxPrice !== '') {
        this.isPriceTaxMax = false;
        if (this.isPriceTaxMax === false) {
            this.displayMaxPrice = 0;
            this.displayMaxPrice = this.selectedPlan.maxPrice + this.selectedPlan.maxPriceTax;
            this.isPriceTaxMax = true;
        }
        else {
            this.maxWithTax = null;
            this.isPriceTaxMax = false;
            return
        }
    }
    else {
        this.selectedPlan.maxPrice = null;
        this.selectedPlan.maxPriceTax = null;
        this.displayMaxPrice = null;
        this.maxWithTax = null;
    }

    this.planPrice = this.selectedPlan.price;
    if (this.ui_id === 'purchase-addon-plan') {
        this.purchaseaddonprice = this.selectedPlan.offerprice;
        this.oldPurchasePrice = this.selectedPlan.offerprice;
    }

    if (planItem) {

        let advRenewal = this.form.get('advRenewal').value;
        let purchaseType = this.form.get('planPurchaseType').value;
        let data: any = {
            // "chargeId": 0,
            // "locationId": this.presentAddressStateChange.state_id,
            'custId': this.custId,
            'planId': this.selectedPlan.planId
        };
        this.taxDetailsByPlanData = planItem.taxAmount;
        if (planItem.taxAmount === null) {
            this.taxDetailsByPlanData = 0;
        }

        if (purchaseType === 'New' || purchaseType === 'Upgrade' || purchaseType === 'Volume Booster') {
            this.onChangeAdvRenewal({ value: false });
            if (this.ui_id === 'purchase-addon-plan') {
                this.selectedPlan.offerprice = this.selectedPlan.offerprice + this.selectedPlan.taxamount;
            }
        } else {
            if (advRenewal === true || advRenewal === false) {
                this.onChangeAdvRenewal({ value: advRenewal });
            } else {
                this.onChangeAdvRenewal('');
            }
        }
    } else {
        this.onChangeAdvRenewal('');
    }
    if (this.ui_id === 'purchase-plan') {
        this.finalpaybal = this.planPrice + this.taxDetailsByPlanData;
    }
    if (this.ui_id === 'purchase-addon-plan') {
        if (!this.isOverhead) {
            this.finalpaybal = this.purchaseaddonprice + this.selectedPlan.taxamount;
        }
        if (this.isOverhead) {
            this.finalpaybal = this.purchaseaddonprice;
        }
    }
  }

  checkIncludingTaxVAS(event: any) 
  {
    let sellPrice = this.form.get('sellPrice').value;

    if (event === 'true') {
        this.form.get('isIncludeTax').setValue(true);
        this.isIncludingTax = true;
    } else {
        this.form.get('isIncludeTax').setValue(false);
        this.isIncludingTax = false;
    }

    if (sellPrice !== '') {
        this.onOverridePrice(sellPrice);
    }

    let data = {
        value: this.form.get('refundOption').value
    }
    this.onRefundOptionChange(data);
  }

  checkIncludingTax(event: any) {

        let sellPrice = this.form.get('sellPrice').value;

        if (event === 'true') {
            this.form.get('isIncludeTax').setValue(true);
            this.isIncludingTax = true;
        } else {
            this.form.get('isIncludeTax').setValue(false);
            this.isIncludingTax = false;
        }

        if (sellPrice !== '') {
            this.onOverridePrice(sellPrice);
        }
    }

  onOverridePrice(amount) 
  {
    this.form.get('isBalanceUsed').setValue(false);
    if (amount === '') {
        this.form.get('sellPrice').setErrors({ 'incorrect': true });
        this.tosterservice.error('Please Enter Override Price!');
        return;
    }

    this.form.get('sellPrice').setErrors(null);

    let event = Number(amount);
    if (this.isOverhead === true) {
        this.taxDetailsByPlanData = 0;
        this.planPrice = 0;
        if (this.isIncludingTax === false) {

            // if (this.ui_id === 'purchase-addon-plan') {
            //     let obj = {
            //         amount: Number(event),
            //         taxId: this.selectedPlan.taxId
            //     };
            //     this.changePlanService.getTaxByAmount(obj).subscribe((res) => {
            //         event = Number(event) + res.TaxAmount;

            //         if (event || this.displayMinPrice !== null || this.displayMaxPrice !== null) {
            //             if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {

            //                 this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
            //                 this.form.get('sellPrice').setErrors({ 'incorrect': true });
            //                 event = 0;
            //                 return;
            //             } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {

            //                 this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
            //                 this.form.get('sellPrice').setErrors({ 'incorrect': true });
            //                 event = 0;
            //                 return;
            //             } else {
            //                 this.purchaseaddonprice = Number(event);
            //             }
            //         } else {
            //             this.purchaseaddonprice = Number(event);

            //         }

            //     });
            // }
            if (this.ui_id === 'purchase-plan') {
                let obj = {
                    amount: Number(event),
                    taxId: this.selectedPlan.planTaxId
                };
                this.changePlanService.getTaxByAmount(obj).subscribe((res) => {
                    event = Number(event) + res.TaxAmount;
                    if (this.displayMinPrice !== null || this.displayMaxPrice !== null) {
                        if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {
                            this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
                            event = 0;
                            this.form.get('sellPrice').setErrors({ 'incorrect': true });
                            ////console.log('aa=>', this.form);
                            return;
                        } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {
                            this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
                            event = 0;
                            this.form.get('sellPrice').setErrors({ 'incorrect': true });
                            return;
                        } else {
                            this.planPrice = Number(event);
                        }
                    } else {
                        this.planPrice = Number(event);
                    }

                });
            }
        } else {
            if (this.ui_id === 'purchase-plan') {
                if (event || this.displayMinPrice !== null || this.displayMaxPrice !== null) {
                    if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {
                        this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
                        event = 0;
                        this.form.get('sellPrice').setErrors({ 'incorrect': true });
                        return;
                    } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {
                        this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
                        event = 0;
                        this.form.get('sellPrice').setErrors({ 'incorrect': true });
                        return;
                    } else {
                        this.planPrice = Number(event);
                    }
                } else {
                    this.planPrice = Number(event);
                }
                // this.planPrice = Number(event)
            }
            // if (this.ui_id === 'purchase-addon-plan') {

            //     if (this.displayMinPrice !== null || this.displayMaxPrice !== null) {
            //         if (event > this.displayMaxPrice && this.displayMaxPrice !== null) {
            //             this.tosterservice.error('Override price Sholud be less than ' + this.displayMaxPrice);
            //             event = 0;
            //             this.form.get('sellPrice').setErrors({ 'incorrect': true });
            //             return;
            //         } else if (event < this.displayMinPrice && this.displayMinPrice !== null) {
            //             this.tosterservice.error('Override price Sholud be greater than ' + this.displayMinPrice);
            //             event = 0;
            //             this.form.get('sellPrice').setErrors({ 'incorrect': true });
            //             return;
            //         } else {
            //             this.purchaseaddonprice = Number(event);
            //         }
            //     } else {
            //         this.purchaseaddonprice = Number(event);
            //     }
            // }
        }

    }
  }

  ClearField() 
  {
    this.form.reset();
    this.selectedPlan = [];
    this.displayActivateDate = null;
    this.displayExpiryDate = null;
    this.taxDetailsByPlanData = null;
    this.planPrice = null;
    this.form.get('isPaymentReceived').setValue('no');
  }

  onChangePurchaseType(purchaseTypeItem) 
  {
    if (purchaseTypeItem) {

        this.purchaseType = purchaseTypeItem.value;
        if (purchaseTypeItem.value === 'Renew') {
            this.form.get('advRenewal').setValidators([Validators.required]);
        }
        if (purchaseTypeItem.value !== 'Renew') {
            this.form.get('advRenewal').clearValidators();
            this.form.get('advRenewal').updateValueAndValidity();

            this.showAdvRenewal = false;
            this.onChangeAdvRenewal({ value: false });
            if (purchaseTypeItem.value == 'Volume Booster' &&
                this.ui_id == 'purchase-addon-plan') {
                //this.getPurchaseAddonePlan();
            }
        } else {

            this.showAdvRenewal = true;
            this.onChangeAdvRenewal({ text: 'Yes', value: true });
        }
    } else {
        this.onChangeAdvRenewal({ text: 'No', value: false });
        this.showAdvRenewal = false;
    }
  }

  // getPurchaseAddonePlan() 
  // {
  //   let serviceType: number = 1;
  //   let purchaseAddonPlan: any = {
  //       'partnerId': this.partnerId,
  //       'planGroup': 'Volume Booster',
  //       'serviceType': serviceType,
  //   };

  //   this.changePlanService.getPurchaseAddonePlanService(purchaseAddonPlan).subscribe(result => {
  //       this.planList = [];
  //       result.dataList.forEach(item => {
  //           let itemData: any = {};
  //           itemData.planName = item.displayName;
  //           itemData.partnerCommType = item.partnerCommType
  //           itemData.planId = item.id;
  //           itemData.dataQuota = item.quota;
  //           itemData.validity = item.validity;
  //           itemData.timeQuota = item.quotatime;
  //           itemData.quotaType = item.quotatype;
  //           itemData.offerprice = item.offerprice;
  //           itemData.quotaUnit = item.quotaUnit;
  //           itemData.quotaunittime = item.quotaunittime;
  //           itemData.endDate = item.endDate;
  //           itemData.minPrice = item.minPrice;
  //           itemData.maxPrice = item.maxPrice;
  //           itemData.minPriceTax = item.minPriceTax
  //           itemData.maxPriceTax = item.maxPriceTax
  //           itemData.taxamount = item.taxamount;
  //           itemData.taxId = item.taxId;
  //           itemData.quotaFormate = item.quotaFormate;//New Key:
  //           this.planList.push(itemData);
  //       });
  //   });
  // }

  ClearFieldPlanType(){
    this.form.get('planId').reset();
    this.selectedPlan = [];
    this.displayActivateDate = null;
    this.displayExpiryDate = null;
    this.taxDetailsByPlanData = null;
    this.planPrice = null;
    this.form.get('isPaymentReceived').setValue('no');
  }

  onChangeAdvRenewal(advRenewalItem) 
  {
    this.form.get('skip').setValue(false);
    this.form.get('skip').updateValueAndValidity();
    this.form.get('isPaymentReceived').setValue('no');
    this.form.get('isPaymentReceived').updateValueAndValidity();
    if (advRenewalItem) {
        if (this.selectedPlan) {
            if (advRenewalItem.value === true) {
                this.advanceRenewFlag = true;
                this.displayActivateDate = this.selectedPlan.renewalActivationDate;
                this.displayExpiryDate = this.selectedPlan.renewalExpiryDate;


                this.refundShow = false;
            } else if (advRenewalItem.value === false) {
                if (this.refundOptionFlag) {
                    let selectedRefundOption = this.refundOption.filter(res => res.value === this.selectedDefaultRefundOption);

                    this.form.get('refundOption').setValue(this.selectedDefaultRefundOption)
                    
                    this.onRefundOptionChange(selectedRefundOption[0]);
                    this.advanceRenewFlag = false;
                    this.refundShow = true;

                    this.displayActivateDate = this.selectedPlan.activationDate;
                    this.displayExpiryDate = this.selectedPlan.expiryDate;
                }
                else {
                    let selectedRefundOption = this.refundOption.filter(res => res.value === this.defaultRefundOptForNoACL);
                    
                    this.form.get('refundOption').setValue(this.defaultRefundOptForNoACL)
                    
                    this.onRefundOptionChange(selectedRefundOption[0]);
                    this.form.controls.refundOption.disable();
                    this.advanceRenewFlag = false;
                    this.refundShow = true;

                    this.displayActivateDate = this.selectedPlan.activationDate;
                    this.displayExpiryDate = this.selectedPlan.expiryDate;
                }
            }
        }
    } else {
        this.displayActivateDate = '';
        this.displayExpiryDate = '';
    }
  }


  onRefundOptionChange(value) {
    if (value.length > 0) {
        value = value[0]
    }
    if (value) {
        if (this.subscriberCurrentPlanData.currentPlanDTO != null) {
            let vasProratedWithoutTax = 0;
            let vasProratedWithTax = 0;
            let vasFullWithoutTax = 0;
            let vasFullWithTax = 0;
            if(this.subscriberCurrentPlanData.associatedVASDTOs && this.subscriberCurrentPlanData.associatedVASDTOs.length > 0){
                this.subscriberCurrentPlanData.associatedVASDTOs.forEach((ls) => {
                    vasProratedWithoutTax += ls.proratedAmount ? ls.proratedAmount : 0;
                    vasProratedWithTax += ls.proratedAmountWithTax ? ls.proratedAmountWithTax : 0;
                    vasFullWithoutTax += ls.fullAmount ? ls.fullAmount : 0;
                    vasFullWithTax += ls.fullAmountWithTax ? ls.fullAmountWithTax : 0;
                })
            }
            if (value.value === 'Prorated Without Tax') {
                if(this.form.get('vasRefurnd').value){
                    this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = 
                    (this.subscriberCurrentPlanData.currentPlanDTO[0].proratedAmount ?
                    this.subscriberCurrentPlanData.currentPlanDTO[0].proratedAmount : 0) + vasProratedWithoutTax;
                }
                else{
                    this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = 
                    this.subscriberCurrentPlanData.currentPlanDTO[0].proratedAmount ? 
                    this.subscriberCurrentPlanData.currentPlanDTO[0].proratedAmount : 0;
                }
            } else if (value.value === 'Prorated With Tax') {
                if(this.form.get('vasRefurnd').value){
                    this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = 
                    (this.subscriberCurrentPlanData.currentPlanDTO[0].proratedAmountWithTax ? 
                    this.subscriberCurrentPlanData.currentPlanDTO[0].proratedAmountWithTax : 0) + vasProratedWithTax;
                }
                else{
                    this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = 
                    this.subscriberCurrentPlanData.currentPlanDTO[0].proratedAmountWithTax ?
                    this.subscriberCurrentPlanData.currentPlanDTO[0].proratedAmountWithTax : 0;
                }
            } else if (value.value === 'None') {
                this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = "0";
            }
            else if (value.value === 'Full Without Tax') {
                if(this.form.get('vasRefurnd').value){
                    this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = 
                    (this.subscriberCurrentPlanData.currentPlanDTO[0].fullAmount ?
                    this.subscriberCurrentPlanData.currentPlanDTO[0].fullAmount : 0 )+ vasFullWithoutTax;
                }
                else{
                    this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = 
                    this.subscriberCurrentPlanData.currentPlanDTO[0].fullAmount ? 
                    this.subscriberCurrentPlanData.currentPlanDTO[0].fullAmount : 0;
                }
            }
            else if (value.value === 'Full With Tax') {
                if(this.form.get('vasRefurnd').value){
                    this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = 
                    (this.subscriberCurrentPlanData.currentPlanDTO[0].fullAmountWithTax ?
                    this.subscriberCurrentPlanData.currentPlanDTO[0].fullAmountWithTax : 0) + vasFullWithTax;                    
                }
                else{
                    this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = 
                    this.subscriberCurrentPlanData.currentPlanDTO[0].fullAmountWithTax ? 
                    this.subscriberCurrentPlanData.currentPlanDTO[0].fullAmountWithTax : 0;
                }
            }
        }
    }
    else {
        this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount = '0';
    }
  }

  get f() 
  {
    return this.form.controls;
  }

  checkOverHead(event: any) 
  {
    if (event === 'true') {
        ////console.log('over head::', event);
        this.form.get('isPriceOverride').setValue(true);
        this.isOverhead = true;
        this.purchaseaddonprice = 0;
        this.form.get('sellPrice').setValidators([Validators.required]);
    } else {
        this.purchaseaddonprice = this.oldPurchasePrice;
        this.form.get('isPriceOverride').setValue(false);
        this.isOverhead = false;
        this.taxDetailsByPlanData = this.selectedPlan.taxAmount;
        this.planPrice = this.selectedPlan.price;
        this.form.get('sellPrice').clearValidators();
        this.form.get('sellPrice').updateValueAndValidity();
    }
  }

  keypress(event: any) 
  {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
        event.preventDefault();
    }
  }

  onSkipCheck() 
  {
    this.form.get('isBalanceUsed').setValue(false);
    if (this.form.value.skip === true) {
        this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
    } else {
    }
  }

  setFinalPaybel() {
    if (this.ui_id === 'purchase-plan') {
        if (this.advanceRenewFlag === true && (this.form.value.skip === null || this.form.value.skip === false)) {
            this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
        }
        if (this.advanceRenewFlag === false && this.form.value.skip === true) {
            this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
        }
        if (this.purchaseType === 'Upgrade') {
            this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
        }
        if (this.purchaseType === 'New') {
            this.finalpaybal = this.planPrice + this.taxDetailsByPlanData
        }
        if (this.purchaseType !== 'Upgrade' && this.advanceRenewFlag === false && (this.form.value.skip === null || this.form.value.skip === false)) {
            this.refundCal();
        }
    }
    if (this.ui_id === 'purchase-addon-plan') {
        if (this.isOverhead) {
            this.purchaseaddonprice;
        }
    }
  }

  refundCal() {
    if (this.purchaseType !== 'New') {
        this.finalAmount = ((this.planPrice + this.taxDetailsByPlanData + this.vasprice) - this.subscriberCurrentPlanData.currentPlanDTO[0].refundableAmount);
        if (this.finalAmount < 0) {
            this.finalAmount = 0;
        }
        this.finalpaybal = this.finalAmount;
        this.finalpaybal = Number(this.finalpaybal.toFixed(2));
        return this.finalAmount.toFixed(2);
    }
  }

  onPaymentTypeChange(event) 
  {
    //this.form.get('isBalanceUsed').reset();
    if (event === 'YES') {
        this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
        this.form.get('recordPaymentDTO').get('amount').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('payment_date').setValidators(Validators.required);
        this.form.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('payment_mode').setValidators(Validators.required);
        this.form.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('referenceNo').setValidators(Validators.required);
        this.form.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
        // this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
        // this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
        // this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
        // this.form.get('recordPaymentDTO').get('amount').setValidators(Validators.required);
    } else {

        this.form.get('recordPaymentDTO').get('amount').clearValidators();
        this.form.get('recordPaymentDTO').get('amount').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('payment_date').clearValidators();
        this.form.get('recordPaymentDTO').get('payment_date').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('payment_mode').clearValidators();
        this.form.get('recordPaymentDTO').get('payment_mode').updateValueAndValidity();
        this.form.get('recordPaymentDTO').get('referenceNo').clearValidators();
        this.form.get('recordPaymentDTO').get('referenceNo').updateValueAndValidity();
    }
  }

  checkamount()
  {
    let oldfinalpay;
    if(this.ui_id === 'purchase-plan'){
        oldfinalpay = this.planPrice + this.taxDetailsByPlanData;
      }
      else{
        if (!this.isOverhead) {
            oldfinalpay = this.purchaseaddonprice + this.selectedPlan.taxamount;
        }
        if (this.isOverhead) {
            oldfinalpay = this.purchaseaddonprice;
        }
      }
    let amount = this.form.get('recordPaymentDTO').get('amount').value;
    if(this.bal >= this.finalpaybal && this.form.get('isBalanceUsed').value == true){
        if(amount == oldfinalpay || amount == 0){
            this.collectorFlag = false;
            this.form.get('balanceAmount').setValue(this.bal);
            //this.form.get('collectorId').reset();
        }else{
            this.collectorFlag = true;
            this.form.get('balanceAmount').setValue(oldfinalpay);
        }
    }else{
        this.collectorFlag = true;
      }
  }

  onClickUpdatePaymentForm() 
  {
    if ((this.form.get('recordPaymentDTO').valid === false && this.ui_id !== 'purchase-addon-plan') || (this.form.get('recordPaymentDTO').valid === false && this.ui_id === 'purchase-addon-plan')) {
        this.tosterservice.error('Please Fill All payment Details');
        this.btnSaveDisable=false;
    }
    if (this.isOverhead === true) {
        ////console.log('aaa =>', this.form);
        //this.onOverridePrice(this.form.get('sellPrice').value);
        if (this.form.invalid) {
            return;
        }
        // if (Number(this.form.get('sellPrice').value) && this.selectedPlan.minPrice !== null && this.selectedPlan.maxPrice !== null) {
        //     if (Number(this.form.get('sellPrice').value) < this.selectedPlan.minPrice && Number(this.form.get('sellPrice').value) > this.selectedPlan.maxPrice) {
        //         this.tosterservice.error('Override price Sholud be between ' + this.selectedPlan.minPrice + ' - ' + this.selectedPlan.maxPrice);
        //         return;
        //     }
        //     if (Number(this.form.get('sellPrice').value) > this.selectedPlan.maxPrice) {
        //         this.tosterservice.error('Override price Sholud be less than ' + this.selectedPlan.maxPrice);
        //         return;
        //     }
        //     if (Number(this.form.get('sellPrice').value) < this.selectedPlan.minPrice) {
        //         this.tosterservice.error('Override price Sholud be greater than ' + this.selectedPlan.minPrice);
        //         return;
        //     }
        // }
        this.form.get('sellPrice').setValidators(Validators.required);
        this.form.get('sellPrice').updateValueAndValidity();
        if (this.form.get('sellPrice').valid === false) {
            this.btnSaveDisable=false;
            this.tosterservice.error('Please Enter Override Price!');
            return;
        }


    } else {
        this.form.get('sellPrice').clearValidators;
        this.form.get('sellPrice').updateValueAndValidity();
        this.form.get('isPriceOverride').setValue(false);
        this.form.get('isPriceOverride').updateValueAndValidity();

    }

    if (this.form.valid === true) {
       
        for (const prop in this.form.controls) {
            this.form.value[prop] = this.form.controls[prop].value;
          }
        let formAllValue = Object.assign({}, this.form.value);
        if (formAllValue.isPaymentReceived && formAllValue.isPaymentReceived == true) {

            if (formAllValue.recordPaymentDTO.payment_mode !== 'tds' && formAllValue.recordPaymentDTO.tdsDeducted === true) {
                if (formAllValue.recordPaymentDTO.tdsAmount.length === 0) {
                    this.tdsAmountValid = false;
                    this.submitted = true;
                    this.btnSaveDisable=false;
                    this.form.markAllAsTouched();
                    return false;
                } else {
                    this.tdsAmountValid = true;
                }
            }

            if (formAllValue.recordPaymentDTO.amount.length === 0) {
                this.amountValid = false;
            } else {
                this.amountValid = true;
            }

            if (formAllValue.recordPaymentDTO.payment_date.length === 0) {
                this.paymentDateValid = false;
            } else {
                this.paymentDateValid = true;
            }

            if (formAllValue.recordPaymentDTO.remarks.length === 0) {
                this.remarksValid = false;
            } else {
                this.remarksValid = true;
            }

            if (formAllValue.recordPaymentDTO.referenceNo.length === 0) {
                this.referenceNoValid = false;
            } else {
                this.referenceNoValid = true;
            }

            let payment_mode_id = formAllValue.recordPaymentDTO.payment_mode;

            if (_.isEmpty(payment_mode_id) === false) {
                this.paymentModeValid = true;
                let selected_payment_mode = _.find(this.paymentModeList, { value: payment_mode_id });
                let payment_mode = selected_payment_mode.value;
                if (payment_mode === 'cheque') {
                    if (formAllValue.recordPaymentDTO.chequeNo.length === 0) {
                        this.chequeNoValid = false;
                    } else {
                        this.chequeNoValid = true;
                    }
                    if (formAllValue.recordPaymentDTO.bankName.length === 0) {
                        this.bankNameValid = false;
                    } else {
                        this.bankNameValid = true;
                    }
                    if (formAllValue.recordPaymentDTO.chequeDate.length === 0) {
                        this.chequeDateValid = false;
                    } else {
                        this.chequeDateValid = true;
                    }
                    if (formAllValue.recordPaymentDTO.branch.length === 0) {
                        this.branchValid = false;
                    } else {
                        this.branchValid = true;
                    }
                    if (formAllValue.recordPaymentDTO.chequeNo.length === 0 ||
                        formAllValue.recordPaymentDTO.bankName.length === 0 ||
                        formAllValue.recordPaymentDTO.chequeDate.length === 0 ||
                        formAllValue.recordPaymentDTO.branch.length === 0 ||
                        formAllValue.recordPaymentDTO.remarks.length === 0
                    ) {
                        this.submitted = true;
                        this.form.markAllAsTouched();
                        return false;
                    } else {
                        this.askQuestion();
                    }
                } else {
                    if (formAllValue.recordPaymentDTO.remarks.length === 0 ||
                        formAllValue.recordPaymentDTO.amount.length === 0 ||
                        formAllValue.recordPaymentDTO.payment_date.length === 0) {
                        this.submitted = true;
                        this.form.markAllAsTouched();
                        return false;
                    } else {
                        this.askQuestion();
                    }
                }
            } else {
                this.paymentModeValid = false;
                this.form.markAllAsTouched();
                return false;
            }
        } else {
            this.askQuestion();
        }
    } else {

        let addonStartDate = this.form.get('addonStartDate').value;
        if (this.ui_id != 'purchase-addon-plan') {
            this.form.get('addonStartDate').clearValidators();
            this.form.get('addonStartDate').updateValueAndValidity();
            //this.onClickUpdatePaymentForm();
            this.submitted = true;
            this.form.markAllAsTouched();
        } else {
            this.submitted = true;
            this.form.markAllAsTouched();
        }

    }
  }

  askQuestion() 
  {
    
    let self = this;
    this.submitted = false;
    let title: string;
    if (this.ui_id == 'purchase-addon-plan') {
        title = 'Purchase add on plan';
    } else {
        title = 'Change Plan';
    }
    alertify.confirm(title, 'Are You Sure You want to ' + title + ' ?', function () {
        self.insertChangePlanOnDb();
        this.btnSaveDisable=true;
    },
        function () {
            this.btnSaveDisable=false;
        });
  }

  insertChangePlanOnDb() 
  {
    this.loadingService.show(); //new changes
    
    if(this.voiceServiceArcd.length > 0){
        this.voiceServiceArcd.forEach(res => {
          if(res.selected == true){
              this.selectedVASIds.push(res.id)
          }
        })
      }
      
      if(this.ottServiceArcd.length > 0){
        this.ottServiceArcd.forEach(res => {
          if(res.selected == true){
              this.selectedVASIds.push(res.id)
          }
        })
      }
      
      if(this.iptvSetrviceArcd.length > 0){
        this.iptvSetrviceArcd.forEach(res => {
          if(res.selected == true){
              this.selectedVASIds.push(res.id)
          }
        })
      }

    let apiInputData = Object.assign({}, this.form.value);
    if (apiInputData.skip == true) {
        apiInputData.skip = false;
    } else {
        apiInputData.skip = true;
    }

    let recordPaymentDTO: any = null;
    let advRenewal = apiInputData.advRenewal;
    if (advRenewal == null && (this.purchaseType === 'Upgrade' || this.purchaseType === 'Volume Booster' || this.purchaseType === 'New')) {
        advRenewal = false;
    }
    console.log('data: ', this.selectedVASIds);
    let formatedData: any = {};
    if (this.ui_id != 'purchase-addon-plan') {
        formatedData = {
            'custId': this.custId,
            'isRefund': apiInputData.skip,
            'isAdvRenewal': advRenewal,
            'planId': apiInputData.planId,
            'purchaseType': apiInputData.planPurchaseType,
            'remarks': apiInputData.remarks,
            'sellPrice': apiInputData.sellPrice,
            'isIncludeTax': apiInputData.isIncludeTax ? apiInputData.isIncludeTax : false,
            'isPriceOverride': apiInputData.isPriceOverride,
            'refundOption': apiInputData.refundOption ? apiInputData.refundOption : null,
            'selectedVASIds': this.selectedVASIds,
            'refundVAS': apiInputData.vasRefurnd
            //'collectorId':apiInputData.collectorId?apiInputData.collectorId: null,
        };
    } else {
        apiInputData.addonStartDate = this.datePipe.transform(this.addonStartDate, 'yyyy-MM-dd');
        formatedData = {
            'custId': this.custId,
            'isRefund': apiInputData.skip,
            'isAdvRenewal': advRenewal,
            'planId': apiInputData.planId,
            'purchaseType': apiInputData.planPurchaseType,
            'remarks': apiInputData.remarks,
            'addonStartDate': apiInputData.addonStartDate,
            'isPaymentReceived': apiInputData.isPaymentReceived,
            'sellPrice': apiInputData.sellPrice,
            'isIncludeTax': apiInputData.isIncludeTax ? apiInputData.isIncludeTax : false,
            'isPriceOverride': apiInputData.isPriceOverride,
            'refundVAS': apiInputData.vasRefurnd
            //'collectorId':apiInputData.collectorId?apiInputData.collectorId: null,
        };
    }
    if (apiInputData.advRenewal === '') {
        formatedData.advRenewal = false;
    }
    if (apiInputData.isPaymentReceived && apiInputData.isPaymentReceived == 'yes') {
        formatedData.isPaymentReceived = true;

        recordPaymentDTO = {};
        recordPaymentDTO.custId = this.custId;
        recordPaymentDTO.paymentAmount = apiInputData.recordPaymentDTO.amount;
        recordPaymentDTO.paymentDate = this.datePipe.transform(apiInputData.recordPaymentDTO.payment_date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
        recordPaymentDTO.referenceNo = apiInputData.recordPaymentDTO.referenceNo;
        recordPaymentDTO.paymentMode = apiInputData.recordPaymentDTO.payment_mode;
        // recordPaymentDTO.isBalanceUsed = apiInputData.isBalanceUsed;
        // if(recordPaymentDTO.isBalanceUsed == true){
        //     recordPaymentDTO.balanceAmount = apiInputData.balanceAmount;
        // }

        let selectedPaymentModeData = _.find(this.paymentModeList, { value: apiInputData.recordPaymentDTO.payment_mode });
        //if paymentMode  cheque
        if (selectedPaymentModeData.value === 'cheque') {
            recordPaymentDTO.bankName = apiInputData.recordPaymentDTO.bankName;
            recordPaymentDTO.branch = apiInputData.recordPaymentDTO.branch;
            recordPaymentDTO.chequeDate = apiInputData.recordPaymentDTO.chequeDate;
            recordPaymentDTO.chequeNo = apiInputData.recordPaymentDTO.chequeNo;
        }

        // if paymentMode  TDS
        if (selectedPaymentModeData.value === 'TDS') {
            recordPaymentDTO.credit_doc_id = apiInputData.recordPaymentDTO.credit_doc_id;
        }

        recordPaymentDTO.isTdsDeducted = apiInputData.recordPaymentDTO.tdsDeducted;
        // if isTdsDeducted true
        if (recordPaymentDTO.isTdsDeducted === true) {
            recordPaymentDTO.tdsAmount = apiInputData.recordPaymentDTO.tdsAmount;
        }
        recordPaymentDTO.remarks = apiInputData.remarks;
    } else {
        formatedData.isPaymentReceived = false;
    }
    formatedData.remarks = apiInputData.remarks
    formatedData.recordPaymentDTO = recordPaymentDTO;
    
    this.loadingService.show();
    this.btnSaveDisable=true;
    this.changePlanService.changePlan(formatedData).subscribe(
        (result) => {
            
            this.loadingService.hide();
            if (result.responseCode !== 200) {
                this.btnSaveDisable=false;
                this.tosterservice.error(result.responseMessage);
            } else {
                if (this.ui_id == 'purchase-addon-plan') {
                    this.tosterservice.success('purchase add on plan Save Successfully.');
                    this.btnSaveDisable=false;
                    this.customModalService.close('purchase-addon-plan', result);
                    this.form.reset();
                    this.checkeligiblity = null;
                    this.selectedPlan = null;
                    this.endDate = null;

                } else {
                    this.tosterservice.success('Change Plan Save Successfully.');
                    this.btnSaveDisable=false;
                    this.isOpenedOnce = true;
                    this.customModalService.close('purchase-plan', result);
                    this.form.reset();
                }
            }
        }, err => {
            this.loadingService.hide();
            this.btnSaveDisable=false;
            this.tosterservice.error(err.error.status + ' ' + err.error.ERROR);
        }
    );
  }

  onAddonSelect(event) {
    this.purchaseEndDate = new Date(event.value);
    this.addonStartDate = new Date(event.value);
    this.purchaseEndDate.setDate(this.purchaseEndDate.getDate() + this.selectedPlan.validity);
    this.endDate = this.datePipe.transform(this.purchaseEndDate, 'dd-MM-yyyy');
  }

  onChangePaymentMode(el) 
  {
    if (el) {
        let payment_mode_value = el.value;
        this.selected_payment_mode_value = payment_mode_value;
        if (this.selected_payment_mode_value === 'TDS') {
            this.recordPaymentService.getTdsPendingPayments(this.custId).subscribe(result => {
                this.tdsPendingPaymentsList = result.dataList;
            });
        }
    } else {
        this.selected_payment_mode_value = '';
    }
  }

  onChangeTdsDeducted(el) 
  {
    let is_tds_deducted = el;
    this.selected_tds_deducted = is_tds_deducted;
  }

  closeModal(id: string) 
  {
    this.customModalService.close(id, { close_from: 'close_button' });
    this.isOpenedOnce = true;
    this.form.reset();
    this.selectedPlan = null;
    this.endDate = null;
    this.req_message = null;
  }
  
  getActivePlanData() {
    let obj=    {
      custId: this.custId,
      purchaseType: "plan"
    }
    this.activePlanListService.getPlanListForActivation(obj).subscribe(result => {
      this.activePlanTableData = result.data;
      if(this.activePlanTableData.length > 0) {
        this.submitBtnDisable= true; 
      }else{
        this.submitBtnDisable= false; 
      }
    });
  }
}

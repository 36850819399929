import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { TroubleTicketDetailsService } from './trouble-ticket-details.service';
import { ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchData } from '../../../../core/modal/search-data';
import { SearchService } from '../../../../services/search.service';
import { SubscriberLeasedLineDetailsComponent } from '../subscriber-leased-line-details.component';
import { OldAbstractBaseListComponent } from '../../../../components/base/old-abstract-base-list-component';
import { filter } from 'rxjs/operators';
import { Updatelistmodel } from '../../../../pages/customer-care/cases/model/updatelistmodel';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { CasesService } from '../../../../pages/customer-care/cases/service/cases.service';
import { CasesModel } from '../../../../pages/customer-care/cases/model/cases-model';
import { DatePipe } from '@angular/common';
import { LoginService } from '../../../../pages/login/login.service';
import { AclClassConstants } from '../../../../pages/aclClassConstants';
import { AclConstants } from '../../../../pages/aclOperationConstants';
declare let $;

@Component({
  selector: 'app-trouble-ticket-details',
  templateUrl: './trouble-ticket-details.component.html',
  styleUrls: ['./trouble-ticket-details.component.scss']
})
export class TroubleTicketDetailsComponent extends OldAbstractBaseListComponent implements OnInit {

  @ViewChild('cancelWrapper', { static: false }) cancelWrapperRef: TemplateRef<any>;
  @ViewChild('editCases', { static: false }) editCasesReff: TemplateRef<any>;
  // @ViewChild(SubscriberDetailsComponent, { static: false }) subscriberdetailReff: SubscriberDetailsComponent;
  // @ContentChild(SubscriberDetailsComponent, {static: false}) subscriberdetailReff: SubscriberDetailsComponent;


  @Input() leasedLineId: any;
  @Input() name:any;
  @Output() onCaseRefresh = new EventEmitter<any>();
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  listTemp: any = [];
  QuickCaseFlag:string;
  editviewId:any
  dataSource = new MatTableDataSource([]);
  ticketDetailsNeoGridSettings: any = {};
  closeCaseData: any;
  subscriptions: Subscription[] = [];
  remark: any = null;
  data: any;
  ticketDetailsTableData: any;
  gridSetting: any;
  id: any;
  //customerName: any;
  updateList:Updatelistmodel;
  entity: CasesModel
  constructor(
    private troubleTicketDetailsService: TroubleTicketDetailsService,
    private ngbModalService: NgbModal,
    private toaster: ToastrService,
    private router: Router,
    private searchService: SearchService,
    private ngbService:NgbModal,
    private route : ActivatedRoute,
    private CommonListService : CommonListServicesService,
    private caseService : CasesService,
    private datePipe: DatePipe,
    loginService: LoginService,
  ) {
    super(searchService)
    this.loginService = loginService;
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.TROUBLETICKETSDETAILS)
    this.id = this.route.snapshot.paramMap.get('id');
    this.setGridSetting();
    //this.getListWithParams();
    this.initPaginationSort();
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      this.applyFilter(obj);
    });
    this.subscriptions.push(filterSubscription);
    this.getListWithParams();
    //this.getLeaseSubscriberById();
  }
  public getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    this.paginationData.rowsPerPage = 5;
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.gridSetting = config;

    this.getTroubleTicketsDetailsData();
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'action') {
        col.customControls = [this.cancelWrapperRef];
      }
      if (col.colKey === 'caseNumber') {
        col.customControls = [this.editCasesReff];
      }
    });
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.ticketDetailsNeoGridSettings.content));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;
    if (this.ticketDetailsNeoGridSettings.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters, this.ticketDetailsNeoGridSettings);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  // onFilterChange(filterObj) {
  //   let serverFilterObj: SearchData[] = this.searchService.getServerFilterObj();
  //   if (filterObj) {
  //     let searchData = new SearchData();
  //     searchData = filterObj;
  //     if (!Array.isArray(searchData) && searchData.filterColumn) {
  //       if (searchData.filterValue) {
  //         serverFilterObj = this.searchService.setServerFilterObj([searchData]);
  //       } else {
  //         serverFilterObj = this.searchService.resetGlobalSearchServerFilterObj();
  //       }
  //     } else {
  //       serverFilterObj = this.searchService.setServerFilterObj(filterObj);
  //     }
  //   } else {
  //     serverFilterObj = this.searchService.resetAdvanceSearchServerFilterObj();
  //   }
  //   this.searchData = JSON.parse(JSON.stringify(serverFilterObj));
  //   this.getListWithParams();
  //   return serverFilterObj;
  // }


  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'caseNumber',
        default: 'Ticket No',
        rowKey: 'caseNumber',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      // {
      //   colKey: 'currentAssigneeName',
      //   default: 'Contact Person',
      //   rowKey: 'currentAssigneeName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      {
        colKey: 'reason',
        default: 'Reason',
        rowKey: 'reason',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'caseStatus',
        default: 'Status',
        rowKey: 'caseStatus',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },

      {
        colKey: 'createdate',
        default: 'Date',
        rowKey: 'createdate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'finalResolutionDate',
      //   default: 'Resolution Date',
      //   rowKey: 'finalResolutionDate',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'finalResolvedByName',
      //   default: 'Resloved By',
      //   rowKey: 'finalResolvedByName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'finalResolutionName',
      //   default: 'Resolution Given',
      //   rowKey: 'finalResolutionName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      {
        colKey: 'createdByName',
        default: 'Created By',
        rowKey: 'createdByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'action',
        default: 'Action',
        rowKey: 'action',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'caseTitle',
      //   default: 'Title',
      //   rowKey: 'caseTitle',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // }
    ];

    this.ticketDetailsNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.TROUBLETICKETSDETAILS,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      serverSideFilter: true,
      serverSideSortPagination: true,
    };
  }
  openCase(trouble, data) {
    this.remark = null;
    this.closeCaseData = data;
    this.ngbModalService.open(trouble, { size: 'lg' });
  }
  openUpdate(update,data) {
    this.getCaseById(data.caseId);
     this.updateList = new Updatelistmodel();
     this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
       res.dataList.forEach((x) => {
         if (x.value === "external") {
           this.updateList.remarkType = x.text;
         }
       });
     });
     setTimeout(() => {
       this.ngbService.open(update, { size: 'lg' });
      }, 500);
   }
   getCaseById(id){
    this.caseService.getById(id).subscribe((res)=>{
      if(res.responseCode == 200){
        this.entity = res.data;
      }
    })
  }
  onEdit(event,editviewmode) {
    this.editviewId =  event.caseId
    this.ngbService.open(editviewmode, { size: 'lg' });
    //this.onEditCaseModule(event);
  }
  onEditCaseModule(event) {
    this.router.navigate(['/customer-care/cases/add-cases'], { queryParams: { id: event.caseId } });
  }
  onQuickCasePopup(caseDetail) {
    this.QuickCaseFlag = 'Lease Line';
   
    this.listTemp = ['name', 'username', 'phone', 'email'];
    
    this.ngbService.open(caseDetail);
   
  }

  refreshPage(data){
    this.updateFormSubmit()
  }

  updateFormSubmit() {
    this.updateList.resolutionId = this.entity.finalResolutionId;
    this.updateList.nextFollowupDate = this.datePipe.transform(this.entity.nextFollowupDate, 'yyyy-MM-dd');
    if(typeof(this.entity.nextFollowupTime) == 'object'){
      this.updateList.nextFollowupTime = this.datePipe.transform(this.entity.nextFollowupTime,'hh:mm:ss')
    }
    else{
      this.updateList.nextFollowupTime = this.entity.nextFollowupTime
    }
    this.updateList.suspendDate = this.datePipe.transform(this.entity.suspendDate, 'yyyy-MM-dd');
    this.updateList.filename = "abc"

    this.updateList.ticketId = this.entity.caseId;
    this.updateList.reasonId = this.entity.caseReasonId;
    this.updateList.status = this.entity.caseStatus;
    this.updateList.priority = this.entity.priority;
    this.updateList.commentBy = 'Staff';
    this.updateList.bucketType = this.entity.bucketType;
    this.updateList.title = this.entity.caseTitle;
    this.updateList.serviceAreaId = this.entity.serviceAreaId;
    this.updateList.assignee = this.entity.currentAssigneeId;
    this.updateList.teamType = this.entity.teamType;
    this.updateList.allocationType = this.entity.allocationType
    this.updateList.algorithmType = this.entity.algorithmType;
    this.caseService.updateList(this.updateList).subscribe((res) => {
      if(res.responseCode == 200){
        //this.entity = res.data;
        this.toaster.success('Case Update Successfully')
        this.getTroubleTicketsDetailsData();
      }
      else{
        this.toaster.error(res.responseMessage)
      }

    });
    this.ngbService.dismissAll();
  }

  onCancel(){
    this.ngbService.dismissAll();
  }

  getlist(){
    //this.getListWithParams();
    //this.getList(this.paginationData, this.orderingData, this.searchData);
    this.getTroubleTicketsDetailsData();
  }
  getTroubleTicketsDetailsData() {
    let formatedInputData: any = {};
    let fetchSettingData = this.ticketDetailsNeoGridSettings.serverSideSortPagination ? this.gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    // params.f(ilters[0].filterId=this.leasedLineId;
    if(params.filters){
   
    params.filters[0].filterId = this.leasedLineId;
    //////console.log(params.filters[0]);
    }
    formatedInputData = Object.assign(params);
    formatedInputData.id = this.leasedLineId;
    //caseData.filters = params;
    //////console.log(formatedInputData);
    this.troubleTicketDetailsService.getTroubleTicketsDetailsList(formatedInputData).subscribe(result => {
      this.ticketDetailsTableData = result.dataList;
      //this.customerName = this.name;
      this.ticketDetailsNeoGridSettings.pageTotalLength = result && result.totalRecords > 0 ? result.totalRecords : 0
      this.refreshDataSource(this.ticketDetailsTableData);

      this.onCaseRefresh.emit(result.data);
      return
    });
  }

  saveCase() {
    let caseData: any = {};
    if (this.remark != null) {
      let ticketId = this.closeCaseData.caseId;
      //////console.log(ticketId);
      let caseStatus = 'Resolved'
      caseData.remark = this.remark;
      caseData.ticketId = ticketId;
      caseData.status = caseStatus;
      this.troubleTicketDetailsService.updateData(caseData).subscribe(res => {
        if (res.responseCode !== 200) {
          this.toaster.error(res.responseMessage);
        } else {
          this.toaster.success("Data has been Successfully saved");
          this.getTroubleTicketsDetailsData();
          this.ngbModalService.dismissAll();
        }
      })
    } else {
      this.toaster.error("Please Enter remark");
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.gridSetting = config;
    this.getTroubleTicketsDetailsData();
  }

}

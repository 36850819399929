import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../services/base-service';
import { Property } from '../../model/Property';
import { Observable, throwError } from 'rxjs';
import { ResponseData } from '../../../../core/modal/base-save-update-response';
import { RequestData } from '../../../../core/modal/base-save-update-modal';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { SearchData } from '../../../../core/modal/search-data';
import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { propDocumentUploadModel } from '../../model/propertyDocupload';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};


@Injectable({
  providedIn: 'root'
})
export class PropertyServiceService extends BaseService<Property> {

  leasedId: any;
  propertyId: any;
  constructor(
    public http: HttpClient
  ) {
    super(http)
  }
  validate(): boolean {
    return false;
  }
  getModuleName(): string {
    return "leasedlinesubscriber";
  }
  setId(id) {
    this.leasedId = id;
  }
  getByproperty(id) {
    this.propertyId = id;
  }
  save(modal: any): Observable<ResponseData<any>> {
     let reqObj: RequestData<any> = new RequestData<any>();
     reqObj.requestToken = this.getRequestToken();
     reqObj.data = modal;
       
    return this.http.post<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/saveProperty', reqObj.data);
  }

  saveDocument(modal: any){
    
    const post_url = baseApiUrl+ this.getModuleName() +'/uploadPropertyDoc';
    return this.http.post<any>(post_url,modal).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }


  update(modal: any): Observable<ResponseData<any>> {
    let reqObj: RequestData<any> = new RequestData<any>();
    reqObj.requestToken = this.getRequestToken();
    reqObj.data = modal;

    return this.http.post<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/updateProperty', reqObj.data);
  }

  getAll(
    paginationData: PaginationData,
    orderingData: OrderingData,
    searchData?: SearchData[],
    status?: any,
    filterBy?: any
  ): Observable<ResponseData<Property>> {
    let searchDataObj = searchData && searchData.length > 0 ? JSON.stringify(searchData) : null;
    let params: any = {};

    params.id = this.leasedId;
    if (paginationData) {
      params.page = paginationData.pageNumber.toString();
      params.pageSize = paginationData.rowsPerPage.toString();

    }
    if (orderingData) {
      params.sortBy = orderingData.column;
      params.sortOrder = (orderingData.descending ? 1 : 0) + '';

    }

    if (searchDataObj) {
      params.filters = JSON.parse(searchDataObj);

    }
    if (status) {

      params.status = status;
    }
    if (filterBy) {
      params.filterBy = filterBy;
    }


    return this.http.post<ResponseData<Property>>(baseApiUrl + this.getModuleName() + '/getAllPropertyByLeaseLine', params, {
      headers: httpOptions.headers
    });
    return null;
  }
  getById(id: number): Observable<ResponseData<Property>> {
    return this.http.get<ResponseData<Property>>(baseApiUrl + this.getModuleName() + '/getPropertyById/' + id, {
      headers: httpOptions.headers,
    });
    return null;
  }

 
  getSubscriberById(id) {
    return this.http.get<any>(baseApiUrl + 'leasedlinesubscriber/' + id);
  }

  propertyList(leaselineId: any): Observable<ResponseData<any>> {
       return this.http.get<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/getAllPropertyByLeaseLine/'+ leaselineId,{
        headers: httpOptions.headers,
       } );
  }

  circuitList(propertyId: any): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/getAllCircuitByProperty/'+ propertyId,{
     headers: httpOptions.headers,
    } );

 }

  updateDocument(modal:propDocumentUploadModel): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(baseApiUrl + this.getModuleName() + '/updatePropertyDoc',modal );
  }

}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OldAbstractBaseListComponent } from '../../../../../src/app/components/base/old-abstract-base-list-component';
import { TableKeyEnum } from '../../../../../src/app/core/enum/table-key.enum';
import { SearchService } from '../../../../../src/app/services/search.service';
import { NetworktreeService } from '../network-tree/service/networktree.service'

@Component({
  selector: 'app-view-slot',
  templateUrl: './view-slot.component.html',
  styleUrls: ['./view-slot.component.scss']
})
export class ViewSlotComponent extends OldAbstractBaseListComponent implements OnInit {
  // @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild ('editCustomerName',{static:false}) customerNameRef:TemplateRef<any>;

  onuListArray:any;
  networkslotGridSettings:any={};
  tableKeyEnum = TableKeyEnum;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  commonYN :any[] = [];
  selectedSnmpEnable:string
  dataSource = new MatTableDataSource([]);
  uuid:any;
  subscriptions: Subscription[] = [];
  frameid:any;
  portid:any;
  slotid:any;
  
  constructor(private route: ActivatedRoute,
    private router: Router,
    private toastr:ToastrService,
    private networktreeService:NetworktreeService,
    private searchService: SearchService) {
    super(searchService);
   }
  
 
  ngOnInit() {
  this.setDefaultPageSize(this.tableKey.NETWORK_DEVICE_ONU_LIST)
  let id = this.route.snapshot.paramMap.get('id');
  let frame = this.route.snapshot.paramMap.get('frame');
  let slot = this.route.snapshot.paramMap.get('slot');
  let port = this.route.snapshot.paramMap.get('port');
 
 if (id) {
     this.uuid=id;
     }else{
       this.uuid=null;
     }

  if(slot) {
    this.slotid=slot;
   }else{
    this.slotid=null;
  }

  if(frame) {
   this.frameid=frame;
     }else{
   this.frameid=null;
    }

  if(port) {
   this.portid=port;
     }else{
   this.portid=null;
    } 
  
  this.onFetchData();
  const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
    // if (obj && obj.filters) {
    this.applyFilter(obj);
    // }else
  });
  this.setGridSetting();
  this.initPaginationSort();
  this.subscriptions.push(filterSubscription);
  }

  setGridSetting() {
    
    let self = this;
    this.allColumns = [
      {
        colKey: 'userName',
        default: 'userName',
        rowKey: 'userName',
        width: '20%',
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true
      },
      {
        colKey: 'serialnumber',
        default: 'serial number',
        rowKey: 'onuid',
        width: '20%',
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true
      },
      {
        colKey: 'onuPower',
        default: 'onu Power',
        rowKey: 'onuPower',
        width: '20%',
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true
      }
    ];

    this.networkslotGridSettings = {
      showFilter:true,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKey.NETWORK_DEVICE_ONU_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      serverSideFilter: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Slot Detail ?',
      deleteMsgBoxDetail: '"Slot Details Name :${name} " '
    };
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if(col.colKey=='userName'){
        col.customControls=[this.customerNameRef]
      }
    });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getNetworkDeviceFrameData(gridSetting: any) {
    
    let fetchSettingData = this.networkslotGridSettings.serverSideSortPagination ?  gridSetting :null;
    let paramsValue: any = {paginationRequestDTO:{},uuid:this.uuid,frame:this.frameid,slot:this.slotid,port:this.portid};
    
    let params = this.getGridSettingInfo(fetchSettingData);
    paramsValue.paginationRequestDTO.page = params.page;
    paramsValue.paginationRequestDTO.pageSize = params.pageSize;
    paramsValue.paginationRequestDTO.sortBy = params.sortBy;
    paramsValue.paginationRequestDTO.sortOrder = params.sortOrder;
    paramsValue.uuid = this.uuid;
    paramsValue.frame = this.frameid;
    paramsValue.slot = this.slotid;
    paramsValue.port = this.portid
    paramsValue.paginationRequestDTO.filters=params.filters;
   
    
    this.networktreeService.getONUList(paramsValue).subscribe(result => {
      if(result.responseCode===200){
        this.onuListArray = result.dataList;
        console.log("ONULIST",this.onuListArray);
        
        this.refreshDataSource(this.onuListArray);
        this.totalRecords = result.totalRecords;
        this.networkslotGridSettings.pageTotalLength = this.totalRecords;
        this.networkslotGridSettings.perPage=result.pageRecords;
       
      
      }else{
        this.toastr.error("Data Not Found");
        this.refreshDataSource([]);
        this.totalRecords = result.totalRecords;
        this.networkslotGridSettings.pageTotalLength = this.totalRecords;
      }
      
    });
  }

  getListWithParams() {
    
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    
    this.getNetworkDeviceFrameData(config);
  }

  applyFilter(filterObj) {
  let filteredDataForGrid = JSON.parse(JSON.stringify(this.onuListArray));
     if (filterObj && filterObj.filters) {
       filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.onuListArray);
     }
    this.refreshDataSource(filteredDataForGrid);
  }

  setPagination(event){
   
   let config = { page: event, order: this.orderingData, search: this.searchData };
   this.getNetworkDeviceFrameData(config);
  }

  onEdit(rowData){
     this.router.navigate(['/network-management/port',rowData.uuid,this.frameid,rowData.oltSlot]);
    }
  
  // transFormPaginationData() {
  //   let paramsValue: any = {paginationRequestDTO:{},deviceid:""};
  //   if (this.isfetchOnPaginaionData === true) {
  //     let fetchSettingData = this.valueAddedNeoGridSettings.serverSideSortPagination ? this.gridSettingData : null;
  //     let params = this.getGridSettingInfo(fetchSettingData);
  //     paramsValue.paginationRequestDTO.page = params.page;
  //     paramsValue.paginationRequestDTO.pageSize = params.pageSize;
  //     paramsValue.deviceid = this.oltDevice;
  //   } else if (this.isfetchOnPaginaionData === false) {
  //     let fetchSettingData = this.valueAddedNeoGridSettings.serverSideSortPagination ? this.gridSettingData : null;
  //     let params = this.getGridSettingInfo(fetchSettingData);
  //     params.page = 1;
  //     params.pageSize = 10;
  //     paramsValue.paginationRequestDTO.page = params.page;
  //     paramsValue.paginationRequestDTO.pageSize = params.pageSize;
  //     paramsValue.deviceid = this.oltDevice;
  //   }
  //   return paramsValue;
  // }

 onFetchData(){
    let paramsValue: any = {uuid:this.uuid,frame:this.frameid,slot:this.slotid,port:this.portid};
    paramsValue.uuid = this.uuid;
    paramsValue.frame = this.frameid;
    paramsValue.slot = this.slotid;
    paramsValue.port = this.portid
    this.networktreeService.getFetchONU(paramsValue).subscribe(result => {
      if(result.responseCode===200){
        console.warn("SNMP CALL");
      }else{
        this.toastr.error("Please Try Again Later");
      }
    });
  }

  onBackTo(){
    this.router.navigate(['/network-management/port',this.uuid,this.frameid,this.slotid]);
  }

  onEditClick(rowData){
    this.router.navigate(['/masters/subscriber/details', rowData.custid]);
  }
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const BASE_URL = 'http://124.123.123.91:26081/api/v1/';
export const environment = {
  production: false,
  hmr: false,
  backend: true,
  DOWNLOAD_GET_FILE: BASE_URL + 'docRepository/getDocument/',
 baseurlDev:"http://143.110.248.5:8080"
};

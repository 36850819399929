import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject,throwError} from 'rxjs';
import { SearchDataTypeEnum, SearchFilterConditionEnum, SearchFilterTypeValueEnum } from '../core/enum/searchType.enum';
import { SearchData } from '../core/modal/search-data';
import { catchError, last, map, tap } from 'rxjs/operators';


const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  //SearchFilterFinalArray:any[]=[]; working
  SearchFilterFinalArray:any;
  finalSearchValue:any[] = [];
  constructor(public datepipe: DatePipe ,public http:HttpClient) {}

  private subject = new Subject<any>();
  private filterSubject = new Subject<any>();
  private subjectAdvance = new Subject<any>();
   serverFilterObj: SearchData[] = [];

  setSearchSettings(obj: any) {
   this.subject.next(obj);
  }

  openSearchWithSettings(): Observable<any> {
    return this.subject.asObservable();
  }

  setSearchFilterSettings(obj: any) {
    this.subjectAdvance.next(obj);
  }

  openSearchFilterWithSettings(): Observable<any> {
    return this.subjectAdvance.asObservable();
  }

  transformSubscriberList(data) {
    let formatedData = [];
    data.customerList.forEach(function(item:any){
      let itemData:any = {};
      itemData.subscriber_id = item.id;
      itemData.email = item.email;
      itemData.username = item.username;
      itemData.status = item.status;
      itemData.name = item.name;
      itemData.mobile = item.mobile;
      itemData.acctno = item.acctno;
      itemData.outstanding = item.outstanding;
      formatedData.push(itemData); //Final store Value

    });
    return {content:formatedData, pageDetails: data.pageDetails ? data.pageDetails : null };
  }

  setFilterOptions(obj: any) {
    console.log("))))))))))))))", obj)
    this.filterSubject.next(obj);
  }


  

  getFilterOptions(): Observable<any> {
    return this.filterSubject.asObservable();
  }

  clearFilterOptions(obj: any) {
    this.filterSubject.next(obj);
  }

  resetFilter(obj: any) {
    this.filterSubject.next(obj);
  }

  getServerFilterObj() {
    return this.serverFilterObj;
  }

  removeCaseSpecificStatusColumn(){
    if(this.serverFilterObj && this.serverFilterObj.length > 0){
      this.serverFilterObj = this.serverFilterObj.filter((ls) => ls.filterColumn != 'mycases' && ls.filterColumn != 'allcases')
    }
  }

  setServerFilterObj(filters: SearchData[]) {
    let filterObj = JSON.parse(JSON.stringify(filters));
    if (filterObj && filterObj.length > 0) {
      if (this.serverFilterObj && this.serverFilterObj.length > 0)
        filterObj.map((fObj) => {
          let objIndex = this.serverFilterObj.findIndex(function (obj) {
            return obj.filterColumn == fObj.filterColumn;
          });
          if (objIndex != -1) {
            this.serverFilterObj[objIndex] = fObj;
          } else {
            this.serverFilterObj.push(fObj);
          }
        });
      else {
        this.serverFilterObj = filterObj;
      }
    } else {
      if (this.serverFilterObj && this.serverFilterObj.length > 0) {
        let serverFilterObj = JSON.parse(JSON.stringify(this.serverFilterObj));
        serverFilterObj = this.serverFilterObj.filter((fObj) => {
          return fObj.filterColumn == 'any';
        });
        this.serverFilterObj = serverFilterObj;
      }
    }
    return this.serverFilterObj;
  }

  resetAdvanceSearchServerFilterObj() {
    let serverFilterObj = JSON.parse(JSON.stringify(this.serverFilterObj));
    serverFilterObj = this.serverFilterObj.filter((fObj) => {
      return fObj.filterColumn == 'any';
    });
    this.serverFilterObj = serverFilterObj;
    return this.serverFilterObj;
  }

  resetGlobalSearchServerFilterObj() {
    
    let serverFilterObj = JSON.parse(JSON.stringify(this.serverFilterObj));
    serverFilterObj = this.serverFilterObj.filter((fObj) => {
      return fObj.filterColumn != 'any';
    });
    this.serverFilterObj = serverFilterObj;
    return this.serverFilterObj;
  }

  getFilteredData(filterObj, filterData) {
     //value comes here  
  //  this.finalSearchValue.push(filterObj)

  if (filterObj && filterData) {
      const filterFieldExists = filterObj.some((filter) => {
        return filter.filterColumn != '';
      });
      if (filterFieldExists && filterData) {
        let filteredData = filterData.filter((data) => {
          let conditionResult = true;
          filterObj.forEach((filter) => {
            if (!filter.filterCondition) {
              filter.filterCondition = SearchFilterConditionEnum.AND;
            }
            let filterValue = this.getFilterVal(filter);
            let dataValue = this.getDataVal(filter, data);
            switch (filter.filterCondition) {
              case SearchFilterConditionEnum.AND: {
                switch (filter.filterOperator) {
                  case SearchFilterTypeValueEnum.IS_EQUAL_TO: {
                    if (typeof filterValue == 'object') {
                      conditionResult = conditionResult && filterValue.indexOf(dataValue) >= 0;
                    } else {
                      conditionResult = conditionResult && filterValue == dataValue;
                    }
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO: {
                    if (typeof filterValue == 'object') {
                      conditionResult = conditionResult && filterValue.indexOf(dataValue) < 0;
                    } else {
                      conditionResult = conditionResult && filterValue != dataValue;
                    }
                    break;
                  }
                  case SearchFilterTypeValueEnum.GREATER_OR_EQUAL: {
                    conditionResult = conditionResult && dataValue >= filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.GREATER_THAN: {
                    conditionResult = conditionResult && dataValue > filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.LESS_OR_EQUAL: {
                    conditionResult = conditionResult && dataValue <= filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.LESS_THAN: {
                    conditionResult = conditionResult && dataValue < filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NULL: {
                    conditionResult = conditionResult && (dataValue == null || dataValue == '');
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NOT_NULL: {
                    conditionResult = conditionResult && (dataValue != null || dataValue != '');
                    break;
                  }
                  case SearchFilterTypeValueEnum.CONTAINS: {
                    conditionResult = conditionResult && dataValue.indexOf(filterValue) > -1;
                    break;
                  }
                  case SearchFilterTypeValueEnum.STARTS_WITH: {
                    conditionResult = conditionResult && dataValue.startsWith(filterValue);
                    break;
                  }
                }
                break;
              }
              case SearchFilterConditionEnum.OR: {
                switch (filter.filterOperator) {
                  case SearchFilterTypeValueEnum.IS_EQUAL_TO: {
                    conditionResult = conditionResult || dataValue == filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO: {
                    conditionResult = conditionResult || dataValue != filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.GREATER_OR_EQUAL: {
                    conditionResult = conditionResult || dataValue >= filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.GREATER_THAN: {
                    conditionResult = conditionResult || dataValue > filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.LESS_OR_EQUAL: {
                    conditionResult = conditionResult || dataValue <= filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.LESS_THAN: {
                    conditionResult = conditionResult || dataValue < filterValue;
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NULL: {
                    conditionResult = conditionResult || dataValue == null || dataValue == '';
                    break;
                  }
                  case SearchFilterTypeValueEnum.IS_NOT_NULL: {
                    conditionResult = conditionResult || dataValue != null || dataValue != '';
                    break;
                  }
                  case SearchFilterTypeValueEnum.CONTAINS: {
                    conditionResult = conditionResult || dataValue.indexOf(filterValue) > -1;
                    break;
                  }
                  case SearchFilterTypeValueEnum.STARTS_WITH: {
                    conditionResult = conditionResult || dataValue.startsWith(filterValue);
                    break;
                  }
                }
                break;
              }
            }
          });
          return conditionResult;
        });
        return filteredData;
      }
      return filterData;
    }
    return filterData;
  }

  getFilterVal(filter) {
    // SearchDataTypeEnum.DATE
    //             ? new Date(filter.filterValue).setHours(0, 0, 0, 0)
    //             : filter.filterDataType == SearchDataTypeEnum.STRING && filter.filterValue
    //             ? filter.filterValue.toLowerCase()
    //             : filter.filterValue;

    if(filter.filterDataType == SearchDataTypeEnum.DATE){
      return new Date(filter.filterValue).setHours(0, 0, 0, 0)
    }else{
      if(filter.filterDataType == SearchDataTypeEnum.STRING && filter.filterValue){
        if(typeof filter.filterValue == 'number'){
          return filter.filterValue.toString().trim();
        }
        return filter.filterValue.toLowerCase().trim();
      } else {
        return filter.filterValue;
      }
    }
  }

  getDataVal(filter, data) {
    // filter.filterDataType == SearchDataTypeEnum.DATE
    // ? new Date(data[filter.filterColumn]).setHours(0, 0, 0, 0)
    // : filter.filterDataType == SearchDataTypeEnum.STRING && filter.filterValue
    // ? (data[filter.filterColumn].toLowerCase()).trim()
    // : (data[filter.filterColumn]).trim();
    if (filter.filterDataType == SearchDataTypeEnum.DATE) {
      return new Date(data[filter.filterColumn]).setHours(0, 0, 0, 0);
    } else {
      if (filter.filterDataType == SearchDataTypeEnum.STRING && filter.filterValue) {
        if (typeof data[filter.filterColumn] == 'number') {
          return data[filter.filterColumn].toString().trim();
        }
        return data[filter.filterColumn].toLowerCase().trim();
      } else {
        return data[filter.filterColumn];
      }
    }
  }

  private getFilterResultByType(filter, conditionResult, filterType, data) {
    if (filterType == SearchFilterConditionEnum.AND) {
      return conditionResult && data[filter.filterColumn] == filter.filterValue;
    } else {
      return conditionResult || data[filter.filterColumn] != filter.filterValue;
    }
  }

  private customDateParser(dateStr: string): string {
    // 2018-01-01T12:12:12.123456; - converting valid date format like this

    let date = dateStr.substring(0, 10);
    let time = dateStr.substring(11, 19);
    let millisecond = dateStr.substring(20);

    let validDate = date + 'T' + time + '.' + millisecond;
    ////console.log(validDate);
    return validDate;
  }

}

import { Component, Inject, OnInit, Optional,OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import _ from 'underscore';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { NotificationService } from '../notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


export class DialogPassData{
  id:number;
}
import { eventNames } from 'cluster';
import { DataSharingServiceService } from '../../data-sharing-service.service';
@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent implements OnInit {
  

  saveBtn:boolean=false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  isChecked:boolean=false;
  isdisabled:boolean=false;

  eventlist:any[]=[];
  eventNotification:any[]=[];
  notificationForm = new FormGroup({
    category: new FormControl(''),
    email_body: new FormControl(''),
    email_enabled: new FormControl('',[Validators.required]),
    name: new FormControl('',Validators.required),
    event_name:new FormControl(null,Validators.required),
    notificationConfig: new FormGroup({
      atrr_condi: new FormControl(''),
      attr_value: new FormControl(''),
      config_atrr_type: new FormControl(''),
      config_attribute: new FormControl(''),
      config_entity: new FormControl(''),
      id: new FormControl('')
    }),
    sms_body: new FormControl(''),
    sms_enabled: new FormControl('',Validators.required),
    pushNotificationEnabled: new FormControl('',Validators.required),
    pushNotificationBody: new FormControl(''),
    status: new FormControl(''),
    template_id: new FormControl(''),
    isBroadcast:new FormControl('false')
  });

  planSelected: boolean;
  dateSelected: boolean;
  categorySelected: boolean;
  public notification_id: any = null;
  show_update: any = false;
  submitted: boolean = false;
  selectForUpdate: any;
  commonStatus: any;
  public sub: any = null;
  valueAbsolutateSelected: boolean;
  attrSelected: boolean;
  smsSelected: boolean;
  pushNotificSelected: boolean;
  emailSelected: boolean;
  notificationGroupId:any;
  // statusList: any[] = [
  //   { value_field: 'ACTIVE', display_field: 'Active' },
  //   { value_field: 'INACTIVE', display_field: 'In Active' },
  // ];
  constructor(
    private notificationService: NotificationService,
    loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private tosterservice:ToastrService,
    private sharingdataService:DataSharingServiceService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<AddNotificationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) {
    this.loginService = loginService;
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.notificationGroupId=null;
    this.notificationGroupId=localStorage.getItem('ngId');//Local Storage old
    if (id) {
      this.notification_id = id;
      this.show_update = true; 
      this.getNotification(this.notification_id);
    }
    // if(this.data && this.data.id){
    //   this.notification_id = this.data.id;
    //   this.isdisabled=true;
    //   this.show_update = true;
    //   this.getBillingTemplateData();
    // }

    this.getEventAll();
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     let id = params['id']
    //     if (id) {
    //       this.notification_id = params['id'];
    //       this.show_update = true;
    //       this.getBillingTemplateData();
    //     }
    //   });
    this.notificationService.commonStatus().subscribe(res => {
      this.commonStatus = res.dataList;
      ////console.log(this.commonStatus);
    })
  }
  // ngOnDestroy(){
  //   localStorage.removeItem('notificationGroupId');
  //  localStorage.removeItem('ngId');
  // }

  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getBillingTemplateData() {
    
    let id = this.notification_id;
    this.notificationService.getNotificationById(id).subscribe(result => {
      ////console.log('data Key ->', result.data);
      this.notificationForm.patchValue(this.transformData(result.data));
      if (result.data.category === 'generic') {
        this.categorySelected = false;
      } else if (result.data.category === 'configurable') {
        this.categorySelected = true;
      }
      if (result.data.sms_enabled === true) {
        this.smsSelected = true;
      }
      else {
        this.smsSelected = false;
      }
      if (result.data.email_enabled === true) {
        this.emailSelected = true;
      }
      else {
        this.emailSelected = false;
      }
      if(result.data.isBroadcast ===true){
        this.notificationForm.get('isBroadcast').setValue(true);
      } 
      else{
        this.notificationForm.get('isBroadcast').setValue(false);
      }
    });
  }
  getNotification(notificationID){
   
    this.notificationService.NotificationById(notificationID).subscribe(result => {
          if (result.data.category === 'generic') {
            this.categorySelected = false;
          } else if (result.data.category === 'configurable') {
            this.categorySelected = true;
          }
          if (result.data.sms_enabled === true) {
            this.smsSelected = true;
            this.notificationForm.get('template_id').setValidators([Validators.required]);
          }
          else {
            this.smsSelected = false;
            this.notificationForm.get('template_id').setValue(null);
            this.notificationForm.get('template_id').clearValidators();
            this.notificationForm.get('template_id').updateValueAndValidity();
          }
          if (result.data.email_enabled === true) {
            this.emailSelected = true;
          }
          else {
            this.emailSelected = false;
          }
          if (result.data.pushNotificationEnabled === true) {
            this.pushNotificSelected = true;
          }
          else {
            this.pushNotificSelected = false;
          }
          if(result.data.isBroadcast ===true){
            this.notificationForm.get('isBroadcast').setValue(true);
          } 
          else{
            this.notificationForm.get('isBroadcast').setValue(false);
          }

          this.notificationForm.patchValue(result.data);

    })
    
  }

  transformData(data) {
    let notification: any = {};
    notification.category = data.category,
      notification.email_body = data.email_body,
      notification.email_enabled = data.email_enabled,
      notification.name = data.name,
      notification.event_name=data.event_name;
      notification.notificationConfig = {};
    if (data.notificationConfig !== null) {
      notification.notificationConfig.atrr_condi = data.notificationConfig.atrr_condi,
        notification.notificationConfig.attr_value = data.notificationConfig.attr_value,
        notification.notificationConfig.config_atrr_type = data.notificationConfig.config_atrr_type,
        notification.notificationConfig.config_attribute = data.notificationConfig.config_attribute,
        notification.notificationConfig.config_entity = data.notificationConfig.config_entity,
        notification.notificationConfig.id = data.notificationConfig.id
    }
    notification.sms_body = data.sms_body,
      notification.sms_enabled = data.sms_enabled,
      notification.status = data.status,
      notification.template_id = data.template_id,
      notification.pushNotificationEnabled = data.pushNotificationEnabled,
      notification.pushNotificationBody = data.pushNotificationBody
    return notification;
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  categoryChange(event) {
    if (event === 'plan') {
      this.notificationForm.get("notificationConfig.config_attribute").reset();
      this.planSelected = true;
    } else if (event === 'customer') {
      this.notificationForm.get("notificationConfig.config_attribute").reset();
      this.planSelected = false;
    }
  }

  templateCategoryChange(event) {
    if (event === 'generic') {
      this.categorySelected = false;
    } else if (event === 'configurable') {
      this.categorySelected = true;
    }
  }

  configAttriChange(event) {
    if (event === 'quota') {
      this.attrSelected = true;
    } else if (event === 'ip_expiry' || event === 'plan_expiry') {
      this.attrSelected = false;
    }
  }

  smsChange(event) {
    if (event === true) {
      this.smsSelected = true;
      this.notificationForm.get('template_id').setValidators([Validators.required]);
    } else {
      this.notificationForm.get('template_id').clearValidators();
      this.notificationForm.get('template_id').updateValueAndValidity();
      this.smsSelected = false;
    }
  }

  pushNotificationChange(event){
    this.notificationForm.get('pushNotificationBody').reset();
    if (event === true) {
      this.pushNotificSelected = true;
    } else {
      this.pushNotificSelected = false;
    }
  }

  onClickCheck(event){
    if(event.target.checked){
      let smsBody = this.notificationForm.get('sms_body').value;
      this.notificationForm.get('pushNotificationBody').setValue(smsBody)
    }
    else{
      this.notificationForm.get('pushNotificationBody').reset();
    }

  }

  emailChange(event) {
    if (event === true) {
      this.emailSelected = true;
    } else {
      this.emailSelected = false;
    }
  }

  configAttriTypeChange(event) {
    if (event === 'date') {
      this.notificationForm.get("notificationConfig.atrr_condi").reset();
      this.dateSelected = true;
      this.valueAbsolutateSelected = false;
    } else if (event === 'value_absolute' || event === 'value_percentage') {
      this.notificationForm.get("notificationConfig.atrr_condi").reset();
      this.dateSelected = false;
      this.valueAbsolutateSelected = true;
    }
  }

    saveNotification() {
  ////console.log("form Vlaue",this.notificationForm)
      this.submitted=true;
      this.notificationForm.markAllAsTouched();
      if(this.notificationForm.invalid ){
      return   
      }

    let apiInputData = Object.assign({}, this.notificationForm.value);
    if (this.categorySelected == false) {
      apiInputData.notificationConfig = null;
    }
    if (!this.show_update) {
      this.saveBtn =true;
      // let notificationGroupId=localStorage.getItem('notificationGroupId');
      apiInputData['notificationGroupId']=this.notificationGroupId;
      this.notificationService.saveNotification(apiInputData).subscribe((result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.saveBtn =false;
          this.tosterservice.error(result.responseMessage);
        } else {
          this.saveBtn =false;
          this.tosterservice.success('Notification Save Successfully.');
          this.router.navigate(['operations/notification-group/notification-group-add',this.notificationGroupId]);
        }
      }, err => {
        this.saveBtn =false;
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    } else {
      this.saveBtn =true;
      apiInputData.id = this.notification_id;
      // let notificationGroupId=localStorage.getItem('notificationGroupId');
      apiInputData['notificationGroupId']=this.notificationGroupId;
      
      this.notificationService.updateNotification(apiInputData).subscribe((result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.saveBtn =false;
          this.tosterservice.error(result.responseMessage);
        } else {
          this.saveBtn =false;
          this.tosterservice.success('Notification Updated Successfully.');
          this.router.navigate(['operations/notification-group/notification-group-add',this.notificationGroupId]);
        }
      }, err => {
        this.saveBtn =false;
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  onBackToNotification() {
    // let notificationGroupId=localStorage.getItem('notificationGroupId');
    //this.router.navigate(['operations/notification-group/notification-group-add',this.notificationGroupId]);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
      }else{
        this.router.navigate(['operations/notification-group/notification-group-add',this.notificationGroupId]);
      }
  }

  checkValue(event: any){
    
    if(event === 'true'){
      this.notificationForm.get('isBroadcast').setValue(true);
    }else{
      this.notificationForm.get('isBroadcast').setValue(false);
    }
  }
  backToViewList(){
    this.router.navigate(['operations/notification']);
  }
  getEventAll(){
    this.notificationService.getAllEventList().subscribe(res=>{
      Object.keys(res.data).forEach(element=>{
        let event:any={};
        event.text=element;
        event.value=element;
        this.eventlist.push(event);
        this.eventNotification=this.eventlist;
      });
    })
  }
}

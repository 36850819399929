import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../../../services/base-service';
import { CasesModel } from '../model/cases-model';
import { Observable, of, throwError } from 'rxjs';
const baseApiUrl = '';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class CasesService extends BaseService<CasesModel> {
Internalcase:boolean
  constructor(http: HttpClient, private toaster: ToastrService) {
    super(http);
  }

  saveCase(data) {
    let post_url =''
    if(this.Internalcase){
       post_url = baseApiUrl + 'internalCase/save';
    }else{
      post_url = baseApiUrl + 'case/save';
    }
    
    return this.http.post<any>(post_url, data);
  }

  getFeedList(caseId: any): Observable<any> {
    const get_url = baseApiUrl + 'feedback/getByTicketId/' + caseId;
    return this.http.get<any>(get_url);
  }

  getTimeString(flag, caseId) {
    const get_url = baseApiUrl + 'case/updateDate'
    let queryParams = new HttpParams();
    queryParams = queryParams.append("caseId", caseId).append('isStarted', flag);

    return this.http.get<any>(get_url, { params: queryParams });
    // http://localhost:20080/api/v1/case/updateDate?caseId=85154&isStarted=false
  }

  getresolutionreason() {
    const get_url = baseApiUrl + 'resolutionReasons/all';
    return this.http.get<any>(get_url);
  }
  getcustomerById(id) {
    const get_url = baseApiUrl + 'subscriber/getBasicCustDetails/' + id;
    return this.http.get<any>(get_url);
  }
  getCaseById(id) {
    let get_url =''
    if(this.Internalcase){
       get_url = baseApiUrl +'internalCase/' + id;
    }else{
       get_url = baseApiUrl + 'case/' + id;
    }
    return this.http.get<any>(get_url);
  }
  getcaseofcustomer(obj) {
    
    const post_url = baseApiUrl + 'case/getPreviousCaseByCaseFor';
    return this.http.post<any>(post_url,obj);
  }
  getCaseBySwitch() {
    const get_url = baseApiUrl + 'case/getMyCases/';
    return this.http.get<any>(get_url);
  }
  updateList(data) {
    const updateListFromdata = new FormData();
    updateListFromdata.append("caseUpdate", "" + JSON.stringify(data));
    let post_url ='';
    if(this.Internalcase){
       post_url = baseApiUrl + 'internalCase/updateDetails';
    }else{
       post_url = baseApiUrl + 'case/updateDetails';
    }
    return this.http.post<any>(post_url, updateListFromdata);
  }
  caseLiveNetworkDetails(obj) {
    const post_url = baseApiUrl + 'NetworkDevice/refreshOnuDevice';
    return this.http.post<any>(post_url, obj);
  }
  getcasebystatus(status) {
    const get_url = baseApiUrl + 'case/getCasesByStatus/' + status;
    return this.http.get<any>(get_url);
  }
  getModuleName(): string {
    if(this.Internalcase){
      return 'internalCase';   
    }else{
      return 'case';
    }
    
  }

  caseAssigneToMe(id) {
    let get_url = ''
    if(this.Internalcase){
       get_url = baseApiUrl + 'internalCase/assignedTo/' + id;
    }else{
       get_url = baseApiUrl + 'case/assignedTo/' + id;
    }
    return this.http.get<any>(get_url);
  }

  excelExportMyCases(): Observable<Blob> {
    const get_url = baseApiUrl + 'case/excel/mycases';
    return this.http.get<any>(get_url, { observe: 'body', responseType: 'blob' as 'json' });
  }

  excelExportCase(): Observable<Blob> {
    const get_url = baseApiUrl + 'case/excel';
    return this.http.get<any>(get_url, { observe: 'body', responseType: 'blob' as 'json' });
  }

  validate(): boolean {
    return false;
  }

  getCaseAdvanceFilter(obj) {
    let post_url = ''
    if(this.Internalcase){
       post_url = baseApiUrl + 'internalCase';
    }else{
       post_url = baseApiUrl + 'case';
    }
    return this.http.post<any>(post_url, obj, { headers: httpOptions.headers });
  }

  getExelDownloadByfilter(obj?): Observable<Blob> {
    let post_url = ''
    if(this.Internalcase){
       post_url = baseApiUrl + 'internalCase/excelWithFilter';
    }else{
       post_url = baseApiUrl + 'case/excelWithFilter';
    }
    return this.http.post<any>(post_url, obj, { observe: 'body', responseType: 'blob' as 'json' });
  }




 

  getLeaseOrNormalCustomerBasicDetails(id:any,name){
    let data = { id: id,name:name }
    const post_url = baseApiUrl + 'case/getLeaseOrNormalCustomerBasicDetails';
    return this.http.post<any>(post_url, data);
  }

  getpropertydetails(leasecustId: any): Observable<any> {
    let data = { id: leasecustId }
    const post_url = baseApiUrl + 'leasedlinesubscriber/getAllPropertyByLeaseLine';
    return this.http.post<any>(post_url, data);

  }
  getcircuitdetails(leaseid) {
    const get_url = baseApiUrl + 'leasedlinesubscriber/getAllCircuitByLeaseLine/' + leaseid;
    return this.http.get<any>(get_url);
  }

  getquickCaseType(){
    const get_url = baseApiUrl + 'commonList/generic/quickCaseType';
    return this.http.get<any>(get_url);
  }
  getbucketType(){
    const get_url = baseApiUrl + 'commonList/generic/bucketType';
    return this.http.get<any>(get_url);
  }
  deleteUpdateDetails(id){
    const get_url = baseApiUrl + 'caseUpdate/deleteUpdate/' + id;
    return this.http.get<any>(get_url);
  }

  updateBulkList(data,idList?) {
    //const updateListFromdata = new FormData();
    // updateListFromdata.append("caseUpdate", "" + JSON.stringify(data));
    // updateListFromdata.append("caseId","" + JSON.stringify(idList))
    let post_url ='';
    if(this.Internalcase){
       post_url = baseApiUrl + 'case/bulkCaseUpdate';
    }else{
       post_url = baseApiUrl + 'case/bulkCaseUpdate';
    }
    return this.http.post<any>(post_url, data);
  }

  acceptService(obj){
    let post_url = baseApiUrl + 'serviceNotification/acceptServiceRequest';
    return this.http.post<any>(post_url, obj);
  }

  getActivityByCaseId(id,type){
    const get_url = baseApiUrl + 'case/getActivityByTicketId/' + id + '/' + type;
    return this.http.get<any>(get_url);
  }

  downloadDocument(file) {
    const mediaType = 'application/pdf';
    this.http.get(baseApiUrl + 'serviceNotification/getImage/' + file, { responseType: 'blob'}).subscribe((response) => {
      const blob = new Blob([response],{ type: mediaType });
      saveAs(blob, file);
    }, (error) => {
      this.toaster.error(error);
    });
  }

  getDocument(id,type){
    const get_url = baseApiUrl + 'case/getDocuments/' + type + id;
    return this.http.get<any>(get_url);
  }
} 
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { SubscriberDetailsService } from '../subscriber-details/subscriber-details.service';

@Component({
  selector: 'app-property-inquiry',
  templateUrl: './property-inquiry.component.html',
  styleUrls: ['./property-inquiry.component.scss']
})
export class PropertyInquiryComponent implements OnInit {

  @ViewChild('desc', { static: true }) descRef:  TemplateRef<any>;
  @ViewChild('dateWrap', { static: true }) dateRef:  TemplateRef<any>;
  @ViewChild('enddateWrap', { static: true }) enddateRef:  TemplateRef<any>;
  @ViewChild('statusWrap', { static: true }) statusWrapRef:  TemplateRef<any>;

  dataList = [    {"Unit":"D-705","Building":"Orian Square","RECNENDABS":"Buy","RECNTXT":"Rajesh Kapoor","email":"rajesh.kapoor@gmail.com","mobile":"9875673421","lead":"Property Finder"},    {"Unit":"D-302","Building":"Orian Square","RECNENDABS":"Buy","RECNTXT":"Priya Patel","email":"priya.patel@gmail.com","mobile":"7867564523","lead":"Property Finder"},    {"Unit":"F-101","Building":"Orian Square","RECNENDABS":"Buy","RECNTXT":"Sanjay Sharma","email":"sanjay.sharma@gmail.com","mobile":"9785645851","lead":"Property Finder"},    {"Unit":"A-107","Building":"Gutron Parks","RECNENDABS":"Rent","RECNTXT":"Aparna Gupta","email":"aparna.gupta@gmail.com","mobile":"6565787890","lead":"Property Finder"},    {"Unit":"F-402","Building":"Gutron Parks","RECNENDABS":"Rent","RECNTXT":"Manish Singh","email":"manish.singh@gmail.com","mobile":"8712345634","lead":"Property Finder"},    {"Unit":"E-203","Building":"Gutron Parks","RECNENDABS":"Rent","RECNTXT":"Nisha Reddy","email":"nisha.reddy@gmail.com","mobile":"9874564321","lead":"Property Finder"}]

  custId : any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  activeContractNeoGridSettings = {};
  subscriptions: Subscription[] = [];

  activePlanTableData : any;
  planMapId: string;
  tenantId: any;

  constructor(
    private modalService: NgbModal,
    private dashboardservice: SubscriberDetailsService,
    private tosterService: ToastrService
  ) { }

  ngOnInit() {
    this.tenantId = localStorage.getItem('custUserId');
    this.setGridSetting();
    //this.custId = localStorage.getItem('custUserId')
    this.getActivePlanData();
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      // if (col.colKey === 'RECNTXT') {
      //   col.customControls = [this.descRef];
      // }
      // if(col.colKey === 'RECNBEG'){
      //   col.customControls = [this.dateRef];
      // }
      // if(col.colKey === 'RECNENDABS'){
      //   col.customControls = [this.enddateRef];
      // }
      // if(col.colKey === 'STATUS'){
      //   col.customControls = [this.statusWrapRef];
      // }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'Unit',
        default: 'Unit',
        rowKey: 'Unit',
        width: '15%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'Building',
        default: 'Building',
        rowKey: 'Building',
        width: '15%',
        isNumber: false,
        isFilterAllow: false,
        enableSort: false,
      },
      {
        colKey: 'RECNENDABS',
        default: 'Unit Type',
        rowKey: 'RECNENDABS',
        width: '15%',
        isNumber: false,
        isFilterAllow: false,
        enableSort: false,
      },
      // {
      //   colKey: 'STATUS',
      //   default: 'Status',
      //   rowKey: 'STATUS',
      //   width: '15%',
      //   isFilterAllow: false,
      //   isNumber: false,
      // },
      {
        colKey: 'RECNTXT',
        default: 'Sender Name',
        rowKey: 'RECNTXT',
        width: '15%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'email',
        default: 'Sender Email',
        rowKey: 'email',
        width: '15%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'mobile',
        default: 'Sender Mobile No.',
        rowKey: 'mobile',
        width: '15%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'lead',
        default: 'Source of lead',
        rowKey: 'lead',
        width: '15%',
        isFilterAllow: false,
        isNumber: false,
      },
    ];

    this.activeContractNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: 'property inquiry',
      perPage: 10,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      //tableHeight:600,
      // verticalScroll:true,
      // headerSticky:true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getActivePlanData() {
    // let dataList: any = [];
    // if(this.tenantId){
    //   this.dashboardservice.getBusinessPartnerContractData(this.tenantId).subscribe(res =>{
    //     if(res.responseCode == 200){
    //       dataList = res.data.ET_CONTRACT.item;
          this.refreshDataSource(this.dataList);
    //     }else{
    //       this.tosterService.error(res.responseMessage);
    //     }
    //   },error => {
    //     if (error.responseCode !== 200) {
    //       this.tosterService.error(error.error.responseMessage);
    //     }
    //   }
    //   )
    // }
  }

  open(paydet, data: any) {
    this.modalService.open(paydet, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    this.planMapId = data.planmapid;
  }
}

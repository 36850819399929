import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { IBaseService } from '../../../services/ibase-service';
import { Customer, Project } from '../model/Customer';
import { ToastrService } from 'ngx-toastr';
import { SearchService } from '../../../services/search.service';
import { MatTableDataSource } from '@angular/material';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { OrderingData } from '@neoboss/core/modal/ordering-data';
import { PaginationData } from '@neoboss/core/modal/pagination-data';
import { SearchControlTypeEnum } from '../../../core/enum/searchType.enum';
import { SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SubscriberLeasedServiceService } from './service/subscriber-leased-service.service';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { LoginService } from '../../login/login.service';
import { SearchData } from '../../../core/modal/search-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectList } from '../model/machineData';

@Component({
  selector: 'app-subscriber-leased-line',
  templateUrl: './subscriber-leased-line.component.html',
  styleUrls: ['./subscriber-leased-line.component.scss']
})
export class SubscriberLeasedLineComponent extends AbstractBaseListComponent<Customer> implements OnInit {
  @ViewChild("actionBtn", { static: false }) actionBtnReff: TemplateRef<any>;
  @ViewChild("totalproperties", { static: false }) totalpropertiesReff: TemplateRef<any>;
  @ViewChild("totalcircuit", { static: false }) totalcircuitReff: TemplateRef<any>;
  @ViewChild ('editCustomerName',{static:false}) customerNameRef:TemplateRef<any>;
  @ViewChild ('attachmentWrapper',{static:false}) attachmentWrapper:TemplateRef<any>;
  @ViewChild ('descriptionWrapper',{static:false}) descriptionWrapperReff:TemplateRef<any>;
  @ViewChild ('amenitiesInfoWrapper',{static:false}) amenitiesInfoWrapperReff:TemplateRef<any>;
  @ViewChild ('addressWrapper',{static:false}) addressWrapperReff:TemplateRef<any>;


  activecount:number = 0;
  inactivecount:number = 0;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  subleasedlineneogridsetting: any = {};
  dataList: any[] = [];
  dataSource = new MatTableDataSource([]);
  dataGrid: any[] = [];
  tableKeyenum: any = TableKeyEnum;
  searchSettings: any = {};
  allColumns: any[] = [];
  subscriptions: Subscription[] = [];
  loginService: LoginService;
  circuitlist: any;
  constructor(
    private toaster: ToastrService,
    private searchSevice: SearchService,
    private suscriberSearchService: SubscriberLeasedServiceService,

    private ngbModalService: NgbModal,
    private router: Router,
    loginService: LoginService
  ) {
    super(toaster, searchSevice)
    this.loginService = loginService;
  }
  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyenum.CUSTOMER_LIST)
    this.init();
    this.getTableColumn();

    const filterSubscription = this.searchSevice.getFilterOptions().subscribe((obj) => {

      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });
    this.subscriptions.push(filterSubscription);
  }


  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filteredDataForGrid) {
      //this.suscriberSearchService.getAdvanceFilterSearchData(filterObj).subscribe((res) => {
        this.refreshDataSource(ProjectList);
        this.neoTableSetting.pageTotalLength = ProjectList.length;
      //});
    }
  }
  
  getService(): IBaseService<Customer> {
    return this.suscriberSearchService;
  }
  setList(list: any) {
    this.dataGrid = ProjectList;
    this.subleasedlineneogridsetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }
  getTableColumn() {
    let self = this;

    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'projectName',
        default: 'Project Name',
        rowKey: 'projectName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'description',
        default: 'Description',
        rowKey: 'description',
        width: '40%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'latitude',
      //   default: 'Latitude',
      //   rowKey: 'latitude',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'longitude',
      //   default: 'Longitude',
      //   rowKey: 'longitude',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'address',
        default: 'Address',
        rowKey: 'address',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'projectType',
        default: 'Project Type',
        rowKey: 'projectType',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'totalBlocks',
        default: 'Total Blocks',
        rowKey: 'totalBlocks',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'totalUnits',
        default: 'Total Units',
        rowKey: 'totalUnits',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'ownerOfTheProject',
        default: 'Owner Of The Project',
        rowKey: 'ownerOfTheProject',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'blueprintDocument',
        default: 'Document',
        rowKey: 'blueprintDocument',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'amenitiesInfo',
        default: 'Amenities Info',
        rowKey: 'amenitiesInfo',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'contactperson',
        default: 'Contact person',
        rowKey: 'contactperson',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'contactnumber',
        default: 'contact number',
        rowKey: 'contactnumber',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'totalProperties',
      //   default: 'No. of Properties',
      //   rowKey: 'totalProperties',
      //   width: '10%',
      //   customControls: [this.totalpropertiesReff],
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'totalCircuits',
      //   default: 'No. of Circuits',
      //   rowKey: 'totalCircuits',
      //   width: '10%',
      //   customControls: [this.totalpropertiesReff],
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'updatedate',
      //   default: 'Last Modified',
      //   rowKey: 'updatedate',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
  }
  getTableSetting() {

    let subleasedlineneogridsetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyenum.CUSTOMER_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Record ?',
      deleteMsgBoxDetail: 'Customer Name :${custname} ',
    };
    this.subleasedlineneogridsetting = subleasedlineneogridsetting
    return subleasedlineneogridsetting
  }
  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'STATUS',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];
  }

  public onDeleteClick(event: Customer) {
    if (event) {
      this.commonDeleteMsgBox(this.subleasedlineneogridsetting, event, this.afterDeleteConfirm.bind(this));
    }
  }
  public onEditClick(event: Customer) {
    this.setUpdateValueInForm(event);
  }
  setUpdateValueInForm(rowData) {
    this.router.navigate(['/masters/suscriberLeased-details/', rowData.id]);
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  onAddClick() {
    this.router.navigate(['/masters/suscriberLeased-details']);
  }
  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);

    }
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnReff];
      }
      if (col.colKey == 'totalCircuits') {
        col.customControls = [this.totalcircuitReff];
      } if (col.colKey == 'totalProperties') {
        col.customControls = [this.totalpropertiesReff];
      }

      if(col.colKey=='projectName'){
        col.customControls=[this.customerNameRef]
      }
      if(col.colKey=='blueprintDocument'){
        col.customControls=[this.attachmentWrapper]
      }
      if(col.colKey=='description'){
        col.customControls=[this.descriptionWrapperReff]
      }
      if(col.colKey=='amenitiesInfo'){
        col.customControls=[this.amenitiesInfoWrapperReff]
      }
      if(col.colKey=='address'){
        col.customControls=[this.addressWrapperReff]
      }
    });
  }
  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  onViewCircuit(data, view) {
    this.circuitlist = data.circuitStatusDtoList;
    this.activecount = 0;
    this.inactivecount = 0;
    if (this.circuitlist.length === 0) {
      this.toaster.error("No Circuits");
    } else {
      this.circuitlist.forEach((res) =>{
        if(res.status == 'Active'){
           this.activecount ++;
        }
        
        if(res.status == 'Inactive'){
          this.inactivecount ++;
        }
      })
      // this.associatePartnerlist = data;
      this.ngbModalService.open(view, { size: 'md' });
    }

  }
} 

import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivityService } from '../service/activity.service';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../../../../auth/token-storage-service';
import { SearchService } from '../../../../services/search.service';
import { LoginService } from '../../../login/login.service';
import { OrderingData } from '../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../core/modal/pagination-data';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { AbstractBaseListComponent } from '../../../../components/base/abstract-base-list-component';
import { MatTableDataSource } from '@angular/material';
import { IBaseService } from '../../../../services/ibase-service';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent extends AbstractBaseListComponent<any> implements OnInit {
 
  
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('activityName', { static: false }) activityNameReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  ngbRef:NgbModalRef
  uuId:any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataGrid: any[] = [];
  searchSettings = [];
  dataSource = new MatTableDataSource([]);
  purchasesNeoGridSettings = {};
  subscriptions: Subscription[] = [];
  // purchasesTableData = proprertyDocList;
  planMapId: string;

  constructor(
    private entityService: ActivityService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    loginservice: LoginService,
    private tosterservice: ToastrService,
    private tokenSerivce:TokenStorageService
  ) {
    super(tosterservice, searchService);
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyEnum.ACTIVITY_LIST)
    this.init();
    this.getTableColumn()
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      this.applyFilter(obj);
      
    });
    this.subscriptions.push(filterSubscription);
  }
  getService(): IBaseService<any> {
    return this.entityService;
  }
  setList(list: Array<any>) {
    this.dataGrid = list;
    
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
    
  }
  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }
  getTableColumn() {
    let self = this;
      this.allColumns = [
        {
          colKey: 'index',
          default: 'ID',
          rowKey: 'id',
          width: '1%',
          isFilterAllow: false,
        },
        {
          colKey: 'activityName',
          default: 'Activity Name',
          rowKey: 'activityName',
          width: '12%',
          isNumber: false,
          isFilterAllow: false,
        },
        {
          colKey: 'activityType',
          default: 'Activity Type',
          rowKey: 'activityType',
          width: '10%',
          isNumber: false,
          isFilterAllow: false,
        },
        {
          colKey: 'description',
          default: 'Description',
          rowKey: 'description',
          width: '10%',
          isNumber: false,
          isFilterAllow: false,
        },
        {
          colKey: 'actionCol',
          default: 'Action',
          rowKey: 'id',
          enableEdit: false,
          enableDelete: false,
          isFilterAllow: false,
          enableSort: false,
          isExpandable: false,
          isNumber: false,
          width: '1%',
          isStickyEnd: true,
        },
      ];
   

    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });


    this.getTableSetting();
    this.getTableSearchSetting();

  }
  getTableSetting() {
    this.neoTableSetting = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.ACTIVITY_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure want to delete this record ?',
      deleteMsgBoxDetail: 'Activity Name :${activityName} ',
    };
  }
  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Country Name',
        value_field: 'activityName',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey === 'activityName') {
        col.customControls = [this.activityNameReff];
      }
    });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }


  onClose(){
    this.ngbRef.close();
  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;

    if (this.neoTableSetting.serverSideFilter) {
      this.onFilterChange(filters);
    } else {
      if (!filters) {
        filteredDataForGrid = this.searchService.getFilteredData(filters,this.dataGrid);
        this.refreshDataSource(filteredDataForGrid);
      }
    }
  }
  onAddClick(){
    this.router.navigate(['/masters/activity-list/add_activity'])
  }

  onEditClick(event) {
    
    // let index = event.RowData;
     let index = event;
     this.setUpdateValueInForm(index);
   }
   setUpdateValueInForm(rowData) {
    
    this.router.navigate(['/masters/activity-list/add_activity/', rowData.id]);
  }
   onDeleteClick(event) {
    
    let self = this;
    
    alertify.confirm(`Are you sure want to delete this`+" record ?",
    function(){
      if (event) {
        
         self.delete(event);
       }},
    function(){
    })
  }
}


import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../services/ibase-service';
import { Property } from '../model/Property';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PropertyServiceService } from './service/property-service.service';
import { ActivatedRoute } from '@angular/router';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { CircuitServiceService } from '../circuit-details/service/circuit-service.service';
import { Circuit } from '../model/Circuit';
import { GenericContactDetailsComponent } from '../generic-contact-details/generic-contact-details.component';
import { SubscriberLeasedLineDetailsComponent } from '../subscriber-leased-line-details/subscriber-leased-line-details.component';
import { SubscriberLeasedServiceService } from '../subscriber-leased-line/service/subscriber-leased-service.service';
import { DataSharingServiceService } from '../../../../../src/app/pages/data-sharing-service.service';
import { BlockList } from '../model/machineData';

declare let $;
@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss']
})
export class PropertyDetailComponent extends AbstractBaseAddUpdateComponent<Property> implements OnInit {
  @ViewChild(SubscriberLeasedLineDetailsComponent, { static: false }) subscriberLeasedLineDetails: SubscriberLeasedLineDetailsComponent;
  @ViewChild(GenericContactDetailsComponent, { static: false })
  private genericContactDetails: GenericContactDetailsComponent;
  property: any = {};
  propertyForm: FormGroup;
  pid: any;
  isNameChecked: boolean = false;
  isPhoneChecked: boolean = false;
  neoTableSetting: any = {};
  allColumns: any[] = [];
  tablekeyEnum: any = TableKeyEnum;
  propertyId: number;
  propertyIds: any;
  editPropertyId: any;
  qosPolicyTypeData: any;
  contactlist: any[] = [];
  constructor(
    private toaster: ToastrService,
    private router: Router,
    public propertyservice: PropertyServiceService,
    public circuitService: CircuitServiceService,
    private activate: ActivatedRoute,
    private genericDataSharingService: DataSharingServiceService,

    private subscriberLeasedService: SubscriberLeasedServiceService
  ) {
    super(toaster, router)
  }

  ngOnInit() {
    this.init();
  }
  init() {
    this.propertyId = +this.activate.snapshot.paramMap.get('propertyId');
    let id = +this.activate.snapshot.paramMap.get('id');
    if (id) {
      ////console.log("id", id);
      this.isUpdateComponent = true;
      let catModal = this.getNewModal();
      catModal.id = id;
      this.propertyId = id;
      this.propertyIds = id;
      this.getById(id);
      this.setData(this.getNewModal());
      this.setValidation();
      this.setId();
      return;
    }
    this.authRole();
    this.setData(this.getNewModal());
    this.setValidation();
    this.setId();
  }
  setId() {
    this.property.subscriberLeasedLine = this.propertyId;
    localStorage.setItem("propertyId", this.propertyIds);
  }
  getService(): IBaseService<Property> {
    return this.propertyservice;
  }
  setData(data: Property) {
    if (data.techconname) {
      this.isNameChecked = true;
    }
    if (data.techconphone) {
      this.isPhoneChecked = true;
    }
    let id = +this.activate.snapshot.paramMap.get('id');
    let blockDetail = BlockList.find(x=>x.id == id)
    if(blockDetail)
      this.property = blockDetail;
    let temp:any={};
    if(this.property.propertyContactDetails){

      this.genericContactDetails.refreshDataSource(this.property.propertyContactDetails);
    }
  }
  getData(): Property {
    return this.property;
  }
  getModuleHomeUrl() {
    return 'masters/subscriberLeased-property-detail/' + this.responseData.data.id
  }
  getActiveRoute() {
    this.activate;
  }
  getNewModal() {
    return new Property();
  }
  getFormGroup(): FormGroup {
    return this.propertyForm;
  }
  get f() {
    return this.propertyForm.controls;
  }
  onEditUrl() {
    this.editPropertyId = this.responseData.data.id;
    localStorage.setItem("editpropertyId", this.editPropertyId);
    this.router.navigate(['masters/subscriberLeased-property-detail/', this.responseData.data.id])
  }
  setValidation() {
    this.propertyForm = new FormGroup({
      blockName: new FormControl('', Validators.required),
      numberOfUnits: new FormControl('', Validators.required),
      noOfFloors: new FormControl('', Validators.required),
      contactperson: new FormControl('', Validators.required),
      contactNumber: new FormControl('')
      //technicalContactName: new FormControl('',),
      //technicalContactPhone: new FormControl(''),
    })
  }
  getId(): number {
    this.propertyservice.getByproperty(this.property.id);
    return this.property.id;
  }
  formSubmit() {

    if(this.genericContactDetails.TechnicalContactList)
    ////console.log('aaaa =>', this.genericContactDetails.TechnicalContactList);
    this.property.propertyContactDetails = this.genericContactDetails.TechnicalContactList;
    if (this.validateForm()) {
      this.onSubmit();
    }
  }

  onBack() {
    let id = localStorage.getItem('leasedId');
    this.router.navigate(['masters/suscriberLeased-details/', id]);
  }
  save(modal: Property): void {
    this.propertyservice.save(modal)
      .subscribe(
        (response) => {
          if (response.responseCode !== 200) {
            this.onResponseError(response.responseMessage);
            return;
          }
          //this.setData(response.data);
          this.responseData = response;
          this.onSavedCallback.emit(response.data);
          this.onSaveSuccess();
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }
  delete(modal: Circuit) {
    this.circuitService
      .delete(modal)
      .subscribe(
        (response) => {
          if (response.responseCode != 200) {
            this.onResponseError(response.responseMessage);
            return;
          }
          this.toaster.success("Circuit deleted successfull.")
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  // checkToSameAsNameNumber(eventData)
  // {
  //   if (eventData.target.checked) {
  //     let lieshedLine = localStorage.getItem("leasedId");
  //     this.propertyservice.getSubscriberById(lieshedLine).subscribe(res => {
  //       let data = res.data;
  //       this.leaslinedContactDetail.get('technicalContactName').setValue(data.techconname);
  //     })
  //   } else {
  //     this.propertyForm.get('technicalContactName').setValue('');
  //   }




  // }




  checkToSameAsName(eventData) {
  
    if (eventData.target.checked) {
      this.contactlist = [];
      let lieshedLine = localStorage.getItem("leasedId");
      this.propertyservice.getSubscriberById(lieshedLine).subscribe(res => {
        
        let data = res.data;
        //this.propertyForm.get('technicalContactName').setValue(data.techconname);
        let temp: any = {};
        //this.contactlist = this.customer.leaslinedContactDetail;
        if (res.data && res.data.leaslinedContactDetail.length > 0) {
          //myObj.map(({ id }) => id);
          // 
          
          res.data.leaslinedContactDetail.forEach((result) => {
            let temp = {
              techconname: null,
              techconphone: null,
              };
            temp.techconname = result.techconname;
            temp.techconphone = result.techconphone;
            this.contactlist.push(temp)
          })
          // this.genericContactDetails.TechnicalContactList.forEach((res)=>{
          //   this.contactlist.push(res)
          // })
          this.genericContactDetails.refreshDataSource(this.contactlist);
        }
      })
    } else {
      //this.propertyForm.get('technicalContactName').setValue('');
     let temp = []
       this.genericContactDetails.refreshDataSource(temp);
    }
  }

  checkSameAsPhone(eventData) {
    if (eventData.target.checked) {
      let lieshedLine = localStorage.getItem("leasedId");
      this.propertyservice.getSubscriberById(lieshedLine).subscribe(res => {
        let data = res.data;
        //this.propertyForm.get('technicalContactPhone').setValue(data.techconphone);
      })
    } else {
      //this.propertyForm.get('technicalContactPhone').setValue('');
    }
  }

getGenericDataService(){
return this.genericDataSharingService
}


}

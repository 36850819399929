export class equipmentinventory {
    id:number;
    name:any;
    serialNo: any
    status:any
    currentlyAllocatedToId: any
    ownerShipType: any
    allocationStartDate: any
    allocationEndDate: any
    warrantyStartDate: any
    warrantyEndDate: any
    resourceDtls : Array<resourceDetails>
}

export class resourceDetails{
    id:number;
    name : string
    frequency : string
    slaId:number
    skills : string
    jobPlan: Array <jobPlanDetails>
}

export class jobPlanDetails {
    id:number;
    activityName : string
    steps:string
    link:string
}

export class allocation {
    resourceId:number
    allocatedTo: number
    allocationStartDate: any
    allocationEndDate: any
    warrantyStartDate: any
    warrantyEndDate: any
}
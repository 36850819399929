import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';

@Component({
  selector: 'app-rellocate-machine-inventory',
  templateUrl: './rellocate-machine-inventory.component.html',
  styleUrls: ['./rellocate-machine-inventory.component.scss']
})


export class RellocateMachineInventoryComponent implements OnInit {
  @Input() ReceivedData: any;
  @Output() closeModalEmit = new EventEmitter<any>();
  customerListStr = [
    {
      name: 'Al Raha Hospitals'
    },
    {
      name: 'American Hospital'
    },
    {
      name: 'Burjeel Hospital'
    },
    {
      name: 'Cleveland Clinic'
    },
    {
      name: 'Danat Al Emarat'
    },
    {
      name: 'Dar Al Shifaa'
    },
    {
      name: 'Salama Hospital'
    },
    {
      name: 'Shaikh Khalifa'
    },
    {
      name: 'Sheikh Shakhbout'
    }
  ]
  relocationPojo = new rellocateMachineDetails();
  newform: FormGroup = new FormGroup({
    allocateCust: new FormControl(null)
  })

  constructor(private ngbModal: NgbModal,public CommonListService: CommonListServicesService,
    private dateAdapter: DateAdapter<Date>) {
      dateAdapter.setLocale("en-in");
     }

  ngOnInit() {
    console.log('data',this.ReceivedData);
  }

  saveForm(){
    this.CommonListService.machineData.forEach((ls,i) => {
      if(this.ReceivedData.SrNo == ls.SrNo){
        this.relocationPojo.allocationStartDate = moment(this.relocationPojo.allocationStartDate).format('DD-MM-YYYY');
        this.relocationPojo.allocationEndDate = moment(this.relocationPojo.allocationEndDate).format('DD-MM-YYYY');
        let tempArr = Object.assign([],ls.hist);
        ls.hist = [];
        this.relocationPojo.currentCustomer = this.ReceivedData.currentCustomer;
        this.relocationPojo.allocationStartDate = this.ReceivedData.currentCustomerAllocationDate;
        this.relocationPojo.allocationEndDate = moment(this.relocationPojo.currentallocationDate).format('DD-MM-YYYY');
        ls.hist.push(this.relocationPojo);
        ls.hist.push(...tempArr);
        console.log('hist',ls.hist);
        ls.currentCustomer = this.newform.get('allocateCust').value;
        if(this.relocationPojo.underWarrenty){
          ls.warrentyStartDate = moment(this.relocationPojo.warrentyStartDate).format('DD-MM-YYYY');
          ls.warrentyEndDate = moment(this.relocationPojo.warrentyEndDate).format('DD-MM-YYYY');
        }else{
          ls.warrentyStartDate = '-';
          ls.warrentyEndDate = '-';
        }
        ls.currentCustomerAllocationDate = moment(this.relocationPojo.currentallocationDate).format('DD-MM-YYYY');
        ls.currentCustomerReleaseDate = '-';
      }
    })
    this.closeModalEmit.emit();
    this.closeModal();
  }

  closeModal(){
    this.ngbModal.dismissAll();
  }

}
export class rellocateMachineDetails{
  currentCustomer: string;
  allocationStartDate: string;
  allocationEndDate: string;
  underWarrenty: boolean = false;
  currentallocationDate: string;
  warrentyStartDate:string;
  warrentyEndDate:string;
}
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { SubscriberDetailsService } from '../subscriber-details.service';

@Component({
  selector: 'app-subscriber-property',
  templateUrl: './subscriber-property.component.html',
  styleUrls: ['./subscriber-property.component.scss']
})
export class SubscriberPropertyComponent implements OnInit {

  @ViewChild('desc', { static: true }) descRef:  TemplateRef<any>;
  @ViewChild('dateWrap', { static: true }) dateRef:  TemplateRef<any>;
  @ViewChild('enddateWrap', { static: true }) enddateRef:  TemplateRef<any>;
  @ViewChild('statusWrap', { static: true }) statusWrapRef:  TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @Input() dataList;

  custId : any;
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  activeContractNeoGridSettings = {};
  subscriptions: Subscription[] = [];

  activePlanTableData : any;
  planMapId: string;
  @Input() tenantId: any;

  constructor(
    private modalService: NgbModal,
    private dashboardservice: SubscriberDetailsService,
    private tosterService: ToastrService
  ) { }

  ngOnInit() {
    // this.tenantId = localStorage.getItem('customerId');
    this.setGridSetting();
    //this.custId = localStorage.getItem('custUserId')
    this.getActivePlanData();
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      // if (col.colKey === 'RECNTXT') {
      //   col.customControls = [this.descRef];
      // }
      // if(col.colKey === 'RECNBEG'){
      //   col.customControls = [this.dateRef];
      // }
      // if(col.colKey === 'RECNENDABS'){
      //   col.customControls = [this.enddateRef];
      // }
      // if(col.colKey === 'STATUS'){
      //   col.customControls = [this.statusWrapRef];
      // }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'ownershipType',
        default: 'Ownership Type',
        rowKey: 'ownershipType',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'allocationStartDate',
        default: 'Allocation Start Date',
        rowKey: 'allocationStartDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'warrantyStartDate',
        default: 'Warranty Start Date',
        rowKey: 'warrantyStartDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'warrantyEndDate',
        default: 'Warranty End Date',
        rowKey: 'warrantyEndDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        width: '10%'
      }
    ];

    this.activeContractNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.CUSTOMER_PROPERTY_LIST,
      perPage: 10,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      //tableHeight:600,
      // verticalScroll:true,
      // headerSticky:true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  getActivePlanData() {
    if(this.tenantId){
      this.dashboardservice.getResourceByClientId(this.tenantId).subscribe(res =>{
        if(res.responseCode == 200){
          this.refreshDataSource(res.dataList);
        }else{
          this.tosterService.error(res.responseMessage);
        }
      },error => {
        if (error.responseCode !== 200) {
          this.tosterService.error(error.error.responseMessage);
        }
      }
      )
    }
  }

  open(paydet, data: any) {
    this.modalService.open(paydet, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    this.planMapId = data.planmapid;
  }
}

import { Component, OnInit, Output, Input, EventEmitter, OnChanges, AfterContentInit } from '@angular/core';
import { neoGridDefaultSettings } from './neo-gridDefaultSetting';
import * as alertify from 'alertifyjs';
import { SearchService } from '../../services/search.service';
import { FileUtilService } from '../../services/FileUtil/file-util.service';
import { SearchData } from '../../core/modal/search-data';
import {
  SearchDataTypeEnum,
  SearchFilterConditionEnum,
  SearchFilterTypeValueEnum,
} from '../../core/enum/searchType.enum';
import { OrderingData } from '../../core/modal/ordering-data';
import { PaginationData } from '../../core/modal/pagination-data';
import { NeoColumnModel } from './neo-column.model';
import { TableKeyEnum } from '../../core/enum/table-key.enum';
import { TableKeyEnumPerPage } from '../../core/enum/table-key-per-page';

@Component({
  selector: 'app-neo-grid',
  templateUrl: './neo-grid.component.html',
  styleUrls: ['./neo-grid.component.css'],
})
export class NeoGridComponent implements OnInit, OnChanges, AfterContentInit {
  @Input() dataSource: any;
  @Input() displayedColumns: any = [];
  @Input() allFinalColumns: NeoColumnModel[] = [];
  @Input() settings: any = {}; 
  @Input() searchSettings: any = [];
  @Input() multipleOptionsList: any = [];

  @Output() fetchDataFromApi = new EventEmitter<any>();
  @Output() onEditClick = new EventEmitter<any>();
  @Output() onDeleteClick = new EventEmitter<any>();
  @Output() onCellClick = new EventEmitter<any>();
  @Output() onExcelDownload = new EventEmitter<any>();
  @Output() onPdfDownload = new EventEmitter<any>();
  @Output() onSortChange = new EventEmitter<any>();
  @Output() onPageChange = new EventEmitter<any>();
  @Output() onFilterChange = new EventEmitter<any>();
  @Output() onMultipleOptionClick = new EventEmitter<any>();
  tableConstant=TableKeyEnum;
  tableConstantPerPage=TableKeyEnumPerPage;
  searchGlobal:any;
  constructor(private searchService: SearchService, public fileUtil: FileUtilService) {
   }

  ngOnInit() {
    
    //this.settings.perPage = 20;
    this.settings.columns.forEach((res)=>{
      res.enableSort = false
    })
    let userId = localStorage.getItem('userId');
    if(localStorage.getItem('tableData_' + userId)){
      let tableData = JSON.parse(localStorage.getItem('tableData_' + userId));
      let GlobalName = this.settings.tableId+' Global'
      if(tableData[GlobalName] && tableData[GlobalName].filterValue){
        this.searchGlobal = tableData[GlobalName].filterValue;  
      }
   
  }
  }

  ngOnChanges(): void {
    if (!this.dataSource || (this.settings && !this.settings.tableId)) {
      return;
    }
  }
  ngAfterContentInit() {
    if (this.dataSource && this.settings.showFilter && !this.settings.serverSideFilter) {
      let filterVal = document.querySelector('.global-input').getAttribute('ng-reflect-model')
        ? document.querySelector('.global-input').getAttribute('ng-reflect-model')
        : document.querySelector('.global-input').getAttribute('value');
      this.dataSource.filter = filterVal;
    }
    this.setGridSetting();
  }

  setGridSetting() {
    this.allFinalColumns = this.settings.columns ? this.settings.columns : [];
    this.displayedColumns = this.settings.columns ? this.settings.columns.map((column) => column.colKey) : [];
    Object.keys(neoGridDefaultSettings).map((setting) => {
      this.setOptionValue(setting);
    });
  }

  setOptionValue(propertyName) {
    if (!this.settings.hasOwnProperty(propertyName)) {
      this.settings[propertyName] = neoGridDefaultSettings[propertyName];
    }
  }

  fillTemplate(templateString, templateVars) {
    var parsed = templateString;
    if (templateVars) {
      Object.keys(templateVars).forEach((key) => {
        const value = templateVars[key];
        parsed = parsed.replace('${' + key + '}', value);
      });
    }
    return parsed;
  }

  editButtonClick(event): void {
    this.onEditClick.emit(event);
  }

  deleteButtonClick(event): void {
    let self = this;
    var msgDetail = this.fillTemplate(this.settings.deleteMsgBoxDetail, event.RowData);
    alertify
      .confirm(
        this.settings.deleteMsgBoxTitle,
        msgDetail,
        function () {
          event = Object.assign({ deletClicked: true }, event);
          self.onDeleteClick.emit(event);
        },
        function () {
          event = Object.assign({ deletClicked: false }, event);
          self.onDeleteClick.emit(event);
        }
      )
      .setting('labels', { ok: 'Yes', cancel: 'No' })
      .set('defaultFocus', 'cancel');
  }

  cellButtonClick(event): void {
    // ////console.log('event', event);
    this.onCellClick.emit(event);
  }

  public getTableColumn(): any {
    const allcols = this.allFinalColumns;
    let columnList;
    allcols.map((col) => {
      if (col.colKey != 'actionCol') {
        var obj = { title: col.default, value: null };
        if (col.customControls && col.customControls.length > 0) {
          obj.value = col;
        }
        if (typeof columnList != 'undefined') {
          columnList[col.colKey] = obj;
        } else {
          columnList = { [col.colKey]: obj };
        }
      }
    });
    return columnList;
  }

  excelDownload(event) {
    // ////console.log(JSON.parse(JSON.stringify(this.dataSource.data)));
    if (!this.settings.excelExportUrl) {
      this.onExcelDownload.emit();
      this.generateCSV();
    }
  }

  pdfDownload(event) {
    if (!this.settings.pdfExportUrl) {
      this.onPdfDownload.emit();
      this.generatePdf();
    }
  }

  async generatePdf() {
    const columns = this.getTableColumn();
    const title = this.getExportTitle();
    let setting = await this.fileUtil.getCloumnAndHeader(columns);
    const data = JSON.parse(JSON.stringify(this.dataSource.data));
    this.prepareExportData(data, columns);
    this.fileUtil.convertIntoPdf(setting, data, title, columns, title);
  }

  async generateCSV() {
    const columns = this.getTableColumn();
    const title = this.getExportTitle();
    let setting = await this.fileUtil.getCsvObject(columns);
    const data = JSON.parse(JSON.stringify(this.dataSource.data));
    this.prepareExportData(data, columns);
    this.fileUtil.exportCSVFile(setting, data, title, columns);
  }

  getExportTitle() {
    return this.settings && this.settings.tableId ? this.settings.tableId : 'File';
  }

  prepareExportData(data, columns) {
    if (data && data.length > 0) {
      data.map((dObj, dIndex) => {
        dObj.index = dIndex + 1;
        Object.keys(dObj).map((key) => {
          if (columns[key]) {
            dObj[key] = columns[key].value ? columns[key].value.customValue(dObj[key]) : dObj[key];
          }
        });
      });
    }
    return data;
  }

  setSearch(): void {
    this.searchService.setSearchSettings(this.searchSettings);
  }

  resetSearch(): void {
    this.searchService.resetFilter(null);
  }

  sortChange(event) {
    let sortObj = new OrderingData();
    let cols = this.allFinalColumns;
    if (event && event.active && event.direction) {
      let eventKey = event.active;
      let filteredSortObj = cols.find((col) => {
        return col.colKey == eventKey;
      });
      sortObj.column = filteredSortObj && filteredSortObj.sortKey ? filteredSortObj.sortKey : eventKey;
      sortObj.descending = event.direction == 'desc' ? true : false;
    } else {
      sortObj = null;
    }
    this.onSortChange.emit(sortObj);
  }

  pageChange(event) {
    let userId = localStorage.getItem('userId');
    if(localStorage.getItem('tableData_' + userId)){
      let tableData = JSON.parse(localStorage.getItem('tableData_' + userId));
      tableData[this.settings.tableId] = event.pageSize
      let name = this.settings.tableId+' Number'

      tableData[name] = event.pageIndex + 1;
      tableData = JSON.stringify(tableData)
      localStorage.setItem('tableData_' + userId,tableData)
  }
    let pageObj = new PaginationData();
    pageObj.pageNumber = event.pageIndex ? event.pageIndex + 1 : pageObj.pageNumber;
    pageObj.rowsPerPage = event.pageSize ? event.pageSize : pageObj.rowsPerPage;
    this.onPageChange.emit(pageObj);
  }

  filterChange(event) {
    let searchData = new SearchData();
    this.searchGlobal = event.filter.trim();
    if (event && event.hasOwnProperty('filter')) {
      searchData.filterColumn = 'any';
      searchData.filterOperator = SearchFilterTypeValueEnum.IS_EQUAL_TO;
      searchData.filterCondition = SearchFilterConditionEnum.AND;
      searchData.filterValue = event.filter.trim();
      searchData.filterDataType = SearchDataTypeEnum.STRING;
    }
    let userId = localStorage.getItem('userId');
    
    if(localStorage.getItem('tableData_' + userId)){
      let tableData = JSON.parse(localStorage.getItem('tableData_' + userId));

      let GlobalName = this.settings.tableId+' Global'

      tableData[GlobalName] = searchData
      tableData = JSON.stringify(tableData)
      
      localStorage.setItem('tableData_' + userId,tableData);

    }
    this.onFilterChange.emit(searchData);
  }

  onClickMultipleOptions(event){
    this.onMultipleOptionClick.emit(event);
  }
}

export class document {
    id:number;
    name:string;
    refNo:any;
    category:any;
    file:File;
    subCategory:any;
    status:any
    
}
export class uploaddocument{
    docType:any;
   
    fileName:string
}
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { invalid } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CaseReasonService } from '../case-reason/service/case-reason.service';
import { CommunicationReportService } from '../../reports/communication-report/communication-report.service';
import { ResponseCode } from '../../../core/constant/response-code';

@Component({
  selector: 'app-sla-add-update',
  templateUrl: './sla-add-update.component.html',
  styleUrls: ['./sla-add-update.component.scss']
})
export class SlaAddUpdateComponent implements OnInit {
  formGroup : FormGroup;
  targetList = [{name:'Resolution'},{name:'Response'}];
  statusList = [{name:'Active'},{name:'Inactive'}];
  scheduleList = [{name:'No Schedule'},{name:'Business Working Days'}];
  submitted: boolean = false;
  start_condition: any;
  cancel_condition: any;
  pause_condition:any;
  stop_condition:any;
  reset_condition:any;
  frequencyList = [{name:'Weekly',value:'Weekly'},{name:'Monthly',value:'Monthly'},{name:'Quaterly',value:'Quaterly'},{name:'Yearly',value:'Yearly'}]
  activityList = [{name:'Regular Inspections',value:'Regular Inspections'},
  {name:'Vibration and Noise Analysis',value:'Vibration and Noise Analysis'},
  {name:'Load Testing',value:'Load Testing'},
  {name:'Lubrication',value:'Lubrication'},
  {name:'Exhaust System Maintenance',value:'Exhaust System Maintenance'},
  {name:'Seals and Gaskets',value:'Seals and Gaskets'},
  {name:'Cooling System Maintenance',value:'Cooling System Maintenance'}
  ]
  operatorList=[{name:'AND',value:'AND'},{name:'OR',value:'OR'}]
  startCondList=[{name:'Active'},{name:'Priority'},{name:'State'}];
  valueList=[{name:'True'},{name:'False'},{name:'New'},{name:'Inprogress'},{name:'Resolved'},{name:'On Hold'},{name:'Closed'},{name:'Canceled'},{name:'High'},{name:'Moderate'},{name:'Low'}]
  isUpdateComponent: boolean = false;
  slaId: any;
  whCanList= [{name:'Start conditions are not met'},{name:'Cancel conditions are met'}];
  selectedIndex: any = 0;
  zoneList = [];
  data = [
    {id: 1,name: 'Blistering and flaking SLA',
    target:'Resolution',
    zone: 'Al Mina',
    schedule: 'No Schedule',
    day: 5,hour: null,
    minute: null,
    status: 'Active',
    start_condition:[{condition:'Active',value:'True',operator:'AND'},{condition:'Priority',value:'High',operator:null}],
    stop_condition:[{condition:'Active',value:'False',operator:'OR'},{condition:'State',value:'Resolved',operator:null}],
    reset_condition:[{condition:'Escalation Notification',value:'2',operator:'Saket Tiwari',action:'Notify'},{condition:'Escalation Assignment',value:'5',operator:'Saket Tiwari',action:'Assign'}],
    pause_condition:[{condition:'State',value:'On Hold'}],
    cancel_condition:[{condition:'Start conditions are not met'}],
    }
  ]
  filterdata: any;
  filData: any;

  notificationList = [{name:'Escalation Notification'},{name: 'Escalation Assignment'}];
  staffList = [];
  Localpartnerid: any;
  actionList = [{name:'Notify'},{name:'Assign'}]

  constructor(private formBuilder: FormBuilder, private router: Router, 
    private route: ActivatedRoute, private toaster: ToastrService, 
    private caseReasonService: CaseReasonService,
    private CommunicationReportService: CommunicationReportService) {
    this.setValidation()
    this.Localpartnerid = Number(localStorage.getItem('partnerId'));
    
  }

  getStaffList(partnerid) {
    this.CommunicationReportService.staffuserByPartner(partnerid).subscribe(result => {
      this.staffList = result.staffUserlist;
    })
    // this.staffList.forEach(res => {
    //   res['name'] = res.fullName
    // })
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.slaId = id;
      this.caseReasonService.getSLAById(this.slaId).subscribe((response) => {
        this.isUpdateComponent = true;
        this.formGroup.patchValue(this.setData(response.data));
        this.filData =  this.formGroup.value;
        this.setitem([this.setData(response.data)]);
      })
    }else{
      this.addItem();
      this.addItem2();
      this.addItem3();
      this.addItem4();
      this.addItemCan();
      //this.addItemAsce();
    }
    this.getServiceArea();
    this.getStaffList(this.Localpartnerid)
  }

  setData(submittedData: any) {
    let data: any = {};
  
    data.name = submittedData.slaName;
    data.status = submittedData.status;
    data.zone = submittedData.serviceAreaName;
    data.zoneId = submittedData.serviceAreaId;
    data.target = submittedData.target;
    data.schedule = submittedData.schedule;
    data.day = submittedData.days;
    data.hour = submittedData.hours;
    data.minute = submittedData.minutes;
    data.slaId = submittedData.slaId;
    data.start_condition = [];
    data.pause_condition = [];
    data.stop_condition = [];
    data.reset_condition = [];
  
    if (submittedData.slaConditionsList && submittedData.slaConditionsList.length > 0) {
      submittedData.slaConditionsList.forEach((condition) => {
        let conditionObj = {
          condition: condition.condition,
          value: condition.value,
          operator: condition.operator,
          id: condition.id
        };
  
        if (condition.type === "start-condition") {
          if (condition.cancelCondition) {
            data.cancel_condition = [
              { condition: condition.cancelCondition }
            ];
          }
          data.start_condition.push(conditionObj);
        } else if (condition.type === "Pause-condition") {
          data.pause_condition.push(conditionObj);
        } else if (condition.type === "Stop-condition") {
          data.stop_condition.push(conditionObj);
        }
      });
    }
  
    if (submittedData.slaEscalationDetailsList && submittedData.slaEscalationDetailsList.length > 0) {
      submittedData.slaEscalationDetailsList.forEach((escalation) => {
        let escalationObj = {
          condition: escalation.notificationName,
          value: escalation.days,
          operator: escalation.assignedTo,
          action: escalation.action,
          id: escalation.id
        };
        data.reset_condition.push(escalationObj);
      });
    }
  
    return data;
  }
  

  setValidation(){
    this.formGroup = this.formBuilder.group({
      slaId: new FormControl(''),
      name: new FormControl('',[Validators.required]),
      target: new FormControl(null,[Validators.required]),
      zone: new FormControl(null,[Validators.required]),
      zoneId: new FormControl(null),
      status: new FormControl(null,[Validators.required]),
      schedule: new FormControl(null,[Validators.required]),
      duration: new FormControl(null,[Validators.required]),
      day: new FormControl(null),
      hour: new FormControl(null,[Validators.pattern('^([0-1]?[0-9]|2[0-3])')]),
      minute: new FormControl(null,[Validators.pattern('^([0-5]?[0-9])')]),
      start_condition: this.formBuilder.array([]),
      cancel_condition: this.formBuilder.array([]),
      pause_condition: this.formBuilder.array([]),
      stop_condition: this.formBuilder.array([]),
      reset_condition: this.formBuilder.array([]),
    });
  }

  getServiceArea(){
    this.caseReasonService.getAllServiceArea().subscribe((res)=>{
      this.zoneList = res.dataList;
    });
  }

  onChangeZone(event){
    if(event){
      this.formGroup.get('zoneId').setValue(event.id);
    }else{
      this.formGroup.get('zoneId').setValue(undefined);
    }
  }

  onCancel(){
    this.router.navigate(['customer-care/sla'])
  }

  onSubmit() {
    if (this.formGroup.get('day').value != null || this.formGroup.get('hour').value != null || this.formGroup.get('minute').value != null) {
      this.formGroup.get('duration').setValue('dsg');
    }
    this.submitted = true;
    if (this.formGroup.invalid) {
      return this.toaster.error('Please fill all the details!')
    }
    console.log(this.getData());
    this.caseReasonService.onSubmitSLA(this.getData(), this.isUpdateComponent).subscribe((response) => {
      if (response.responseCode != ResponseCode.OK) {
        this.onResponseError(response.responseMessage);
        return;
      }
      this.onCancel();
    },
      (error) => {
        this.onResponseError(error.message);
      })
  }

  public onResponseError(message: string) {
    this.toaster.error(message.replace('\n', '<br/>'), 'Error', { enableHtml: true });
  }
  
  getData(){
    let data = this.formGroup.value;
    let submittedData: any = {};
    submittedData.slaName = data.name;
    submittedData.status = data.status;
    submittedData.serviceAreaName = data.zone;
    submittedData.serviceAreaId = data.zoneId;
    submittedData.target = data.target;
    submittedData.schedule = data.schedule;
    submittedData.days = data.day;
    submittedData.hours = data.hour;
    submittedData.minutes = data.minute;
    submittedData.slaConditionsList = [];
    submittedData.slaEscalationDetailsList = [];
    submittedData.slaId = data.slaId;
    if(data.start_condition && data.start_condition.length > 0){
      data.start_condition.forEach((startCondition) => {
        let obj = {
          type: "start-condition",
          condition: startCondition.condition,
          value: startCondition.value,
          operator: startCondition.operator,
          id: startCondition.id,
          cancelCondition: (data.cancel_condition && data.cancel_condition.length > 0) ? data.cancel_condition[0].condition : null
        }
        submittedData.slaConditionsList.push(obj);
      });
    }
    if(data.pause_condition && data.pause_condition.length > 0){
      data.pause_condition.forEach((pauseCondition) => {
        let obj = {
          type: "Pause-condition",
          condition: pauseCondition.condition,
          value: pauseCondition.value,
          operator: pauseCondition.operator,
          id: pauseCondition.id
        }
        submittedData.slaConditionsList.push(obj);
      });
    }
    if(data.stop_condition && data.stop_condition.length > 0){
      data.stop_condition.forEach((stopCondition) => {
        let obj = {
          type: "Stop-condition",
          condition: stopCondition.condition,
          value: stopCondition.value,
          operator: stopCondition.operator,
          id: stopCondition.id
        }
        submittedData.slaConditionsList.push(obj);
      });
    }
    if(data.reset_condition && data.reset_condition.length > 0){
      data.reset_condition.forEach((resetCondition) => {
        let obj = {
          notificationName: resetCondition.condition,
          days: resetCondition.value,
          assignedTo: resetCondition.operator,
          action: resetCondition.action,
          id: resetCondition.id
        }
        submittedData.slaEscalationDetailsList.push(obj);
      })
    }
    return submittedData;
  }

  get f(){
    return this.formGroup.controls;
  }

  keypress(event: any) {
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  addItem(){
    this.start_condition = this.formGroup.get('start_condition') as FormArray;
    this.start_condition.push(this.createTierItem());
  }

  addItemCan(){
    this.cancel_condition = this.formGroup.get('cancel_condition') as FormArray;
    this.cancel_condition.push(this.createTierItem());
  }

  // addItemAsce(){
  //   this.cancel_condition = this.formGroup.get('cancel_condition') as FormArray;
  //   this.cancel_condition.push(this.createTierItem());
  // }

  createTierItem(): FormGroup {
    return this.formBuilder.group({
      condition: null,
      operator: null,
      value: null,
      id: null
    });
  }

  createTierItem2(): FormGroup {
    return this.formBuilder.group({
      condition: null,
      operator: null,
      value: null,
      action: null,
      id: null
    });
  }

  addItem2(){
    this.pause_condition = this.formGroup.get('pause_condition') as FormArray;
    this.pause_condition.push(this.createTierItem());
  }

  addItem3(){
    this.stop_condition = this.formGroup.get('stop_condition') as FormArray;
    this.stop_condition.push(this.createTierItem());
  }

  addItem4(){
    this.reset_condition = this.formGroup.get('reset_condition') as FormArray;
    this.reset_condition.push(this.createTierItem2());
  }

  deleteItem(i){
    (<FormArray>this.formGroup.get('start_condition')).removeAt(i);
  }

  deleteItem2(i){
    (<FormArray>this.formGroup.get('pause_condition')).removeAt(i);
  }

  deleteItem3(i){
    (<FormArray>this.formGroup.get('stop_condition')).removeAt(i);
  }

  deleteItem4(i){
    (<FormArray>this.formGroup.get('reset_condition')).removeAt(i);
  }

  deleteItemCan(i){
    (<FormArray>this.formGroup.get('cancel_condition')).removeAt(i);
  }

  setitem(Data){
    let taxFormData: any = {
    };
    if(this.isUpdateComponent){
      if (Data[0].start_condition && Data[0].start_condition.length) {
        taxFormData.start_condition = Data[0].start_condition;
        Data[0].start_condition.forEach(element => {
          this.addItem();
        });
        this.start_condition = Data[0].start_condition;
      }
      if (Data[0].stop_condition && Data[0].stop_condition.length) {
        taxFormData.stop_condition = Data[0].stop_condition;
        Data[0].stop_condition.forEach(element => {
          this.addItem3();
        });
        this.stop_condition = Data[0].stop_condition;
      }
      if (Data[0].pause_condition && Data[0].pause_condition.length) {
        taxFormData.pause_condition = Data[0].pause_condition;
        Data[0].pause_condition.forEach(element => {
          this.addItem2();
        });
        this.pause_condition = Data[0].pause_condition;
      }
      if (Data[0].cancel_condition && Data[0].cancel_condition.length) {
        taxFormData.cancel_condition = Data[0].cancel_condition;
        Data[0].cancel_condition.forEach(element => {
          this.addItemCan();
        });
        this.cancel_condition = Data[0].cancel_condition;
      }
      if (Data[0].reset_condition && Data[0].reset_condition.length) {
        taxFormData.reset_condition = Data[0].reset_condition;
        Data[0].reset_condition.forEach(element => {
          this.addItem4();
        });
        this.reset_condition = Data[0].reset_condition;
      }
      this.formGroup.patchValue(taxFormData);
    }
  }

  tabChange(el){
    if(el){
      this.selectedIndex = el.index
    }
  }

  checkForValida(){
    if(this.formGroup.get('day').value != null || this.formGroup.get('hour').value != null || this.formGroup.get('minute').value != null){
      this.formGroup.get('duration').setValue('dsg');
    }
    if(this.formGroup.get('day').value == null && this.formGroup.get('hour').value == null && this.formGroup.get('minute').value == null){
      this.formGroup.get('duration').reset();
      this.formGroup.get('duration').markAsTouched();
      this.formGroup.get('duration').setErrors({'invalid': true});
    }
  }

}

export class Team {
    id: number;
    name: string;
    status: string;
    isDeleted: boolean = false;
    serviceAreaIdList:[];
    typeOfTeam:string;
    teamUserMapping:Array<TeamUser>;
    groupid: any
}

export class TeamUser{
    isAssignable: boolean;
    teamId: number;
    staffId: number;

    //for angular use
    staffName:string;
    empCode:string;
}
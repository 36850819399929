import { Injectable } from '@angular/core';
import { BaseService } from '../../../services/base-service';
import { HttpClient } from '@angular/common/http';
import { PaginationData } from '../../../core/modal/pagination-data';
import { OrderingData } from '../../../core/modal/ordering-data';
import { ResponseData } from '../../../core/modal/base-save-update-response';
import { SearchData } from '../../../core/modal/search-data';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { document } from './document.modal';
import { id } from '@swimlane/ngx-datatable/release/utils';
const baseApiUrl = '';
@Injectable({
  providedIn: 'root'
})
export class DocumentPropertyService extends BaseService<document> {
 
  constructor(http: HttpClient,private toaster: ToastrService) {
    super(http);
  }

  getModuleName(): string {
    return 'docRepository';
  }
  validate(): boolean {
    return false;
  }
  getAll(
    paginationData: PaginationData,
    orderingData: OrderingData,
    searchData?: SearchData[],
    status?:any,
    filterBy?:any
  ): Observable<ResponseData<any>> {
    const searchDataObj = searchData && searchData.length > 0 ? JSON.stringify(searchData) : null;
    const params:any = {}; //new HttpParams();
    if (paginationData) {
       params.page = paginationData.pageNumber.toString();
       params.pageSize = paginationData.rowsPerPage.toString();
       //params.set('page', paginationData.pageNumber.toString());
       //params.set('pageSize', paginationData.rowsPerPage.toString());
    }
    if (orderingData) {
      params.sortBy = orderingData.column;
      params.sortOrder = (orderingData.descending ? 1 : 0) + '';
       // params.set('sortBy', orderingData.column);
       // params.set('sortOrder', (orderingData.descending ? 1 : 0) + '');
    }
    // //console.log('searchData', searchData);
    if (searchDataObj) {
       params.filters = JSON.parse(searchDataObj);
       // params.append('search', searchDataObj);
    }
    if(status){
      //console.log("status::",status);
      params.status = status;
    }
    if(filterBy){
      //console.log("filter::",filterBy);
      params.filterBy = filterBy;
    }
    // api/v1/anyModuleUrl/getAllByPagination?page=1&pageSize=1&sortBy="id"&sortOrder="1"

    // return this.http.get<ResponseData<T>>(baseApiUrl + this.getModuleName(), { headers: httpOptions.headers });

    return this.http.post<ResponseData<any>>(baseApiUrl + this.getModuleName(), params);
  }
  categoryList():Observable<any>{
    return this.http.get<any>(baseApiUrl +this.getModuleName()+'/getCategoryList' );
  }
  documentList():Observable<any>{
    return this.http.get<any>(baseApiUrl +'commonList'+'/generic'+'/docName' );
  }
  documentsave(param):Observable<any>{
    return this.http.post<any>(baseApiUrl +this.getModuleName()+'/save',param );
  }
  documentupdate(param):Observable<any>{
    return this.http.post<any>(baseApiUrl +this.getModuleName()+'/update',param );
  }
  documentuploadsave(id,obj):Observable<any>{
    return this.http.post<any>(baseApiUrl +this.getModuleName()+'/saveDocumentDtls/'+id,obj);
  }
  documentuploadupdate(id,id2,obj):Observable<any>{
    return this.http.post<any>(baseApiUrl +this.getModuleName()+'/updateDocumentDtls/'+id+'/'+id2,obj);
  }
  uploaddocumentList(id):Observable<any>{
    return this.http.get<any>(baseApiUrl +'docRepository/'+id );
  }
  uploaddocumentdelete(id,id2):Observable<any>{
    return this.http.get<any>(baseApiUrl +'docRepository/'+'deleteDocumentDtls/'+id+'/'+id2);
  }
  dowaloaddocument(uniquefilename){
    return this.http.get<any>(baseApiUrl +'docRepository/'+'getDocument/'+uniquefilename);
  }
  downloadDocument(file) {
    const mediaType = 'application/pdf';
    this.http.get(baseApiUrl + 'docRepository/getDocument/' + file, { responseType: 'blob'}).subscribe((response) => {
      const blob = new Blob([response],{ type: mediaType });
      saveAs(blob, file);
    }, (error) => {
      this.toaster.error(error);
    });
  }
  statuslist():Observable<any>{
    return this.http.get<any>(baseApiUrl +'commonList/'+'generic/'+'planStatus');
  }
}

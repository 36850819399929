import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { EventColor } from 'calendar-utils';

const colors: Record<string, EventColor> = {
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  white: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF'
  }
};

@Component({
  selector: 'app-service-request-schedule',
  templateUrl: './service-request-schedule.component.html',
  styleUrls: ['./service-request-schedule.component.scss']
})
export class ServiceRequestScheduleComponent implements OnInit {
  customerList = [
    {
      name: 'Deepika rao'
    },
    {
      name: 'Nisha patel'
    },
    {
      name: 'Rekha verma'
    },
    {
      name: 'Cleveland Clinic'
    },
    {
      name: 'Suresh reddy'
    },
    {
      name: 'Ritu verma'
    },
    {
      name: 'Arjun sharma'
    },
    {
      name: 'Rajesh purohit'
    },
    {
      name: 'Meera desai'
    }
  ]

  machineList = [
    { name: 'GRN-JAN-2022-4-5' },
    { name: 'GRN-JAN-2022-4-4' },
    { name: 'GRN-JAN-2022-4-3' },
    { name: 'GRN-JAN-2022-4-2' },
    { name: 'GRN-JAN-2022-4-1' },
    { name: 'GRN-JAN-2022-5-2' }
  ]

  serviceRequestList = [
    { name: 'TKT-81033' },
    { name: 'TKT-81034' },
    { name: 'TKT-81035' },
    { name: 'TKT-81039' },
    { name: 'TKT-81040' },
    { name: 'TKT-81041' },
    { name: 'TKT-81042' },
    { name: 'TKT-81043' },
  ]

  maintananceCategoryList = [
    { name: 'Scheduled' },
    { name: 'Raised By Customer' }
  ]

  staffList = [
    { name: 'Wilber Wuckert' },
    { name: 'Zoya Akhtar' },
    { name: 'Siddharth Chaudhary' }
  ]
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild('createEvent', { static: true }) createEvent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();


  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
      },
    },
  ];

  refresh = new Subject<void>();

  events: any[] = [
    {
      start: addHours(startOfDay(new Date()), 1),
      end: addHours(new Date(), 1),
      title: 'Scheduled#wilberwuckert#SR-300000145 : GRN-JAN-2022-1-2, Fusion Founders ',
      customer: 'Nisha Patel',
      scheduledDate: '25-AUG-2023',
      serviceRequestNo: 'SR-300000145',
      resourceNo: 'GRN-JAN-2022-1-2',
      maintananceCategory: 'Scheduled',
      assignTo: 'Wilber Wuckert',
      activites: '',
      remarks: '',
      color: { ...colors.blue },
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
    {
      start: addHours(startOfDay(new Date()), 1),
      end: addHours(new Date(), 1),
      scheduledDate: '-',
      title: 'RBC#wilberwuckert#SR-300000137 : GRN-JAN-2022-1-3, Reilly Raynor',
      customer: 'Deepika Rao',
      serviceRequestNo: 'SR-300000137',
      resourceNo: 'GRN-JAN-2022-1-3',
      maintananceCategory: 'Raised By Customer',
      assignTo: 'Wilber Wuckert',
      activites: '',
      remarks: '',
      color: { ...colors.yellow },
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
    // {
    //   start: addHours(startOfDay(new Date()), 1),
    //   end: addHours(new Date(), 1),
    //   title: 'RBC#wilberwuckert#TKT-81051 : GRN-JAN-2022-5-2, Arjunsharma',
    //   customer: 'Arjun Sharma',
    //   serviceRequestNo: 'TKT-810351',
    //   scheduledDate: '-',
    //   resourceNo: 'GRN-JAN-2022-5-2',
    //   maintananceCategory: 'Raised By Customer',
    //   assignTo: 'Wilber Wuckert',
    //   activites: '',
    //   remarks: '',
    //   color: { ...colors.yellow },
    //   actions: this.actions,
    //   allDay: true,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // }
  ];

  activeDayIsOpen: boolean = false;

  constructor(private modal: NgbModal) {
    const startOfMonth = new Date(2023, 7, 1); // August is the 8th month (0-based index)
    const endOfMonth = new Date(2023, 7, 31); // August is the 8th month (0-based index)

    for (let day = startOfMonth.getDate(); day <= endOfMonth.getDate(); day++) {
      if (day === 24 || day === 25) {
        continue; // Skip generating events for 24th and 25th
      }
      const currentDate = new Date(2023, 7, day);

      // Skip generating events for Saturdays and Sundays (day 0 is Sunday, day 6 is Saturday)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        const randomRecord = Math.floor(Math.random() * 3); // Generate random record between 0, 1, and 2
        if (randomRecord === 0) {
          continue;
        }
        if (randomRecord === 2) {
          // If random number is 2, push two records with the same date
          this.events.push(
            this.createEventObject(currentDate),
            this.createEventObject(currentDate)
          );
        } else {
          // If random number is not 2, push one record with the date
          this.events.push(this.createEventObject(currentDate));
        }
      }
    }
  }

  createEventObject(date: Date) {
    return {
      start: date,
      end: date,
      title: `A ${date.toLocaleDateString()} event`,
      color: { ...colors.red }, // Assuming colors is an object with predefined color values
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    };
  }

  ngOnInit() {
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }, modal): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }

  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
  }
  
  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

}

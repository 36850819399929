
import { Component, ElementRef, OnInit, Optional, Inject} from '@angular/core';
import _ from 'underscore';
import * as alertify from 'alertifyjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { LoginService } from '../../../login/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingServiceService } from '../../../data-sharing-service.service';
import { SubscriberDetailsService } from '../subscriber-details.service';
import { TroubleTicketsDetailsService } from '../subscriber-tables/trouble-tickets-details/trouble-tickets-details.service';
import { TokenStorageService } from '../../../../auth/token-storage-service';
import { TenantList } from '../../model/machineData';
declare let jQuery: any;
declare let $:any;

@Component({
  selector: "app-tenant-detail",
  templateUrl: "./tenant-detail.component.html",
  styleUrls: ["./tenant-detail.component.scss"],
})
export class TenantDetailComponent implements OnInit {
  navTabActiveFlag: boolean = false;
  dataSource = new MatTableDataSource();
  $menuSection: any;
  operation_type: any;
  el: ElementRef;
  private ngbRef: NgbModalRef;
  ui_id: string;
  tableKeyEnum = TableKeyEnum;
  caseDetail: any;
  custId: any;
  allColumns: any[] = [];
  neogridsetting = {};
  backbutton :boolean=false;
  dashboardData: any;
  operationTypeList = [
    { label: "Basic Details" },
    { label: "Service Request Details" },
    { label: "Machine Details" },
  ];
  dataForTenant: any;
  dataForUnit: any = [];
  dataForInvoice: any = [];
  dataForContract: any  = []

  public loginService: LoginService;
  constructor(
    el: ElementRef,
    private ngbModalService: NgbModal,
    private subscriberDetailsService: SubscriberDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    loginservice: LoginService,
    private tosterservice: ToastrService,
    private sharingdataService: DataSharingServiceService,
    private tokenService: TokenStorageService,
    private troubleTicketsDetailsService: TroubleTicketsDetailsService
  ) {
    this.el = el;
    this.loginService = loginservice;
  }

  ismobileback(): boolean {
    let w = document.documentElement.clientWidth;
    let breakpoint = 992;
    if (w < breakpoint) return true;
    else return false;
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get("id");
    if (id) {
      this.custId = id;
      //this.custId = TenantList.filter(res => res.id == Number(id))[0].bpid;
      this.dataForTenant = TenantList.filter(res => res.id == Number(id))[0].ES_PARTNER
      this.dataForUnit = TenantList.filter(res => res.id == Number(id))[0].ET_UNIT.item
      this.dataForInvoice = TenantList.filter(res => res.id == Number(id))[0].ET_INVOICE.item
      this.dataForContract = TenantList.filter(res => res.id == Number(id))[0].ET_CONTRACT.item
      this.custId = (Number(this.custId)).toString();
      //this.getSubscriberBasicDetails();
    }
    this.operation_type = this.operationTypeList.find(
      (res) => res.label == "BasicDetails"
    );
    this.onOperationTypeChange("Basic Details");
    this.filedSetCollapse();
    this.getTroubleTicketsDetailsData();
    this.backbutton = this.ismobileback();
    window.onresize = () => {
      this.backbutton = this.ismobileback();
    };
  }

  getSubscriberBasicDetails() {
    if(this.custId){
      this.subscriberDetailsService.getBusinessPartnerContractData(this.custId).subscribe(res =>{
        if(res.responseCode == 200){
          this.dashboardData = res.data.ES_PARTNER;
        }else{
          this.tosterservice.error(res.responseMessage);
        }
      },error => {
        if (error.responseCode !== 200) {
          this.tosterservice.error(error.error.responseMessage);
        }
      }
      )
    }
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }

  scroll(prm) {
    let el = document.getElementById(prm);
    console.log(el);
    el.scrollIntoView({ behavior: "smooth" });
  }

  filedSetCollapse() {
    (function ($) {
      $.fn.collapsible = function (options) {
        var settings = $.extend(
          {
            collapsed: false,
            animation: true,
            speed: "medium",
          },
          options
        );

        this.each(function () {
          var $fieldset = $(this);
          var $legend = $fieldset.children("legend");
          var isCollapsed = $fieldset.hasClass("collapsed");

          $legend.click(function () {
            collapse($fieldset, settings, !isCollapsed);
            isCollapsed = !isCollapsed;
          });

          // Perform initial collapse.
          // Don't use animation to close for initial collapse.
          if (isCollapsed) {
            collapse($fieldset, { animation: false }, isCollapsed);
          } else {
            collapse($fieldset, settings, isCollapsed);
          }
        });
      };
      /**
       * Collapse/uncollapse the specified fieldset.
       * @param {object} $fieldset
       * @param {object} options
       * @param {boolean} collapse
       */
      function collapse($fieldset, options, doCollapse) {
        let $container = $fieldset.find("div");
        if (doCollapse) {
          if (options.animation) {
            $container.slideUp(options.speed);
          } else {
            $container.hide();
          }
          $fieldset.removeClass("expanded").addClass("collapsed");
        } else {
          $container.show();
        }
        $fieldset.removeClass("collapsed").addClass("expanded");
      }
    })($);

    $("fieldset.collapsible").collapsible();
  }

 
  
  getTroubleTicketsDetailsData() {
    this.troubleTicketsDetailsService
      .getTroubleTicketsDetailsList(this.custId)
      .subscribe((result) => {
        this.caseDetail = result.data;
      });
  }
 
  backToView() {
    if (this.sharingdataService.lastAuditUrl == this.router.url) {
      this.router.navigate(["/operations/audit"]);
      this.sharingdataService.lastAuditUrl = undefined;
    } else {
      this.router.navigate(["/masters/subscriber"]);
    }
  }

  onOperationTypeChange(event){
    this.operation_type = event;
  }

  openModal(modal){
    this.ngbModalService.open(modal)
  }

  onClickSaveForm(){
    this.closeModal();
  }

  OnCaseRefresh(data) {
    this.caseDetail = data
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from '../../../services/base-service';
import { equipmentinventory } from '../model/activity-model';
const base_api_url = ''
@Injectable({
  providedIn: 'root'
})
export class EquipmentInventoryService extends BaseService<equipmentinventory> {

data=[
{
  "id":"1",
"vanId":"7894565",
"plateNumber":"L-54544",
"model":"Ford Transit",
"manufacturer":"Ford",
"manufacturerdate":"2027-09-20",
"Capacity":"200KG",
"fueltype":"Petrol"
},
{
  "id":"2",
  "vanId": "7894566",
  "plateNumber": "L-54545",
  "manufacturerdate":"2027-09-20",
  "model": "Mercedes Sprinter",
  "manufacturer": "Mercedes-Benz",
  "Capacity": "300KG",
  "fueltype": "Diesel"
},
{
  "id":"3",
  "vanId": "7894567",
  "plateNumber": "L-54546",
  "manufacturerdate":"2027-09-20",
  "model": "Volkswagen Crafter",
  "manufacturer": "Volkswagen",
  "Capacity": "250KG",
  "fueltype": "Diesel"
},
{
  "id":"4",
  "vanId": "7894568",
  "plateNumber": "L-54547",
  "manufacturerdate":"2027-09-20",
  "model": "Renault Master",
  "manufacturer": "Renault",
  "Capacity": "220KG",
  "fueltype": "Diesel"
},
{
  "id":"5",
  "vanId": "7894569",
  "plateNumber": "L-54548",
  "manufacturerdate":"2027-09-20",
  "model": "Citroën Relay",
  "manufacturer": "Citroën",
  "Capacity": "270KG",
  "fueltype": "Petrol"
},
{
  "id":"6",
  "vanId": "7894570",
  "plateNumber": "L-54549",
  "manufacturerdate":"2027-09-20",
  "model": "Peugeot Boxer",
  "manufacturer": "Peugeot",
  "Capacity": "210KG",
  "fueltype": "Diesel"
},
{
  "id":"7",
  "vanId": "7894571",
  "plateNumber": "L-54550",
  "manufacturerdate":"2027-09-20",
  "model": "Iveco Daily",
  "manufacturer": "Iveco",
  "Capacity": "320KG",
  "fueltype": "Diesel"
},
{
  "id":"8",
  "vanId": "7894572",
  "plateNumber": "L-54551",
  "model": "Nissan NV400",
  "manufacturerdate":"2027-09-20",
  "manufacturer": "Nissan",
  "Capacity": "230KG",
  "fueltype": "Petrol"
},
{
  "id":"9",
  "vanId": "7894573",
  "plateNumber": "L-54552",
  "model": "Fiat Ducato",
  "manufacturer": "Fiat",
  "manufacturerdate":"2027-09-20",
  "Capacity": "280KG",
  "fueltype": "Diesel"
},
{
  "id":"10",
  "vanId": "7894574",
  "plateNumber": "L-54553",
  "manufacturerdate":"2027-09-20",
  "model": "Vauxhall Movano",
  "manufacturer": "Vauxhall",
  "Capacity": "240KG",
  "fueltype": "Petrol"
}
]

  constructor(http: HttpClient,private toaster: ToastrService) {
    super(http);
  }

  getModuleName(): string {
    return 'resources';
  }
  validate(): boolean {
    return false;
  }

  allocationSave(data){
    return this.http.post(base_api_url + 'resources/allocateResource',data)
  }


}

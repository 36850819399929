import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { SearchService } from '../../../../services/search.service';
import * as alertify from 'alertifyjs';
import { NetworkDevicesService } from '../services/network-devices.service';
import { OldAbstractBaseListComponent } from '../../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../../aclOperationConstants';
import { AclClassConstants } from '../../../aclClassConstants';
import { LoginService } from '../../../login/login.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-network-devices',
  templateUrl: './network-devices.component.html',
  styleUrls: ['./network-devices.component.scss']
})
export class NetworkDevicesComponent extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('serviceAreaWrapper', { static: false }) serviceAreaWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('networkDevice', { static: false }) networkDeviceReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  networkDevicesDataForGrid:any = {content:[]};
  networkDevicesGridSettings: any = {};
  show_update:any = false;
  selectForUpdate: any;
  tableKey = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  searchSettings = [];
  subscriptions: Subscription[] = [];
  filteredServiceDataForGrid: any = [];
  filteredNetworkDevicesDataForGrid : any = [];
  constructor(
    private networkDevicesService : NetworkDevicesService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private searchService: SearchService,
    loginService: LoginService,
    private tosterservice: ToastrService
  ) {
    super(searchService);
    this.loginService = loginService;
  }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKey.NETWORK_DEVICE_LIST)
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      if (obj && obj.filters) {
      this.applyFilter(obj);
       }
    });
    this.setGridSetting();
    this.initPaginationSort();
    this.subscriptions.push(filterSubscription);
  }
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey === 'servicearea') {
        col.customControls = [this.serviceAreaWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.networkDeviceReff];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Device Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'devicetype',
        default: 'Device Type',
        rowKey: 'devicetype',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'snmpEnabled',
        default: 'SNMP Enabled',
        rowKey: 'snmpEnabled',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'servicearea',
        default: 'Service Area',
        rowKey: 'servicearea',
        width: '10%',
        customControls: [this.serviceAreaWrapperRef],
        customValue:(servicearea) => {
          let serviceAreaName = servicearea.name;
          return serviceAreaName;
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        customControls: [this.statusWrapperRef],
        customValue:(status) => {
          if(status === 'A'){
            return 'Active';
          } else {
            return 'InActive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      }
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Device  Type',
        value_field: 'devicetype',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Device  Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['A', 'I'],
          },
          {
            display_field: 'True',
            value_field: 'A',
          },
          {
            display_field: 'False',
            value_field: 'I',
          },
        ],
      },
    ];

    this.networkDevicesGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKey.NETWORK_DEVICE_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Network Device  ?',
      deleteMsgBoxDetail: '"Network Device  Name : ${name} " ',
    };
    if (this.networkDevicesGridSettings.serverSideSortPagination && this.networkDevicesGridSettings.perPage) {
      this.setPaginationPerPage(this.networkDevicesGridSettings.perPage);
    }
  }

  getNetworkDeviceData(gridSetting: any) {
    let fetchSettingData = this.networkDevicesGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.networkDevicesService.getNetworkDevice(params).subscribe(result => {
      this.networkDevicesDataForGrid = result.dataList;
      result.dataList.forEach(element => {
        element.snmpEnabled = element.snmpEnabled ? element.snmpEnabled : '-';
      });
      this.filteredNetworkDevicesDataForGrid = JSON.parse(JSON.stringify(this.networkDevicesDataForGrid));
      this.refreshDataSource(this.networkDevicesDataForGrid);
      this.totalRecords = result.totalRecords;
      this.networkDevicesGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getNetworkDeviceData(config);
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.networkDevicesDataForGrid));
    if (filterObj && filterObj.filters) {
      // Client Side Filter
      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.networkDevicesDataForGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    ////console.log(event);
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
     if (event) {
       this.commonDeleteMsgBox(this.networkDevicesGridSettings,event,this.delete.bind(this));
     }
  }

  delete(objEvent) {
    let self = this;
    let selected_network_devices_data = objEvent.event;
    if (objEvent.deleteClicked) {
    this.networkDevicesService.getNetworkDevicesData(selected_network_devices_data.id).subscribe((result) => {
      this.networkDevicesService.deleteNetworkDevices(result.data).subscribe(
        (data:any) => {
          if(data.responseCode !== 200){
            this.tosterservice.error(data.responseMessage);
          }
          else{
            this.tosterservice.success('Network Device  is deleted successfully');
            self.networkDevicesDataForGrid.forEach(function(item, index) {
              if (item.id === data.data.id) {
                self.networkDevicesDataForGrid.splice(index, 1);
              }
            });
            self.refreshDataSource(this.networkDevicesDataForGrid);
            ////console.log(self.refreshDataSource(this.networkDevicesDataForGrid));
          }
        
        }
      );

    },err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR + err.responseMessage);
    });
   }
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/network-management/network/add_network',rowData.id]);
 }

 onAddNetworkDevicesClick() {
  this.router.navigate(['/network-management/network/add_network']);
 }

 ngOnDestroy() {
  if (this.subscriptions.length > 0) {
    this.subscriptions.map((subscription) => {
      subscription.unsubscribe();
    });
  }
}
setPagination(event){
  let config = { page: event, order: this.orderingData, search: this.searchData };
  this.getNetworkDeviceData(config);
    }
}

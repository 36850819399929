export class Customer{
    id:number;
    custname:string;
    cusemail:string;
    cusphone:number;
    billname:string;
    billaddress:string;
    techconname:string;
    techconphone:number;
    smsenable:boolean = true;  
    emailenable:boolean = true;
    serviceareaid:number;
    leaslinedContactDetail : Array<any>;
}

export class Project {
  id: number;
  projectName: string;
  description: string;
  latitude: string;
  longitude: string;
  address: string;
  projectType:string;
  totalBlocks:number;
  totalUnits:number;
  ownerOfTheProject:string;
  blueprintDocument:string;
  amenitiesInfo:string;
  contactperson:string;
  contactnumber:number;
}
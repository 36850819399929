import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import {FormControl,FormGroup, Validators} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { machineData } from '../model/machineData';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import{document, uploaddocument} from '../document-property-list/document.modal'
import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../services/ibase-service';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { ToastrService } from 'ngx-toastr';
import { CommonListServicesService } from '../../../services/common_list_service/common-list-services.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TokenStorageService } from '../../../auth/token-storage-service';
import { AddUpdateAddressComponent } from '../../address/add-update-address/add-update-address.component';
import { CommonStatusService } from '../../../services/common_status/common-status.service';
import { DocumentPropertyService } from '../document-property-list/document-property.service';
import { Property } from '../model/Property';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-document-property-add-update',
  templateUrl: './document-property-add-update.component.html',
  styleUrls: ['./document-property-add-update.component.scss']
})
export class DocumentPropertyAddUpdateComponent extends AbstractBaseAddUpdateComponent<document> implements OnInit{

  
  public propertyName: string;
  public documentCategory: any;
  public documentSubtype: any;
  isFileSelected: boolean=false;
  selectedFileName: string = '';
  uploadFormData = new FormData();
  circuitDetail: FormGroup;
  isSave:boolean=false
  files: uploaddocument = new uploaddocument();
  circuit = new document();
  public documentUpload:any;
  machineList = machineData
  filteredList: [];
  Statuslist:[]
  updatebutton:boolean=false
  category: any[] = [];
  isUpdateComponent: boolean = false;
  machineCategory = [{
    'brand': 'Lutronic'
  },{
    'brand': 'Cutera'
  },{
    'brand': 'Ultherapy'
  },{
    'brand': 'Brera'
  }]
       documents = [
       ]

  documents1 =  []
  entity: any = {};
  newform: FormGroup = new FormGroup({
    doc: new FormControl(null,[Validators.required]),
    docType: new FormControl(null),
    file: new FormControl(null,[Validators.required])
  })
  urlHome: 'masters/document-property-list';
  fileid: any;
  submittednf : boolean = false;
  acceptedFileType = '.pdf, .xls, .xlsx, .doc, .docx .jpeg, .jpg, .png'
  constructor(
    private toaster: ToastrService,
    private router: Router,
    private ngbModalService:NgbModal,
    private activate: ActivatedRoute,
    private circuitService: DocumentPropertyService,
    private genericDataSharingService: DataSharingServiceService,
    private detectChangeRef: ChangeDetectorRef
  ) {
    super(toaster, router)
    this.setValidation()
  }

  ngOnInit() {
    this.init();
    this.documentList();
    this.statuslist();
    this.selectedFileName = '';
    let id = this.activate.snapshot.paramMap.get('id');

    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));

    }
    this.uploaddocumentlist()
    this.categoryList()
    this.circuit=new document();
    
  }
  public filterlist: any[];
  getService(): IBaseService<document> {
    return this.circuitService;
  }
  getGenericDataService() {
    return this.genericDataSharingService
  }
  setData(data: document) {
    this.circuit = data;
  }
  getData(): document {
    return this.circuit;
  }
  getNewModal() {
    return new document();
  }
  getFormGroup(): FormGroup {
    return this.circuitDetail;
  }
 
  public getModuleHomeUrl(): string {

    return this.urlHome;
  }


  public getActiveRoute(): ActivatedRoute {
    return this.activate;
  }
  setValidation() {
    this.circuitDetail = new FormGroup({
      name: new FormControl(''),
      refno: new FormControl(''),
      category: new FormControl('',[Validators.required]),
      subcategory: new FormControl(''),
      status:new FormControl('')
    })
  }
  getId(): number {
    return this.circuit.id;
  }
  get f() {
    return this.circuitDetail.controls;
  }
  get nf() {
    return this.newform.controls;
  }
  onCancel(){
    this.router.navigate(['/masters/document-property-list']);
  }

  onChangeCat(event){
    this.filteredList = [];
    this.circuitDetail.get('subcategory').reset();
    if(event){
      this.circuit.category=event.value
      this.filteredList=event.subTypeList
    }

  }
  onChangesubCat(event){
    if(event)
      this.circuit.subCategory=event.text
  }
  formSubmit() {
    let self = this;
   
      if (this.validateForm()) {
        // this.circuitService.documentsave(this.circuit).subscribe((res)=>{
        //   if(res.responseCode==200){
        //     this.toaster.success('Data has been Save successfully.', 'Success');

        // this.onSaveAndContinue(this.circuit)
        //   }
        // })
        this.onSaveAndContinue(this.circuit)
      }
    
    else {
      this.toaster.error("Please fill all the details!");
    }
  }
  formSubmit2() {
    let self = this;
   let obj={
    id:this.activate.snapshot.paramMap.get('id'),
    category:this.circuit.category,
    subCategory:this.circuit.subCategory,
    name:this.circuit.name,
    refNo:this.circuit.refNo,
    status:this.circuit.status

   }
      if (this.validateForm()) {
        
        this.circuitService.documentupdate(obj).subscribe((res)=>{
          if(res.responseCode==200){
            this.toaster.success('Data has been Updated successfully.', 'Success');
            this.router.navigate(['/masters/document-property-list']);

          }
        })
      }
    
    else {
      this.toaster.error("Please fill all the details!");
    }
  }
  onSaveAndContinue(modal: document) {
    this.getService()
      .save(modal)
      .subscribe(
        (response) => {
          this.validateResponse(response, () => {
            this.submitted = false;
            const responseParty = response.data;
            this.isUpdateComponent=true
            this.router.navigate(['/masters/document-property-list/document_update/' + responseParty.id]);
            //his.t.add({ severity: 'success', summary: 'Success', detail: 'Data has been save successfully.' });

          });
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }
    onAddClick(qcDet){
      this.submittednf = false;
      this.newform.reset();
      this.detectChangeRef.detectChanges();
      this.ngbModalService.open(qcDet,{size:'lg'});
    }

    saveActivity(){
      this.submittednf = true;
      if(this.newform.valid){
          this.uploadFormData.delete('file');
          this.uploadFormData.delete('docDtlsRequest');
          this.uploadFormData.append('file',this.circuit.file)
        this.uploadFormData.append('docDtlsRequest', JSON.stringify(this.files))
        
        this.circuitService.documentuploadsave(this.activate.snapshot.paramMap.get('id'),this.uploadFormData).subscribe((res)=>{
          if(res.responseCode==200){
            this.toaster.success('File has been Upload successfully.', 'Success');
            this.newform.get('doc').reset();
            this.selectedFileName = '';
            this.isFileSelected = false;
            this.uploaddocumentlist()
            this.closeModal()
          }
        })
      }else{
        this.toaster.error('Please fill all required details.')
      }
    }

    saveActivity2(){
      this.submittednf = true;
      if(this.newform.valid){
        this.uploadFormData.delete('file');
        this.uploadFormData.delete('docDtlsRequest');
        this.uploadFormData.append('file',this.circuit.file)
        this.files.fileName=this.selectedFileName
      this.uploadFormData.append('docDtlsRequest', JSON.stringify(this.files))
      
      this.circuitService.documentuploadupdate(this.activate.snapshot.paramMap.get('id'),this.fileid,this.uploadFormData).subscribe((res)=>{
        if(res.responseCode==200){
          this.toaster.success('File has been Updated  successfully.', 'Success');
          this.newform.get('doc').reset();
          this.selectedFileName = '';
          this.isFileSelected = false;
          this.uploaddocumentlist()
          this.closeModal()
        }
      })
      }else{
        this.toaster.error('Please fill all required details.')
      }
    }

    closeModal(){
      this.ngbModalService.dismissAll();
      this.updatebutton=false
      this.newform.get('doc').reset();
      this.files.docType=null
      this.selectedFileName = '';
      this.isFileSelected = false;
    }

    onChangeDocType(event){
      if(event){

      }
    }
    onEditClick(event,qcDet){
      this.submittednf = false
      this.detectChangeRef.detectChanges();
      this.updatebutton=true
      this.fileid=event.id
      this.files.docType=event.docType
      if(event.uniqueFileName){
        this.selectedFileName=event.fileName
        this.isFileSelected=true
      }
      
      this.ngbModalService.open(qcDet,{size:'lg'});
    }
    onDeleteClick(event){
      this.circuitService.uploaddocumentdelete(this.activate.snapshot.paramMap.get('id'),event.id).subscribe((res)=>{
      if(res.responseCode===200){
        this.toaster.success('File has been deleted successfully.', 'Success');
        this.uploaddocumentlist()
      }
      })
    }

    categoryList() {
      this.circuitService.categoryList().subscribe(response => {
        this.category = response.dataList;
      })
    }
    documentList() {
      this.circuitService.documentList().subscribe(response => {
        this.documents = response.dataList;
      })
    }
    // changedocument(event){
    //   const file = event.target.files[0];
    //   this.files.fileName = file ? file.name : '';
    //   this.circuit.file=file

    //   this.newform.controls['docType'].updateValueAndValidity();
    // }
    uploadFile(event: any, inputType: string) {
      const file = event.target.files[0];
      if (inputType === 'attachment') {
        this.files.fileName = file ? file.name : '';
        this.selectedFileName=file?file.name:'';
        this.isFileSelected = !!file;
        this.circuit.file=file
      } 
    }
    resetFileInput(fileInput: HTMLInputElement, inputType: string) {
      fileInput.value = '';
      if (inputType === 'attachment') {
        this.selectedFileName = '';
        this.isFileSelected = false;
        this.circuit.file = null;
        this.newform.get('file').setValue(null);
      } 
    }
    truncateFileName(fileName: string): string {
      const maxLength = 10; 
      if (fileName.length <= maxLength) {
        return fileName;
      } else {
        return fileName.substring(0, maxLength) + '...';
      }
    }
    docchange(event){
      if(event)
      this.files.docType=event.value
    }
    uploaddocumentlist(){
      if(this.isUpdateComponent==true){
        this.circuitService.uploaddocumentList(this.activate.snapshot.paramMap.get('id')).subscribe((res)=>{
          
          this.documents1=res.data.documentRepositoryDetails
        })
      }
    }
    dowloadfile(item){
     this.circuitService.downloadDocument(item.uniqueFileName) 
      }

      statuslist(){
        this.circuitService.statuslist().subscribe((res)=>{
         this.Statuslist= res.dataList
        })
      }
      onChangestatus(event){
        this.circuit.status=event.value
      }
    
}

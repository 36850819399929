import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { SubscriberService } from './subscriber.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import _ from 'underscore';
import { SearchService } from '../../../services/search.service';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../../core/constant/response-code';
import { CustomModalService } from '../../../components/custom-modal/custom-modal.service';
import { SubscriberDetailsService } from '../subscriber-details/subscriber-details.service';
import { ChangePlanComponent } from '../subscriber-details/change-plan/change-plan.component';
import { ReversePaymentComponent } from '../subscriber-details/reverse-payment/reverse-payment.component';
import { ChangeExpiryComponent } from '../subscriber-details/change-expiry/change-expiry.component';
import { ApplyChargeComponent } from '../subscriber-details/apply-charge/apply-charge.component';
import { PurchaseIpComponent } from '../subscriber-details/purchase-ip/purchase-ip.component';
import { AllocationIpComponent } from '../subscriber-details/allocation-ip/allocation-ip.component';
import { NetworkDetailsComponent } from '../subscriber-details/network-details/network-details.component';
import { ChangePasswordComponent } from '../subscriber-details/change-password/change-password.component';
import { ResetPasswordComponent } from '../subscriber-details/reset-password/reset-password.component';
import { ResetCpePasswordComponent } from '../subscriber-details/reset-cpe-password/reset-cpe-password.component';
import { CpePasswordComponent } from '../subscriber-details/cpe-password/cpe-password.component';
import { ManageMacComponent } from '../subscriber-details/manage-mac/manage-mac.component';
import { ChangeStatusComponent } from '../subscriber-details/change-status/change-status.component';
import { ResetMacComponent } from '../subscriber-details/reset-mac/reset-mac.component';
import { PurchaseAddonComponent } from '../subscriber-details/purchase-addon/purchase-addon.component';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { AdvanceSearchComponent } from '../../../components/advance-search/advance-search.component';
import { PurchaseAddonPlanComponent } from '../subscriber-details/purchase-addon-plan/purchase-addon-plan.component';
import { PurchasePlanComponent } from '../subscriber-details/purchase-plan/purchase-plan.component';
import { TenantList } from '../model/machineData';


declare let $: any;

@Component({
  selector: '[subscriber]',
  templateUrl: './subscriber.template.html',
  styleUrls: ['./subscriber.style.scss'],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: true,
})
export class SubscriberComponent extends OldAbstractBaseListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('connectivity', { static: false }) livestatusRef: TemplateRef<any>;
  @ViewChild('downgradespeed', { static: false }) downgradeRef: TemplateRef<any>;
  @ViewChild ('editUserName',{static:false}) editUserNameRef:TemplateRef<any>;
  @ViewChild ('outstanding',{static:false}) outstandingWrapperRef:TemplateRef<any>;
  @ViewChild ('addressWrapper',{static:false}) addressWrapperReff:TemplateRef<any>;
  @ViewChild ('macWrapper',{static:false}) macWrapperReff:TemplateRef<any>;
  @ViewChild ('firstPayment',{static:false}) firstPaymentRef:TemplateRef<any>;

  @ViewChild(AdvanceSearchComponent, { static: false }) advanceSearch: AdvanceSearchComponent;
  @ViewChild(PurchasePlanComponent, { static: false }) changePlan: PurchasePlanComponent;
  @ViewChild(PurchaseAddonPlanComponent, { static: false }) purchaseAddon: PurchaseAddonPlanComponent;
  @ViewChild(ChangeStatusComponent, { static: false }) changeStatus: ChangeStatusComponent;
  @ViewChild(ReversePaymentComponent, { static: false }) rollbackPayment: ReversePaymentComponent;
  @ViewChild(ChangeExpiryComponent, { static: false }) changeExpiry: ChangeExpiryComponent;
  @ViewChild(ApplyChargeComponent, { static: false }) applyCharge: ApplyChargeComponent;
  @ViewChild(PurchaseIpComponent, { static: false }) purchaseIp: PurchaseIpComponent;
  @ViewChild(AllocationIpComponent, { static: false }) allocationIp: AllocationIpComponent;
  @ViewChild(NetworkDetailsComponent, { static: false }) networkDetails: NetworkDetailsComponent;
  @ViewChild(ChangePasswordComponent, { static: false }) changePass: ChangePasswordComponent;
  @ViewChild(ResetPasswordComponent, { static: false }) resetPass: ResetPasswordComponent;
  @ViewChild(CpePasswordComponent, { static: false }) cpePass: CpePasswordComponent;
  @ViewChild(ResetCpePasswordComponent, { static: false }) resetCpePass: ResetCpePasswordComponent;
  @ViewChild(ManageMacComponent, { static: false }) manageMac: ManageMacComponent;
  @ViewChild(ResetMacComponent, { static: false }) resetMac: ResetMacComponent;

 
  
  // @ViewChild ('createdDate',{static:false}) createdDateReff:TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  openChangePlanPopup: boolean;
  custPassDetails: any;
  passwordDetailsName: any;
  custId: any;
  partnerId: number;
  service: string;

  partnerNameInput: string;
  subscriberBasicDetails: any = {
    macAddressModelList: [],
    planList: []
  };

  subscriberDataForGrid: any = { content: [] };
  subscriberGridSettings: any = {};
  public countryList: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  selectForUpdate: any;
  show_update: any = false;
filterObj:any;
  // config for neo-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  subscriberNeoGridSettings: any = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  public loginService: LoginService;
  advSearchSetting = [];

  editUnameFlag:boolean=false;
  username:string;
  isChangePlanOpen: boolean;
  selectedCustomer: any;
  ui_id: string;
  multipleOptionsList = [];
  filters=[];
  value:any;
  constructor(
    private subscriberService: SubscriberService,
    private subscriberDetailsService: SubscriberDetailsService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private tosterservice:ToastrService,
    private customModalService: CustomModalService,
    loginservice: LoginService,
    private sharingdataService:DataSharingServiceService
  ) {
    super(searchService);
    this.loginService = loginservice;
  }

  ngOnInit() {

    this.searchService.setSearchFilterSettings('subscriber');
    //this.setMenuByACL();
    this.setDefaultPageSize(this.tableKeyEnum.SUBSCRIBER_LIST)
    this.setGridSetting();

    if(this.sharingdataService.filterStatusName){
      this.getMethodValue();
      this.sharingdataService.resetSubscriberStatus();
      
    }else{
      this.initPaginationSort();


  }
  const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
   // if (obj && obj.filters) {
   this.setFilterObjForAdvanceSearch(obj);
   // }else
   });
   this.subscriptions.push(filterSubscription);
        
    }

  setFilterObjForAdvanceSearch(obj){
    let temp= {
      filters:obj
    }
    this.applyFilter(temp);
  }

  setMenuByACL(){
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_CHANGE_PLAN,AclConstants.OPERATION_CUSTOMER_ALL)){
      let purchasePlanObj = {key:'purchase-plan',label:'Purchase Plan'};
      this.multipleOptionsList.push(purchasePlanObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_UPDATE_EXPIRY,AclConstants.OPERATION_CUSTOMER_ALL)){
      let getGeoTrackingObj = {key:'change-expiry',label:'Change Plan Expiry'};
      this.multipleOptionsList.push(getGeoTrackingObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_UPDATE_STATUS,AclConstants.OPERATION_CUSTOMER_ALL)){
      let changeStatusObj = {key:'change-status',label:'Change Status'};
      this.multipleOptionsList.push(changeStatusObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_APPLY_CHARGE,AclConstants.OPERATION_CUSTOMER_ALL)){
      let applyChargeObj = {key:'apply-charge',label:'Apply Charge'};
      this.multipleOptionsList.push(applyChargeObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_PURCHASE_IP,AclConstants.OPERATION_CUSTOMER_ALL)){
      let purchaseIpObj = {key:'purchase-ip',label:'Purchase IP'};
      this.multipleOptionsList.push(purchaseIpObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_ALLOCATE_IP,AclConstants.OPERATION_CUSTOMER_ALL)){
      let allocateIpObj = {key:'allocation-ip',label:'Allocate Ip'};
      this.multipleOptionsList.push(allocateIpObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_CHANGE_PLAN,AclConstants.OPERATION_CUSTOMER_ALL)){
      let purchaseAddonPlanObj = {key:'purchase-addon-plan',label:'Purchase addon plan'};
      this.multipleOptionsList.push(purchaseAddonPlanObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_UPDATE_NETWORK_DETAILS,AclConstants.OPERATION_CUSTOMER_ALL)){
      let networkDetailsObj = {key:'network-details',label:'Network Details'};
      this.multipleOptionsList.push(networkDetailsObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_CHANGE_SELFCARE_PASSWORD,AclConstants.OPERATION_CUSTOMER_ALL)){
      let changeSelfcarePasswordObj = {key:'change-password',label:'Change Selfcare Password'};
      this.multipleOptionsList.push(changeSelfcarePasswordObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_RESET_SELFCARE_PASSWORD,AclConstants.OPERATION_CUSTOMER_ALL)){
      let resetSelfcarePasswordObj = {key:'reset-password',label:'Reset Selfcare Password'};
      this.multipleOptionsList.push(resetSelfcarePasswordObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_CHANGE_CPE_PASSWORD,AclConstants.OPERATION_CUSTOMER_ALL)){
      let changeCPEPasswordObj = {key:'cpe-password',label:'Change CPE Password'};
      this.multipleOptionsList.push(changeCPEPasswordObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_RESET_CPE_PASSWORD,AclConstants.OPERATION_CUSTOMER_ALL)){
      let resetCPEPasswordObj = {key:'reset-cpe-password',label:'Reset CPE Password'};
      this.multipleOptionsList.push(resetCPEPasswordObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_RESET_MAC,AclConstants.OPERATION_CUSTOMER_ALL)){
      let resetMacObj = {key:'reset-mac',label:'Reset Mac'};
      this.multipleOptionsList.push(resetMacObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_MANAGE_MAC,AclConstants.OPERATION_CUSTOMER_ALL)){
      let manageMacObj = {key:'manage-mac',label:'Manage Mac'};
      this.multipleOptionsList.push(manageMacObj);
    }
    if(this.loginService.hasOperationPermission(AclClassConstants.ACL_CUSTOMER,AclConstants.OPERATION_CUSTOMER_VIEW_PASSWORD,AclConstants.OPERATION_CUSTOMER_ALL)){
      let viewPasswordObj = {key:'viewPassword',label:'View Password'};
      this.multipleOptionsList.push(viewPasswordObj);
    }
  }

  popupNameList: any = [
    {
      ui_id: 'change-expiry',
      key_name: 'openChangeExpiryPopup'
    },
    {
      ui_id: 'change-status',
      key_name: 'openChangeStatusPopup'
    },
    {
      ui_id: 'purchase-plan',
      key_name: 'openChangePlanPopup'
    },
    {
      ui_id: 'reset-mac',
      key_name: 'openResetMacPopup'
    },
    {
      ui_id: 'manage-mac',
      key_name: 'openManageMacPopup'
    },
    {
      ui_id: 'change-password',
      key_name: 'openChangePasswordPopup'
    },
    {
      ui_id: 'reset-password',
      key_name: 'openResetPasswordPopup'
    },
    {
      ui_id: 'cpe-password',
      key_name: 'openCPEPasswrdPopup'
    },
    {
      ui_id: 'reset-cpe-password',
      key_name: 'openCPEResetPasswordPopup'
    },
    {
      ui_id: 'purchase-ip',
      key_name: 'openPurchaseIpPopup'
    },
    {
      ui_id: 'purchase-addon-plan',
      key_name: 'openPurchaseAddOnPlan',
    },    
    {
      ui_id: 'allocation-ip',
      key_name: 'openAllocationIpPopup'
    },
    {
      ui_id: 'apply-charge',
      key_name: 'openApplyChargePopup'
    },
    {
      ui_id: 'network-details',
      key_name: 'openNetworkDetailsPopup'
    },
    {
      ui_id: 'block-subscriber',
      key_name: 'openBlockStatusPopup'
    }
  ]
  

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
        if(this.multipleOptionsList.length == 0){
          col.isMultipleOption = false
        }
      }
      if (col.colKey == 'connectivity') {
        col.customControls = [this.livestatusRef];
      }
      if (col.colKey == 'downgradespeed') {
        col.customControls = [this.downgradeRef];
      }
      if(col.colKey== 'username'){
        col.customControls=[this.editUserNameRef];
       
      }
      if(col.colKey== 'outstanding'){
        col.customControls=[this.outstandingWrapperRef];
       
      }
      if(col.colKey== 'outstanding'){
        col.customControls=[this.outstandingWrapperRef];
       
      }
      if(col.colKey== 'fulladdress'){
        col.customControls=[this.addressWrapperReff];
       
      }
      if(col.colKey== 'macaddress'){
        col.customControls=[this.macWrapperReff];
      }
      if(col.colKey== 'firstPayment'){
        col.customControls=[this.firstPaymentRef];
      }
      // if(col.colKey== 'createdate'){
      //   col.customControls=[this.createdDateReff];
      // }
    });
  }
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '20px',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'connectivity',
        default: 'Live',
        rowKey: 'connectivity',
        width: '25px',
        customControls: [this.livestatusRef],
        isFilterAllow: false,
        isNumber: true,
      
      },
      // {
      //   colKey: 'downgradespeed',
      //   default: 'D.grade',
      //   rowKey: 'downgradespeed',
      //   width: '25px',
      //   customControls: [this.downgradeRef],
      //   isFilterAllow: false,
      //   isNumber: true,
     
      // },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        sortKey: 'name',
        width: '15%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'username',
        default: 'Username',
        rowKey: 'username',
        sortKey: 'username',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
        customControls:[this.editUserNameRef]
        
      },
      {
        colKey: 'email',
        default: 'Email',
        rowKey: 'email',
        sortKey: 'email',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'mobile',
        default: 'Mobile',
        rowKey: 'mobile',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'fulladdress',
        default: 'Address',
        rowKey: 'fulladdress',
        width: '0.5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'acctno',
      //   default: 'Account No',
      //   rowKey: 'acctno',
      //   sortKey: 'acctno',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'firstPayment',
      //   default: 'First Payment',
      //   rowKey: 'firstPayment',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'partnerName',
      //   default: 'Partner',
      //   rowKey: 'partnerName',
      //   sortKey: 'partnerName',
      //   width: '15%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: false,
      //   enableDelete: false,
      //   enableSort: false,
      // },
      // {
      //   colKey: 'outstanding',
      //   default: 'Balance',
      //   rowKey: 'outstanding',
      //   sortKey: 'outstanding',
      //   customControls: [this.outstandingWrapperRef],
      //   customValue:(outstanding) => {
      //     return outstanding;
      //   },
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'createdate',
        default: 'Created Date',
        rowKey: 'createdate',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: false,
        enableDelete: false,
        enableSort: false,
      },
      {
        colKey: 'createdby',
        default: 'Created by',
        rowKey: 'createdby',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: false,
        enableDelete: false,
        enableSort: false,
      },
      {
        colKey: 'lastmodifieddate',
        default: 'Lastmodified date',
        rowKey: 'lastmodifieddate',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: false,
        enableDelete: false,
        enableSort: false,
      },
      {
        colKey: 'lastmodifiedby',
        default: 'Lastmodified by',
        rowKey: 'lastmodifiedby',
        width: '20%',
        isNumber: false,
        isFilterAllow: false,
        enableEdit: false,
        enableDelete: false,
        enableSort: false,
      },
     
      //    {
      //   colKey: 'planDisplayName',
      //   default: 'Active Plan Name',
      //   rowKey: 'planDisplayName',
      //   width: '15%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: false,
      //   enableDelete: false,
      //   enableSort: false,
      // },
      // {
      //   colKey: 'expirydate',
      //   default: 'Plan Expiry Date',
      //   rowKey: 'expirydate',
      //   width: '15%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: false,
      //   enableDelete: false,
      //   enableSort: false,
      // },
      // {
      //   colKey: 'maxexpirydate',
      //   default: 'Max Plan Expiry Date',
      //   rowKey: 'maxexpirydate',
      //   width: '20%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: false,
      //   enableDelete: false,
      //   enableSort: false,
      // },
      // {
      //   colKey: 'didno',
      //   default: 'DID No',
      //   rowKey: 'didno',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: false,
      //   enableDelete: false,
      //   enableSort: false,
      // },
      // {
      //   colKey: 'ipAddress',
      //   default: 'Ip Address',
      //   rowKey: 'ipAddress',
      //   width: '15%',
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: false,
      //   enableDelete: false,
      //   enableSort: false,
      // },
      // {
      //   colKey: 'macaddress',
      //   default: 'MAC Address',
      //   rowKey: 'macaddress',
      //   width: '0.5%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: false,
      //   enableDelete: false,
      //   enableSort: false,
      // },
      {
        colKey: 'status',
        default: 'Status',
        rowKey: 'status',
        sortKey: 'status',
        width: '20%',
        customControls: [this.statusWrapperRef],
        customValue: (status) => {
          if (status != 'Suspend') {
            if (status === 'Active') {
              return 'Active';
            } else if (status === 'New Activation') {
              return 'New Activation';
            } else {
              return 'In Active';
            }
          } else {
            return 'Suspend';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'networkDevice',
      //   default: 'Network Device',
      //   rowKey: 'networkDevice',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'serviceArea',
      //   default: 'Service Area',
      //   rowKey: 'serviceArea',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      //  {
      //   colKey: 'slotName',
      //   default: 'slot Name',
      //   rowKey: 'slotName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'portName',
      //   default: 'portName',
      //   rowKey: 'portName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'snmpslotname',
      //   default: 'SNMP slot Name',
      //   rowKey: 'snmpslotname',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'serviceArea',
        default: 'Zone',
        rowKey: 'serviceArea',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '8%',
        isStickyEnd: true,
        isMultipleOption: true
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;

    });

    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Username',
        value_field: 'username',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Email',
        value_field: 'email',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Mobile',
        value_field: 'mobile',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Address',
        value_field: 'address',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Account No',
        value_field: 'acctno',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Partner',
        value_field: 'partner',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
      {
        display_field: 'Plan',
        value_field: 'plan',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'ExpiryDate',
        value_field: 'expirydate',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Mac Address',
        value_field: 'macaddress',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'DID No',
        value_field: 'didno',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'IP Address',
        value_field: 'ipaddress',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field:'Service Area',
        value_field:'serviceArea',
        control_setting:[
          {
            control_type:SearchControlTypeEnum.TEXT,
            display_style:'',
          },
        ],
        data_type:SearchDataTypeEnum.STRING,
      },
      {
        display_field:'OLT',
        value_field:'serviceNetwork',
        control_setting:[
          {
            control_type:SearchControlTypeEnum.TEXT,
            display_style:'',
          },
        ],
        data_type:SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'ONU-Id',
        value_field: 'onuid',
        control_setting: [
          { 
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type:SearchDataTypeEnum.STRING,
      },
      {
        display_field:'Sales Representative',
        value_field:'salesRepresentative',
        control_setting:[
          {
            control_type:SearchControlTypeEnum.TEXT,
            display_style:'',
          },
        ],
        data_type:SearchDataTypeEnum.STRING,
      }
    ];


    this.subscriberNeoGridSettings = {
      showAdvanceSearch: true,
      showFooter: false,
      showPagination: true,
      showFilters: false,
      tableId: this.tableKeyEnum.SUBSCRIBER_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageTotalLength: 0,
      serverSideSortPagination: true,
      serverSideFilter: true,
      pageNumber:this.paginationData.pageNumber - 1 ,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Tenant ?',
      deleteMsgBoxDetail: 'Subscriber Name : ${name} ',
    };
    if (this.subscriberNeoGridSettings.serverSideSortPagination && this.subscriberNeoGridSettings.perPage) {
      this.setPaginationPerPage(this.subscriberNeoGridSettings.perPage);
    }
  }

  getSubscriberData(gridSetting: any) {  //List of Neo Table Data
    let fetchSettingData = this.subscriberNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    if(this.filterObj){
      params["filters"] = this.filterObj.filters

    }
     this.subscriberService.getSubscriberList(params).subscribe((result) => {
       this.subscriberDataForGrid = result.content;
      this.refreshDataSource(this.subscriberDataForGrid);
      this.totalRecords = result.pageDetails ? result.pageDetails.totalRecords : 0;
      this.subscriberNeoGridSettings.pageTotalLength = this.totalRecords;
    });
  }

  applyFilter(filterObj) {
    // Server Side Filter API Call
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.subscriberDataForGrid));
    if (filterObj && filteredDataForGrid) {
      this.filterObj = filterObj
       console.log("FilterObject",this.filterObj)
       
       filterObj.pageSize =  this.paginationData.rowsPerPage;
       filterObj.page =  this.paginationData.pageNumber;
      this.subscriberService.getAdvanceFilter(filterObj).subscribe((res) => {
        console.warn("res of Adv",res.content)
        this.refreshDataSource(res.content);
        this.totalRecords = res.pageDetails ? res.pageDetails.totalRecords : 0;
        this.subscriberNeoGridSettings.pageTotalLength = this.totalRecords;
      });
    }
    else {
      /** Clear Button Click*/
      //this.refreshDataSource(this.subscriberDataForGrid);
       this.filterObj =null
       this.getListWithParams();
    }
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  setPagination(event) {
    this.paginationData = event;
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getSubscriberData(config);
      }
  
  // onEdit(event) {
  //   this.setUpdateValueInForm(event.RowData);
  // }

  onEdit(event) {
    this.setUpdateValueInForm(event);
  }

  // onDelete(event) {
  //   let self = this;
  //   if (event.deletClicked) {
  //     self.delete(event.RowData);
  //   }
  // }

  onDelete(event) {
    let self = this;
    if (event) {
      this.commonDeleteMsgBox(this.subscriberNeoGridSettings,event,this.delete.bind(this));
    }
  }

  delete(objEvent) {
    let self = this;
    let selected_subscriber_data = objEvent.event;
    if (objEvent.deleteClicked) {
      this.subscriberService.deleteSubscriber(selected_subscriber_data.subscriber_id).subscribe((result) => {
        if(result.status === ResponseCode.OK){
          this.tosterservice.success('Tenant is deleted successfully');
          this.show_update = false;
          self.subscriberDataForGrid.forEach(function (item, index) {
            if (item.subscriber_id === selected_subscriber_data.subscriber_id) {
              self.subscriberDataForGrid.splice(index, 1);
            }
          });
          self.refreshDataSource(self.subscriberDataForGrid);
        }
      },
      err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  setUpdateValueInForm(rowData) {
    this.router.navigate(['/masters/subscriber/details', rowData.subscriber_id]);
  }

  onAddSubscriberClick() {
    this.router.navigate(['/masters/subscriber/add_subscriber']);

  }

  onAddQuickSubscriberClick() {
    this.router.navigate(['/masters/subscriber/quick_add_subscriber']);
    // let url = '/#/masters/subscriber/quick_add_subscriber';
    // window.open(url);
  }

  getListWithParams() {
    if(Array.isArray(this.searchData)){
      if(this.searchData.length > 0){
        if(this.searchData[0].filterValue && this.searchData[0].filterValue !== ''){
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getSubscriberData(config);
  }

 
  saveUname(udata ,i){
    this.setUpdateValueInForm(udata);
  }

  // onChangeExpiry() {
  //   this.changeExpiry.ngOnInit();
  // }

  // onChangeStatus()
  // {
  //   this.changeStatus.ngOnInit();
  // }

  // onApplyCharge() {
  //   this.applyCharge.ngOnInit();
  // }

  // onPurchaseIp() {
  //   this.purchaseIp.ngOnInit();
  // }

  // onAllocationIp() {
  //   this.allocationIp.ngOnInit();
  // }

  // onNetworkDetails() {
  //   this.networkDetails.ngOnInit();
  // }

  // onChangePass() {
  //   this.changePass.ngOnInit();
  // }
  
  // onResetPass() {
  //   this.resetPass.ngOnInit();
  // }

  // onCpePass() {
  //   this.cpePass.ngOnInit();
  // }

  // onResetCpePass() {
  //   this.resetCpePass.ngOnInit();
  // }

  // onManageMac() {
  //   this.manageMac.ngOnInit();
  // }

  // onReserMac()
  // {
  //   this.resetMac.ngOnInit();
  // }
  // onPurchaseAddon()
  // {
  //   this.purchaseAddon.ngOnInit();
  // }



  // openModal(id,data){
  //   this.ui_id = id;
  //   console.log("",this[this.getKeyName(id)]);
  //   this[this.getKeyName(id)] = true;
  //   this.selectedCustomer = data;
  //   console.log("selectedCustomer",this.selectedCustomer);
  //   setTimeout(() => {
  //     this.customModalService.open(id);
  //     if (id === 'change-plan' ) {
        
  //       this.changePlan.ngOnInit();
  //     }
  //     if (id === 'reverse-paymenchange-plan') {
  //       this.ngOnInit()
  //       this.rollbackPayment.ngOnInit();
  //     }
  //   }, 100);
  // }

  getKeyName(id){
    let keyName = this.popupNameList.find((ls)=>ls.ui_id == id);
    return keyName.key_name;
  }

  // openViewPasswordModel(data) {
  //   this.subscriberDetailsService.getCustomerPasswordDetails(data.subscriber_id).subscribe((res) => {
  //     if (res.responseCode == 200) {
  //       this.passwordDetailsName = data.name;
  //       this.custPassDetails = res.data;
  //       $("#viewPasswordPopup").modal('show');
  //     }
  //   }, err => {
  //     this.tosterservice.error(err.error.status + " " + err.error.ERROR);
  //   }); 
  // }

  closePasswordPopup() {
    $("#viewPasswordPopup").modal('hide');
  }

  

  ngOnDestroy() {
   if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
    this.searchService.serverFilterObj = null;
  }

  closePopUpRefreshData()
  {
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getSubscriberData(config);
  }
  

  closeChangePlanPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    // this.openChangePlanPopup = false;
    
    this.closePopUpRefreshData();
  }

  closeChangeExpiryPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeChabgeStatusPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeApplyChargePopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closePurchaseIpPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeAllocationIpPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeNetworkDetailsPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeChangePasswordPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeResetPasswordPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeCPEPasswrdPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeCPEResetPasswordPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeResetMacPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  closeManageMacPopup(data: any) {
    this[this.getKeyName(this.ui_id)] = false;
    this.closePopUpRefreshData();
  }

  onMultipleOptionClick(event){
    console.log('event =>',event);
    if(event.optionData.key == 'viewPassword'){
      this.subscriberDetailsService.getCustomerPasswordDetails(event.RowData.subscriber_id).subscribe((res) => {
        if (res.responseCode == 200) {
          this.passwordDetailsName = event.RowData.name;
          this.custPassDetails = res.data;
          $("#viewPasswordPopup").modal('show');
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }); 
    }
    else{
      this.ui_id = event.optionData.key
      this[this.getKeyName(event.optionData.key)] = true;
      this.selectedCustomer = event.RowData;
      console.log("selectedCustomer",this.selectedCustomer);
      setTimeout(() => {
        this.customModalService.open(event.optionData.key);
        if (event.optionData.key === 'purchase-plan' ) {
          if(this.changePlan && this.changePlan.isOpenedOnce)
            this.changePlan.ngOnInit();
        }
        if( event.optionData.key === 'purchase-addon-plan' ){
          this.purchaseAddon.ngOnInit();
        }
        if(event.optionData.key === 'change-expiry'){
          this.changeExpiry.ngOnInit();
        }
        if(event.optionData.key === 'change-status'){
          this.changeStatus.ngOnInit();
        }
        if(event.optionData.key === 'apply-charge'){
          this.applyCharge.ngOnInit();
        }
        if(event.optionData.key === 'purchase-ip'){
          this.purchaseIp.ngOnInit();
        }
        if(event.optionData.key === 'allocation-ip'){
          this.allocationIp.ngOnInit();
        }
        if(event.optionData.key === 'change-password'){
          this.changePass.ngOnInit();
        }
        if(event.optionData.key === 'network-details'){
          this.networkDetails.ngOnInit();
        }
        if(event.optionData.key === 'reset-password'){
          this.resetPass.ngOnInit();
        }
        if(event.optionData.key === 'cpe-password'){
          this.cpePass.ngOnInit();
        }
        if(event.optionData.key === 'reset-cpe-password'){
          this.resetCpePass.ngOnInit();
        }
        if(event.optionData.key === 'reset-mac'){
          this.resetMac.ngOnInit();
        }
        if(event.optionData.key === 'manage-mac'){
          this.manageMac.ngOnInit();
        }
      }, 200);
    }
  }

  
getMethodValue(){
  let myobj= {
    filters:[
      {
        filterColumn: "status",
        filterDataType: "boolean",
        filterOperator: "equalto",
        filterValue: this.sharingdataService.filterStatusName
     }
    ]
  }
  this.applyFilter(myobj);
  }
}

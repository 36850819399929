import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { AbstractBaseListComponent } from '../../../components/base/abstract-base-list-component';
import { IBaseService } from '../../../services/ibase-service';
import { Property } from '../model/Property';
import { PaginationData } from '../../../core/modal/pagination-data';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SearchService } from '../../../services/search.service';
import { CircuitServiceService } from './service/circuit-service.service';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { Circuit } from '../model/Circuit';
import { SearchControlTypeEnum } from '../../../core/enum/searchType.enum';
import { SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { MatTableDataSource } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CommonStatusService } from '../../../services/common_status/common-status.service';
import { RecidencialData } from '../model/machineData';
declare let $;
@Component({
  selector: 'app-circuit-details',
  templateUrl: './circuit-details.component.html',
  styleUrls: ['./circuit-details.component.scss']
})
export class CircuitDetailsComponent extends AbstractBaseListComponent<Circuit> implements OnInit {
  @ViewChild("actionBtn", { static: false }) actionBtnReff: TemplateRef<any>;
  @ViewChild("actionBtn1", { static: false }) actionBtnReff1: TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('editCircuitid',{ static: false }) circuitidWrapperRef: TemplateRef<any>;
  circuit: any = {};
  
  @Input() propertyId;
  @Input() editPropertyId;
  isIPUpdate:boolean = false
  circuitneogridsetting: any = {};
  DataSource = new MatTableDataSource([]);
  DataSources = new MatTableDataSource([]);
  searchSettings: any = {};
  deviceTypes: any[] = [];
  connectionTypes:any[]=[];
  authTypes:any[]=[];
  //searchSettings: any = {};
  allColumns: any[] = [];
  allColumns1: any[] = [];
  qosPolicyTypeData:any[]=[];
  circuitTypeData:any[]=[];
  connectvityType: any[] = [];
  dataGrid: any[] = [];
  isModelopen: boolean = false;
  subscriptions: Subscription[] = [];
  propId: any;
  editCircuitId: any;
  submitted:boolean = false
  isUpdateComponent: boolean = false;
  circuitdata: any = null;
  tableKeyenum: any = TableKeyEnum;
  leasedLineCircuitNeoGridSettings :any={};
  circuitDetail: FormGroup;
  flagforcircuit:boolean=true;
  connectionTypeflag:boolean=false;
  flatTypes: any[] = [{value :1,name :'1 BHK'},{value :2,name :'2 BHK'},{value :3,name :'3 BHK'}]
  existValue:boolean;
  ipList: any[] = [];
  isUpdate: boolean;
  tablekeyEnum = TableKeyEnum;
  IpAddressIndex:any
  techObj = {
    ipAddress: '',
  };
  allColumnss: ({ colKey: string; default: string; rowKey: string; width: string; isFilterAllow: boolean; isNumber: boolean; enableEdit?: undefined; enableDelete?: undefined; isStickyEnd?: undefined; } | { colKey: string; default: string; rowKey: string; width: string; isNumber: boolean; isFilterAllow: boolean; enableEdit: boolean; enableDelete: boolean; isStickyEnd?: undefined; } | { colKey: string; default: string; rowKey: string; enableEdit: boolean; enableDelete: boolean; isNumber: boolean; width: string; isStickyEnd: boolean; isFilterAllow?: undefined; })[];
  statusList: any

  constructor(
    private toaster: ToastrService,
    private router: Router,
    private searchService: SearchService,
    private circuitService: CircuitServiceService,
    public CommonStatus: CommonStatusService,


  ) {
    super(toaster, searchService);
  }
  ngOnInit() {
    this.setDefaultPageSize(this.tableKeyenum.CIRCUIT_LIST)
    this.setFormData();
    this.ConnectvityType();
    this.deviceType();
    this.getQosPOlicyTyps();
    this.circuidId();
    this.circuitService.setId(this.propertyId);
    this.init();
    this.getTableColumn();
    this.getTableColumns()
    this.getCircuitType();
    this.connectionType();
    this.authType();
    this.circuitDetail.get('circuitType').setValue('Internet');
    
    const filterSubscription = this.searchService.getFilterOptions().subscribe((obj) => {
      // if (obj && obj.filters) {
      this.applyFilter(obj);
      // }else
    });

    this.subscriptions.push(filterSubscription);

    this.CommonStatus.getCommonList().subscribe((res) => {
      this.statusList = res.dataList;
    });
    
  }
  setFormData() {
    
    this.circuitDetail = new FormGroup({
      circuitId: new FormControl('', Validators.required),
      circuitidentity: new FormControl(''),
      bandwith_policyName: new FormControl(null),
      static_Ippool: new FormControl(''),
      connectvityType: new FormControl(''),
      conectinvalue:new FormControl(''),
      typeDevice: new FormControl(''),
      otherValue: new FormControl(''),
      status: new FormControl(''),

      circuitType:new FormControl(''),
      didNos:new FormControl(''),
      pilotNo:new FormControl(''),
      noOfChannels:new FormControl(''),
      connectionType:new FormControl(''),
      authType: new FormControl(''),
      userName:new FormControl(''),
      password:new FormControl(''),
      ipAddress:new FormControl(''),
      ownerName: new FormControl(''),
      unitNumber: new FormControl(''),
      mobile: new FormControl(''),
      floorNo: new FormControl(''),
      unitId: new FormControl(''),
      tenantName: new FormControl(''),
      tenantMobile: new FormControl(''),
      flatType:new FormControl(''),
    })
  }
  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filterObj.filters) {

      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.dataGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  getQosPOlicyTyps(){
    this.circuitService.getQosPolicyByType().subscribe(response=>{
      this.qosPolicyTypeData=response.dataList;
    })
}

refreshDataSources(data) {
  if (data) {
    this.DataSources = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
  }
}
getCircuitType(){

  this.circuitService.getCircuitType().subscribe(response=>{
    this.circuitTypeData=response.dataList;
    this.circuitDetail.get('circuitType').setValue(this.circuitTypeData[1].text)
    this.circuit.circuitType=this.circuitTypeData[1].text;
    this.flagforcircuit=true;
    
  })
}
 
newCircuitGenerate(data){
  
  if(data.value !==null){
    
    this.circuitService.generateCircuitid(data.value).subscribe(res=>{
     
      if(data.value==='SIP'){
        this.circuit.circuitid = res.data.circuit_id;
        this.circuit.circuitidentity=res.data.circuit_id;
        this.flagforcircuit=true;
        //this.circuitDetail.get('circuitType').setValue('SIP');
        //this.resetByFlagForInternet();/**New set */
        this.resetBySIP();
      }else{
        this.circuit.circuitid = res.data.circuit_id;
        this.circuit.circuitidentity=res.data.circuit_id;
        this.flagforcircuit=false;
        // this.circuitDetail.get('circuitType').setValue('Internet');
        //this.resetBySIP();/**New set */
        this.resetByFlagForInternet();
      }
    })
  }
}


  getService(): IBaseService<Circuit> {
    return this.circuitService;
  }
  setList(list: Array<Circuit>) {
    let rlist = RecidencialData.filter(x=>x.blockId == this.propertyId);
    this.dataGrid = rlist
    this.leasedLineCircuitNeoGridSettings.pageTotalLength = rlist.length;
    this.valuePreperationFunction(this.dataGrid)
    this.refreshDataSource(this.dataGrid);
  }
  valuePreperationFunction(list: Array<Circuit>){
  list.forEach((res)=>{
  res.bandPoliname = res.bandPoliname ? res.bandPoliname : '-'
  res.conectinvalue = res.conectinvalue ? res.conectinvalue : '-'
  res.conecttype = res.conecttype ? res.conecttype : '-'
  res.staticidpool = res.staticidpool ? res.staticidpool : '-'
  res.typeofdevice = res.typeofdevice ? res.typeofdevice : '-'
  //res.pilotNo =res.pilotNo  ? res.pilotNo:'-'
  //res.noOfChannels=res.noOfChannels ? res.noOfChannels:'-'
  })
  }
  refreshDataSource(data) {
    if (data) {
      this.DataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }
  getTableColumn() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'Unit Id',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'unitNumber',
        default: 'Unit Number',
        rowKey: 'unitNumber',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'ownerName',
        default: 'Owner Name',
        rowKey: 'ownerName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'mobile',
        default: 'Mobile',
        rowKey: 'mobile',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'floorNo',
        default: 'Floor No',
        rowKey: 'floorNo',
        width: '5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'flatType',
        default: 'Flat Type',
        rowKey: 'flatType',
        width: '5%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'ownershipDocuments',
        default: 'Ownership Documents',
        rowKey: 'ownershipDocuments',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'tenantName',
        default: 'Tenant Name',
        rowKey: 'tenantName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'tenantMobile',
        default: 'Tenant Mobile',
        rowKey: 'tenantMobile',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'conectinvalue',
      //   default: 'Connectivty Input Value',
      //   rowKey: 'conectinvalue',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      // {
      //   colKey: 'typeofdevice',
      //   default: 'Type of Device',
      //   rowKey: 'typeofdevice',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },

    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
  }
  getTableSetting() {
   let leasedLineCircuitNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showFilter: false,
      showPagination: true,
      tableId: this.tableKeyenum.CIRCUIT_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Circuit ?',
      deleteMsgBoxDetail: 'Circuit ID :${circuitid} ',
    };
    this.leasedLineCircuitNeoGridSettings = leasedLineCircuitNeoGridSettings
    return  leasedLineCircuitNeoGridSettings;
  }

  getTableColumns() {
    let self = this;

    this.allColumns1 = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'ipAddress',
        default: 'IP Address',
        rowKey: 'ipAddress',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: false,
        enableDelete: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      }
    ];
    this.allColumns1.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSettings();
  }
  getTableSettings() {

    let circuitneogridsetting = {
      showFooter: false,
      showPagination: false,
      showFilter: false,

      // perPage: 10,
      // pageSizeOptions: [5, 10, 15, 20, 50, 100],
      // sort: true,
      // serverSideSortPagination: true,
      // pageTotalLength: 0,
      // serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      tableId: this.tablekeyEnum.IP_LIST,
      enableMultiDelete: true,
      columns: this.allColumns1,

    };
    this.circuitneogridsetting = circuitneogridsetting
    return circuitneogridsetting
  }

  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'STATUS',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];
  }
  public onDeleteClick(event) {
    if (event) {
      this.commonDeleteMsgBox(this.getTableSetting(), event, this.afterDeleteConfirm.bind(this));
    }
  }

  onDeleteClicks(data) {
    this.circuit.ipAddress = null
    if(this.isUpdate){
      this.isUpdate =false
    }
    let temp = this.ipList.findIndex(res => res.ipAddress === data.ipAddress)
    this.ipList.splice(temp, 1);
    this.refreshDataSources(this.ipList);
  }

  addip(){
    //this.ipList = [];
    //this.isUpdate = false
    if(this.circuit.ipAddress && this.circuit.ipAddress.toString() !== ''){
      this.techObj.ipAddress = this.circuit.ipAddress.toString();
      let temp = Object.assign({}, this.techObj)
      
      if(this.ipList && this.ipList.length>0)
      {  
      
        let tempflag:any
        let sample:any=[]
        this.ipList.forEach((res)=>{
          sample.push(res.ipAddress)
        })
      tempflag = sample.includes(this.circuit.ipAddress)
       if(tempflag){
      this.toaster.error("IP Already Exists!");
      this.circuit.ipAddress = null
        return
       }
       else{
        this.ipList.push(temp);
       }
      }
      else{
        this.ipList.push(temp);
      }
    
   
      this.refreshDataSources(this.ipList);
      this.circuitDetail.get('ipAddress').reset();
    }
    else{
      this.toaster.error("IP Address is Empty!")
    }
 
  }
  Updateip(){
  this.isUpdate = false;
  let tempflag:any
  let sample:any=[]
  this.ipList.forEach((res)=>{
    sample.push(res.ipAddress)
  })
  tempflag = sample.includes(this.circuit.ipAddress)
  if(tempflag){
    this.toaster.error("IP Already Exists!");
    this.circuit.ipAddress = null
      return
  }
  else{
    this.ipList[this.IpAddressIndex].ipAddress = this.circuit.ipAddress;
  }
  
    this.refreshDataSources(this.ipList)
    this.circuitDetail.get('ipAddress').reset();
  }
  ConnectvityType() {
    this.circuitService.getConnectvityType().subscribe(response => {
      this.connectvityType = response.dataList;
    })
  }
  deviceType() {
    this.circuitService.getDeviceType().subscribe(response => {
      this.deviceTypes = response.dataList;
    })
  }

  connectionType() {
    this.circuitService.getConnectionType().subscribe(response => {
      this.connectionTypes = response.dataList;
      this.circuitDetail.get('connectionType').setValue(this.connectionTypes[0].text)
    })
  }

  authType() {
    this.circuitService.getAuthType().subscribe(response => {
      this.authTypes = response.dataList;
      this.circuitDetail.get('authType').setValue(this.authTypes[0].text)
    })
  }

  circuidId() {
    this.circuitService.generateCircuitid("Internet").subscribe(res => {
      this.circuit.circuitid = res.data.circuit_id;
      this.circuit.circuitidentity=res.data.circuit_id;
      this.flagforcircuit=false; 
    })
  }
  setUpdateValueInForm(rowData) {

    this.isUpdateComponent = true;
    // this.circuit.id=rowData.id;
    // this.circuit.circuitid=rowData.circuitid;
    // this.circuit.bandPoliname=rowData.bandPoliname;
    // this.circuit.qospolicyid=rowData.qospolicyid;
    // this.circuit.staticidpool=rowData.staticidpool;
    // this.circuit.typeofdevice=rowData.typeofdevice;
    // this.circuit.othervalue=rowData.othervalue;
    // this.circuit.propertyLine=rowData.propertyLine;
    // this.circuit.conecttype=rowData.conecttype;
    // this.circuit.conectinvalue = rowData.conectinvalue;
    // this.circuit.circuitType = rowData.circuitType;
    // this.circuit.circuitIpAddressDetails = rowData.circuitIpAddressDetails;
    this.circuit = rowData
    // this.circuit['flatType'] = this.flatTypes.find(x=>x.value == rowData.flatType)
    
    //let temp:any=[];
    if(this.circuit.circuitIpAddressDetails){
      this.ipList = [];
      this.circuit.circuitIpAddressDetails.forEach((res) =>{
        if(res){
          
           
          // this.refreshDataSources(this.ipList);
          //temp.ipAddress = res.ipAddress;
          this.techObj.ipAddress = res.ipAddress;
          let temp = Object.assign({}, this.techObj)
          
          this.ipList.push(temp);
          //console.log(this.ipList);
          this.refreshDataSources(this.ipList);
        }
      })
      
    }
    if(this.circuit.circuitType==='SIP'){
      this.flagforcircuit=true;
   //   this.circuitDetail.get('connectionType').setValue(null);
    }else{
      this.flagforcircuit=false;
    }

    this.circuit.didNos = rowData.didNos;
    this.circuit.pilotNo = rowData.pilotNo;
    this.circuit.noOfChannels = rowData.noOfChannels;
    this.circuit.connectionType = rowData.connectionType;

    if(this.circuit.connectionType==="Credential Based" && this.connectionTypeflag){
      this.circuitDetail.get('connectionType').patchValue(this.circuit.connectionType);
      this.connectionTypeflag=true;/**New */
      this.circuit.userName = rowData.userName;
      this.circuit.password = rowData.password;
    }else{
      this.circuitDetail.get('connectionType').patchValue(this.circuit.connectionType);
      this.circuit.ipAddress = rowData.ipAddress;
      this.connectionTypeflag=false; /**New */
    }
    this.circuitDetail.patchValue(rowData);
    this.editTimeCurcuitModal();
  }
  setObjectData() {
    this.openCurcuitModal();
  }
  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }
  public onEditClick(event) {
    // this.circuitService.getCircuitDetail(event.id).subscribe(res=>
    //   {
        let data = RecidencialData.find(x=>x.unitId == event.unitId)
        this.setUpdateValueInForm(data);    
    //   })
  }

  onEditClicks(data) {
    this.isUpdate = true;
    this.IpAddressIndex = this.ipList.findIndex(res => res.ipAddress === data.ipAddress)
    this.circuit.ipAddress = data.ipAddress;
  }
  
  openCurcuitModal() {
    this.circuit=new Circuit();
    this.circuitDetail.reset();
    this.ipList = [];
    this.circuidId();
    this.isUpdateComponent=false; 
    this.isUpdate  = false
    this.circuitDetail.get('circuitType').setValue('Internet');  /**new Changes */
   // this.circuitDetail.get('connectionType').setValue('P2P');
   
    $("#circuitModal").modal('show');
    
    
  }
  get f(){
    return this.circuitDetail.controls;
  }
  
  editTimeCurcuitModal() {
    $("#circuitModal").modal('show');
   
  }
  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }
  closeModal(){
    $("#circuitModal").modal('hide');
    this.isUpdateComponent=false;
  }
  onSubmit() {
    this.validateForm();
  }
  validateForm() {
    this.submitted =  true
    if(this.circuit.didNos){
      this.circuitDetail.get('pilotNo').setErrors(null);
      this.circuitDetail.get('pilotNo').setValidators([Validators.required]);
      this.circuitDetail.get('pilotNo').updateValueAndValidity();
    }
    else{
      this.circuitDetail.get('pilotNo').setErrors(null);
      this.circuitDetail.get('pilotNo').clearValidators();
      this.circuitDetail.get('pilotNo').updateValueAndValidity();
    }
    if (!this.circuitDetail.valid) {
      Object.keys(this.circuitDetail.controls).forEach(field => {
        let control = this.circuitDetail.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      })
      this.toaster.error('Please fill all details');
    } else {

      if (!this.isUpdateComponent) {

        if(this.circuit && (this.circuit.didNos ||  this.circuit.pilotNo )&& this.circuit.circuitType==='SIP'){
          
          let didList
          if(this.circuit.didNos){
            didList = this.circuit.didNos.split(',');
            if(didList  && didList.length>0 && didList.includes(this.circuit.pilotNo)){
              this.save();
            }
            else{
              this.toaster.error("Pilot No. must be in DID No.");
            }
            
          }else{
            this.toaster.error("Please enter DID No.");
          }
        
              
          }else{
            this.save()
          }
       
      }
      else 
       {
        
        if(this.circuit && (this.circuit.didNos ||  this.circuit.pilotNo )&& this.circuit.circuitType==='SIP'){
          
          let didList
          if(this.circuit.didNos){
            didList = this.circuit.didNos.split(',');
            if(didList  && didList.length>0 && didList.includes(this.circuit.pilotNo)){
              this.update();
            }
            else{
              this.toaster.error("Pilot No. must be in DID No.");
            }
            
          }else{
            this.toaster.error("Please enter DID No.");
          }
        
              
          }else{
            this.update();
          }


      }
    }
  }


  save() {
    if(this.circuit.authType === 'P2P'){
      if(this.ipList.length != 0){
        this.circuit.circuitIpAddressDetails = this.ipList;
      } else {
        this.toaster.error("Please Enter IP Address");
        return;
      }
     
    }
    this.circuit.propertyLine = this.propertyId;
    this.circuit.conectinvalue = this.circuitDetail.get('conectinvalue').value;
    this.circuit.circuitType=this.circuitDetail.get('circuitType').value;
    this.circuitService.saveProperty(this.circuit).subscribe(response => {
      
      if (response.responseCode === 200) {
        this.toaster.success("Data has been successfully saved");
      } else {
        this.toaster.error(response.responseMessage);
      }
      this.closeModal();
      this.getList(this.paginationData, this.orderingData, this.searchData);
      //this.init();
    })

    this.isUpdateComponent=false;
  }

  resetByFlagForInternet(){
    
    this.circuitDetail.get('didNos').clearValidators();
    this.circuitDetail.get('didNos').updateValueAndValidity();
    this.circuitDetail.get('pilotNo').clearValidators();
    this.circuitDetail.get('pilotNo').updateValueAndValidity();
    this.circuitDetail.get('userName').clearValidators();
    this.circuitDetail.get('userName').updateValueAndValidity();
    this.circuitDetail.get('password').clearValidators();
    this.circuitDetail.get('password').updateValueAndValidity();
    this.circuitDetail.get('ipAddress').clearValidators();
    this.circuitDetail.get('ipAddress').updateValueAndValidity();
    this.circuitDetail.get('noOfChannels').clearValidators();
    this.circuitDetail.get('noOfChannels').updateValueAndValidity();

    // this.circuitDetail.get('didNos').setValue(null);
    this.circuitDetail.get('didNos').reset();
    // this.circuitDetail.get('pilotNo').setValue(null);
    this.circuitDetail.get('pilotNo').reset();

    // this.circuitDetail.get('userName').setValue(null);
    this.circuitDetail.get('userName').reset();
    // this.circuitDetail.get('password').setValue(null);
    this.circuitDetail.get('password').reset();
    // this.circuitDetail.get('ipAddress').setValue(null);
    this.circuitDetail.get('ipAddress').reset();
    // this.circuitDetail.get('noOfChannels').setValue(null);
    this.circuitDetail.get('noOfChannels').reset();

    


    this.circuitDetail.updateValueAndValidity();
  }

  resetBySIP(){

    this.circuitDetail.get('bandwith_policyName').setValue(null);
    this.circuitDetail.get('bandwith_policyName').clearValidators();
    this.circuitDetail.get('bandwith_policyName').reset();

    this.circuitDetail.get('static_Ippool').setValue(null);
    this.circuitDetail.get('static_Ippool').clearValidators();
    this.circuitDetail.get('static_Ippool').reset();
    
    this.circuitDetail.get('connectvityType').setValue(null);
    this.circuitDetail.get('connectvityType').clearValidators();
    this.circuitDetail.get('connectvityType').reset();
    

    this.circuitDetail.get('conectinvalue').setValue(null);
    this.circuitDetail.get('conectinvalue').clearValidators();
    this.circuitDetail.get('conectinvalue').reset();
    
    this.circuitDetail.get('typeDevice').setValue(null);
    this.circuitDetail.get('typeDevice').clearValidators();
    this.circuitDetail.get('typeDevice').reset();
    
    this.circuitDetail.get('otherValue').setValue(null);
    this.circuitDetail.get('otherValue').clearValidators();
    this.circuitDetail.get('otherValue').reset();

    this.circuitDetail.updateValueAndValidity();
   }

  update() {
    if(this.circuit.authType === 'P2P'){
      if(this.ipList.length != 0){
        this.circuit.circuitIpAddressDetails = this.ipList;
      } else {
        this.toaster.error("Please Enter IP Address");
        return;
      }
    }
    this.circuit.propertyLine = this.propertyId;
    this.circuit.conectinvalue = this.circuitDetail.get('conectinvalue').value;
    this.circuit.circuitType=this.circuitDetail.get('circuitType').value;
    if(this.circuit.circuitType==="Internet"){
      this.circuit.connectionType=null;
    }
    
    this.circuitService.updateProperty(this.circuit).subscribe(response => {
      if (response.responseCode === 200) {
        this.toaster.success("Data has been successfully update");
      } else {
        this.toaster.error(response.responseMessage);
      }
      this.closeModal();
      this.getList(this.paginationData, this.orderingData, this.searchData);
      // this.init();
    })
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnReff];
      }
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if(col.colKey == 'circuitid'){
        col.customControls = [this.circuitidWrapperRef];
      }
    });
    this.allColumns1.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnReff1];
      }
    });
  }

  resetConnection(){
   this.circuitDetail.get('connectionType').patchValue('Internet');
  }

  authTypeChange(data){
    
  if(data.value==="P2P"){
    this.ipList = [];
    this.refreshDataSources(this.ipList);
   this.circuitDetail.get('userName').clearValidators();
  // this.circuitDetail.get('userName').setErrors(null);
   this.circuitDetail.get('userName').updateValueAndValidity();
   this.circuitDetail.get('password').clearValidators();
  // this.circuitDetail.get('password').setErrors(null);
   this.circuitDetail.get('password').updateValueAndValidity();
  
   //this.circuitDetail.get('ipAddress').setValidators(Validators.required);
   //,Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)'
   //this.circuitDetail.get('ipAddress').updateValueAndValidity();

  }
  else{
  
  this.circuitDetail.get('ipAddress').clearValidators();
 // this.circuitDetail.get('ipAddress').setErrors(null);
  this.circuitDetail.get('ipAddress').updateValueAndValidity();

  this.circuitDetail.get('userName').setValidators(Validators.required);
  this.circuitDetail.get('userName').updateValueAndValidity();

  this.circuitDetail.get('password').setValidators(Validators.required);
  this.circuitDetail.get('password').updateValueAndValidity();
  
  }
  }
} 

import { Component, OnInit, Input, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../../services/message_service/message_service.service';
import { IppoolServices } from '../../../../services/ippool/ippool.service';
import { ServiceAreaServices } from '../../../../services/service_area/service_area.service';
import { NetworkDeviceServices } from '../../../../services/network_device/network_device.service';
import { SubscriberService } from '../../subscriber/subscriber.service';
import { PubSubService } from '../../../../services/pubsub/pubsub.service';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { HostListener } from '@angular/core';
import { AddSubscriberDetailsService } from '../add-subscriber-details.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AllocationIpService } from '../../subscriber-details/allocation-ip/allocation-ip.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-other-details',
  templateUrl: './other-details.component.html',
  styleUrls: ['./other-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OtherDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OtherDetailsComponent),
      multi: true
    }
  ]
})
export class OtherDetailsComponent implements ControlValueAccessor, OnInit {
  @ViewChild('select', { static: false }) select: NgSelectComponent;
  subscriptions: Subscription[] = [];
  subscription: Subscription;
  req_message = "This field is required.";
  oltSlotListAllData: any;
  public documentList: any[] = [];
  serviceType: any;
  ConnectionType: any;
  RouterType: any;
  connectionTypeList: any;
  routerTypeList: any;
  bridgeTypeList: any;
  snmpEnabled;
  submitted: boolean;
  @Input() partnerid: number;
  partnerIdTemp: Number;
  openAllocationIpSelectPopup: boolean = false;
  allocationIpSelectedData: any = "";
  showIpcontrol: boolean = false;
  ApicallChange: string = "other-details";
  boxTypeList = [];
  insertOtherDetailsForm = new FormGroup({
    network_type: new FormControl(null),
    defult_pool: new FormControl(null),
    area: new FormControl(null),
    olt: new FormControl(null),
    olt_slot: new FormControl(null),
    olt_port: new FormControl(null),
    connection_type: new FormControl(null),
    service_area: new FormControl(null),
    onuid: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    salesremark: new FormControl('', [Validators.maxLength(150)]),
    servicetype: new FormControl(null),
    routerType: new FormControl(null),
    url: new FormControl(''),
    gis_code: new FormControl(''),
    approvedBy: new FormControl(null),
    reason: new FormControl(null),
    select_ip: new FormControl(null),
    poolId: new FormControl(null),
    ipPoolDtlsId: new FormControl(null),
    bridgeType: new FormControl(null),
    feasibilityDistance: new FormControl(null),
    box_type: new FormControl(null),
    iptvSerialNo: new FormControl(null),
    iptvEthMacAddress: new FormControl(null),
    iptvMacAddress: new FormControl(null),
    iptvId: new FormControl(null)
  })
  public ippoolList: any[] = [
    {
      poolName: '',
      poolId: '',
    },
  ];
  public serviceAreaList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  public networkDeviceList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public networkTypeList: any[] = [
    {
      text: '',
      value: '',
    },
  ];

  public oltSlotList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public oltPortList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  dynamicrequiredArray: any;

  get value(): OtherDetailsComponent {
    return this.insertOtherDetailsForm.value;
  }

  set value(value: OtherDetailsComponent) {
    console.log("otherdetails", value)
    this.insertOtherDetailsForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }
  feasibilityDistanceObj: any;
  bridgeTypeObj:any;
  urlObj:any;
  gisObj:any;
  latituteObj:any;
  longituteObj:any;
  public serviceTypeList: any;
  constructor(private messageService: MessageService,
    private commonListService: CommonListServicesService,
    private ippoolServices: IppoolServices,
    private serviceAreaServices: ServiceAreaServices,
    private networkDeviceServices: NetworkDeviceServices,
    private subscriberService: SubscriberService,
    private pubsub: PubSubService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,
    private toasterService: ToastrService,
    private ngbmodalservice: NgbModal,
    private allocationIpService: AllocationIpService,
  ) {
    this.getRequiredAllField();
    this.subscriptions.push(
      this.insertOtherDetailsForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      }));


    this.pubsub.$sub('other_details_validation_fail').subscribe((data) => {
      ////console.log(data);
      this.submitted = true;
      this.validateAllFields(this.insertOtherDetailsForm);
    });

  }

  ngOnInit() {
    if (this.partnerIdTemp && this.partnerIdTemp != this.partnerid) {
      this.serviceAreaList = [];
      this.insertOtherDetailsForm.controls.area.setValue('');
    }
    this.clearErrorMsg();
    this.getIpPoolList();
    if (this.partnerid != null) {
      this.getServiceAreaList();
    }
    this.getNetworkTypeList();
    this.getServiceTypeList();
    this.getConnectionTypeList();
    this.getRouterTypeList();
    this.getBridgeTypeList();
    this.getIPTVTypeList();
  }

  getIPTVTypeList(){
    this.commonListService.getGenericCommonList('IptvType').subscribe(res=>{
      this.boxTypeList = res.dataList;
    })
  }

  getServiceTypeList() {
    this.commonListService.getGenericRemarkType('serviceType').subscribe((res) => {
      this.serviceTypeList = res.dataList;
    });
  }

  getConnectionTypeList() {
    this.commonListService.getGenericRemarkType('strconntype').subscribe((res) => {
      this.connectionTypeList = res.dataList;
    });
  }

  getRouterTypeList() {
    this.commonListService.getGenericRemarkType('routerType').subscribe((res) => {
      this.routerTypeList = res.dataList;
    });
  }
  getBridgeTypeList() {
    this.commonListService.getGenericRemarkType('bridgeType').subscribe((res) => {
      this.bridgeTypeList = res.dataList;
    });
  }

  onChangeNetworkType(event) {
    if (event && event.value == 'IPOE') {
      this.showIpcontrol = true;
      this.insertOtherDetailsForm.get('select_ip').setValidators([Validators.required]);
      this.insertOtherDetailsForm.get('select_ip').updateValueAndValidity();
    }
    else {
      this.showIpcontrol = false;
      this.insertOtherDetailsForm.get('select_ip').clearValidators();
      this.insertOtherDetailsForm.get('select_ip').updateValueAndValidity();
    }
  }

  onChangeConnectionType(event) {
    console.log("e-->", event)
    this.insertOtherDetailsForm.get('routerType').reset();
    this.insertOtherDetailsForm.get('bridgeType').reset();
    if (event.value === 'Router' && this.RouterType.isMandatory === true) {
      this.insertOtherDetailsForm.get('routerType').setValidators(Validators.required);
      this.insertOtherDetailsForm.get('routerType').updateValueAndValidity();
    }
    else if (event.value !== 'Router') {
      this.insertOtherDetailsForm.get('routerType').clearValidators();
      this.insertOtherDetailsForm.get('routerType').updateValueAndValidity();
    }
    if ((event.value === 'Free' || event === 'Free')) {
      this.insertOtherDetailsForm.get('approvedBy').setValidators(Validators.required);
      this.insertOtherDetailsForm.get('reason').setValidators(Validators.required);
      this.insertOtherDetailsForm.get('approvedBy').updateValueAndValidity();
      this.insertOtherDetailsForm.get('reason').updateValueAndValidity();
    }
    else if (event.value !== 'Free' || event !== 'Free') {
      this.insertOtherDetailsForm.get('approvedBy').reset();
      this.insertOtherDetailsForm.get('approvedBy').clearValidators();
      this.insertOtherDetailsForm.get('reason').reset();
      this.insertOtherDetailsForm.get('reason').clearValidators();
      this.insertOtherDetailsForm.get('approvedBy').updateValueAndValidity();
      this.insertOtherDetailsForm.get('reason').updateValueAndValidity();
    }

    if(event.value === 'Bridge/Converter' && this.bridgeTypeObj.isMandatory === true) {
      this.insertOtherDetailsForm.get('bridgeType').setValidators(Validators.required);
      this.insertOtherDetailsForm.get('bridgeType').updateValueAndValidity();
    } else{
      this.insertOtherDetailsForm.get('bridgeType').clearValidators();
      this.insertOtherDetailsForm.get('bridgeType').updateValueAndValidity();
    }
  }

  onCLearConnectionType() {
    this.insertOtherDetailsForm.get('routerType').reset();
    this.insertOtherDetailsForm.get('routerType').clearValidators();
    this.insertOtherDetailsForm.get('approvedBy').reset();
    this.insertOtherDetailsForm.get('approvedBy').clearValidators();
    this.insertOtherDetailsForm.get('reason').reset();
    this.insertOtherDetailsForm.get('reason').clearValidators();
    this.insertOtherDetailsForm.get('routerType').updateValueAndValidity();
    this.insertOtherDetailsForm.get('approvedBy').updateValueAndValidity();
    this.insertOtherDetailsForm.get('reason').updateValueAndValidity();
  }

  clearErrorMsg() {
    if (this.submitted === true) {
      this.submitted = false;
      this.insertOtherDetailsForm.reset();
    }
  }
  getIpPoolList() {
    this.ippoolServices.getIppoolList().subscribe((result) => {
      this.ippoolList = result.dataList;
      this.ippoolList.forEach(data => {
        if (data.defaultPoolFlag === true) {
          this.insertOtherDetailsForm.patchValue({ defult_pool: data.poolId });
        }
      });
    });
  }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }


  getRequiredAllField() {
    this.serviceType = null;
    this.ConnectionType = null;
    this.addSubscriberDetailsService.getRequiredAllField().subscribe(result => {

      if (result.responseCode === 200) {
        
        this.dynamicrequiredArray = result.data;
        this.serviceType = this.dynamicrequiredArray.find((e => e.fieldname === 'serviceType'));
        this.ConnectionType = this.dynamicrequiredArray.find((e => e.fieldname === 'connectionType'));
        this.RouterType = this.dynamicrequiredArray.find((e => e.fieldname === 'routerType'));
        this.feasibilityDistanceObj = this.dynamicrequiredArray.find(e => e.fieldname === 'feasibilityDistance');
        this.bridgeTypeObj = this.dynamicrequiredArray.find(e => e.fieldname === 'bridgeType');
        
        this.urlObj = this.dynamicrequiredArray.find(e => e.fieldname === 'url');
        this.gisObj = this.dynamicrequiredArray.find(e => e.fieldname === 'gis_code');
        this.latituteObj = this.dynamicrequiredArray.find(e => e.fieldname === 'latitude');
        this.longituteObj = this.dynamicrequiredArray.find(e => e.fieldname === 'longitude');
        
        if ((this.serviceType !== null && this.serviceType !== undefined) && this.serviceType.isMandatory === true) {
          this.insertOtherDetailsForm.get('servicetype').setValidators(Validators.required)
          this.insertOtherDetailsForm.get('servicetype').updateValueAndValidity();
        }
        else {
          this.insertOtherDetailsForm.get('servicetype').clearValidators()
          this.insertOtherDetailsForm.get('servicetype').updateValueAndValidity();
        }

        if ((this.ConnectionType !== null && this.ConnectionType !== undefined) && this.ConnectionType.isMandatory === true) {
          this.insertOtherDetailsForm.get('connection_type').setValidators(Validators.required)
          this.insertOtherDetailsForm.get('connection_type').updateValueAndValidity();
        }
        else {
          this.insertOtherDetailsForm.get('connection_type').clearValidators()
          this.insertOtherDetailsForm.get('connection_type').updateValueAndValidity();
        }

        if ((this.feasibilityDistanceObj !== null && this.feasibilityDistanceObj !== undefined) && this.feasibilityDistanceObj.isMandatory === true) {
          this.insertOtherDetailsForm.get('feasibilityDistance').setValidators(Validators.required)
          this.insertOtherDetailsForm.get('feasibilityDistance').updateValueAndValidity();
        }
        else {
          this.insertOtherDetailsForm.get('feasibilityDistance').clearValidators()
          this.insertOtherDetailsForm.get('feasibilityDistance').updateValueAndValidity();
        }

        if ((this.bridgeTypeObj !== null && this.bridgeTypeObj !== undefined) && this.bridgeTypeObj.isMandatory === true) {
          this.insertOtherDetailsForm.get('bridgeType').setValidators(Validators.required)
          this.insertOtherDetailsForm.get('bridgeType').updateValueAndValidity();
        }
        else {
          this.insertOtherDetailsForm.get('bridgeType').clearValidators()
          this.insertOtherDetailsForm.get('bridgeType').updateValueAndValidity();
        }


        if ((this.urlObj !== null && this.urlObj !== undefined) && this.urlObj.isMandatory === true) {
          this.insertOtherDetailsForm.get('url').setValidators(Validators.required)
          this.insertOtherDetailsForm.get('url').updateValueAndValidity();
        }
        else {
          this.insertOtherDetailsForm.get('url').clearValidators()
          this.insertOtherDetailsForm.get('url').updateValueAndValidity();
        }


        if ((this.gisObj !== null && this.gisObj !== undefined) && this.gisObj.isMandatory === true) {
          this.insertOtherDetailsForm.get('gis_code').setValidators(Validators.required)
          this.insertOtherDetailsForm.get('gis_code').updateValueAndValidity();
        }
        else {
          this.insertOtherDetailsForm.get('gis_code').clearValidators()
          this.insertOtherDetailsForm.get('gis_code').updateValueAndValidity();
        }

        if ((this.latituteObj !== null && this.latituteObj !== undefined) && this.latituteObj.isMandatory === true) {
          this.insertOtherDetailsForm.get('latitude').setValidators(Validators.required)
          this.insertOtherDetailsForm.get('latitude').updateValueAndValidity();
        }
        else {
          this.insertOtherDetailsForm.get('latitude').clearValidators()
          this.insertOtherDetailsForm.get('latitude').updateValueAndValidity();
        }

        if ((this.longituteObj !== null && this.longituteObj !== undefined) && this.longituteObj.isMandatory === true) {
          this.insertOtherDetailsForm.get('longitude').setValidators(Validators.required)
          this.insertOtherDetailsForm.get('longitude').updateValueAndValidity();
        }
        else {
          this.insertOtherDetailsForm.get('longitude').clearValidators()
          this.insertOtherDetailsForm.get('longitude').updateValueAndValidity();
        }
      
      }
    })
  }
  // getNetworkDeviceList(){
  //   this.networkDeviceServices.getNetworkDeviceList().subscribe((result) => {
  //     this.networkDeviceList = result.dataList;
  //   });
  // }

  onChangeServiceAreaGetNetworkDeviceByServiceId(service_area) {
    if (service_area) {
      this.networkDeviceServices.getNetworkDeviceByServiceId(service_area.id).subscribe((result) => {
        this.networkDeviceList = result.dataList;
        this.snmpEnabled = null;
        this.insertOtherDetailsForm.get('olt').reset();
        this.insertOtherDetailsForm.get("olt_slot").reset();
        this.insertOtherDetailsForm.get("olt_port").reset();
      });
    } else {
      this.networkDeviceList = [];
      this.oltSlotListAllData = null;
      this.oltSlotList = [];
      this.oltPortList = [];
      this.insertOtherDetailsForm.get('olt').reset();
      this.insertOtherDetailsForm.get('olt_slot').reset();
      this.insertOtherDetailsForm.get('olt_port').reset();
    }
  }

  openAllocationIpSelectPopupClick(content) {
    this.openAllocationIpSelectPopup = true;
    if (this.partnerid != null) {
      this.ngbmodalservice.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    } else {
      this.toasterService.error('Please Select Partner');
    }
  }

  closeAllocationIpSelectPopup(data) {
    if (data) {
      this.allocationIpSelectedData = data;
      this.allocationIpService.getippoolnamebyid(this.allocationIpSelectedData.selectedFreeIpData.poolId).subscribe((res) => {
        this.insertOtherDetailsForm.get('poolId').setValue(this.allocationIpSelectedData.selectedIpPool.poolId)
        this.insertOtherDetailsForm.get('ipPoolDtlsId').setValue(this.allocationIpSelectedData.selectedFreeIpData.poolDetailsId)
        this.insertOtherDetailsForm.patchValue({ select_ip: this.allocationIpSelectedData.selectedFreeIpData.ipAddress });
      })
    }
    this.ngbmodalservice.dismissAll();
    this.openAllocationIpSelectPopup = false;
  }


  onChangeOltGetOltSlotByNetworkDeviceId(network_device_data) {
    if (network_device_data) {
      this.networkDeviceServices.getOltSlotByNetworkDeviceId(network_device_data.id).subscribe((result) => {
        this.oltSlotListAllData = result;
        this.oltSlotList = result.dataList;
        this.oltPortList = [];
        this.snmpEnabled = network_device_data.snmpEnabled;
        this.insertOtherDetailsForm.get('olt_slot').reset();
        this.insertOtherDetailsForm.get('olt_port').reset();
      });
    } else {
      this.oltSlotListAllData = null;
      this.oltSlotList = [];
      this.oltPortList = [];
      this.insertOtherDetailsForm.get('olt_slot').reset();
      this.insertOtherDetailsForm.get('olt_port').reset();
    }
  }

  onChangeOltSlot(olt_slot_data) {
    if (olt_slot_data) {
      let olt_port = [];
      this.oltSlotListAllData.dataList.forEach(element => {
        if (element.id === olt_slot_data.id) {
          this.oltPortList = element.oltPortDetailsList;
          this.insertOtherDetailsForm.get('olt_port').reset();
        }
      });
    } else {
      this.oltPortList = [];
      this.insertOtherDetailsForm.get('olt_port').reset();
    }
  }

  getNetworkTypeList() {
    this.networkDeviceServices.getNetworkTypeList().subscribe((result) => {
      this.networkTypeList = result.dataList;
    });
  }

  onClickSaveDocumentDetails() {
    if (this.insertOtherDetailsForm.valid === true) {
      let formData = Object.assign({}, this.insertOtherDetailsForm.value);
      let docData = {
        document_type: formData.document_type,
        document_sub_type: formData.document_sub_type,
        document_status: formData.document_status,
        remarks: formData.remarks
      };
      this.documentList.push(docData);
    }
  }

  deleteSelectedDocument(index) {
    this.documentList.splice(index, 1);
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  validateAllFields(insertOtherDetailsForm) {
    Object.keys(insertOtherDetailsForm.controls).forEach(field => {
      const control = insertOtherDetailsForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.insertOtherDetailsForm.valid ? null : { otherInformation: { valid: true, }, };
  }

  getServiceAreaList() {
    if (this.partnerid) {
      this.partnerIdTemp = this.partnerid
      this.serviceAreaServices.getPlanServiceArea(this.partnerid).subscribe((res) => {
        this.serviceAreaList = res.dataList;
      });

    } else {
      this.toasterService.error("Please Select Partner.");
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setFocusOnFirstElement(elementName: string, timeoutTime: number) {
    setTimeout(() => {
      if (!this.insertOtherDetailsForm.get('network_type').value)
        this.select.open();
    }, timeoutTime)
  }
  changerouterType(obj) {
    console.log(obj)

  }

  
  onChangeBoxType(event){
    if(event){
      if(event.value == 'Android TV'){
        this.insertOtherDetailsForm.get('iptvMacAddress').reset();
        this.insertOtherDetailsForm.get('iptvSerialNo').reset();
        this.insertOtherDetailsForm.get('iptvEthMacAddress').reset();
        this.insertOtherDetailsForm.get('iptvMacAddress').clearValidators();
        this.insertOtherDetailsForm.get('iptvMacAddress').updateValueAndValidity();
        this.insertOtherDetailsForm.get('iptvEthMacAddress').clearValidators();
        this.insertOtherDetailsForm.get('iptvEthMacAddress').updateValueAndValidity();
        this.insertOtherDetailsForm.get('iptvSerialNo').clearValidators();
        this.insertOtherDetailsForm.get('iptvSerialNo').updateValueAndValidity();
        this.insertOtherDetailsForm.get('iptvId').setValidators(Validators.required);
        this.insertOtherDetailsForm.get('iptvId').updateValueAndValidity();
        this.insertOtherDetailsForm.updateValueAndValidity();
      }else{
        this.insertOtherDetailsForm.get('iptvId').reset();
        this.insertOtherDetailsForm.get('iptvMacAddress').setValidators(Validators.required);
        this.insertOtherDetailsForm.get('iptvMacAddress').updateValueAndValidity();
        this.insertOtherDetailsForm.get('iptvEthMacAddress').setValidators(Validators.required);
        this.insertOtherDetailsForm.get('iptvEthMacAddress').updateValueAndValidity();
        this.insertOtherDetailsForm.get('iptvSerialNo').setValidators(Validators.required);
        this.insertOtherDetailsForm.get('iptvSerialNo').updateValueAndValidity();
        this.insertOtherDetailsForm.get('iptvId').clearValidators();
        this.insertOtherDetailsForm.get('iptvId').updateValueAndValidity();
        this.insertOtherDetailsForm.updateValueAndValidity();
      }
    }else{
      this.insertOtherDetailsForm.get('iptvId').clearValidators();
      this.insertOtherDetailsForm.get('iptvMacAddress').clearValidators();
      this.insertOtherDetailsForm.get('iptvEthMacAddress').clearValidators();
      this.insertOtherDetailsForm.get('iptvSerialNo').clearValidators();
      this.insertOtherDetailsForm.reset();
      this.insertOtherDetailsForm.updateValueAndValidity();
    }
  }
}

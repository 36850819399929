import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MachineInventoryModel } from '../model/machine-inventory-model';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MachineInventoryService } from '../service/machine-inventory.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { IBaseService } from '../../../../services/ibase-service';
import { DataSharingServiceService } from '../../../data-sharing-service.service';

@Component({
  selector: 'app-machine-inventory-add-update',
  templateUrl: './machine-inventory-add-update.component.html',
  styleUrls: ['./machine-inventory-add-update.component.scss']
})
export class MachineInventoryAddUpdateComponent extends AbstractBaseAddUpdateComponent<MachineInventoryModel> implements OnInit{

  machineDetail: FormGroup;
  circuit = new MachineInventoryModel();
  updatebutton:boolean=false
  activityType: any[] = [];
  isUpdateComponent: boolean = false;
  entity: any = {};
  urlHome: 'masters/machine-inventory';
  fileid: any;
  filteredList: [];
  category: any[] = [];
  submittednf : boolean = false;
  constructor(
    private toaster: ToastrService,
    private router: Router,
    private ngbModalService:NgbModal,
    private activate: ActivatedRoute,
    private circuitService: MachineInventoryService,
    private genericDataSharingService: DataSharingServiceService,
    private detectChangeRef: ChangeDetectorRef
  ) {
    super(toaster, router)
    this.setValidation()
  }

  ngOnInit() {
    this.init();
    let id = this.activate.snapshot.paramMap.get('id');

    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));

    }
    this.categoryList()
    this.circuit=new MachineInventoryModel();
    
  }
  public filterlist: any[];
  getService(): IBaseService<MachineInventoryModel> {
    return this.circuitService;
  }
  getGenericDataService() {
    return this.genericDataSharingService
  }
  setData(data: MachineInventoryModel) {
    this.circuit = data;
  }
  getData(): MachineInventoryModel {
    return this.circuit;
  }
  getNewModal() {
    return new MachineInventoryModel();
  }
  getFormGroup(): FormGroup {
    return this.machineDetail;
  }
 
  public getModuleHomeUrl(): string {

    return this.urlHome;
  }


  public getActiveRoute(): ActivatedRoute {
    return this.activate;
  }
  setValidation() {
    this.machineDetail = new FormGroup({
      name: new FormControl(''),
      serialNo: new FormControl(''),
      category: new FormControl('',[Validators.required]),
      subcategory: new FormControl(''),
    })
  }
  getId(): number {
    return this.circuit.id;
  }
  get f() {
    return this.machineDetail.controls;
  }
  onCancel(){
    this.router.navigate(['/masters/machine-inventory']);
  }
  formSubmit() {
    let self = this;
   
      if (this.validateForm()) {
        this.onSaveAndContinue(this.circuit)
      }
    
    else {
      this.toaster.error("Please fill all the details!");
    }
  }
  formSubmit2() {
    let self = this;
   let obj={
    id:this.activate.snapshot.paramMap.get('id'),
    serialNo:this.circuit.serialNo,
    category:this.circuit.category,
    subcategory:this.circuit.subCategory,
    name:this.circuit.name
   }
      if (this.validateForm()) {
        
        this.circuitService.documentupdate(obj).subscribe((res)=>{
          if(res.responseCode==200){
            this.toaster.success('Data has been Updated successfully.', 'Success');
            this.router.navigate(['/masters/machine-inventory']);

          }
        })
      }
    
    else {
      this.toaster.error("Please fill all the details!");
    }
  }
  onSaveAndContinue(modal: MachineInventoryModel) {
    this.getService()
      .save(modal)
      .subscribe(
        (response) => {
          this.validateResponse(response, () => {
            this.submitted = false;
            const responseParty = response.data;
            this.isUpdateComponent=true
            this.router.navigate(['/masters/machine-inventory/add_machine_inventory/' + responseParty.id]);

          });
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }

    closeModal(){
      this.ngbModalService.dismissAll();
      this.updatebutton=false
    }

    categoryList() {
      this.circuitService.categoryList().subscribe(response => {
        this.category = response.dataList;
      })
    }

    
  onChangeCat(event){
    this.filteredList = [];
    this.machineDetail.get('subcategory').reset();
    if(event){
      this.circuit.category=event.value
      this.filteredList=event.subTypeList
    }

  }
  onChangesubCat(event){
    if(event)
      this.circuit.subCategory=event.text
  }
   
    
}

import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonListServicesService } from '../../../../../src/app/services/common_list_service/common-list-services.service';
import { AppConfig } from '../../../../../src/app/app.config';
import { AbstractBaseListComponent } from '../../../../../src/app/components/base/abstract-base-list-component';
import { TableKeyEnum } from '../../../../../src/app/core/enum/table-key.enum';
import { PaginationData } from '../../../../../src/app/core/modal/pagination-data';
import { SearchData } from '../../../../../src/app/core/modal/search-data';
import { IBaseService } from '../../../../../src/app/services/ibase-service';
import { SearchService } from '../../../../../src/app/services/search.service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { propDocumentUploadModel } from '../model/propertyDocupload';
import { PropertyServiceService } from '../property-detail/service/property-service.service';
import { PropertyDocService } from './service/property-doc.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-property-document-list',
  templateUrl: './property-document-list.component.html',
  styleUrls: ['./property-document-list.component.scss']
})
export class PropertyDocumentListComponent extends AbstractBaseListComponent<propDocumentUploadModel> implements OnInit {
  @ViewChild("actionBtn", { static: false }) actionBtnReff: TemplateRef<any>;
  @ViewChild("totalCircuit", { static: false }) totalCircuitReff: TemplateRef<any>;
  @ViewChild('editPropertyName', { static: false }) editWrapperRef: TemplateRef<any>;
  @ViewChild('attachmentWrapper', { static: false }) attachmentWrapperRef: TemplateRef<any>;
  @ViewChild('documentStatusWrapper', { static: false }) documentStatusWrapperRef: TemplateRef<any>;
  filters = new Array<filters>()
  filtersObj = new filters();
  allColumns: any[] = [];
  tablekeyEnum = TableKeyEnum;
  searchSettings: any[] = [];
  dataGrid: any[] = [];
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  leasedLinePropertytNeoGridSettings: any = {};
  lieseLineId = localStorage.getItem('leasedId');
  @Input() leasedId: any;
  @Input() onCaseRefresh = new EventEmitter<any>();
  activecount: number = 0;
  inactivecount: number = 0;
  circuitlist: any;
  config;
  leaselineid: any;
  documentUploadTypeList: any[] = [];
  propertyDetailsList: any[] = [];
  circuitDetailsList: any[] = [];
  dataCircuitid: any;
  dataid: any;
  doctype:any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  circuId:any;
  circuitidentity:any;
  id:any;
  circuitValue:any;
  
  constructor(
    private toaster: ToastrService,
    private searchSerice: SearchService,
    private router: Router,
    private propertyDocService: PropertyDocService,
    private commonservice: CommonListServicesService,
    private activtedRouter: ActivatedRoute,
    private propertyService: PropertyServiceService,
    private ngbService:NgbModal,
    public loginService: LoginService,

    appConfig: AppConfig
  ) {
    
    super(toaster, searchSerice)
    this.config = appConfig.getConfig();
    this.leaselineid = this.activtedRouter.snapshot.paramMap.get('id');
  }

  ngOnInit() {
  
    this.setDefaultPageSize(this.tablekeyEnum.PROPERTY_DOCUMENT_LIST)
    this.init();
    this.getTableColumn();
    
    const filterSubscription = this.searchSerice.getFilterOptions().subscribe((obj) => {
       if (obj && obj.filters) {
        this.applyFilter(obj);
      }
    });

    this.subscriptions.push(filterSubscription);
    
    
    this.getListOfProperty(this.leaselineid);
    this.getDocumentList();
  }
  getService(): IBaseService<propDocumentUploadModel> {
   
    return this.propertyDocService;
  }
  setList(list: Array<propDocumentUploadModel>) {

    this.dataGrid = list;
    this.leasedLinePropertytNeoGridSettings.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  onFilterChange(filterObj) {
    
    if (this.searchSerice) {
      let serverFilterObj: SearchData[] = this.searchSerice.getServerFilterObj();
      
      if (filterObj) {
        let searchData = new SearchData();
        searchData = filterObj;
        if (!Array.isArray(searchData) && searchData.filterColumn) {
          if (searchData.filterValue) {
            serverFilterObj = this.searchSerice.setServerFilterObj([searchData]);
          } else {
            serverFilterObj = this.searchSerice.resetGlobalSearchServerFilterObj();
          }
        } else {
          serverFilterObj = this.searchSerice.setServerFilterObj(filterObj);
        }
      } else {
        serverFilterObj = this.searchSerice.resetAdvanceSearchServerFilterObj();
      }
      this.searchData = JSON.parse(JSON.stringify(serverFilterObj));
      this.paginationData.pageNumber = this.neoTableSetting.pageNumber = 1;
      this.getList(this.paginationData, this.orderingData, this.searchData);
      return serverFilterObj;
    } else {
      return null;
    }
  }

  applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filterObj.filters) {
      filteredDataForGrid = this.searchSerice.getFilteredData(filterObj.filters, this.dataGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }
  getTableColumn() {
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'propertyName',
        default: 'Property Name',
        rowKey: 'propertyName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'circuitIdentity',
        default: 'Circuit Name',
        rowKey: 'circuitIdentity',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'filename',
        default: 'File Name',
        rowKey: 'filename',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'docType',
        default: 'Doc Type',
        rowKey: 'docType',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'docStatus',
        default: 'Document Status',
        rowKey: 'docStatus',
        width: '13%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'remark',
        default: 'Remark',
        rowKey: 'remark',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastModifiedDate',
        default: 'Last Modified Date',
        rowKey: 'lastModifiedDate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },

    ];

    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();
  }
  getTableSetting() {
    let leasedLinePropertytNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showFilter: false,
      showPagination: true,
      tableId: this.tablekeyEnum.PROPERTY_DOCUMENT_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber: this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Document ?',
      deleteMsgBoxDetail: 'Document :${filename} ',
    };
    this.leasedLinePropertytNeoGridSettings = leasedLinePropertytNeoGridSettings;
    return leasedLinePropertytNeoGridSettings;
  }
  getTableSearchSetting() {
    this.searchSettings = [
      {
        display_field: 'Name',
        value_field: 'name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'STATUS',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
    ];
  }
  public onDeleteClick(event: propDocumentUploadModel) {
 
    if (event) {
      this.commonDeleteMsgBox(this.getTableSetting(), event, this.afterDeleteConfirm.bind(this));
    }
  }

  ngAfterViewInit() {
    
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnReff];
      }
      if (col.colKey == 'proname') {
        col.customControls = [this.editWrapperRef]

      }
      if (col.colKey === 'filename') {
        col.customControls = [this.attachmentWrapperRef];
      }

     if (col.colKey === 'docStatus') {
        col.customControls = [this.documentStatusWrapperRef];
      }
    });
  }

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
      this.getList(this.paginationData, this.orderingData, this.searchData);
    }
  }

  public onEditClick(event: propDocumentUploadModel) {
    this.ngbService.open(event,{ size: 'lg' });
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }
  
  delete(modal: propDocumentUploadModel) {
    this.propertyDocService
      .delete(modal)
      .subscribe(
        (response) => {
          if (response.responseCode != 200) {
            this.onResponseError(response.responseMessage);
            return;
          }
          this.onDeleteSuccess();
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }

  onDeleteSuccess() {
    this.toaster.success("Document deleted Successfully")
    this.getList(this.paginationData, this.orderingData, this.searchData);
  }

  getListOfProperty(id) {
    
    if (id) {
      this.dataid = Number(id)
    }
    this.propertyService.propertyList(this.dataid).subscribe(result => {
      if (result.responseCode === 200) {
        this.propertyDetailsList = result.dataList;
      }
    })
  }

  getDocumentList() {
    this.commonservice.getGenericCommonList('propertyDocType').subscribe((res) => {
      this.documentUploadTypeList = res.dataList;
    });
  }

  propertyBycircuit(obj,event) {
    if (obj) {
      this.dataCircuitid = Number(obj.id);
      this.setFilterObj(obj,event)  
      //this.circuitValue=obj.proname;//change1 
    }
    this.propertyService.circuitList(this.dataCircuitid).subscribe(result => {
      this.circuitDetailsList=[];
      if (result.responseCode === 200) {
        this.circuitDetailsList = result.dataList;
      }
    })
  }
  
  documentVerification(modal: propDocumentUploadModel) {
    let docData: any = {
      leasedline:Number(this.leaselineid),
      property: modal.propertyId,
      circuit: modal.circuitId,
      id: modal.id,
      docType: modal.docType,
      docStatus: 'verified',
      remark: modal.remark,
      filename: modal.filename,
      uniquename: modal.uniquename
    };

   this.propertyService.updateDocument(docData).subscribe(res => {
      if (res.responseCode !== 200 && res.responseMessage) {
        this.toaster.error(res.responseMessage);
      } else {
        this.toaster.success("Document Verify Successfully");
        this.getList(this.paginationData, this.orderingData, this.searchData);
      }
    })
  }
  
  openDocument(docUpload) {
    this.ngbService.open(docUpload,{ size: 'lg' });
  }

  getlist(){
    this.getList(this.paginationData, this.orderingData, this.searchData);
  }

  circuitChange(val,filtervalue){
    this.circuitValue=val.circuitidentity; //change
    this.circuId  =val.id;
    this.circuitidentity=val.circuitidentity
    this.setFilterObj(val,filtervalue)   
  }
  
  docTypeChange(val,filtervalue){
    this.setFilterObj(val,filtervalue)   
  }

  setFilterObj(val, filterName?) {
    if(val.proname){
      this.filtersObj.filterColumn = 'propertyId';
      this.filtersObj.filterCondition = 'equalto';
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = val.id;
      this.filters.push(this.filtersObj);
    }
    if(val.circuitid){
      this.filtersObj.filterColumn = 'circuitId';
      this.filtersObj.filterCondition = 'equalto';
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = val.id;
      this.filters.push(this.filtersObj);
    }
    if(val.type==='propertyDocType'){
      this.filtersObj.filterColumn = 'docType';
      this.filtersObj.filterCondition = 'equalto';
      this.filtersObj.filterDataType = null;
      this.filtersObj.filterOperator = null;
      this.filtersObj.filterValue = val.value;
      this.filters.push(this.filtersObj);
    }
    this.onFilterChange(this.filters);
  }

  resetFilter(val){
   
   if(val==='docType'){
     let temp = this.searchData.findIndex(res=>res.filterColumn == 'docType')
     this.searchData.splice(temp,1)
     this.getList(this.paginationData, this.orderingData, this.searchData);
    }
    if(val==='property'){
      this.circuId=null;
      this.circuitidentity=null;
      this.circuitDetailsList=[];
      
      let tempClearPropertyId = this.searchData.findIndex(res=>res.filterColumn == 'propertyId')
      this.searchData.splice(tempClearPropertyId,1)
      let tempCleareCircuitId = this.searchData.findIndex(res=>res.filterColumn == 'circuitId')
      this.searchData.splice(tempCleareCircuitId,1)
      this.getList(this.paginationData, this.orderingData, this.searchData);
    }
    if(val==='circuit'){
      let temp = this.searchData.findIndex(res=>res.filterColumn == 'circuitId')
      this.searchData.splice(temp,1)
      this.getList(this.paginationData, this.orderingData, this.searchData);
    }
  }

}

 export class filters {
    filterColumn: string
    filterCondition: string
    filterDataType: string
    filterOperator: string
    filterValue: string
  }

  
  



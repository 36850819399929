import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TableKeyEnum } from '../../../../../src/app/core/enum/table-key.enum';
import { SearchService } from '../../../../../src/app/services/search.service';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { IpPoolConfigService } from '../ip-pool-config/service/ip-pool-config.service';
import { OldAbstractBaseListComponent } from '../../../components/base/old-abstract-base-list-component';
import { IppoolConfigService } from '../../masters/add-franchies/ippool-config/ippool-config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonListServicesService } from '../../../services/common_list_service/common-list-services.service';
import { IppoolConfigDetailsModel } from '../ip-pool-config/model/ip-pool-detail';

@Component({
  selector: 'app-ippool-details',
  templateUrl: './ippool-details.component.html',
  styleUrls: ['./ippool-details.component.scss']
})
export class IppoolDetailsComponent extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild ('allocdateWrapper',{static:false}) allocdateWrapperReff:TemplateRef<any>;
  @ViewChild ('reldateWrapper',{static:false}) reldateWrapperReff:TemplateRef<any>;
  @ViewChild ('hibdateWrapper',{static:false}) hibdateWrapperReff:TemplateRef<any>;
  @ViewChild ('userWrapper',{static:false}) userWrapperReff:TemplateRef<any>;
  @ViewChild ('poolnameWrapper',{static:false}) poolnameWrapperReff:TemplateRef<any>;
  @ViewChild ('ipWrapper',{static:false}) ipWrapperReff:TemplateRef<any>;
  franchiesDataForGrid: any = { content: [] };
  franchiesGridSettings: any = {};
  // config for n-table
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  ipPoolNeoGridSettings: any = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  public ipPoolDetailList: Array<IppoolConfigDetailsModel>;
  IpStatusList:any;
  obj:any;
  @Input() data:any;


  constructor(
    private entityService : IpPoolConfigService,
    private router: Router,
    private searchSerice: SearchService,
    private toaster: ToastrService,
    private ipDetails:IppoolConfigService,
    private modalService: NgbModal,
    private commonListService: CommonListServicesService,
  ) { super(searchSerice)
    
  }

  ngOnInit() {
    this.searchSerice.resetGlobalSearchServerFilterObj()
    this.setDefaultPageSize(this.tableKeyEnum.IPPOOL_IPDETAILS_LIST)
    this.setGridSetting();
    this.initPaginationSort();
    const filterSubscription = this.searchSerice.getFilterOptions().subscribe((obj) => {
        // if (obj && obj.filters) {
        this.applyFilter(obj);
        // }else
    });
     this.obj = {
        filterColumn: 'status',
        filterValue:  null,
        filterOperator: 'equalto',
        filterCondition: 'and',
        filterDataType: 'string'
      }

    this.subscriptions.push(filterSubscription);
    this.commonListService.getGenericCommonList('ipStatus').subscribe((res)=>{
        this.IpStatusList = res.dataList;
    })
  }

  onChangeIPType(event){
    if(event &&  event.value!=null){
        this.paginationData.pageNumber = 1
        this.obj.filterValue = event.value;
        this.onFilterChange(this.obj);  
    }
    else{
        this.onFilterChange(null);
    }
  }

  onEditClick(data){
    if(data && data.custId){
        this.modalService.dismissAll();
        this.router.navigate(['masters/subscriber/details',data.custId]);
    }else{
        this.toaster.error('User Not Found.')
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
        if (col.colKey == 'allocatedDate') {
            col.customControls = [this.allocdateWrapperReff];
        }
        if (col.colKey === 'hibernateDateString') {
            col.customControls = [this.hibdateWrapperReff];
        }
        if (col.colKey == 'releaseDate') {
            col.customControls = [this.reldateWrapperReff];
        }
        if(col.colKey == 'userName'){
            col.customControls = [this.userWrapperReff];
        }
        if(col.colKey == 'poolName'){
            col.customControls = [this.poolnameWrapperReff];
        }
        if(col.colKey == 'ipAddress'){
            col.customControls = [this.ipWrapperReff];
        }
    });
  }

  getIpDetailsData(gridSetting: any) {
      let fetchSettingData = this.ipPoolNeoGridSettings.serverSideSortPagination ? gridSetting : null;
      let params = this.getGridSettingInfo(fetchSettingData);
      let formatedApiData = this.transformSearchData(params);
      this.ipDetails.getIpDetailsDataWithPagination(formatedApiData).subscribe((result) => {
        this.ipPoolDetailList = new Array<IppoolConfigDetailsModel>();  
        this.ipPoolDetailList = result.dataList;
          this.refreshDataSource(this.ipPoolDetailList);
          this.totalRecords = result.totalRecords;
          this.ipPoolNeoGridSettings.pageTotalLength = this.totalRecords;
      });
  }

  transformSearchData(params?) {
    let formatedData: any = {};
    let apiInputData = this.data;
    if (params) {
      formatedData = Object.assign(params);
    }
    if (apiInputData.poolId != null) {
      formatedData.id = apiInputData.poolId;
    }
    return formatedData;
  }
  getListWithParams() {
      
    if (Array.isArray(this.searchData)) {
        if (this.searchData.length > 0) {
            if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
                this.paginationData.pageNumber = 1;
            }
        }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getIpDetailsData(config);
  }

applyFilter(filterObj) {
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.franchiesDataForGrid.content));
    let filters = filterObj && filterObj.filters ? JSON.parse(JSON.stringify(filterObj.filters)) : null;
    if (this.ipPoolNeoGridSettings.serverSideFilter) {
        this.onFilterChange(filters);
    } else {
        if (!filters) {
            filteredDataForGrid = this.searchSerice.getFilteredData(filters, this.ipPoolNeoGridSettings);
            this.refreshDataSource(filteredDataForGrid);
        }
    }
    this.refreshDataSource(filteredDataForGrid);
}

setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getIpDetailsData(config);
}

setGridSetting() {
    let self = this;
    this.allColumns = [
        {
            colKey: 'index',
            default: 'Id',
            rowKey: 'id',
            width: '3%',
            isFilterAllow: false,
            isNumber: true,
        },
        {
            colKey: 'ipAddress',
            default: 'ipAddress',
            rowKey: 'ipAddress',
            width: '20%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
        },
        {
            colKey: 'status',
            default: 'status',
            rowKey: 'status',
            width: '20%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
        },
        {
            colKey: 'userName',
            default: 'userName',
            rowKey: 'userName',
            width: '20%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
        },
        {
            colKey: 'partnerName',
            default: 'partnerName',
            rowKey: 'partnerName',
            width: '20%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
          },
        {
            colKey: 'allocatedDate',
            default: 'allocated Date',
            rowKey: 'allocatedDate',
            width: '20%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
        },
        {
            colKey: 'releaseDate',
            default: 'release Date',
            rowKey: 'releaseDate',
            width: '20%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
        },
        {
            colKey: 'hibernateDateString',
            default: 'hibernate Date',
            rowKey: 'hibernateDateString',
            width: '10%',
            isNumber: false,
            isFilterAllow: true,
            enableEdit: true,
            enableDelete: true,
            enableSort: true,
        },
        {
            colKey: 'displayName',
            default: 'poolName',
            rowKey: 'displayName',
            sortKey: 'slctpartner',
            width: '20%',
           // customControls: [this.checkboxWrapperRef],
            isNumber: false,
            isFilterAllow: false,
            enableEdit: true,
            enableDelete: true,
            enableSort: false,
          }
    ];
    this.allColumns.forEach(element => {
        element.isFilterAllow = false;
    });
    this.searchSettings = [
        {
            display_field: 'Status',
            value_field: 'status',
            control_setting: [
                {
                    control_type: SearchControlTypeEnum.SELECT,
                    display_style: '',
                },
            ],
            data_type: SearchDataTypeEnum.BOOLEAN,
            select_items: [
                {
                    display_field: 'All',
                    value_field: ['Y', 'N'],
                },
                {
                    display_field: 'True',
                    value_field: 'Y',
                },
                {
                    display_field: 'False',
                    value_field: 'N',
                },
            ],
        },
        {
            display_field: 'poolName',
            value_field: 'displayName',
            control_setting: [
                {
                    control_type: SearchControlTypeEnum.TEXT,
                    display_style: '',
                },
            ],
            data_type: SearchDataTypeEnum.STRING,
        },
    ];

    this.ipPoolNeoGridSettings = {
        showAdvanceSearch: false,
        showFooter: false,
        showPagination: true,
        tableId: this.tableKeyEnum.IPPOOL_IPDETAILS_LIST,
        perPage: this.paginationData.rowsPerPage,
        pageNumber:this.paginationData.pageNumber - 1,
        pageSizeOptions: [10],
        sort: true,
        serverSideFilter: true,
        serverSideSortPagination: true,
        pageTotalLength: 0,
        defaultSortCol: '',
        defaultSortDirection: 'asc',
        // enableMultiDelete: true,
        columns: this.allColumns,
        deleteMsgBoxTitle: 'Are you sure to delete this Franchies ?',
        deleteMsgBoxDetail: 'Franchies Name :${name} ',
    };
    if (this.ipPoolNeoGridSettings.serverSideSortPagination && this.ipPoolNeoGridSettings.perPage) {
        this.setPaginationPerPage(this.ipPoolNeoGridSettings.perPage);
    }
}

refreshDataSource(data) {
    if (data) {
        this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
}

closeModal() {
    this.modalService.dismissAll();
  }
}
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../../src/app/core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../app/core/enum/table-key.enum';
import { NotificationService } from '../notification/notification.service';
import { MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../../pages/login/login.service';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { OldAbstractBaseListComponent } from '../../../../app/components/base/old-abstract-base-list-component';
import { AppConfig } from '../../../app.config';
import { SearchService } from '../../../services/search.service';
import { couldStartTrivia } from 'typescript';

@Component({
  selector: 'app-notification-group',
  templateUrl: './notification-group.component.html',
  styleUrls: ['./notification-group.component.scss']
})
export class NotificationGroupComponent extends OldAbstractBaseListComponent implements OnInit {
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('partner', { static: false }) patnerWrapperRef: TemplateRef<any>;
  @ViewChild('Notification', { static: false }) notificationReff: TemplateRef<any>;
  allColumns: any[] = [];
  searchSettings = [];
  allGroupNotificationArray = [];
  NotificationGroupNeoGridSettings: any = {};
  NotificationDataForGrid: any
  tableKeyEnum = TableKeyEnum;
  dataSource = new MatTableDataSource([]);
  loginService: LoginService;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;

  associatePartnerlist: any;
  constructor(
    private router: Router,
    private notificationservice: NotificationService,
    private toastr: ToastrService,
    private ngbModalService: NgbModal,
    loginService: LoginService,
    private searchService: SearchService,
    appConfig: AppConfig,
  ) {
    super(searchService)
    this.loginService = loginService;
  }

  ngOnInit() {
      this.setDefaultPageSize(this.tableKeyEnum.GROUP_NOTIFICATION)
    this.setGridSetting();
    localStorage.removeItem('ngId');
    //localStorage.removeItem('notificationGroupId');
    this.getListWithParams();
    // this.getListingData();
  }

  // getListingData(){
  //   this.notificationservice.notificationGroupAll().subscribe(result=>{
  //     this.allGroupNotificationArray=result.dataList;
  //     //////console.log(JSON.stringify(this.allGroupNotificationArray))
  //     this.refreshDataSource(this.allGroupNotificationArray);
  //    }
  // )
  // }


  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(data);
    }
  }

  onAddNotificationGroupClick() {
    this.router.navigate(['operations/notification-group/notification-group-add']);
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '1%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      // {
      //   colKey: 'numberOfPartnerAssociate',
      //   default: 'Associated Partners',
      //   rowKey: 'numberOfPartnerAssociate',
      //   width: '5%',
      //   isNumber: false,
      //   isFilterAllow: true,
      //   enableEdit: true,
      //   enableDelete: true,
      //   enableSort: true,
      // },

      {
        colKey: 'status',
        default: 'STATUS',
        rowKey: 'status',
        width: '10%',
        customValue: (status) => {
          if (status.equalsIgnoreCase() === 'Active') {
            return 'Active';
          } else {
            return 'Inactive';
          }
        },
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: true,
        enableDelete: true,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.searchSettings = [
      {
        display_field: 'Template Name',
        value_field: 'templatename',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Template Type',
        value_field: 'templatetype',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'Active',
            value_field: 'Y',
          },
          {
            display_field: 'In Active',
            value_field: 'N',
          },
        ],
      },
    ];

    this.NotificationGroupNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.GROUP_NOTIFICATION,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Notification Group?',
      deleteMsgBoxDetail: ' Group Name :${name} ',
    };

  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'status') {
        col.customControls = [this.statusWrapperRef];
      }
      if (col.colKey == 'numberOfPartnerAssociate') {
        col.customControls = [this.patnerWrapperRef];
      }
      if (col.colKey == 'name') {
        col.customControls = [this.notificationReff];
      }
    });
  }

  onEdit(eventObj) {
    let id:any;
    if (eventObj.RowData) {
      id = eventObj.RowData.id;
    } else {
      id = eventObj.id;
    }
    this.router.navigate(['operations/notification-group/notification-group-add',id]);
  }

  onDelete(eventObj) {
    this.notificationservice.NotificationGroupdeleteID(eventObj.RowData).subscribe(result => {
      if (result.responseCode === 200) {
        this.toastr.success("Group Delete Successfully", "Success");
        this.getListWithParams();
      }
      else {
        this.toastr.error(result.responseMessage);
      }

    })
  }

  setPagination(event) {
    let config = { page: event, order: this.orderingData, search: this.searchData };
    this.getNotificationData(config);
  }

  getNotificationData(gridSetting: any) {
    let fetchSettingData = this.NotificationGroupNeoGridSettings.serverSideSortPagination ? gridSetting : null;
    let params = this.getGridSettingInfo(fetchSettingData);
    this.notificationservice.getNotificationGroupDataWithPageing(params).subscribe(result => {
      ////console.log('result =>', result);
      this.NotificationDataForGrid = result.dataList;
      this.refreshDataSource(this.NotificationDataForGrid);
      this.totalRecords = result.totalRecords;
      this.NotificationGroupNeoGridSettings.pageTotalLength = this.totalRecords;
    });
  }


  getListWithParams() {
    if (Array.isArray(this.searchData)) {
      if (this.searchData.length > 0) {
        if (this.searchData[0].filterValue && this.searchData[0].filterValue !== '') {
          this.paginationData.pageNumber = 1;
        }
      }
    }
    let config = { page: this.paginationData, order: this.orderingData, search: this.searchData };
    this.getNotificationData(config);
  }

  onViewPartner(id, view) {
    ////console.log("id::", id)
    this.notificationservice.getAssociatedPartnerByNotificationGroup(id).subscribe(res => {
      this.associatePartnerlist = res.data
      ////console.log("res:", res.data)

      if (this.associatePartnerlist.length === 0) {
        this.toastr.error("No partner Associated");
      } else {
        // this.associatePartnerlist = data;
        this.ngbModalService.open(view, { size: 'md' });
      }
    })

  }

}

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, Optional, Input, Output, EventEmitter, TemplateRef, ElementRef } from '@angular/core';
import { CasesModel } from '../model/cases-model';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../../services/ibase-service';
import { CasesService } from '../service/cases.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { SubscriberService } from '../../../masters/subscriber/subscriber.service';
import { CaseReasonService } from '../../case-reason/service/case-reason.service';
import { StaffService } from '../../../hr/staff/staff.service';
import { CaseReasonModel } from '../../case-reason/model/case-reason-model';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Updatelistmodel } from '../model/updatelistmodel';
import { HostListener } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { SearchAutocompleteComponent } from '../../../../components/search-autocomplete/search-autocomplete.component';
import { LoginService } from '../../../login/login.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSharingServiceService } from '../../../data-sharing-service.service';
import { InventoryService } from '../../../../../../src/app/pages/inventory/inventory/service/inventory.service';

@Component({
  selector: 'app-case-update-popup',
  templateUrl: './case-update-popup.component.html',
  styleUrls: ['./case-update-popup.component.scss']
})
export class CaseUpdatePopupComponent {
  // descendants: boolean;
  // first: boolean;
  // read: any;
  // isViewQuery: boolean;
  custId: any;
  checkResolved: boolean;
  // selector: any;
  // static: boolean;
  // @ViewChild(SearchAutocompleteComponent, { static: false }) autoCompleteRef: SearchAutocompleteComponent;
  @ViewChild('paydet', { static: false }) paydetRef: ElementRef;
  @Input() entity: CasesModel;
  @Input() updateList: Updatelistmodel;

  @Output() saveData: EventEmitter<any> = new EventEmitter();
  public entity_id: any = null;
  form: FormGroup;
  formupdate: FormGroup;
  url: string;
  error_customer: boolean;
  error_assignto: boolean;
  public remark: string;
  public subscriberList: string;
  casesData: any;
  default_value: any
  autoField: any = ['name', 'username', 'phone', 'email']
  isdisabled: boolean = false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  disableflag: boolean = false;
  public customer: any;
  public staffList: string;
  public staffAsId: any
  public caseReason: CaseReasonModel;
  public caseReasonById: CaseReasonModel;
  public caseType: any;
  public bucketType: any;
  public collapse = false;
  public accordinid = '';
  public caseOrigin: any;
  public caseStatus: any;
  public caseFor: any;
  public priority: any;
  public resolution: any;
  public error_remark: boolean;
  public error_resolution: boolean;
  public onlymessage: boolean;
  public loginService: LoginService;
  field: string[];
  followTime: Date;
  check: any;
  datePipeString: string;
  timePipeString: string;
  caseofcustomer: any;
  dataSource: CasesModel;
  state: string = 'default';
  circuit: any;
  dynamicdata: any;
  Data: { field_label: string; field_value: any; }[];
  serviceAreaList: any;
  teamTypeList: any = [];
  TeamList: any = [];
  serviceAreaId: any;
  algorithmType: any;
  allocationList: any;
  algoTypeList: any;
  enableflag: boolean = false;
  serviceIdPass: any;
  teamName: any;
  staffListArray: any = [];
  showFlag: boolean;
  submitted: boolean;
  ngbRef: NgbModalRef;
  paymentList = [{
    part:'QC Param 1',
    epVal:'100-200'
  },{
    part:'QC Param 2',
    epVal:'100-200'
  },{
    part:'QC Param 3',
    epVal:'100-200'
  }]
  activityList2 = [{
    actvType:'Activity-1',
    desc:'Description for activity - 1'
  },{
    actvType:'Activity-2',
    desc:'Description for activity - 2'
  }]
  constructor(
    public CasesService: CasesService,
    private ngbModalService: NgbModal,
    public CommonListService: CommonListServicesService,
    public caseReasonService: CaseReasonService,
    public staffService: StaffService,
    public Subscriber: SubscriberService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private datePipe: DatePipe,
    private genericDataSharingService: DataSharingServiceService,
    loginService: LoginService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CaseUpdatePopupComponent>,
    private inventoryService: InventoryService
  ) {
    this.loginService = loginService;
  }
  Internalcase: boolean
  ngOnInit() {
    let URL = this.router.url.includes('add-casesInternal')
    if (URL) {
      this.Internalcase = true
      this.disableflag = true
    } else {
      this.Internalcase = false
      this.disableflag = false
    }
    this.getcaseReason();
    this.commonList();
    this.getresolution();
    this.setValidation();
    setTimeout(() => {
      this.defaultSelectionAssignment();
    }, 500);
    this.getupdateListData();
    if (this.resolution && this.resolution.length > 0 && this.entity.caseStatus == "Resolved") {
      this.formupdate.get('resolution_update').setValue(this.resolution[0].id)
    }
    // console.log('entity',this.entity)
    // console.log('update list',this.updateList)
  }

  closePopup() {
    this.ngbRef.close();
  }

  getService(): IBaseService<CasesModel> {
    return this.CasesService;
  }

  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }


  public getcaseReason() {
    this.caseReasonService.getAllcaseReason().subscribe((res) => {
      this.caseReason = res.dataList;
    });
  }
  public getresolution() {
    this.CasesService.getresolutionreason().subscribe((res) => {
      this.resolution = res.dataList;
      if (this.resolution && this.resolution.length > 0 && this.entity.caseStatus == "Resolved") {
        this.formupdate.get('resolution_update').setValue(this.resolution[0].id)
      }
    });
  }

  getstaffByID(id) {
    if (id) {
      this.staffService.getStaffById(id).subscribe((res) => {
        this.staffAsId = res.Staff;
        return this.staffAsId.firstname;
      });
    }
  }
  settime(event) {
    this.entity.nextFollowupTime = event.value
  }

  setStatus() {
    this.formupdate.get('casestatus_update').setValue(this.entity.caseStatus);
  }

  setServiceToTeam(el) {
    this.serviceIdPass = el;
    this.formupdate.get('allocationType').reset();
    this.formupdate.get('teamId').reset();
    this.formupdate.get('algorithmType').reset();
    this.teamTypeList = null;
    this.algoTypeList = null;
    this.allocationList = null;
    this.entity.teamType = null;
    this.entity.algorithmType = null;
    this.entity.teamId = null;
    this.entity.currentAssigneeId = null;
    this.getTeamType(el);
  }

  getTeamType(id: any) {
    this.CommonListService.getTeamTypeList(id).subscribe((res) => {
      this.teamTypeList = res.dataList;
    });
    this.getallocationType();
  }

  getallocationType() {
    this.CommonListService.allocationType().subscribe((res) => {
      this.allocationList = res.dataList;
    });
  }

  getalgoType() {
    this.CommonListService.algorithmType().subscribe((res) => {
      this.algoTypeList = null;
      if (this.entity.longitude == null && this.entity.latitude == null) {
        this.algoTypeList = res.dataList;
      } else {
        if (this.dynamicdata) {
          if ((this.dynamicdata['Latitude'] !== '-') || (this.dynamicdata['Longitude'] !== '-')) {
            this.algoTypeList = res.dataList;
          }
          else {
            this.algoTypeList = []
            this.algoTypeList = res.dataList.filter(res => res.text == "Least Allocation")
          }
        }
      }
    });
  }

  setTeamAPiCall(e) {
    if (e) {
      this.teamName = e;
      this.TeamList = null
      this.staffList = null
      this.allocationList = null
      this.algoTypeList = null
      this.formupdate.get('allocationType').reset()
      this.formupdate.get('algorithmType').reset()
      this.formupdate.get('teamId').reset()
      this.formupdate.get('staffId').reset()
      this.getallocationType()
    }

  }

  allocationType(e) {
    this.formupdate.get('teamId').reset();
    this.formupdate.get('staffId').reset();
    this.formupdate.get('algorithmType').reset();
    if (e.value == 'Auto') {
      this.enableflag = true;
      this.getalgoType();
      delete this.entity.teamId
      delete this.entity.currentAssigneeId
      this.formupdate.get('algorithmType').updateValueAndValidity();
      this.formupdate.get('teamId').clearValidators();
      this.formupdate.get('teamId').updateValueAndValidity();
      this.formupdate.get('staffId').clearValidators();
      this.formupdate.get('staffId').updateValueAndValidity();
    }
    else if (e.value == 'Manual') {
      this.enableflag = false;
      delete this.entity.algorithmType
      this.getalgoType();//*refresh
      this.getTeamList();
    }
  }

  // setData(data: CasesModel) {
  //   this.entity = new CasesModel();
  //   this.entity = data;
  //     this.getcaseReason();
  //     if (this.entity.customersId)
  //       this.getSubscriberByID(this.entity.customersId)
  //     if (this.entity && this.entity['dynamicParams']) {
  //       let data = this.entity['dynamicParams'];
  //       this.casesData = Object.keys(data).map(field => {
  //         let value = data[field];
  //         return { field_label: field, field_value: value }
  //       })
  //     }

  //     this.getstaffByID(this.entity.currentAssigneeId);
  //     ////console.log("case Date ::", this.entity.caseStartedOn)
  //     let dateNew = new Date(this.entity.caseStartedOn);
  //     this.entity.calculatedD = this.calculateDiff(dateNew, 'caseDay');
  //     if (this.entity.calculatedD === 0) {
  //       this.entity.calculatedTimeH = this.calculateDiff(dateNew, 'caseHours');
  //     }
  //     if (this.entity.calculatedTimeH === 0) {
  //       this.entity.calculatedTimeM = this.calculateDiff(dateNew, 'caseMinutes');
  //     }
  //     if (this.entity.calculatedTimeM === 0) {
  //       this.entity.calculatedTimeS = this.calculateDiff(dateNew, 'caseSecond');
  //     }
  //     this.getupdateListData();
  // }

  setnewdata() {
    this.entity
  }

  getData(): CasesModel {
    return this.entity;
  }

  getSubscriberByID(id) {
    if (id) {
      this.Subscriber.getSubscriberById(id).subscribe((res) => {
        this.customer = res.data;
      });
    }

  }

  public commonList() {

    this.CommonListService.getCommonListcaseFor().subscribe((res) => {
      if (this.Internalcase) {
        this.caseFor = res.dataList.filter(res => res.value === 'Internal')
      }
      else {
        this.caseFor = res.dataList.filter(res => res.value !== 'Internal')
      }
    });
    this.CommonListService.getCommonListcaseType().subscribe((res) => {
      this.caseType = res.dataList

    });
    this.CommonListService.getCommonListbucketType().subscribe((res) => {
      this.bucketType = res.dataList

    });
    this.CommonListService.getServiceAreabyTeamList().subscribe((res) => {
      this.serviceAreaList = res.dataList
    });

    this.CommonListService.algorithmType().subscribe((res) => {
      this.algorithmType = res.dataList
    });

    this.CommonListService.getCommonListcaseOrigin().subscribe((res) => {
      this.caseOrigin = res.dataList.filter((res) => res.text != 'Auto Generated')

    });
    this.CommonListService.getCommonListcaseStatus().subscribe((res) => {
      if(!localStorage.getItem('userRoles').includes('79')){
        let temp = res.dataList.filter(el=> el.text != 'Resolved')
        this.caseStatus = temp;
      }else{
        this.caseStatus = res.dataList;
      }

    });
    this.CommonListService.getCommonListpriority().subscribe((res) => {
      this.priority = res.dataList

    });
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): CasesModel {
    let abc = new CasesModel();
    delete abc.caseReasonId
    return new CasesModel();
  }


  public getFormGroup(): FormGroup {
    return this.formupdate;
  }

  public getId(): number {
    return this.entity.caseId;

  }


  getCircuit() {
    this.CasesService.getcircuitdetails(this.custId).subscribe((res) => {
      this.circuit = res.dataList;
      res.dataList.forEach((ls) => {
        this.entity.propertyId = ls.propertyLine;
      });
    });
  }

  get f() {
    return this.formupdate.controls;
  }

  setValidation() {
    this.formupdate = new FormGroup({
      case_message_update: new FormControl('', [Validators.required]),
      resolution_update: new FormControl(''),
      casestatus_update: new FormControl(''),
      case_reason_update: new FormControl(''),
      case_priority_update: new FormControl(''),
      case_nextFollowupDate_update: new FormControl(''),
      case_nextFollowupTime_update: new FormControl(''),
      case_suspendtilldate_update: new FormControl(''),
      bucket_update: new FormControl(null),
      title: new FormControl(''),
      serviceAreaId: new FormControl(null),
      teamType: new FormControl(null),
      teamId: new FormControl(null),
      staffId: new FormControl(null),
      allocationType: new FormControl(null),
      algorithmType: new FormControl(null)
    });
  }

  calculateDiff(ms, lable: string) {
    let currentDate = new Date();
    ms = new Date(ms);
    if (currentDate && ms) {
      ms = currentDate.getTime() - ms.getTime();
      let seconds = Math.floor(ms / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      if (lable === 'caseHours') {
        return hours;
      }
      if (lable === 'caseDay') {
        return days;
      }
      if (lable === 'caseMinutes') {
        return minutes;
      }
      if (lable === 'caseSecond') {
        return seconds;
      }
    }
  }
  numberOnly(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  setcase_nextFollowupDateTime() {
    this.entity.nextFollowupDate = this.datePipe.transform(this.datePipeString, 'yyyy-MM-dd');
    this.entity.nextFollowupTime = this.datePipe.transform(this.followTime, 'hh:mm:ss');
  }
 

  setResolution(objValue) {
    if (objValue === "Resolved") {
      this.formupdate.get('resolution_update').setValue(this.resolution[0].id);
      // this.ngbRef = this.ngbModalService.open(this.paydetRef,{size:'lg'});
    }
    if (objValue === "Suspend") {
      this.formupdate.get('case_suspendtilldate_update').setValidators(Validators.required);
      this.formupdate.get('case_suspendtilldate_update').updateValueAndValidity();
    }
    else {
      this.formupdate.get('case_suspendtilldate_update').clearValidators()
      this.formupdate.get('case_suspendtilldate_update').updateValueAndValidity();
    }
  }

  closeModal(){
    this.ngbRef.close();
  }

  updateFormSubmit() {
    this.submitted = true;
    if (this.formupdate.controls.case_message_update.value) {
      this.saveData.emit(this.entity)
    } else {
      this.toastrService.error('Please fill all details');
    }
  }

  getTeamList() {
    let obj = {
      "serviceAreaIdList": [],
      "teamType": this.teamName
    }
    obj.serviceAreaIdList.push(this.serviceIdPass)
    this.CommonListService.teamListByManual(obj).subscribe((res) => {
      this.TeamList = res.dataList;
    });
  }

  getupdateListData() {
    if (this.entity && this.entity.caseUpdateList && this.entity.caseUpdateList.length > 0) {
      this.entity.caseUpdateList.forEach((res) => {
        res.updateDetails.forEach((x) => {
          if (x.entitytype === "Remarks") {
            res.remark = x.newvalue;
          }
          if (x.remarktype === "Public") {
            res.remarkType = x.remarktype;
          }
          if (x.remarktype === "Private") {
            res.remarkType = x.remarktype;
          }
        });
        let newDate = new Date(res.createDateString);
        res.calculatedDay = this.calculateDiff(newDate, 'caseDay');
        if (res.calculatedDay === 0)
          res.calculatedTimeH = this.calculateDiff(newDate, 'caseHours');
        if (res.calculatedTimeH === 0)
          res.calculatedTimeM = this.calculateDiff(newDate, 'caseMinutes');
        if (res.calculatedTimeM === 0) {
          res.calculatedTimeS = this.calculateDiff(newDate, 'caseSecond');
        }
      });
    }
  }
  defaultSelectionAssignment() {
    this.entity.serviceAreaId = -1;
    this.setServiceToTeam(this.entity.serviceAreaId)
    this.entity.teamType = 'Back Office';
    setTimeout(() => {
      this.entity.allocationType = 'Manual';
    }, 100);
    setTimeout(() => {
      this.getTeamList();
    }, 200);
    setTimeout(() => {
      if (this.TeamList.length > 0) {
        this.entity.teamId = this.TeamList[0].id
        this.selectStaff(this.TeamList[0]);
      }
    }, 800);
  }

  // valuepraparation(data: Array<CasesModel>) {
  //   if (data) {
  //     data.forEach((temp) => {
  //       temp.creationDate = String(temp.createdate)
  //       temp.finalResolutionDate = this.datePipe.transform(temp.finalResolutionDate, 'yyyy-MM-dd');
  //       temp.finalResolutionDate = temp.finalResolutionDate ? temp.finalResolutionDate : '-';
  //       if (temp.caseReasonId) {
  //         this.caseReasonService.getcaseReasonByID(temp.caseReasonId).subscribe((res) => {
  //           temp.caseReasonName = res.data.name ? res.data.name : '-';
  //         });
  //       }
  //       if (temp.finalResolvedById) {
  //         this.staffService.getStaffById(temp.finalResolvedById).subscribe((res) => {
  //           temp.assigne = res.Staff.firstname ? res.Staff.firstname : '-';
  //         });
  //       }
  //     });
  //   }
  // }

  cancelmodal() {
    this.CasesService.getCaseById(this.entity.caseId).subscribe((res) => {
      this.entity.priority = res.data.priority;
      this.entity.bucketType = res.data.bucketType;
      this.entity.caseReasonId = res.data.caseReasonId;
      this.entity.caseStatus = res.data.caseStatus;
      this.entity.nextFollowupDate = res.data.nextFollowupDate;
      this.entity.nextFollowupTime = res.data.nextFollowupTime;
      this.entity.suspendDate = res.data.suspendDate;
      this.entity.caseTitle = res.data.caseTitle;
      this.saveData.emit(this.entity);
    });
    this.ngbModalService.dismissAll();
  }


  selectStaff(val: any) {
    this.staffListArray = null;
    this.formupdate.get('staffId').reset();
    this.formupdate.get('staffId').updateValueAndValidity();
    this.staffListArray = val.teamUserMapping.filter(v => v.isAssignable === true)
  }

  onEnterRemark() {
    if (this.updateList.remark) {
      this.error_remark = false;
      return;
    }
  }

  // getLeaseOrNormalCustomerBasicDetails() {
  //   this.CasesService.getLeaseOrNormalCustomerBasicDetails(this.custId, this.entity.caseFor).subscribe((res) => {
  //     this.dynamicdata = res.data.dynamicParams;
  //     let data = this.dynamicdata;
  //     console.log("dynamic data ",data);
  //     this.Data = Object.keys(data).map(field => {
  //       let value = data[field];
  //       return { field_label: field, field_value: value }
  //     })
  //   })
  // }

  closeDialog() {
    this.dialogRef.close();
  }

  openResovled(resolvedpopup, event) {
    if (this.checkResolved == true)
      this.ngbRef = this.ngbModalService.open(resolvedpopup, { size: 'xl', backdrop: 'static' });
  }

  // getGenericDataService() {
  //   return this.genericDataSharingService
  // }

  // public getModuleHomeUrl() {
  // }

  // onChangeReason(reason){
  //   if(reason && reason.bucket){
  //     this.formupdate.get('bucket_update').setValue(reason.bucket);
  //   }else{
  //     this.formupdate.get('bucket_update').reset();
  //   }
  // }

}

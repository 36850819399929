import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { TableKeyEnum } from "../../../../core/enum/table-key.enum";
import { TokenStorageService } from "../../../../auth/token-storage-service";
import { SubscriberDetailsService } from "../subscriber-details.service";

@Component({
  selector: "app-invoice-list",
  templateUrl: "./invoice-list.component.html",
  styleUrls: ["./invoice-list.component.scss"],
})
export class InvoiceListComponent implements OnInit {
  @ViewChild("downloadRecipt", { static: false })
  downloadReciptWrapperRef: TemplateRef<any>;
  @ViewChild("amount", { static: false }) amountWrapperRef: TemplateRef<any>;
  @ViewChild("status", { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild("payOption", { static: false })
  payOptionWrapperRef: TemplateRef<any>;
  @ViewChild("dateWrap", { static: true }) dateRef: TemplateRef<any>;

  @Input() dataList;

  custId: any
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  paymentsNeoGridSettings = {};
  subscriptions: Subscription[] = [];
  paymentsTableData: any;
  baseURL;
  openPayments: boolean;
  openCharges: boolean;
  PayaAmount: boolean;

  paymentGatewayList = [
    {
      createdate: "22-01-2022 08:26 PM",
      updatedate: "12-04-2022 02:39 PM",
      createdByName: "admin admin",
      lastModifiedByName: "Neoadmin admin",
      createdById: 1,
      lastModifiedById: 207,
      id: 5,
      partnerid: 1,
      paymentGateWayId: 2,
      paymentGateWayName: "CCAvenue",
      defaultpgflag: "No",
      isDeleted: false,
      pgPrefix: "ccavenue",
      isPartnerEnable: true,
      isCustEnable: true,
      isSplitEnabled: false,
      merchantKey: null,
      chargeType: "Fixed",
      percent: 0,
      partnerPGCharges: [],
    },
    {
      createdate: "12-04-2022 02:39 PM",
      updatedate: "12-04-2022 02:39 PM",
      createdByName: "Neoadmin admin",
      lastModifiedByName: "Neoadmin admin",
      createdById: 207,
      lastModifiedById: 207,
      id: 18,
      partnerid: 1,
      paymentGateWayId: 1,
      paymentGateWayName: "Payu-Money",
      defaultpgflag: "Yes",
      isDeleted: false,
      pgPrefix: "payu",
      isPartnerEnable: true,
      isCustEnable: true,
      isSplitEnabled: false,
      merchantKey: null,
      chargeType: "Fixed",
      percent: 0,
      partnerPGCharges: [],
    },
  ];

  // dataList = [
  //   {
  //     invNo: 1,
  //     contractNo: "0000180910000",
  //     invDate: "01-04-2023",
  //     amount: "500000",
  //     status: "Unpaid",
  //   },
  //   {
  //     invNo: 2,
  //     contractNo: "0000180910001",
  //     invDate: "01-05-2023",
  //     amount: "380000",
  //     status: "Paid",
  //   },
  // ];

  constructor(
    private tokenStorageService: TokenStorageService,
    private modalService: NgbModal,
    private dashboardservice: SubscriberDetailsService,
    private tosterService: ToastrService
  ) {}

  ngOnInit() {
    this.setGridSetting();
    this.getPaymentsData();
    this.onPaymentsClick();
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === "receiptNo") {
        col.customControls = [this.downloadReciptWrapperRef];
      }
      if (col.colKey === "TOTALNETAMOUNT") {
        col.customControls = [this.amountWrapperRef];
      }
      if (col.colKey === "STATUS") {
        col.customControls = [this.statusWrapperRef];
      }
      // if (col.colKey === "payOption") {
      //   col.customControls = [this.payOptionWrapperRef];
      // }
      if (col.colKey === "DERF") {
        col.customControls = [this.dateRef];
      }
    });
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: "INVNO",
        default: "Invoice No",
        rowKey: "INVNO",
        width: "10%",
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
      },
      {
        colKey: "RECNNR",
        default: "Contract No",
        rowKey: "RECNNR",
        width: "10%",
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: "DERF",
        default: "Invoice Date",
        rowKey: "DERF",
        width: "10%",
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
      },
      {
        colKey: "TOTALNETAMOUNT",
        default: "TOTAL NET AMOUNT",
        rowKey: "TOTALNETAMOUNT",
        width: "10%",
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
      },
      {
        colKey: "STATUS",
        default: "Status",
        rowKey: "STATUS",
        width: "10%",
        isNumber: false,
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
      },
      // {
      //   colKey: "payOption",
      //   default: "Payment Option",
      //   rowKey: "payOption",
      //   width: "10%",
      //   isNumber: false,
      //   isFilterAllow: false,
      //   enableEdit: true,
      //   enableDelete: true,
      // },
    ];

    this.paymentsNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.PAYMENTSLIST_LIST,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      // tableHeight:300,
      // headerSticky:true,
      //verticalScroll:true,
      sort: true,
      defaultSortCol: "",
      defaultSortDirection: "asc",
      columns: this.allColumns,
    };
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(
        JSON.parse(JSON.stringify(data))
      );
    }
  }

  getPaymentsData() {
    // let dataList: any = [];
    // if (this.custId) {
    //   this.dashboardservice
    //     .getBusinessPartnerContractData(this.custId)
    //     .subscribe(
    //       (res) => {
    //         if (res.responseCode == 200) {
    //          dataList = res.data.ET_INVOICE.item;
              this.refreshDataSource(this.dataList);
    //         } else {
    //           this.tosterService.error(res.responseMessage);
    //         }
    //       },
    //       (error) => {
    //         if (error.responseCode !== 200) {
    //           this.tosterService.error(error.error.responseMessage);
    //         }
    //       }
    //     );
    // }
  }

  onPaymentsClick() {
    this.openPayments = true;
  }

  onChargesClick() {
    this.openCharges = true;
  }

  onClickPay(content, data) {
    this.PayaAmount = data.TOTALNETAMOUNT;
    this.modalService.open(content);
  }
}

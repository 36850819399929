export class CaseReasonModel {
    reasonId: number;
    name:string;
    status:string;
    tatConsideration:string;
    slaId: any;
    bucketType: string
    isCustomerAllowed:boolean = false;
    jobPlan: Array <jobPlanDetails>
    isCommunicationAllowed:boolean = false;
    isDelete:boolean=false
    constructor(){
        this.name="";
        this.status=null;
        this.tatConsideration=null;
        this.slaId= null
    }
}

export class jobPlanDetails {
    id:number;
    activityName : string
    steps:string;
    link: string;
}
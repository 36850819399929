import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { TroubleTicketsDetailsService } from './trouble-tickets-details.service';
import { ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router , ActivatedRoute } from '@angular/router';
import { SubscriberDetailsComponent } from '../../subscriber-details.component';
import { AclClassConstants } from '../../../../../pages/aclClassConstants';
import { AclConstants } from '../../../../../pages/aclOperationConstants';
import { LoginService } from '../../../../../pages/login/login.service';
import { Updatelistmodel } from '../../../../../pages/customer-care/cases/model/updatelistmodel';
import { CommonListServicesService } from '../../../../../services/common_list_service/common-list-services.service';
import { CasesModel } from '../../../../../pages/customer-care/cases/model/cases-model';
import { CasesService } from '../../../../../pages/customer-care/cases/service/cases.service';
import { DatePipe } from '@angular/common';
declare let $;
@Component({
  selector: 'app-trouble-tickets-details',
  templateUrl: './trouble-tickets-details.component.html',
  styleUrls: ['./trouble-tickets-details.component.scss']
})
export class TroubleTicketsDetailsComponent implements OnInit {
  @ViewChild('cancelWrapper', { static: false }) cancelWrapperRef: TemplateRef<any>;
  @ViewChild('editCases', { static: false }) editCasesReff: TemplateRef<any>;
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('notifi', { static: false }) notifyRef: TemplateRef<any>;
  // @ViewChild(SubscriberDetailsComponent, { static: false }) subscriberdetailReff: SubscriberDetailsComponent;
  // @ContentChild(SubscriberDetailsComponent, {static: false}) subscriberdetailReff: SubscriberDetailsComponent;
  listTemp: any = [];
  QuickCaseFlag:string;
  @Input() custId: any;
  @Input() name:any;
  @Output() onCaseRefresh = new EventEmitter<any>();
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  ticketDetailsNeoGridSettings = {};
  closeCaseData: any;
  subscriptions: Subscription[] = [];
  remark: any = null;
  editviewId:any;
  id:any;
  //ticketDetailsTableData: any;
  customerName: any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  updateList:Updatelistmodel;
  entity: CasesModel

  ticketDetailsTableData : any =
  [
    {
        "createdate": "09-09-2022 03:50 PM",
        "updatedate": "27-10-2022 05:50 PM",
        "createdByName": "Neoadmin admin",
        "lastModifiedByName": "Mharof Shaikh",
        "createdById": 207,
        "lastModifiedById": 123,
        "caseId": 85147,
        "caseReasonId": 1,
        "caseTitle": "Test",
        "unitNo":'APT001',
        "caseType": "Issue",
        "caseNumber": "Req-46299",
        "caseFor": "Customer",
        "caseOrigin": "Phone",
        "caseStatus": "Resolved",
        "priority": "Medium",
        "algorithmType": null,
        "allocationType": null,
        "serviceareaid": 3,
        "teamType": null,
        "customersId": 30123,
        "propertyId": null,
        "circuitId": null,
        "altMobile": null,
        "leaseLineId": null,
        "dynamicParams": {
            "User Name": "billdesk",
            "Mobile": "9909458851",
            "Port": "-",
            "Slot": "-",
            "OLT": "-",
            "Onu Id": "",
            "Service Area": "calangute constituency"
        },
        "latitude": null,
        "longitude": null,
        "distance": null,
        "distanceWithUnit": null,
        "caseForPartner": null,
        "caseForZone": null,
        "bucketType": "O&M",
        "nextFollowupDate": null,
        "nextFollowupTime": null,
        "suspendDate": null,
        "caseStartedOn": "2022-09-09T15:50:29",
        "caseStartedOnString": "09-09-2022 03:50 PM",
        "firstAssignedOn": "2022-09-09T15:50:29",
        "firstAssignedOnString": "09-09-2022 03:50 PM",
        "isDelete": false,
        "currentAssigneeId": 165,
        "finalResolutionId": null,
        "finalResolvedById": 123,
        "finalClosedById": null,
        "finalResolutionDate": "2022-10-27T17:50:23",
        "finalClosedDate": null,
        "caseUpdateList": [
            {
                "createdate": "27-10-2022 05:50 PM",
                "updatedate": "27-10-2022 05:50 PM",
                "createdByName": "Mharof Shaikh",
                "lastModifiedByName": "Mharof Shaikh",
                "createdById": 123,
                "lastModifiedById": 123,
                "id": 186067,
                "ticketId": 85147,
                "status": null,
                "caseType": null,
                "assignee": null,
                "priority": null,
                "attachment": null,
                "filename": null,
                "title": null,
                "resolutionId": null,
                "remarkType": null,
                "remark": null,
                "reasonId": null,
                "serviceAreaId": null,
                "teamType": null,
                "allocationType": null,
                "algorithmType": null,
                "commentBy": "Customer",
                "isDeleted": false,
                "nextFollowupDate": null,
                "nextFollowupTime": null,
                "bucketType": null,
                "createby": "Mharof Shaikh",
                "updateby": "Mharof Shaikh",
                "createDateString": "2022-10-27T17:50:23",
                "updateDateString": "27-10-2022 05:50 PM",
                "teamId": null,
                "etr": null,
                "etrUnit": null,
                "notified_enable": false,
                "suspendDate": null,
                "updateDetails": [
                    {
                        "id": 333634,
                        "operation": "Change Status",
                        "entitytype": "Status",
                        "oldvalue": "In Progress",
                        "newvalue": "Resolved",
                        "attachment": null,
                        "filename": null,
                        "resolutionId": null,
                        "remarktype": null,
                        "suspendDate": "-",
                        "isDeleted": false
                    }
                ]
            },
            {
                "createdate": "25-10-2022 10:38 AM",
                "updatedate": "25-10-2022 10:38 AM",
                "createdByName": "Mharof Shaikh",
                "lastModifiedByName": "Mharof Shaikh",
                "createdById": 123,
                "lastModifiedById": 123,
                "id": 184111,
                "ticketId": 85147,
                "status": null,
                "caseType": null,
                "assignee": null,
                "priority": null,
                "attachment": null,
                "filename": null,
                "title": null,
                "resolutionId": null,
                "remarkType": "Private",
                "remark": null,
                "reasonId": null,
                "serviceAreaId": null,
                "teamType": null,
                "allocationType": null,
                "algorithmType": null,
                "commentBy": "Staff",
                "isDeleted": false,
                "nextFollowupDate": null,
                "nextFollowupTime": null,
                "bucketType": null,
                "createby": "Mharof Shaikh",
                "updateby": "Mharof Shaikh",
                "createDateString": "2022-10-25T10:38:46",
                "updateDateString": "25-10-2022 10:38 AM",
                "teamId": null,
                "etr": null,
                "etrUnit": null,
                "notified_enable": false,
                "suspendDate": null,
                "updateDetails": [
                    {
                        "id": 329910,
                        "operation": "Add Remarks",
                        "entitytype": "Remarks",
                        "oldvalue": null,
                        "newvalue": "need to check ",
                        "attachment": null,
                        "filename": "abc",
                        "resolutionId": null,
                        "remarktype": "Private",
                        "suspendDate": null,
                        "isDeleted": false
                    },
                    {
                        "id": 329909,
                        "operation": "Change Bucket",
                        "entitytype": "Bucket",
                        "oldvalue": "NOC",
                        "newvalue": "O&M",
                        "attachment": null,
                        "filename": null,
                        "resolutionId": null,
                        "remarktype": null,
                        "suspendDate": null,
                        "isDeleted": false
                    }
                ]
            },
            {
                "createdate": "24-10-2022 03:20 PM",
                "updatedate": "24-10-2022 03:20 PM",
                "createdByName": "Kishan Naik",
                "lastModifiedByName": "Kishan Naik",
                "createdById": 140,
                "lastModifiedById": 140,
                "id": 183721,
                "ticketId": 85147,
                "status": null,
                "caseType": null,
                "assignee": null,
                "priority": null,
                "attachment": null,
                "filename": null,
                "title": null,
                "resolutionId": null,
                "remarkType": "Private",
                "remark": null,
                "reasonId": null,
                "serviceAreaId": null,
                "teamType": null,
                "allocationType": null,
                "algorithmType": null,
                "commentBy": "Staff",
                "isDeleted": false,
                "nextFollowupDate": null,
                "nextFollowupTime": null,
                "bucketType": null,
                "createby": "Kishan Naik",
                "updateby": "Kishan Naik",
                "createDateString": "2022-10-24T15:20:17",
                "updateDateString": "24-10-2022 03:20 PM",
                "teamId": null,
                "etr": null,
                "etrUnit": null,
                "notified_enable": false,
                "suspendDate": null,
                "updateDetails": [
                    {
                        "id": 329227,
                        "operation": "Add Remarks",
                        "entitytype": "Remarks",
                        "oldvalue": null,
                        "newvalue": "check",
                        "attachment": null,
                        "filename": "abc",
                        "resolutionId": null,
                        "remarktype": "Private",
                        "suspendDate": null,
                        "isDeleted": false
                    },
                    {
                        "id": 329226,
                        "operation": "Change Bucket",
                        "entitytype": "Bucket",
                        "oldvalue": "Common",
                        "newvalue": "NOC",
                        "attachment": null,
                        "filename": null,
                        "resolutionId": null,
                        "remarktype": null,
                        "suspendDate": null,
                        "isDeleted": false
                    }
                ]
            },
            {
                "createdate": "09-09-2022 03:54 PM",
                "updatedate": "09-09-2022 03:54 PM",
                "createdByName": "Neoadmin admin",
                "lastModifiedByName": "Neoadmin admin",
                "createdById": 207,
                "lastModifiedById": 207,
                "id": 148432,
                "ticketId": 85147,
                "status": null,
                "caseType": null,
                "assignee": null,
                "priority": null,
                "attachment": null,
                "filename": null,
                "title": null,
                "resolutionId": null,
                "remarkType": "Private",
                "remark": null,
                "reasonId": null,
                "serviceAreaId": null,
                "teamType": null,
                "allocationType": null,
                "algorithmType": null,
                "commentBy": "Staff",
                "isDeleted": false,
                "nextFollowupDate": null,
                "nextFollowupTime": null,
                "bucketType": null,
                "createby": "Neoadmin admin",
                "updateby": "Neoadmin admin",
                "createDateString": "2022-09-09T15:54:20",
                "updateDateString": "09-09-2022 03:54 PM",
                "teamId": null,
                "etr": null,
                "etrUnit": null,
                "notified_enable": false,
                "suspendDate": null,
                "updateDetails": [
                    {
                        "id": 265592,
                        "operation": "Change Status",
                        "entitytype": "Status",
                        "oldvalue": "Unassigned",
                        "newvalue": "In Progress",
                        "attachment": null,
                        "filename": null,
                        "resolutionId": null,
                        "remarktype": null,
                        "suspendDate": "-",
                        "isDeleted": false
                    },
                    {
                        "id": 265591,
                        "operation": "Change Assignee",
                        "entitytype": "Assignee",
                        "oldvalue": "-",
                        "newvalue": "Rohit Sonu Mayekar",
                        "attachment": null,
                        "filename": null,
                        "resolutionId": null,
                        "remarktype": null,
                        "suspendDate": null,
                        "isDeleted": false
                    },
                    {
                        "id": 265590,
                        "operation": "Add Remarks",
                        "entitytype": "Remarks",
                        "oldvalue": null,
                        "newvalue": "No Fiber",
                        "attachment": null,
                        "filename": "abc",
                        "resolutionId": null,
                        "remarktype": "Private",
                        "suspendDate": null,
                        "isDeleted": false
                    }
                ]
            },
            {
                "createdate": "09-09-2022 03:50 PM",
                "updatedate": "09-09-2022 03:50 PM",
                "createdByName": "Neoadmin admin",
                "lastModifiedByName": "Neoadmin admin",
                "createdById": 207,
                "lastModifiedById": 207,
                "id": 148428,
                "ticketId": 85147,
                "status": null,
                "caseType": null,
                "assignee": null,
                "priority": null,
                "attachment": null,
                "filename": null,
                "title": null,
                "resolutionId": null,
                "remarkType": "external",
                "remark": null,
                "reasonId": null,
                "serviceAreaId": null,
                "teamType": null,
                "allocationType": null,
                "algorithmType": null,
                "commentBy": "Staff",
                "isDeleted": false,
                "nextFollowupDate": null,
                "nextFollowupTime": null,
                "bucketType": null,
                "createby": "Neoadmin admin",
                "updateby": "Neoadmin admin",
                "createDateString": "2022-09-09T15:50:29",
                "updateDateString": "09-09-2022 03:50 PM",
                "teamId": null,
                "etr": null,
                "etrUnit": null,
                "notified_enable": false,
                "suspendDate": null,
                "updateDetails": [
                    {
                        "id": 265585,
                        "operation": "Add Remarks",
                        "entitytype": "Remarks",
                        "oldvalue": null,
                        "newvalue": "Test",
                        "attachment": null,
                        "filename": null,
                        "resolutionId": null,
                        "remarktype": "external",
                        "suspendDate": null,
                        "isDeleted": false
                    }
                ]
            }
        ],
        "firstRemark": "Test",
        "liveUserServiceAreaDetails": null,
        "oltName": null,
        "slotName": null,
        "portName": null,
        "serviceAreaName": "calangute constituency",
        "mobile": "9909458851",
        "reason": "Electricity Issue",
        "userName": "billdesk",
        "currentAssigneeName": "Rohit Sonu Mayekar",
        "finalResolvedByName": "Mharof Shaikh",
        "finalClosedByName": "-",
        "finalResolutionName": "-",
        "customerName": "Mr billdesk",
        "finalClosedByDateString": null,
        "finalResolutionDateString": "27-10-2022 05:50 PM",
        "createDateString": "31-05-2023 08:18 PM",
        "updateDateString": "27-10-2022 05:50 PM",
        "partnerid": 22,
        "partnerName": "testh8",
        "gis_code": "",
        "url": "",
        "ticketRestriction": false,
        "fulladdress": "E 704 Daksh, Batim, 403108, Goa, Goa, India",
        "address1": null,
        "prevStatus": null,
        "isSnmpEnable": false,
        "operationDate": null,
        "feasibilityDistance": "-",
        "connectionType": "RF",
        "serviceType": "Broadband",
        "routerType": "-",
        "bridgeType": "-",
        "power": "-",
        "salesRepName": "-",
        "teamId": null,
        "teamName": null,
        "etr": "24",
        "etrUnit": "Hr",
        reqType:'Maintanance Request',
        RECNNR: '0000180910000'
    }
]

  constructor(
    private troubleTicketsDetailsService: TroubleTicketsDetailsService,
    private ngbModalService: NgbModal,
    private toaster: ToastrService,
    private router: Router,
    private ngbService:NgbModal,
    private route : ActivatedRoute,
    loginService: LoginService,
    private CommonListService : CommonListServicesService,
    private caseService : CasesService,
    private datePipe: DatePipe,
  ) { 
    this.loginService = loginService;
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.setGridSetting();
    this.getTroubleTicketsDetailsData();
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'actionCol') {
        col.customControls = [this.cancelWrapperRef];
      }
      if (col.colKey === 'caseNumber') {
        col.customControls = [this.editCasesReff];
      }
      if(col.colKey === 'caseStatus'){
        col.customControls = [this.statusWrapperRef];
      }
      if(col.colKey === 'notificationNumber'){
        col.customControls = [this.notifyRef];
      }
    });
  }
  
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'caseNumber',
        default: 'Service Request No.',
        rowKey: 'caseNumber',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      {
        colKey: 'caseTitle',
        default: 'Title',
        rowKey: 'caseTitle',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'unitNo',
      //   default: 'Unit No',
      //   rowKey: 'unitNo',
      //   width: '8%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      {
        colKey: 'equipmentNo',
        default: 'Unit No.',
        rowKey: 'equipmentNo',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'asset',
        default: 'Asset/Unit',
        rowKey: 'asset',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'createDateString',
        default: 'Requested On',
        rowKey: 'createDateString',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'reqType',
      //   default: 'Service Request Type',
      //   rowKey: 'reqType',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      {
        colKey: 'reason',
        default: 'Service Request Reason',
        rowKey: 'reason',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'caseStatus',
        default: 'Status',
        rowKey: 'caseStatus',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'finalResolutionDate',
      //   default: 'Resolution Date',
      //   rowKey: 'finalResolutionDate',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'finalResolvedByName',
      //   default: 'Resloved By',
      //   rowKey: 'finalResolvedByName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'finalResolutionName',
      //   default: 'Resolution Given',
      //   rowKey: 'finalResolutionName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'currentAssigneeName',
      //   default: 'Assign To',
      //   rowKey: 'currentAssigneeName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'actionCol',
      //   // default: 'Action',
      //   rowKey: 'id',
      //   isFilterAllow: false,
      //   isExpandable: false,
      //   isNumber: false,
      //   width: '3%'
      // }
    ];

    this.ticketDetailsNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.TROUBLETICKETSDETAILS,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }
  openCase(trouble, data) {
    this.remark = null;
    this.closeCaseData = data;
    this.ngbModalService.open(trouble, { size: 'xl' });
  }
  onEdit(event,editviewmode) {
    this.editviewId =  event.caseId
    this.ngbService.open(editviewmode, { size: 'xl' });
    //this.onEditCaseModule(event);
  }
  onEditCaseModule(event) {
    this.router.navigate(['/customer-care/cases/add-cases'], { queryParams: { id: event.caseId } });
  }

  saveCase() {
    let caseData: any = {};
    if (this.remark != null) {
      let ticketId = this.closeCaseData.caseUpdateList[0].ticketId;
      let caseStatus = 'Resolved'
      caseData.remark = this.remark;
      caseData.ticketId = ticketId;
      caseData.status = caseStatus;
      this.troubleTicketsDetailsService.updateData(caseData).subscribe(res => {
        if (res.responseCode !== 200) {
          this.toaster.error(res.responseMessage);
        } else {
          this.toaster.success("Data has been Successfully saved");
          this.getTroubleTicketsDetailsData();
          this.ngbModalService.dismissAll();
        }
      })
    } else {
      this.toaster.error("Please Enter remark");
    }
  }

  openUpdate(update,data) {
    this.getCaseById(data.caseId);
     this.updateList = new Updatelistmodel();
     this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
       res.dataList.forEach((x) => {
         if (x.value === "external") {
           this.updateList.remarkType = x.text;
         }
       });
     });
     setTimeout(() => {
       this.ngbService.open(update, { size: 'lg' });
      }, 500);
   }

   getCaseById(id){
    this.caseService.getById(id).subscribe((res)=>{
      if(res.responseCode == 200){
        this.entity = res.data;
      }
    })
  }

  refreshPage(data){
    this.updateFormSubmit()
  }

  updateFormSubmit() {
    this.updateList.resolutionId = this.entity.finalResolutionId;
    this.updateList.nextFollowupDate = this.datePipe.transform(this.entity.nextFollowupDate, 'yyyy-MM-dd');
    if(typeof(this.entity.nextFollowupTime) == 'object'){
      this.updateList.nextFollowupTime = this.datePipe.transform(this.entity.nextFollowupTime,'hh:mm:ss')
    }
    else{
      this.updateList.nextFollowupTime = this.entity.nextFollowupTime
    }
    this.updateList.suspendDate = this.datePipe.transform(this.entity.suspendDate, 'yyyy-MM-dd');
    this.updateList.filename = "abc"

    this.updateList.ticketId = this.entity.caseId;
    this.updateList.reasonId = this.entity.caseReasonId;
    this.updateList.status = this.entity.caseStatus;
    this.updateList.priority = this.entity.priority;
    this.updateList.commentBy = 'Staff';
    this.updateList.bucketType = this.entity.bucketType;
    this.updateList.title = this.entity.caseTitle;
    this.updateList.serviceAreaId = this.entity.serviceAreaId;
    this.updateList.assignee = this.entity.currentAssigneeId;
    this.updateList.teamType = this.entity.teamType;
    this.updateList.allocationType = this.entity.allocationType
    this.updateList.algorithmType = this.entity.algorithmType;
    this.caseService.updateList(this.updateList).subscribe((res) => {
      if(res.responseCode == 200){
        //this.entity = res.data;
        this.toaster.success('Case Update Successfully')
        this.getTroubleTicketsDetailsData();
      }
      else{
        this.toaster.error(res.responseMessage)
      }

    });
    this.ngbService.dismissAll();
  }
 
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onQuickCasePopup(caseDetail) {
    
    this.QuickCaseFlag = 'Customer';
   
    this.listTemp = ['name', 'username', 'phone', 'email'];
    
    this.ngbService.open(caseDetail);
   
  }

  onCancel(){
    this.ngbService.dismissAll();
  }

  getlist(){
  
    //this.getList(this.paginationData, this.orderingData, this.searchData);
    //this.getTroubleTicketsDetailsData();
  }

  getTroubleTicketsDetailsData() {
    this.troubleTicketsDetailsService.getTroubleTicketsDetailsList(this.custId).subscribe(result => {
      this.ticketDetailsTableData = result.dataList;
      this.ticketDetailsTableData.forEach(res => {
        if(!res.equipmentNo || res.equipmentNo == '-' || res.equipmentNo == ' ' ){
          res['asset'] = 'Asset'
          res.equipmentNo = '-'
        }else{
          res['asset'] = 'Unit'
        }
      })
       this.refreshDataSource(this.ticketDetailsTableData);
      this.customerName = this.name;
      this.onCaseRefresh.emit(result.data);
      return
    });
    
  }

}

export class Property{
    id:number;
    proname:string;
    proaddress:string;
    techconname:string;
    techconphone:string;
    subscriberLeasedLine:number;
    propertyContactDetails : Array<any>;
    blockName : string;
    numberOfUnits : number;
    noOfFloors : number;
    contactperson : string;
    contactNumber:string;
    // no_of_circuits:number;
}
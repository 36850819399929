export const machineData =
[
    {
       "SrNo":1,
       "customerId":39848,
      "serialNo":"GRN-MAR-2022-4-5",
      "brand":"Portable generators",
       "status":"Allocated",
      "name":"15kVA Prime Diesel Generator",
      "currentCustomer":"Reilly Raynor",
      "currentCustomerAllocationDate": "16-05-2022",
       "currentCustomerReleaseDate": "-",
       "ownershipType":"Self",
       "supplierDetails":"9898010076",
       "Warranty":'Yes',
      "warrentyStartDate":"16-05-2022",
      "warrentyEndDate":"16-05-2026",
       "subName":'Joshuva',
       "subStartDate":'15-10-2022',
       "subEndDate":'15-10-2023',
       "contract":'Yes',
       "contractStart":'06-01-2022',
       "contractEnd":'06-01-2030',
       "machineOwnership":'Sub Contract',
       "lastServiceDate":'6-10-2022',
      "lastServiceBy":'Wilber Wuckert',
      "lastserviceReqNo":'SR-300000171',
       "serDueDate":'6-10-2023',
       "caseId":'85216',
       "underContact":'Yes',
       "startDtae":'2-8-2022',
       "endDate":'2-8-2030',
       "hist":[
          {
             "currentCustomer":"Sheikh Shakhbout",
             "allocationStartDate":"07-06-2022",
             "allocationEndDate":"06-03-2023"
          },
          {
             "currentCustomer":"Salama Hospital",
             "allocationStartDate":"07-06-2022",
             "allocationEndDate":"06-06-2022"
          }
       ]
    },
    {
      "SrNo":2,
      "customerId":39848,
       "serialNo":"GRN-MAR-2022-4-4",
       "brand":"Inverter generators",
      "status":"Allocated",
       "name":"35kVA Diesel Generator Set",
       "currentCustomer":"Reilly Raynor",
      "currentCustomerAllocationDate": "16-05-2022",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"SubContract",
      "supplierDetails":"8765898643",
      "Warranty":'Yes',
      "warrentyStartDate":"16-05-2022",
       "warrentyEndDate":"16-05-2026",
      "subName":'Joshuva',
      "subStartDate":'06-03-2018',
      "subEndDate":'10-05-2023',
      "contract":'Yes',
      "contractStart":'06-02-2022',
      "contractEnd":'-',
      "machineOwnership":'Sub Contract',
      "lastServiceDate":'12-1-2023',
       "lastServiceBy":'Wilber Wuckert',
       "lastserviceReqNo":'SR-300000138',
      "serDueDate":'06-08-2023',
      "caseId":'85184',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
      "hist":[
         {
            "currentCustomer":"American Hospital",
            "allocationStartDate":"06-02-2021",
            "allocationEndDate":"05-02-2022"
         },
         {
            "currentCustomer":"Burjeel Hospital",
            "allocationStartDate":"06-02-2020",
            "allocationEndDate":"05-02-2021"
         }
      ]
    },
    {
       "SrNo":8,
       "customerId":39848,
       "serialNo":"GRN-MAR-2022-4-3",
       "brand":"Standby generators",
       "status":"Allocated",
       "name":"5kVA Single Phase Diesel Generator(Amf)",
       "currentCustomer":"Reilly Raynor",
       "currentCustomerAllocationDate": "16-05-2022",
       "currentCustomerReleaseDate": "-",
       "ownershipType":"Self",
       "supplierDetails":"8988645056",
       "Warranty":'No',
       "warrentyStartDate":"16-05-2022",
       "warrentyEndDate":"16-05-2026",
       "subName":'Joshuva',
       "subStartDate":'14-09-2022',
       "subEndDate":'14-09-2025',
       "contract":'-',
       "contractStart":'-',
       "contractEnd":'-',
       "machineOwnership":'Self',
       "lastServiceDate":'-',
       "lastServiceBy":'Wilber Wuckert',
       "lastserviceReqNo":'SR-300000137',
       "serDueDate":'6-10-2023',
       "caseId":'85183',
       "underContact":'Yes',
       "startDtae":'2-8-2022',
       "endDate":'2-8-2030',
      //  "hist":[
      //     {
      //        "currentCustomer":"Sheikh Shakhbout",
      //        "allocationStartDate":"20-01-2022",
      //        "allocationEndDate":"19-01-2023"
      //     }
      //  ]
    },
    {
      "SrNo":1,
      "customerId":39844,
     "serialNo":"GRN-MAR-2022-4-2",
     "brand":"Portable generators",
      "status":"Allocated",
     "name":"40kVA Diesel Generator Set",
     "currentCustomer":"Fusion Founders",
     "currentCustomerAllocationDate": "16-05-2022",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"Self",
      "supplierDetails":"9898010076",
      "Warranty":'Yes',
     "warrentyStartDate":"16-05-2022",
     "warrentyEndDate":"16-05-2026",
      "subName":'Joshuva',
      "subStartDate":'15-10-2022',
      "subEndDate":'15-10-2023',
      "contract":'Yes',
      "contractStart":'06-01-2022',
      "contractEnd":'06-01-2030',
      "machineOwnership":'Sub Contract',
      "lastServiceDate":'6-10-2022',
     "lastServiceBy":'Wilber Wuckert',
     "lastserviceReqNo":'SR-300000159',
      "serDueDate":'6-10-2023',
      "caseId":'85206',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
      "hist":[
         {
            "currentCustomer":"Sheikh Shakhbout",
            "allocationStartDate":"07-06-2022",
            "allocationEndDate":"06-03-2023"
         },
         {
            "currentCustomer":"Salama Hospital",
            "allocationStartDate":"07-06-2022",
            "allocationEndDate":"06-06-2022"
         }
      ]
   },
   {
     "SrNo":2,
     "customerId":39844,
      "serialNo":"GRN-MAR-2022-4-6",
      "brand":"Inverter generators",
     "status":"Allocated",
      "name":"Diesel Generator Engine Type: Air",
      "currentCustomer":"Fusion Founders",
     "currentCustomerAllocationDate": "16-05-2022",
     "currentCustomerReleaseDate": "-",
     "ownershipType":"SubContract",
     "supplierDetails":"8765898643",
     "Warranty":'Yes',
     "warrentyStartDate":"16-05-2022",
      "warrentyEndDate":"16-05-2026",
     "subName":'Joshuva',
     "subStartDate":'06-03-2018',
     "subEndDate":'10-05-2023',
     "contract":'Yes',
     "contractStart":'06-02-2022',
     "contractEnd":'-',
     "machineOwnership":'Sub Contract',
     "lastServiceDate":'12-1-2023',
      "lastServiceBy":'Wilber Wuckert',
      "lastserviceReqNo":'SR-300000158',
     "serDueDate":'06-08-2023',
     "caseId":'85205',
     "underContact":'Yes',
     "startDtae":'2-8-2022',
     "endDate":'2-8-2030',
     "hist":[
        {
           "currentCustomer":"American Hospital",
           "allocationStartDate":"06-02-2021",
           "allocationEndDate":"05-02-2022"
        },
        {
           "currentCustomer":"Burjeel Hospital",
           "allocationStartDate":"06-02-2020",
           "allocationEndDate":"05-02-2021"
        }
     ]
   },
   {
      "SrNo":8,
      "customerId":39844,
      "serialNo":"GRN-MAR-2022-4-7",
      "brand":"Standby generators",
      "status":"Allocated",
      "name":"25kVA Diesel Generator Set",
      "currentCustomer":"Fusion Founders",
      "currentCustomerAllocationDate": "16-05-2022",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"Self",
      "supplierDetails":"8988645056",
      "Warranty":'No',
      "warrentyStartDate":"16-05-2022",
      "warrentyEndDate":"16-05-2026",
      "subName":'Joshuva',
      "subStartDate":'14-09-2022',
      "subEndDate":'14-09-2025',
      "contract":'-',
      "contractStart":'-',
      "contractEnd":'-',
      "machineOwnership":'Self',
      "lastServiceDate":'-',
      "lastServiceBy":'Wilber Wuckert',
      "lastserviceReqNo":'SR-300000156',
      "serDueDate":'6-10-2023',
      "caseId":'85204',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
     //  "hist":[
     //     {
     //        "currentCustomer":"Sheikh Shakhbout",
     //        "allocationStartDate":"20-01-2022",
     //        "allocationEndDate":"19-01-2023"
     //     }
     //  ]
   },
    {
      "SrNo":5,
      "customerId":39845,
      "serialNo":"SRL-JAN-2023-8",
      "brand":"Ultherapy",
      "status":"Allocated",
      "name":"Ultherapy",
      "currentCustomer":"Cleveland Clinic",
      "currentCustomerAllocationDate": "01-08-2022",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"SubContract",
      "supplierDetails":"8786909456",
      "Warranty":'No',
      "warrentyStartDate":"-",
      "warrentyEndDate":"-",
      "subName":'Naman',
      "subStartDate":'20-12-2022',
      "subEndDate":'20-12-2023',
      "contract":'No',
      "contractStart":'-',
      "contractEnd":'-',
      "machineOwnership":'Self',
      "lastServiceDate":'-',
      "lastServiceBy":'-',
      "lastserviceReqNo":'TKT-81031',
      "serDueDate":'6-10-2023',
      "caseId":'85143',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
      "hist":[
         {
            "currentCustomer":"Cleveland Clinic",
            "allocationStartDate":"02-04-2022",
            "allocationEndDate":"01-08-2022"
         },
         {
            "currentCustomer":"Burjeel Hospital",
            "allocationStartDate":"02-04-2022",
            "allocationEndDate":"01-04-2022"
         },
         {
            "currentCustomer":"American Hospital",
            "allocationStartDate":"02-10-2021",
            "allocationEndDate":"01-04-2022"
         }
      ]
    },
    {
      "SrNo":2,
      "customerId":39846,
      "serialNo":"SRL-JAN-2023-56",
      "brand":"Brera",
      "status":"Allocated",
      "name":"Plasmage",
      "currentCustomer":"Burjeel Hospital",
      "currentCustomerAllocationDate": "07-03-2023",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"SubContract",
      "supplierDetails":"8765898643",
      "Warranty":'Yes',
      "warrentyStartDate":"07-03-2023",
      "warrentyEndDate":"06-03-2024",
      "subName":'Joshuva',
      "subStartDate":'06-03-2018',
      "subEndDate":'10-05-2023',
      "contract":'Yes',
      "contractStart":'06-02-2022',
      "contractEnd":'-',
      "machineOwnership":'Sub Contract',
      "lastServiceDate":'12-1-2023',
      "lastServiceBy":'yash Patel',
      "lastserviceReqNo":'TKT-81021',
      "serDueDate":'6-10-2023',
      "caseId":'85133',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
      "hist":[
         {
            "currentCustomer":"Burjeel Hospital",
            "allocationStartDate":"07-03-2021",
            "allocationEndDate":"06-03-2023"
         },
         {
            "currentCustomer":"Shaikh Khalifa",
            "allocationStartDate":"07-03-2020",
            "allocationEndDate":"06-03-2021"
         }
      ]
    },
    {
      "SrNo":4,
      "customerId":'-',
      "serialNo":"SRL-JAN-2023-24",
      "brand":"Lutronic",
      "status":"Free",
      "name":"Lase MD",
      "currentCustomer":"-",
      "currentCustomerAllocationDate": "-",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"Self",
      "supplierDetails":"9886509874",
      "Warranty":'No',
      "warrentyStartDate":"-",
      "warrentyEndDate":"-",
      "subName":'Joshuva',
      "subStartDate":'10-11-2017',
      "subEndDate":'10-11-2021',
      "contract":'Yes',
      "contractStart":'04-11-2017',
      "contractEnd":'04-12-2022',
      "machineOwnership":'Sub Contract',
      "lastServiceDate":'4-5-2023',
      "lastServiceBy":'Harsh Panchal',
      "lastserviceReqNo":'-',
      "serDueDate":'6-10-2023',
      "caseId":'85143',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
     //  "hist":[
     //     {
     //        "currentCustomer":"Al Raha Hospitals",
     //        "allocationStartDate":"10-11-2017",
     //        "allocationEndDate":"10-11-2021"
     //     }
     //  ]
    },
    {
      "SrNo":6,
      "customerId":'-',
      "serialNo":"SRL-JAN-2023-18",
      "brand":"Cutera",
      "status":"Free",
      "name":"Excel V",
      "currentCustomer":"-",
      "currentCustomerAllocationDate": "-",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"Self",
      "supplierDetails":"9897634567",
      "Warranty":'Yes',
      "warrentyStartDate":"-",
      "warrentyEndDate":"-",
      "subName":'Joshuva',
      "subStartDate":'14-07-2018',
      "subEndDate":'14-07-2024',
      "contract":'Yes',
      "contractStart":'14-05-2018',
      "contractEnd":'14-05-2025',
      "machineOwnership":'Sub Contract',
      "lastServiceDate":'-',
      "lastServiceBy":'-',
      "lastserviceReqNo":'-',
      "serDueDate":'6-10-2023',
      "caseId":'85143',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
     //  "hist":[
     //     {
     //        "currentCustomer":"Al Raha Hospitals",
     //        "allocationStartDate":"14-07-2018",
     //        "allocationEndDate":"14-07-2024"
     //     }
     //  ]
    },
    {
      "SrNo":7,
      "customerId":'-',
      "serialNo":"SRL-JAN-2023-89",
      "brand":"Lutronic",
      "status":"Free",
      "name":"PicoPlus",
      "currentCustomer":"-",
      "currentCustomerAllocationDate": "-",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"SubContract",
      "supplierDetails":"9898634987",
      "Warranty":'Yes',
      "warrentyStartDate":"-",
      "warrentyEndDate":"-",
      "subName":'Joshuva',
      "subStartDate":'14-09-2022',
      "subEndDate":'14-09-2025',
      "contract":'-',
      "contractStart":'-',
      "contractEnd":'-',
      "machineOwnership":'Self',
      "lastServiceDate":'-',
      "lastServiceBy":'-',
      "lastserviceReqNo":'-',
      "serDueDate":'6-10-2023',
      "caseId":'85143',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
     //  "hist":[
     //     {
     //        "currentCustomer":"Cleveland Clinic",
     //        "allocationStartDate":"14-09-2022",
     //        "allocationEndDate":"13-09-2025"
     //     }
     //  ]
    },
    {
      "SrNo":9,
      "customerId":'-',
      "serialNo":"SRL-JAN-2023-91",
      "brand":"Lutronic",
      "status":"Free",
      "name":"Genius",
      "currentCustomer":"-",
      "currentCustomerAllocationDate": "-",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"SubContract",
      "supplierDetails":"8768906754",
      "Warranty":'Yes',
      "warrentyStartDate":"-",
      "warrentyEndDate":"-",
      "subName":'Joshuva',
      "subStartDate":'01-05-2018',
      "subEndDate":'01-05-2026',
      "contract":'Yes',
      "contractStart":'01-05-2018',
      "contractEnd":'01-05-2026',
      "machineOwnership":'Sub Contract',
      "lastServiceDate":'-',
      "lastServiceBy":'-',
      "lastserviceReqNo":'-',
      "serDueDate":'6-10-2023',
      "caseId":'85143',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
     //  "hist":[
     //     {
     //        "currentCustomer":"Sheikh Shakhbout",
     //        "allocationStartDate":"01-05-2018",
     //        "allocationEndDate":"01-05-2026"
     //     }
     //  ]
    },
    {
      "SrNo":3,
      "customerId":'-',
      "serialNo":"SRL-JAN-2023-56",
      "brand":"Brera",
      "status":"Free",
      "name":"MED 400",
      "currentCustomer":"-",
      "currentCustomerAllocationDate": "-",
      "currentCustomerReleaseDate": "-",
      "ownershipType":"Self",
      "supplierDetails":"7567040050",
      "Warranty":'No',
      "warrentyStartDate":"-",
      "warrentyEndDate":"-",
      "subName":'Joshuva',
      "subStartDate":'18-01-2022',
      "subEndDate":'18-01-2025',
      "contract":'Yes',
      "contractStart":'15-01-2022',
      "contractEnd":'15-01-2025',
      "machineOwnership":'Sub Contract',
      "lastServiceDate":'4-2-2023',
      "lastServiceBy":'Rakesh Pandya',
      "lastserviceReqNo":'-',
      "serDueDate":'6-10-2023',
      "caseId":'85143',
      "underContact":'Yes',
      "startDtae":'2-8-2022',
      "endDate":'2-8-2030',
     //  "hist":[
     //     {
     //        "currentCustomer":"American Hospital",
     //        "allocationStartDate":"18-01-2022",
     //        "allocationEndDate":"18-01-2025"
     //     }
     //  ]
    },
 ]


 export const proprertyDocList = [{
   'id':1,
   'propertyName': 'ABC',
   'docType': 'General',
   'docSubType': 'ISO 9001:2015',
   'totalDoc':'1',
   'status': 'Active',
   'refNo':'-'
 },{
   'id':3,
   'propertyName': 'ABC',
   'docType': 'Machine Documents',
   'docSubType': 'Machine Guide (Excle HR)',
   'totalDoc':'2',
   'status': 'Active',
   'refNo':'GRN-MAR-2022-4-5'
 },{
   'id':4,
   'propertyName': 'ABC',
   'docType': 'Machine Documents',
   'docSubType': 'Installation Steps (Infini)',
   'totalDoc':'1',
   'status': 'Active',
   'refNo':'GRN-MAR-2022-4-2'
 },{
   'id':5,
   'propertyName': 'ABC',
   'docType': 'Machine Compliance',
   'docSubType': 'Machine Compliance-1',
   'totalDoc':'1',
   'status': 'Active',
   'refNo':'-'
 },{
   'id':7,
   'propertyName': 'ABC',
   'docType': 'Others',
   'docSubType': '-',
   'totalDoc':'1',
   'status': 'Active',
   'refNo':'-'
 },]


 export const caseMachineData = [
   {
      caseId: 85146,
      serialNo: 'SRL-JAN-2023-1',
      machineName: 'Excel HR',
      brandName: 'Lutronic'
   },
   {
      caseId: 85145,
      serialNo: 'SRL-JAN-2023-18',
      machineName: 'Excel V',
      brandName: 'Cutera'
   },
   {
      caseId: 85144,
      serialNo: 'SRL-JAN-2023-48',
      machineName: 'Infini',
      brandName: 'Cutera'
   },
   {
      caseId: 85143,
      serialNo: 'SRL-JAN-2023-1',
      machineName: 'Excel HR',
      brandName: 'Lutronic'
   },
   {
      caseId: 85142,
      serialNo: 'SRL-JAN-2023-1',
      machineName: 'Excel HR',
      brandName: 'Lutronic'
   },
   {
      caseId: 85141,
      serialNo: 'SRL-JAN-2023-91',
      machineName: 'Genius',
      brandName: 'Lutronic'
   },
   {
      caseId: 85140,
      serialNo: 'SRL-JAN-2023-1',
      machineName: 'Excel HR',
      brandName: 'Lutronic'
   },
   {
      caseId: 85139,
      serialNo: 'SRL-JAN-2023-56',
      machineName: 'MED 400',
      brandName: 'Brera'
   },
   {
      caseId: 85138,
      serialNo: 'SRL-JAN-2023-8',
      machineName: 'Ultherapy',
      brandName: 'Ultherapy'
   },
   {
      caseId: 85137,
      serialNo: 'SRL-JAN-2023-90',
      machineName: 'Lutronic',
      brandName: 'Clarity'
   },
   {
      caseId: 85136,
      serialNo: 'SRL-JAN-2023-89',
      machineName: 'Lutronic',
      brandName: 'PicoPlus'
   },
   {
      caseId: 85135,
      serialNo: 'SRL-JAN-2023-1',
      machineName: 'Excel HR',
      brandName: 'Lutronic'
   },
   {
      caseId: 85134,
      serialNo: 'SRL-JAN-2023-56',
      machineName: 'MED 400',
      brandName: 'Brera'
   },
   {
      caseId: 85133,
      serialNo: 'SRL-JAN-2023-48',
      machineName: 'Infini',
      brandName: 'Cutera'
   },
   {
      caseId: 85132,
      serialNo: 'SRL-JAN-2023-89',
      machineName: 'Lutronic',
      brandName: 'PicoPlus'
   },
   {
      caseId: 85131,
      serialNo: 'SRL-JAN-2023-1',
      machineName: 'Excel HR',
      brandName: 'Lutronic'
   },
   {
      caseId: 85130,
      serialNo: 'SRL-JAN-2023-18',
      machineName: 'Excel V',
      brandName: 'Cutera'
   },
 ]


 export const TenantList = [
   {
       "id": 39848,
       'bpid':'0000000267',
       "name": "Amarendar Kumar",
       "username": "amarendar",
       "mobile": "7434028923",
       "email": "amarendra@gmail.com",
       "connectivity": true,
       "activeuserflag": false,
       "partnerId": 1,
       "partnerName": "Default",
       "status": "Active",
       "createdate": "20-12-2023 01:25:17 PM",
       "createdby": "admin admin",
       "lastmodifieddate": "01-02-2023 02:03:44 PM",
       "lastmodifiedby": "admin admin",
       "fulladdress": "560093, Bengaluru, India",
       ES_PARTNER : {
         "PARTNER": "0000000267",
         "BPNAME": "Amarendar Kumar",
         "TELNR_LONG": null,
         "SMTP_ADDR": null,
         "LANGU": "E",
         "STREET": null,
         "POST_CODE1": "560093",
         "CITY1": "Bengaluru",
         "COUNTRY": "IN",
         "TIME_ZONE": "INDIA"
    },
    ET_CONTRACT: { "item": [
      {
        RECNNR:'CT-ORS-1001',
        RECNBEG:'01-Jan-2023',
        RECNENDABS: '-',
        RECNTXT: 'Contract Owned Premise'
      },{
        RECNNR:'CT-GP-1117',
        RECNBEG:'12-Feb-2022',
        RECNENDABS: '11-Feb-2025',
        RECNTXT: 'Leased Property'
      },{
        RECNNR:'CT-BC-21005',
        RECNBEG:'01-Jun-2021',
        RECNENDABS: '-',
        RECNTXT: 'Contract for Owned Property'
      }
    ]
   },
   ET_INVOICE: {
      "item": [
        {
          INVNO: 'INV-12231',
          RECNNR: "CT-ORS-1001",
          DERF: "03-Mar-2023",
          TOTALNETAMOUNT: "69500.00",
          STATUS: "Paid",
        },
        {
          INVNO: 'INV-12432',
          RECNNR: "CT-ORS-1001",
          DERF: "15-Jul-2023",
          TOTALNETAMOUNT: "15000.00",
          STATUS: "Due",
        },
        {
          INVNO: 'INV-12562',
          RECNNR: "CT-GS-1023",
          DERF: "15-Aug-2023",
          TOTALNETAMOUNT: "65000.00",
          STATUS: "Due",
        }
       ]
    },
    ET_UNIT : {
      "item": [
        {      
          SMENR: 'A-102',
          SGENR: 'Orian Square',
          RECNNR: 'CT-ORS-1001',
          MEASVALUE: '50000',
          SUPPLEMENTINFO: 'Owned',
          XMBEZ:'Office'
        },{
          SMENR: 'A-113',
          SGENR: 'Gutron Parks',
          RECNNR: 'CT-GP-1117',
          MEASVALUE: '10000',
          SUPPLEMENTINFO: 'Leased',
          XMBEZ:'Office'
        },{
          SMENR: '22/7',
          SGENR: 'Blue Corp',
          RECNNR: 'CT-BC-21005',
          MEASVALUE: '22000',
          SUPPLEMENTINFO: 'Owned',
          XMBEZ:'House'
        },{
          SMENR: '202/c',
          SGENR: 'Gunam Sky',
          RECNNR: 'CT-GS-1023',
          MEASVALUE: '40000',
          SUPPLEMENTINFO: 'Leased',
          XMBEZ:'Office'
        }
       ]
   }
   }
]

export const ProjectList =[
   {
     "id": 1,
     "projectName": "Oceanz Danube",
     "description": "Oceanz Danube is a sleek and striking high-rise living development\nwith luxurious studios, 1, 2 & 3 bedroom \napartments at the coast of Dubai",
     "latitude": 25.8548503249241,
     "longitude": 54.9618942368053,
     "address": "Dubai Maritime City\nDubai\nUnited Arab Emirates",
     "projectType": "Residential",
     "totalBlocks": 5,
     "totalUnits": 100,
     "ownerOfTheProject": "Danuabe Properties",
     "blueprintDocument": "project_one.jpeg",
     "amenitiesInfo": "Photo Booth\r\nPrayer Hall\r\nKids Pool\r\nSwimming Pool\r\nKids Play Area\r\nBusiness Centre with Secretary\r\nLibrary\r\nBeauty Salon\r\nDoctor On Call\r\nCigar Lounge",
     "contactperson": "Kareem Abozeid",
     "contactnumber": "+971543550919",
     "partnerid": 11,
     "isDeleted": false,
     "smsenable": true,
     "emailenable": true,
     "totalProperties": 1,
     "salesrepid": null,
     "createdate": "31-08-2023 02:57 PM",
     "updatedate": "31-08-2023 02:57 PM",
     "createdByName": "Test",
     "lastModifiedByName": "Test",
     "createdById": 236,
     "lastModifiedById": 236
   },
   {
     "id": 2,
     "projectName": "Emaar Grande Signature Residences",
     "description": "It offers 2, 3, 4 BHK Apartment in Dubai North. \nThe project is Under Construction project and possession in Feb 24.\n Among the many luxurious amenities that the project boasts are CCTV, \n24 Hours Water Supply, Earthquake Resistant Structure, Car Parking, Lift Available etc.",
     "latitude": 25.1958266275361,
     "longitude": 55.2717913539736,
     "address": "Downtown Dubai \nDubai \nUnited Arab Emirates",
     "projectType": "Residential",
     "totalBlocks": 10,
     "totalUnits": 200,
     "ownerOfTheProject": "Emaar UAE",
     "blueprintDocument": "project_second.jpeg",
     "amenitiesInfo": "24 Hours Water Supply\r\n24 X 7 Security\r\nCar Parking\r\nCCTV\r\nChildren's play area\r\nEarthquake Resistant Structure\r\nLift Available",
     "contactperson": "Agha Salman",
     "contactnumber": "+971543550818",
     "isDeleted": false,
     "smsenable": true,
     "emailenable": true,
     "totalProperties": 1,
     "salesrepid": null,
     "createdate": "31-08-2023 02:57 PM",
     "updatedate": "31-08-2023 02:57 PM",
     "createdByName": "Test",
     "lastModifiedByName": "Test",
     "createdById": 236,
     "lastModifiedById": 236
   },
   {
     "id": 3,
     "projectName": "Deyaar Central Park Towers",
     "description": "Looking for property investment opportunities in Dubai, Deyaar Central Park Towers can be the right bet for you. \nIt is an under construction project in Difc, Dubai, offering investment options within your budget. \nFor those looking for exciting returns on investment, Deyaar Central Park Towers is Dubai's most desirable commercial project, \nwhere property options are available in varied budget range. \nDeyaar Central Park Towers Difc has commercial properties, offering maximum visibility and high footfalls.",
     "latitude": 26.2979288556817,
     "longitude": 55.404815038414,
     "address": "Sheikh Zayed Rd  \nTrade Centre \nDIFC - Dubai \nUnited Arab Emirates",
     "projectType": "Commercial",
     "totalBlocks": 45,
     "totalUnits": 900,
     "ownerOfTheProject": "Deyaar Properties Group",
     "blueprintDocument": "project_three.jpeg",
     "amenitiesInfo": "24 Hours Water Supply\n24 X 7 Security\nCar Parking\nCCTV\nEarthquake Resistant Structure\nLift Available",
     "contactperson": "Adnan Khan",
     "contactnumber": "+971543550717",
     "isDeleted": false,
     "smsenable": true,
     "emailenable": true,
     "totalProperties": 1,
     "salesrepid": null,
     "createdate": "31-08-2023 02:57 PM",
     "updatedate": "31-08-2023 02:57 PM",
     "createdByName": "Test",
     "lastModifiedByName": "Test",
     "createdById": 236,
     "lastModifiedById": 236
   }
 ]

export const BlockList = [
   {
      "id": 1,
      "blockName" : "Block A",
      "numberOfUnits":20 ,
      "noOfFloors" : 5,
      "contactperson" : "Suhail Galadari",
      "contactNumber" : "+971543550929",
      "type": "propertyDocType",
      "status": "Active",
      "subTypeList": null,
      "projectId":1
  },
  {
   "id": 2,
   "blockName" : "Block B",
   "numberOfUnits":20 ,
   "noOfFloors" : 5,
   "contactperson" : "Khalid Ahmed",
   "contactNumber" : "+971543550828",
   "type": "propertyDocType",
   "status": "Active",
   "subTypeList": null,
   "projectId":1
},
{
   "id": 3,
   "blockName" : "Sheikh Mohammed",
   "numberOfUnits":10 ,
   "noOfFloors" : 5,
   "contactperson" : "Naseer Hussain",
   "contactNumber" : "+971543550727",
   "type": "propertyDocType",
   "status": "Active",
   "subTypeList": null,
   "projectId":2
},
{
   "id": 4,
   "blockName" : "Saeed bin Suroor",
   "numberOfUnits":10 ,
   "noOfFloors" : 5,
   "contactperson" : "Fahad Alam",
   "contactNumber" : "+971543550515",
   "type": "propertyDocType",
   "status": "Active",
   "subTypeList": null,
   "projectId":2
},
{
   "id": 5,
   "blockName" : "Tower 1",
   "numberOfUnits":300 ,
   "noOfFloors" : 25,
   "contactperson" : "Abdul Sharif",
   "contactNumber" : "+971543550414",
   "type": "propertyDocType",
   "status": "Active",
   "subTypeList": null,
   "projectId":3
},
{
   "id": 6,
   "blockName" : "Tower 2",
   "numberOfUnits":300 ,
   "noOfFloors" : 25,
   "contactperson" : "Ahmed Ali Bin Sayed",
   "contactNumber" : "+971543550313",
   "type": "propertyDocType",
   "status": "Active",
   "subTypeList": null,
   "projectId":3
}



]

export const RecidencialData = [
   {
       "unitId": 1,
       "unitNumber": "A101",
       "ownerName": "Rashid Ahmed",
       "mobile": "+971 543550939",
       "floorNo": 1,
       "flatType": "2BHK",
       "ownershipDocuments": null,
       "tenantName": "-",
       "tenantMobile": "-",
       "blockId": 1
   },
   {
       "unitId": 2,
       "unitNumber": "B202",
       "ownerName": "Saeed Ali",
       "mobile": "+971 543550838",
       "floorNo": 2,
       "flatType": "3BHK",
       "ownershipDocuments": null,
       "tenantName": "-",
       "tenantMobile": "-",
       "blockId": 2
   },
   {
       "unitId": 3,
       "unitNumber": "SM301",
       "ownerName": "Sultan Zarawani",
       "mobile": "+971 543550737",
       "floorNo": 3,
       "flatType": "2BHK",
       "ownershipDocuments": null,
       "tenantName": "Amir Ali",
       "tenantMobile": "+971 543550111",
       "blockId": 3
   },
   {
       "unitId": 4,
       "unitNumber": "SBS401",
       "ownerName": "Hussain Sajwani",
       "mobile": "+971 543550525",
       "floorNo": 4,
       "flatType": "3BHK",
       "ownershipDocuments": null,
       "tenantName": "Wasim Jaffer",
       "tenantMobile": "+971 543550222",
       "blockId": 4
   },
   {
       "unitId": 5,
       "unitNumber": "OF501",
       "ownerName": "Gaurav Chaudhry",
       "mobile": "+971 543550424",
       "floorNo": 5,
       "flatType": "Office",
       "ownershipDocuments": null,
       "tenantName": "Ajay Shah",
       "tenantMobile": "+971 543550101",
       "blockId": 5
   },
   {
       "unitId": 6,
       "unitNumber": "OF601",
       "ownerName": "Jessi Mendolia",
       "mobile": "+971 543550323",
       "floorNo": 6,
       "flatType": "Office",
       "ownershipDocuments": null,
       "tenantName": "Nick Johnson",
       "tenantMobile": "+971 543550202",
       "blockId": 6
   }
]
import { AfterContentInit, Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../../services/message_service/message_service.service';

import { CityService } from '../../city/city.service';
import { CountryService } from '../../country/country.service';
import { StateService } from '../../state/state.service';
import * as alertify from 'alertifyjs';
import { PubSubService } from '../../../../services/pubsub/pubsub.service';
import { AddSubscriberDetailsService }  from '../add-subscriber-details.service';
import _ from 'underscore';
import { HostListener } from '@angular/core';
import { TokenStorageService } from '../../../../auth/token-storage-service';
@Component({
  selector: 'app-address-information',
  templateUrl: './address-information.component.html',
  styleUrls: ['./address-information.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInformationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressInformationComponent),
      multi: true
    }
  ]
})
export class AddressInformationComponent implements ControlValueAccessor, OnInit{
  public countryList: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  public stateList: any[] = [
    {
      state_name: '',
      state_id: '',
    },
  ];
  public cityList: any[] = [
    {
      city_name: '',
      city_id: '',
    },
  ];

  public country2List: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  public state2List: any[] = [
    {
      state_name: '',
      state_id: '',
    },
  ];
  public city2List: any[] = [
    {
      city_name: '',
      city_id: '',
    },
  ];

  public area1AllList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  searchPincodeLink: string = '';

  public pincode1AllList: any[] = [
    {
      pincode: '',
      pincodeid: '',
    },
  ];

  public area2AllList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public pincode2AllList: any[] = [
    {
      pincode: '',
      pincodeid: '',
    },
  ];
  addressMapArray:any;
  addressMapArray2:any;
  addressMapArray3:any;
  buildingList:[];
  buildingList2:[];
  subscriptions: Subscription[] = [];
  subscription: Subscription;
  req_message = "This field is required.";
  submitted: boolean;
  default_pincode1_value:string = '';
  default_pincode2_value:string = '';
  parentpin:any
  insertAddressInformationForm = new FormGroup({
    address1: new FormControl('', [Validators.required]),
    landmark1: new FormControl(''),
    area1: new FormControl(null),
    pincode1: new FormControl('', [Validators.required]),
    Country1: new FormControl(null, [Validators.required]),
    State1: new FormControl(null, [Validators.required]),
    City1: new FormControl(null,[Validators.required]),
    Taluka1: new FormControl(null),
    District1: new FormControl(null),
    Constituency1: new FormControl(null),
    countryId1: new FormControl(null),
    stateId1: new FormControl(null),
    cityId1: new FormControl(null),
    talukaId1: new FormControl(null),
    districtId1: new FormControl(null),
    constituencyId1: new FormControl(null),
    building1: new FormControl(null),
    block1:new FormControl(null),
    address2: new FormControl('', [Validators.required]),
    landmark2: new FormControl(''),
    area2: new FormControl(null),
    pincode2: new FormControl('', [Validators.required]),
    Country2: new FormControl(null, [Validators.required]),
    State2: new FormControl(null, [Validators.required]),
    City2: new FormControl(null,[Validators.required]),
    Taluka2: new FormControl(null),
    District2: new FormControl(null),
    Constituency2: new FormControl(null),
    countryId2: new FormControl(null),
    stateId2: new FormControl(null),
    cityId2: new FormControl(null),
    talukaId2: new FormControl(null),
    districtId2: new FormControl(null),
    constituencyId2: new FormControl(null),
    building2: new FormControl(null),
    block2:new FormControl(null),
  });

  get value(): AddressInformationComponent {
    return this.insertAddressInformationForm.value;
  }

  set value(value: AddressInformationComponent) {
    this.insertAddressInformationForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }


  constructor(private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private messageService: MessageService,
    private pubsub: PubSubService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,
    private tokenservice: TokenStorageService
  ) {
    this.subscriptions.push(
      this.insertAddressInformationForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      }));

    this.pubsub.$sub('address_information_validation_fail').subscribe((data) => {
      this.submitted = true;
      this.validateAllFields(this.insertAddressInformationForm);
    });

    this.pubsub.$sub('address_information_validation_fail').subscribe((data) => {
      this.submitted = true;
      this.validateAllFields(this.insertAddressInformationForm);
    });

    this.pubsub.$sub('receive_present_address_for_permanent_address_details').subscribe((data) => {
      this.setPresentAddressValueInFormPermanentAddressDetails(data);
    });

    this.pubsub.$sub('receive_present_address_for_payment_address_details').subscribe((data) => {
      this.setPresentAddressValueInFormPaymentAddressDetails(data);
    });

  }

  ngOnInit() {
    this.clearErrorMsg();
    this.getCountryList();
    // this.getPincode1All();
    // this.getPincode2All();
    // this.getArea1All();
    // this.getArea2All();
    this.getAddressMapping();
    this.getPincode();
  }

  setFocusOnFirstElement(elementName: string, timeoutTime: number){
    let el = document.getElementById(elementName);
    setTimeout(()=>{
      if(el)
      el.focus();
    },timeoutTime)
  }

  getPincode() {
    this.searchPincodeLink = 'address/searchNew?id=' + this.parentpin + '&s=';
  }

  getAddressMapping(){
    let temp = this.tokenservice.getAddressLevel()
    this.addressMapArray = temp.filter(res=> res.name.toLowerCase()!='pincode' && res.name.toLowerCase() != 'area' && res.name.toLowerCase() != 'building' )
    this.addressMapArray2 = temp.filter(res=> (res.name.toLowerCase() == 'area' ))
    this.addressMapArray3 = temp.filter(res=> (res.name.toLowerCase() == 'building' ))
    let tempn = temp.filter(res=> (res.name.toLowerCase() == 'pincode' ))
    this.parentpin = tempn[0].levelNo
    if(this.addressMapArray.find(res=> res.name.toLowerCase()=='district')){
      this.insertAddressInformationForm.get('District1').setValidators([Validators.required]);
      this.insertAddressInformationForm.get('District1').updateValueAndValidity();
      this.insertAddressInformationForm.get('District2').setValidators([Validators.required]);
      this.insertAddressInformationForm.get('District2').updateValueAndValidity();
    }
    if(this.addressMapArray.find(res=> res.name.toLowerCase()=='taluka')){
      this.insertAddressInformationForm.get('Taluka1').setValidators([Validators.required]);
      this.insertAddressInformationForm.get('Taluka1').updateValueAndValidity();
      this.insertAddressInformationForm.get('Taluka2').setValidators([Validators.required]);
      this.insertAddressInformationForm.get('Taluka2').updateValueAndValidity();
    }
    if(this.addressMapArray.find(res=> res.name.toLowerCase()=='constituency')){
      this.insertAddressInformationForm.get('Constituency1').setValidators([Validators.required]);
      this.insertAddressInformationForm.get('Constituency1').updateValueAndValidity();
      this.insertAddressInformationForm.get('Constituency2').setValidators([Validators.required]);
      this.insertAddressInformationForm.get('Constituency2').updateValueAndValidity();
    }
    console.log('-->',this.addressMapArray)
  }

  onChangeArea(event){
    this.buildingList = [];
    this.insertAddressInformationForm.get('building1').reset();
    this.insertAddressInformationForm.get('building1').updateValueAndValidity();
    if(event && event.buildings){
      this.buildingList = event.buildings
    }
    else{
      
    }
  }

  onChangeArea2(event){
    this.buildingList2 = [];
    this.insertAddressInformationForm.get('building2').reset();
    this.insertAddressInformationForm.get('building2').updateValueAndValidity();
    if(event && event.buildings){
      this.buildingList2 = event.buildings
    }
    else{
      
    }
  }


  spaceNotAllow(event) {
    if (event.which == 32) {
     // alertify.error("Space Not Allowed");
     return true;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  validateAllFields(insertAddressInformationForm) {
    Object.keys(insertAddressInformationForm.controls).forEach(field => {
      const control = insertAddressInformationForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }
  clearErrorMsg() {
    if (this.submitted === true) {
      this.submitted = false;
      this.insertAddressInformationForm.reset();
    }
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.insertAddressInformationForm.valid ? null : { addressInformation: { valid: true, }, };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  chkSameAsPresentAddressForPermanentAddressDetails(el) {
    if (el.checked === true) {
      this.pubsub.$pub('get_present_address_for_permanent_address_details',el.checked);
    } else {
      this.default_pincode1_value  = '';
      let formData = {
        Country1: null,
        State1: null,
        City1: null,
        Taluka1: null,
        District1: null,
        Constituency1: null,
        building1: null,
        block1: null,
        address1: null,
        landmark1: null,
        area1: null,
        pincode1: null,
      };
      this.insertAddressInformationForm.patchValue(formData);
    
    }
  }

  chkSameAsPresentAddressForPaymentAddressDetails(el) {
    if (el.checked === true) {
      this.pubsub.$pub('get_present_address_for_payment_address_details', el.checked);
    } else {
      this.default_pincode2_value  = '';
      let formData = {
        Country2: null,
        State2: null,
        City2: null,
        District2:null,
        Constituency2:null,
        Taluka2:null,
        building2:null,
        address2: null,
        landmark2: null,
        area2: null,
        pincode2: null,
        block2:null
      };
      this.insertAddressInformationForm.patchValue(formData);
    }
  }

  getPincodeAllAndSetValueInPincode1(dataList?:any) {
    if (dataList) {
      let area:any
      if (dataList) {
        area = this.area1AllList.filter(res=>res.id == dataList.area)
        if(area.length == 1) {
          this.insertAddressInformationForm.patchValue({ area1: area[0].id });
          this.buildingList = area[0].buildings
        }
      } else {
        this.insertAddressInformationForm.patchValue({ area1: null });
      }
      
      if(dataList){
        let build:any
        build = area[0].buildings.filter(res=>res.id == dataList.building)
        if(build.length == 1) {
          this.insertAddressInformationForm.patchValue({ building1: build[0].id });
        }
      }
      else{
        this.insertAddressInformationForm.patchValue({ building1: null});
      }
    }
  }

  getPincodeAllAndSetValueInPincode2(dataList?:any) {
      if (dataList) {
        let area:any
        if (dataList) {
          area = this.area2AllList.filter(res=>res.id == dataList.area)
          if(area.length == 1) {
            this.insertAddressInformationForm.patchValue({ area2: area[0].id });
            this.buildingList2 = area[0].buildings
          }
        } else {
          this.insertAddressInformationForm.patchValue({ area2: null });
        }
        
        if(dataList){
          let build:any
          build = area[0].buildings.filter(res=>res.id == dataList.building)
          if(build.length == 1) {
            this.insertAddressInformationForm.patchValue({ building2: build[0].id });
          }
        }else{
          this.insertAddressInformationForm.patchValue({ building2: null });
        }
      }
  }

  setPresentAddressValueInFormPermanentAddressDetails(data) {
    let formData = {
      Country1: data.Country,
      State1: data.State,
      City1: data.City,
      District1:data.District,
      Taluka1:data.Taluka,
      Constituency1:data.Constituency,
      block1:data.block,
      address1: data.address,
      landmark1: data.landmark,
      area1: data.area,
      pincode1: data.pincode,
      building1:data.building
    };
    this.insertAddressInformationForm.patchValue(formData);
    this.default_pincode1_value  = data.temppincode;
    setTimeout(() => {
      this.getPincodeAllAndSetValueInPincode1(data); 
    }, 300);
    
    // this.getStateListByCountryIdAndSetState(formData.country1,'state1', formData.state1);
    // this.getCityListByStateIdAndSetCity(formData.state1,'city1',formData.city1);
  }

  setPresentAddressValueInFormPaymentAddressDetails(data) {
    let formData = {
      Country2: data.Country,
      State2: data.State,
      City2: data.City,
      District2:data.District,
      Taluka2:data.Taluka,
      Constituency2:data.Constituency,
      block2:data.block,
      address2: data.address,
      landmark2: data.landmark,
      area2: data.area,
      pincode2: data.pincode,
    };
    this.insertAddressInformationForm.patchValue(formData);
    this.default_pincode2_value  = data.temppincode;
    setTimeout(() => {
      this.getPincodeAllAndSetValueInPincode2(data); 
    }, 300);
    // this.getStateListByCountryIdAndSetState(formData.country2,'state2', formData.state2);
    // this.getCityListByStateIdAndSetCity(formData.state2,'city2',formData.city2);
  }

  getCountryList() {
    this.countryService.getCountryList().subscribe((result) => {
      this.countryList = result.content;
      this.country2List = result.content;
    });
  }

  // onCountryChange($event) {
  //   if ($event) {
  //     this.getStateListByCountryId($event.country_id, 'country1');
  //   } else {
  //     this.stateList = [];
  //     this.insertAddressInformationForm.get('country1').patchValue([]);
  //   }
  // }
  // onCountry2Change($event) {
  //   if ($event) {
  //     this.getStateListByCountryId($event.country_id, 'country2');
  //   } else {
  //     this.state2List = [];
  //     this.insertAddressInformationForm.get('country2').patchValue([]);
  //   }
  // }
  // onStateChange($event) {
  //   if ($event) {
  //     this.getCityListByStateId($event.state_id, 'city1');
  //   } else {
  //     this.cityList = [];
  //     this.insertAddressInformationForm.get('city1').patchValue([]);
  //   }
  // }
  // onState2Change($event) {
  //   if ($event) {
  //     this.getCityListByStateId($event.state_id, 'city2');
  //   } else {
  //     this.city2List = [];
  //     this.insertAddressInformationForm.get('state2').patchValue([]);
  //   }
  // }

  getArea1All() {
    this.addSubscriberDetailsService.getAreaAll().subscribe(result => {
      this.area1AllList = result.dataList;
    });
  }

  onArea1Change(el) {
    ////console.log(el);
  }

  getPincode1All() {
    this.addSubscriberDetailsService.getPincodeAll().subscribe(result => {
      this.pincode1AllList = result.dataList;
    });
  }

  getArea2All() {
    this.addSubscriberDetailsService.getAreaAll().subscribe(result => {
      this.area2AllList = result.dataList;
    });
  }

  onArea2Change(el) {
    ////console.log(el);
  }

  getPincode2All() {
    this.addSubscriberDetailsService.getPincodeAll().subscribe(result => {
      this.pincode2AllList = result.dataList;
    });
  }

  onPincode1Change(elItem) {
    this.insertAddressInformationForm.get('area1').reset();
    this.insertAddressInformationForm.get('building1').reset();
    this.buildingList2 = [];
    let el;
    let call_from_autocomplete;
    if (_.isArray(elItem.event) == true) {
      el = elItem.event[0];
    } else {
      el = elItem.event;
      call_from_autocomplete = elItem.call_from_autocomplete;
    }
    if (el && el.name) {
      this.setFocusOnFirstElement('area',300)
      this.insertAddressInformationForm.controls.pincode1.setValue(el.addressDetails.pincodeId);
      this.insertAddressInformationForm.patchValue({ Country1: el.addressDetails.country});
      this.insertAddressInformationForm.patchValue({ State1: el.addressDetails.state});
      this.insertAddressInformationForm.patchValue({ City1: el.addressDetails.city});
      this.insertAddressInformationForm.patchValue({ District1: el.addressDetails.district});
      this.insertAddressInformationForm.patchValue({ Taluka1: el.addressDetails.taluka});
      this.insertAddressInformationForm.patchValue({ Constituency1: el.addressDetails.constituency});
      this.insertAddressInformationForm.patchValue({ stateId1: el.addressDetails.stateId});
      this.insertAddressInformationForm.patchValue({ countryId1: el.addressDetails.countryId});
      this.insertAddressInformationForm.patchValue({ cityId1: el.addressDetails.cityId});
      this.insertAddressInformationForm.patchValue({ districtId1: el.addressDetails.districtId});
      this.insertAddressInformationForm.patchValue({ talukaId1: el.addressDetails.talukaId});
      this.insertAddressInformationForm.patchValue({ constituencyId1: el.addressDetails.constituencyId});
      this.addSubscriberDetailsService.getDetailsByPincode(el.parentId).subscribe(result => {
        this.area1AllList = result.dataList;
        ////console.log(result);
        let data = result.data;
        // if (data) {
        //   if (data.country && data.country.id) {
        //     this.insertAddressInformationForm.patchValue({ country1: data.country.id });
        //   }
        //   if (data.state && data.state.id) {
        //     this.getStateListByCountryIdAndSetState(data.country.id, 'state1', data.state.id);
        //   }
        //   if (data.city && data.city.id) {
        //     this.getCityListByStateIdAndSetCity(data.state.id, 'city1', data.city.id);
        //   }
        //   if (data.areaList) {
        //     this.area1AllList = data.areaList;
        //     if (data.areaList.length === 1) {
        //       let area = data.areaList[0];
        //       this.insertAddressInformationForm.patchValue({ area1: area.id });
        //     } else {
        //       this.insertAddressInformationForm.patchValue({ area1: null });
        //     }
        //   }
        // }
      });
    }
    else if(el == null){
      this.insertAddressInformationForm.reset();
      this.area1AllList = [];
      this.buildingList = [];
    }
  }

  onPincode2Change(elItem) {
    this.insertAddressInformationForm.get('area2').reset();
    this.insertAddressInformationForm.get('building2').reset();
    this.buildingList2 = [];
    let el;
    let call_from_autocomplete;
    if (_.isArray(elItem.event) == true) {
      el = elItem.event[0];
    } else {
      el = elItem.event;
      call_from_autocomplete = elItem.call_from_autocomplete;
    }
    if (el && el.name) {
      this.insertAddressInformationForm.controls.pincode2.setValue(el.addressDetails.pincodeId);
      this.insertAddressInformationForm.patchValue({ Country2: el.addressDetails.country});
      this.insertAddressInformationForm.patchValue({ State2: el.addressDetails.state});
      this.insertAddressInformationForm.patchValue({ City2: el.addressDetails.city});
      this.insertAddressInformationForm.patchValue({ District2: el.addressDetails.district});
      this.insertAddressInformationForm.patchValue({ Taluka2: el.addressDetails.taluka});
      this.insertAddressInformationForm.patchValue({ Constituency2: el.addressDetails.constituency});
      this.insertAddressInformationForm.patchValue({ countryId2: el.addressDetails.countryId});
      this.insertAddressInformationForm.patchValue({ stateId2: el.addressDetails.stateId});
      this.insertAddressInformationForm.patchValue({ cityId2: el.addressDetails.cityId});
      this.insertAddressInformationForm.patchValue({ districtId2: el.addressDetails.districtId});
      this.insertAddressInformationForm.patchValue({ talukaId2: el.addressDetails.talukaId});
      this.insertAddressInformationForm.patchValue({ constituencyId2: el.addressDetails.constituencyId});
      this.addSubscriberDetailsService.getDetailsByPincode(el.parentId).subscribe(result => {
        this.area2AllList = result.dataList;
        ////console.log(result);
        let data = result.data;
      });
    }
    else if(el == null){
      this.insertAddressInformationForm.reset();
      this.area2AllList = [];
      this.buildingList2 = [];
    }
  }

  // onCountryClear() {
  //   this.stateList = [];
  //   this.insertAddressInformationForm.get('state1').patchValue([]);
  // }
  // onCountry2Clear() {
  //   this.stateList = [];
  //   this.insertAddressInformationForm.get('state2').patchValue([]);
  // }
  // getStateListByCountryId(country_id: any, country: any) {
  //   this.stateService.getStateListByCountryId(country_id).subscribe((result) => {
  //     if (country == 'country2') {
  //       this.state2List = result.content;
  //     } else {
  //       this.stateList = result.content;
  //     }
  //   });
  // }

  // getStateListByCountryIdAndSetState(country_id: any, statename: any, default_state_id: any) {
  //   this.stateService.getStateListByCountryId(country_id).subscribe((result) => {
  //     if (statename == 'state2') {
  //       this.state2List = result.content;
  //       this.insertAddressInformationForm.patchValue({ state2: default_state_id });
  //     } else {
  //       this.stateList = result.content;
  //       this.insertAddressInformationForm.patchValue({ state1: default_state_id });
  //     }
  //   });
  // }

  // getCityListByStateIdAndSetCity(state_id: any, city: any, default_city_id: any) {
  //   this.cityService.getCityListByStateId(state_id).subscribe(
  //     result => {
  //       if (city == 'city2') {
  //         this.city2List = result.content;
  //         this.insertAddressInformationForm.patchValue({ city2: default_city_id });
  //       } else {
  //         this.cityList = result.content;
  //         this.insertAddressInformationForm.patchValue({ city1: default_city_id });
  //       }
  //     });
  // }

  // getCityListByStateId(state_id, city) {
  //   this.cityService.getCityListByStateId(state_id).subscribe(
  //     result => {
  //       if (city == 'city2') {
  //         this.city2List = result.content;
  //       } else {
  //         this.cityList = result.content;
  //       }
  //     });
  // }

  onChange: any = () => { };
  onTouched: any = () => { };
}

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, Optional, Input, AfterContentInit } from '@angular/core';
import { CasesModel } from '../model/cases-model';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../../services/ibase-service';
import { CasesService } from '../service/cases.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { ToastrService } from 'ngx-toastr';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { SubscriberService } from '../../../masters/subscriber/subscriber.service';
import { CaseReasonService } from '../../case-reason/service/case-reason.service';
import { StaffService } from '../../../hr/staff/staff.service';
import { CaseReasonModel } from '../../case-reason/model/case-reason-model';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Updatelistmodel } from '../model/updatelistmodel';
import * as alertify from 'alertifyjs';
import { HostListener } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { throwIfEmpty } from 'rxjs/operators';
import { SearchAutocompleteComponent } from '../../../../components/search-autocomplete/search-autocomplete.component';
import { id } from '@swimlane/ngx-datatable/release/utils';
import { LoginService } from '../../../login/login.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSharingServiceService } from '../../../../../app/pages/data-sharing-service.service';
import { InventoryAllocationComponent } from '../../../inventory/inventory/inventory-allocation/inventory-allocation.component';
import { AppConfig } from '../../../../app.config';
import { caseMachineData, proprertyDocList } from '../../../masters/model/machineData';
import { SubscriberDetailsService } from '../../../masters/subscriber-details/subscriber-details.service';

export interface DialogPassData {
  id: number;
}

@Component({
  selector: 'app-cases-add-update',
  templateUrl: './cases-add-update.component.html',
  styleUrls: ['./cases-add-update.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})

export class CasesAddUpdateComponent extends AbstractBaseAddUpdateComponent<CasesModel> implements OnInit, ViewChild, AfterContentInit {
  descendants: boolean;
  first: boolean;
  read: any;
  isViewQuery: boolean;
  custId: any;
  selector: any;
  static: boolean;
  @ViewChild(SearchAutocompleteComponent, { static: false }) autoCompleteRef: SearchAutocompleteComponent;
  @ViewChild(InventoryAllocationComponent, { static: false }) inventoryRef: InventoryAllocationComponent;

  @ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;
  public entity: CasesModel = new CasesModel();
  public entity_id: any = null;
  @Input() editviewmode:any;
  @Input() editviewid:any;
  form: FormGroup;
  formupdate: FormGroup;
  url: string;
  error_customer: boolean;
  error_assignto: boolean;
  public updateList: Updatelistmodel;
  public remark: string;
  public subscriberList: string;
  casesData: any;
  default_value: any
  autoField: any = ['name', 'username', 'phone', 'email']
  isdisabled: boolean = false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  disableflag: boolean = false;
  public leaselinesubscriberList: string;
  public customer: any;
  public leasecustomer: any;
  public staffAsId: any
  public staffList: string;
  public caseReason: any;
  public caseReasonById: CaseReasonModel;
  public caseType: any;
  public bucketType: any;
  public collapse = false;
  public accordinid = '';
  public caseOrigin: any;
  public caseStatus: any;
  public caseFor: any;
  public priority: any;
  public resolution: any;
  public error_remark: boolean;
  public error_resolution: boolean;
  public onlymessage: boolean;
  public connectivity: boolean;
  doc: any
  //custflag:boolean = false; 
  public loginService: LoginService;
  field: string[];
  followTime: Date;
  check: any;
  datePipeString: string;
  timePipeString: string;
  caseofcustomer: any;
  dataSource: CasesModel;
  state: string = 'default';
  circuit: any;
  dynamicdata: any;
  Data: { field_label: string; field_value: any; }[];
  serviceAreaList:any;
  teamTypeList: any =[];
  TeamList:any=[];
  serviceAreaId:any;
  algorithmType:any;
  allocationList:any;
  algoTypeList:any;
  enableflag:boolean=false;
  serviceIdPass:any;
  teamName:any;
  staffListArray:any=[];
  showFlag:boolean;
  caseInventoryVisible: boolean = false;
  ngbRef: NgbModalRef;
  isSelectedAll = false;
  paymentList = [{
    part:'QC Param 1',
    epVal:'100-200',
    iVal:'123',
    verified:true
  },{
    part:'QC Param 2',
    epVal:'100-200',
    iVal:'157',
    verified:false
  },{
    part:'QC Param 3',
    epVal:'100-200',
    iVal:'178',
    verified:true
  }]
  paymentList2 = [{
    part:'QC Param 4',
    epVal:'100-200',
    iVal:'123',
    verified:false
  },{
    part:'QC Param 5',
    epVal:'100-200',
    iVal:'157',
    verified:false
  }]
  activityList = [];
  consumptionList = [];
  activityList2 = [{
    actvType:'Activity-1',
    desc:'Description for activity - 1'
  },{
    actvType:'Activity-2',
    desc:'Description for activity - 2'
  }]
  attachmentList = []
  signatureList = [{
    actvType:'Signature',
    desc:'Signature'
  }]
  documentList = [{
    actvType:'letter-head.pdf',
    desc:'letter-head'
  }] 
  docTypeList = proprertyDocList.filter(res => res.docSubType != '-');
  docList = [
    {
       caseId: 85144,
       documents: [
          {
             documentType: 'ISO 9001:2015',
             documentcat: 'General',
             document: 'iso.pdf'
          },
          {
             documentType: 'Machine Guide',
             documentcat: 'Machine Documents (SRL-JAN-2023-48)',
             document: 'machine-guide.pdf'
          },
       ]
    },
    {
       caseId: 85145,
       documents: [
          {
             documentType: 'Machine Compliences - 1',
             documentcat: 'Machine Compliences',
             document: 'machine-cpl-1.pdf'
          },
          {
             documentType: 'ISO 9001:2015',
             documentcat: 'General',
             document: 'iso.pdf'
          },
          {
             documentType: '-',
             documentcat: 'Others',
             document: 'company-desc.pdf'
          }
       ]
    },
    {
       caseId: 85146,
       documents: [
          {
             documentType: 'Installation Steps',
             documentcat: 'Machine Documents (SRL-JAN-2023-1)',
             document: 'installation-steps.pdf'
          }
       ]
    }
 ]
 documents =  [
  {
     documentType: 'General',
     document: 'iso.pdf'
  },
  {
     documentType: 'Machine Documents (SRL-JAN-2023-1)',
     document: 'machine-guide.pdf'
  },
  {
     documentType: 'Machine Documents (SRL-JAN-2023-48)',
     document: 'letter-head.pdf'
  },
  {
    documentType: 'Machine Complience',
    document: 'machine-cpl-1.pdf'
 },
 {
  documentType: 'Others',
  document: 'company-desc.pdf'
},
]
document2 =  [
  {
     documentType: 'General',
     document: 'ISO 9001:2015',
     doc: 'iso.pdf'
  },
  {
    documentType: 'General',
    document: 'Company Letter Head',
    doc: 'letter-head.pdf'
 },
  {
     documentType: 'Machine Documents (SRL-JAN-2023-1)',
     document: 'Machine Guide',
     doc: 'machine-guide.pdf'
  },
  {
     documentType: 'Machine Documents (SRL-JAN-2023-48)',
     document: 'Installation Steps',
     doc: 'installation-step.pdf'
  },
  {
    documentType: 'Machine Complience',
    document: 'Machine Complience - 1',
    doc: 'machine-cpl-1.pdf'
 },
 {
  documentType: 'Machine Complience',
  document: 'Machine Complience - 2',
  doc: 'machine-cpl-2.pdf'
},
 {
  documentType: 'Others',
  document: '-',
  doc: 'company-desc.pdf'
},
]
 filtereddocList: any;
  config;
  newform: FormGroup = new FormGroup({
    actType: new FormControl(null),
    desc: new FormControl(null)
  })
  finalList: any;
  doc1: any;
  doc2: any;
  caseMachineList = caseMachineData;
  machineListByCustomer = [];
  constructor(
    public CasesService: CasesService,
    private ngbModalService: NgbModal,
    public CommonListService: CommonListServicesService,
    public caseReasonService: CaseReasonService,
    public staffService: StaffService,
    public Subscriber: SubscriberService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private genericDataSharingService: DataSharingServiceService,
    private datePipe: DatePipe,
    loginService: LoginService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CasesAddUpdateComponent>,
    appConfig: AppConfig,
    private subscriberDetailsService: SubscriberDetailsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData
  ) {
    super(toastrService, router);
    this.loginService = loginService;
    this.config = appConfig.getConfig();
  }
  Internalcase:boolean
  ngOnInit() {
    let URL  = this.router.url.includes('add-casesInternal')
    
    if(URL){
      this.Internalcase = true
      this.disableflag = true
    }else{
      this.Internalcase = false
      this.disableflag = false
    }
    if(!this.editviewmode){

      let id = this.activeRoute.snapshot.paramMap.get('id');
      if (id)  {
        this.isUpdateComponent = true;
        this.getById(Number(id));
        this.filtereddocList = this.docList.filter((res)=> res.caseId == Number(id))
        this.getActivityByCaseId(id)
        this.getAttachmentList(id);
      }
    }else{
      console.log(this.editviewid)
      this.isUpdateComponent = true;
      this.getById(Number(this.editviewid));
      this.filtereddocList = this.docList.filter((res)=> res.caseId == Number(this.editviewid))
      this.getActivityByCaseId(this.editviewid);
      this.getAttachmentList(this.editviewid);
    }
   
    this.init();
    
    //this.getstaff();
    this.getcaseReason();
    this.commonList();
    this.getresolution();
    if(!this.isUpdateComponent){
      this.defaultSelectionAssignment();
    }
  }

  ngAfterContentInit(): void {
  }

  getService(): IBaseService<CasesModel> {
    return this.CasesService;
  }

  saveActivity(){
    let data = {
      actvType : null,
      desc: null
    }
    data.actvType =  this.newform.get('actType').value;
    data.desc = this.newform.get('desc').value;
    if(data.actvType != null && data.desc != null ){
      this.activityList.push(data);
    }
    this.ngbRef.close();
  }

  changecasefor() {
    this.Data = new Array<any>()
    this.autoCompleteRef.reset();
    this.autoCompleteRef.autocomplete.clearAllText;
    this.autoCompleteRef.dataList = [];
    this.algorithmType=[];
    if(this.isUpdateComponent){
      this.formupdate.get('allocationType').reset();
      this.formupdate.get('allocationType').updateValueAndValidity();
    }{
      this.form.get('allocationType').reset();
      this.form.get('allocationType').updateValueAndValidity();
    }
    
    if (this.entity.caseFor == 'Lease Line') {
      this.disableflag = false;
      this.getleaselineSubscriber();
      this.autoField = ['circuitId', 'name', 'username', 'phone', 'email']
      this.form.get('circuit').setValidators([Validators.required]);
      this.form.get('circuit').updateValueAndValidity();
      this.error_assignto = false;
      this.entity.customersId = null;


      /** New Validation */
      this.form.get('serviceAreaId').setValidators([Validators.required]);
      this.form.get('serviceAreaId').updateValueAndValidity();
      this.form.get('teamType').setValidators([Validators.required]);
      this.form.get('teamType').updateValueAndValidity();
      this.form.get('allocationType').setValidators([Validators.required]);
      this.form.get('allocationType').updateValueAndValidity();
      this.form.get('algorithmType').setValidators([Validators.required]);
      this.form.get('algorithmType').updateValueAndValidity();

      /**New Validation */
      if(this.enableflag){
        this.form.get('algorithmType').setValidators([Validators.required]);
        this.form.get('algorithmType').updateValueAndValidity();
        this.form.get('teamId').clearValidators();
        this.form.get('teamId').updateValueAndValidity();
        this.form.get('currentAssigneeId').clearValidators();
        this.form.get('currentAssigneeId').updateValueAndValidity();
        this.form.get('staffId').clearValidators();
        this.form.get('staffId').updateValueAndValidity();
      } else{
        this.form.get('algorithmType').clearValidators();
        this.form.get('algorithmType').updateValueAndValidity();
        this.form.get('teamId').setValidators([Validators.required]);
        this.form.get('teamId').updateValueAndValidity();
        this.form.get('staffId').setValidators([Validators.required]);
        this.form.get('staffId').updateValueAndValidity();
      }


     

      // this.form.get('teamId').setValidators([Validators.required]);
      // this.form.get('teamId').updateValueAndValidity();
      // this.form.get('currentAssigneeId').setValidators([Validators.required]);
      // this.form.get('currentAssigneeId').updateValueAndValidity();
    

    } else if (this.entity.caseFor == 'Customer') {
      this.disableflag = false;
      this.getSubscriber();
      this.autoField = ['name', 'username', 'phone', 'email']
      this.form.get('circuit').clearValidators();
      this.form.get('circuit').updateValueAndValidity();
      this.entity.leaseLineId = null;
      this.form.get('circuit').reset();
      this.entity.circuitId = null;
      this.entity.propertyId = null;
      this.error_assignto = false;

      this.form.get('serviceAreaId').clearValidators();
      this.form.get('serviceAreaId').updateValueAndValidity();

      this.form.get('teamType').clearValidators();
      this.form.get('teamType').updateValueAndValidity();
      this.form.get('allocationType').clearValidators();
      this.form.get('allocationType').updateValueAndValidity();
      this.form.get('algorithmType').clearValidators();
      this.form.get('algorithmType').updateValueAndValidity();
      this.form.get('teamId').clearValidators();
      this.form.get('teamId').updateValueAndValidity();
      this.form.get('staffId').clearValidators();
      this.form.get('staffId').updateValueAndValidity();

    } else if (this.entity.caseFor == 'Ring') {
      this.disableflag = true;
      this.error_customer = false;
      this.error_assignto = false;
      this.form.get('circuit').clearValidators();
      this.form.get('circuit').updateValueAndValidity();
      this.form.get('circuit').reset();
      this.entity.customersId = null;
      this.entity.leaseLineId = null;
      this.entity.propertyId = null;
      this.form.get('serviceAreaId').clearValidators();
      this.form.get('serviceAreaId').updateValueAndValidity();

      this.form.get('teamType').clearValidators();
      this.form.get('teamType').updateValueAndValidity();
      this.form.get('allocationType').clearValidators();
      this.form.get('allocationType').updateValueAndValidity();
      this.form.get('algorithmType').clearValidators();
      this.form.get('algorithmType').updateValueAndValidity();
      this.form.get('teamId').clearValidators();
      this.form.get('teamId').updateValueAndValidity();
      this.form.get('staffId').clearValidators();
      this.form.get('staffId').updateValueAndValidity();
    } else if (this.entity.caseFor == 'Internal') {
      this.disableflag = true;
      this.error_customer = false;
      this.error_assignto = false;
      this.form.get('circuit').clearValidators();
      this.form.get('circuit').updateValueAndValidity();
      this.form.get('circuit').reset();
      this.entity.customersId = null;
      this.entity.leaseLineId = null;
      this.entity.propertyId = null;
      this.form.get('serviceAreaId').clearValidators();
      this.form.get('serviceAreaId').updateValueAndValidity();

      this.form.get('teamType').clearValidators();
      this.form.get('teamType').updateValueAndValidity();
      this.form.get('allocationType').clearValidators();
      this.form.get('allocationType').updateValueAndValidity();
      this.form.get('algorithmType').clearValidators();
      this.form.get('algorithmType').updateValueAndValidity();
      this.form.get('teamId').clearValidators();
      this.form.get('teamId').updateValueAndValidity();
      this.form.get('staffId').clearValidators();
      this.form.get('staffId').updateValueAndValidity();
    }
  }
  rotate() {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
  }

  getSubscriberByID(id) {
    if (id) {
      this.Subscriber.getSubscriberById(id).subscribe((res) => {
        this.customer = res.data;
      });
    }

  }

  refreshPage(data){
    this.updateFormSubmit(data)
  }

  onAddClick(qcDet){
    this.newform.reset();
    this.ngbRef = this.ngbModalService.open(qcDet,{size:'lg'});
  }

  closeModal(){
    this.ngbRef.close();
  }

  getcaseofcustomer() {

    let obj =
    {
      id: this.entity.caseFor == 'Lease Line' ? this.entity.leaseLineId : this.entity.customersId,
      name: this.entity.caseFor
    }
    this.CasesService.getcaseofcustomer(obj).subscribe((res) => {
      if (res) {
        this.valuepraparation(res.dataList);
      }

      if (res && res.dataList) {
        this.caseofcustomer = res.dataList.filter(res => res.caseId !== this.entity.caseId);
      }
    });
  }

  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }

  setData(data: CasesModel) {

    this.entity = new CasesModel();

    this.entity = data;
    if(this.entity && this.entity.caseNumber){
      this.caseInventoryVisible = true;
    }
    console.log('data',data)
    if (this.isUpdateComponent) {
      this.getcaseReason();
      if (this.entity.customersId)
        this.getSubscriberByID(this.entity.customersId)
      if (this.entity && this.entity['dynamicParams']) {
        let data = this.entity['dynamicParams'];
        this.casesData = Object.keys(data).map(field => {
          let value = data[field];
          return { field_label: field, field_value: value }
        })
      }

      this.getcaseofcustomer();
      this.getstaffByID(this.entity.currentAssigneeId);
      ////console.log("case Date ::", this.entity.caseStartedOn)
      let dateNew = new Date(this.entity.caseStartedOn);
      this.entity.calculatedD = this.calculateDiff(dateNew, 'caseDay');
      if (this.entity.calculatedD === 0) {
        this.entity.calculatedTimeH = this.calculateDiff(dateNew, 'caseHours');
      }
      if (this.entity.calculatedTimeH === 0) {
        this.entity.calculatedTimeM = this.calculateDiff(dateNew, 'caseMinutes');
      }
      if (this.entity.calculatedTimeM === 0) {
        this.entity.calculatedTimeS = this.calculateDiff(dateNew, 'caseSecond');
      }
      // if (this.entity.caseReasonId)
      //   this.getreasonById(this.entity.caseReasonId);
      this.getupdateListData();
      if (this.entity && this.entity.caseFor && this.entity.caseFor != 'Lease Line') {
        this.onrefreshclick(this.entity.customersId);
      }

    }

    //this.getAttachmentList();
    //this.attachmentList = data.documents

  }

  getData(): CasesModel {
    return this.entity;
  }
  public getleaselineSubscriber() {
    this.leaselinesubscriberList = 'leasedlinesubscriber/searchLeaseCustomer?s=';
  }
  public getSubscriber() {
    this.subscriberList = 'subscriber/searchNew?s=';
  }
  public setSubscriber(eventcust) {

    if (eventcust.event != null) {
      let event = eventcust.event;
      this.custId = event.custid;
      if (this.entity.caseFor == 'Lease Line') {
        this.entity.leaseLineId = event.custid;
        this.getCircuit();
        this.entity.circuitId = event.circuitPrimaryId;
        this.form.get('circuit').disable();
      }
      else if (this.entity.caseFor == 'Customer') {
        this.entity.customersId = event.custid;
        this.getResourcesByClientId(this.entity.customersId);
      }
      this.error_customer = false;
      //this.autoCompleteRef.check(false);
      this.getLeaseOrNormalCustomerBasicDetails();

    } else {
      this.autoCompleteRef.autocomplete.clearAllText;
      this.autoCompleteRef.dataList = [];
      this.error_customer = true;
      this.entity.circuitId = null;
      this.entity.customersId = null
      this.Data = null;
      this.machineListByCustomer = [];
      this.entity.machineId = undefined; 
      //this.autoCompleteRef.check(true);
    }

  }

  getResourcesByClientId(customerId){
    this.subscriberDetailsService.getResourceByClientId(customerId).subscribe((res) => {
      this.machineListByCustomer = res.dataList;
    })
  }

  onSelectMachine(event){
    this.entity.machineName = event.name;
    this.entity.serialNo = event.serialNo;
    this.entity.warrantyStartDate = event.warrantyStartDate;
    this.entity.warrantyEndDate = event.warrantyEndDate;
    this.entity.allocationStartDate = event.allocationStartDate;
    this.entity.allocationEndDate = event.allocationEndDate;
  }


  public getcaseReason() {
    this.caseReasonService.getAllcaseReason().subscribe((res) => {
      this.caseReason = res.dataList;
      if(this.entity && this.entity.caseReasonId){
        console.log(this.entity.caseReasonId)
        debugger
        let selectedReason = this.caseReason.find((ls) => ls.reasonId == this.entity.caseReasonId);
        this.entity.slaName = selectedReason.slaName;
      }
    });
  }
  public getresolution() {
    this.CasesService.getresolutionreason().subscribe((res) => {
      this.resolution = res.dataList;
    });
  }
  // getreasonById(id) {
  //   this.caseReasonService.getcaseReasonByID(id).subscribe((res) => {
  //     this.caseReasonById = res.data;
  //   });
  // }
  getstaffByID(id) {
    if (id) {
      this.staffService.getStaffById(id).subscribe((res) => {
        this.staffAsId = res.Staff;
        return this.staffAsId.firstname;
      });
    }


  }
  settime(event) {
    ////console.log(event.value)
    this.entity.nextFollowupTime = event.value
  }
  // public getstaff() {
  //   this.staffList = 'staff/search?s=';

  // }
  // public setstaff(eventstaff) {

  //   let event = eventstaff.event
  //   if (event === null) {
  //     if (this.entity.caseFor == 'Lease Line') {
  //       this.error_assignto = true;
  //     }
  //     this.entity.currentAssigneeId = null;
  //     this.entity.caseStatus = "Unassigned"
  //     if (this.isUpdateComponent) {
  //       this.formupdate.get('casestatus_update').setValue(this.entity.caseStatus);
  //     }
  //   } else {
  //     this.error_assignto = false;
  //   }
  //   this.entity.currentAssigneeId = event.id;
  //   if (this.entity.caseStatus = "Unassigned") {
  //     this.entity.caseStatus = 'In Progress';
  //     if (this.isUpdateComponent) {
  //       this.formupdate.get('casestatus_update').setValue(this.entity.caseStatus);
  //     }
  //   }

  //   if (this.isUpdateComponent) {
  //     this.updateList.assignee = event.id;
  //   }
  // }

  setStatus(){
    this.entity.caseStatus = 'In Progress';
    if (this.isUpdateComponent) {
      this.formupdate.get('casestatus_update').setValue(this.entity.caseStatus);
    }
  }

setServiceToTeam(el){
  this.serviceIdPass=el;

  // if(this.isUpdateComponent){
  //   this.formupdate.get('allocationType').reset();
  //   this.formupdate.get('teamId').reset();
  //   this.formupdate.get('algorithmType').reset();
  // }
  // else{
    this.form.get('allocationType').reset();
    this.form.get('teamId').reset();
    this.form.get('algorithmType').reset();
  //}
  this.teamTypeList=null;
  this.algoTypeList = null;
  this.allocationList = null;
  this.entity.teamType=null;
  this.entity.algorithmType=null;
  this.entity.teamId=null;
  this.entity.currentAssigneeId=null;

  this.getTeamType(el);
  }

getTeamType(id:any){
   this.CommonListService.getTeamTypeList(id).subscribe((res) => {
    this.teamTypeList = res.dataList;
    });
    this.getallocationType();
 }

getallocationType(){
 this.CommonListService.allocationType().subscribe((res) => {
 this.allocationList = res.dataList;
 });
}

getalgoType(){
 this.CommonListService.algorithmType().subscribe((res) => {
     this.algoTypeList = null;
    //  if(this.isUpdateComponent){
    //   if(this.entity.longitude != null && this.entity.latitude != null){
       
    //     this.algoTypeList = res.dataList;
    //  }else{
       
    //    this.algoTypeList = []
    //   this.algoTypeList =  res.dataList.filter(res=>res.text=="Least Allocation")
        
    //  }
    //  }else{
      //  if(this.dynamicdata){
      //   
      //   if((this.dynamicdata['Latitude'] !== '-')||(this.dynamicdata['Longitude'] !== '-')){
      //     this.algoTypeList = res.dataList; 
      //   } 
      //   else{
      //     this.algoTypeList = []
      //     this.algoTypeList =  res.dataList.filter(res=>res.text=="Least Allocation")
            
      //   }
 //}

        if((this.Data && this.Data.length > 0 && this.Data[7].field_label === 'Latitude') && (this.Data[7].field_value !== '-')){
          this.algoTypeList = res.dataList; 
        } 
        else{
          this.algoTypeList = []
          this.algoTypeList =  res.dataList.filter(res=>res.text=="Least Allocation")
        }  
   });
 }
 
setTeamAPiCall(e){
  
  if(e){
    this.teamName= e;
    this.TeamList = null
    this.staffList = null
    this.allocationList = null
    this.algoTypeList = null
    // if(this.isUpdateComponent){
    //   this.formupdate.get('allocationType').reset()
    //   this.formupdate.get('algorithmType').reset()
    //   this.formupdate.get('teamId').reset()
    //   this.formupdate.get('staffId').reset()
    // }else{
      this.form.get('allocationType').reset()
      this.form.get('algorithmType').reset()
      this.form.get('teamId').reset()
      this.form.get('staffId').reset()
    //}
 
    this.getallocationType()
  }

}

allocationType(e){
  
  if(e =='Auto'){

    this.enableflag=true;
    // this.form.get('algorithmType').reset();
    // this.form.get('algorithmType').updateValueAndValidity(); 
    this.getalgoType();
    delete this.entity.teamId
    delete this.entity.currentAssigneeId
    
// if(this.isUpdateComponent){
 
//   this.form.get('algorithmType').setValidators([Validators.required])
//     this.formupdate.get('algorithmType').updateValueAndValidity(); 
//     this.formupdate.get('teamId').clearValidators();
//     this.formupdate.get('teamId').updateValueAndValidity();
//     this.formupdate.get('staffId').clearValidators();
//     this.formupdate.get('staffId').updateValueAndValidity();
// }
// else{
  if(this.entity.caseFor == 'Lease Line'){
    this.form.get('algorithmType').setValidators([Validators.required])
    this.form.get('algorithmType').updateValueAndValidity();
  }else{
    this.form.get('algorithmType').clearValidators()
    this.form.get('algorithmType').updateValueAndValidity(); 
  }
//}
  }else{
    this.form.get('teamId').reset();
    this.form.get('teamId').updateValueAndValidity();
    this.form.get('staffId').reset();
    this.form.get('staffId').updateValueAndValidity();
    this.enableflag=false;
    delete this.entity.algorithmType
    this.getalgoType();//*refresh
    this.getTeamList();
// if(this.isUpdateComponent){
//   this.formupdate.get('algorithmType').clearValidators();
//   this.formupdate.get('algorithmType').updateValueAndValidity();
//   this.formupdate.get('teamId').setValidators([Validators.required]);
//   this.formupdate.get('teamId').updateValueAndValidity();
//   this.formupdate.get('staffId').setValidators([Validators.required]);
//   this.formupdate.get('staffId').updateValueAndValidity();
// }else{
  this.form.get('algorithmType').clearValidators();
  this.form.get('algorithmType').updateValueAndValidity();
  if(this.entity.caseFor == 'Lease Line'){
    this.form.get('teamId').setValidators([Validators.required]);
    this.form.get('teamId').updateValueAndValidity();
    this.form.get('staffId').setValidators([Validators.required]);
    this.form.get('staffId').updateValueAndValidity();
  }
  else{
    this.form.get('teamId').clearValidators();
    this.form.get('teamId').updateValueAndValidity();
    this.form.get('staffId').clearValidators();
    this.form.get('staffId').updateValueAndValidity();
  }
 

//}
   

  }
}

getTeamList(){
  let obj={
    "serviceAreaIdList":[],
    "teamType": this.teamName
  }
  obj.serviceAreaIdList.push(this.serviceIdPass)
  this.CommonListService.teamListByManual(obj).subscribe((res) => {
    
    this.TeamList = res.dataList;
   });
 }

 selectStaff(val:any){
   
   this.staffListArray=null;
   this.staffListArray=val.teamUserMapping.filter(v=>v.isAssignable===true)
   
  }

  public commonList() {
    
    this.CommonListService.getCommonListcaseFor().subscribe((res) => {
      if(this.Internalcase){
        this.caseFor = res.dataList.filter(res=>res.value === 'Internal');
      }
      else{
        this.caseFor = res.dataList.filter(res=> res.value !== 'Internal')
      }
    });
    this.CommonListService.getCommonListcaseType().subscribe((res) => {
      this.caseType = res.dataList

    });
    this.CommonListService.getCommonListbucketType().subscribe((res) => {
      this.bucketType = res.dataList

    });
    /**New */
    this.CommonListService.getServiceAreabyTeamList().subscribe((res) => {
      this.serviceAreaList = res.dataList
    });

    
    this.CommonListService.algorithmType().subscribe((res) => {
       this.algorithmType = res.dataList
     });
   
    this.CommonListService.getCommonListcaseOrigin().subscribe((res) => {
      this.caseOrigin = res.dataList.filter((res) => res.text != 'Auto Generated')

    });
    this.CommonListService.getCommonListcaseStatus().subscribe((res) => {
      if(!localStorage.getItem('userRoles').includes('79')){
        let temp = res.dataList.filter(el=> el.text != 'Resolved')
        this.caseStatus = temp;
      }else{
        this.caseStatus = res.dataList;
      }
    });
    this.CommonListService.getCommonListpriority().subscribe((res) => {
      this.priority = res.dataList

    });
    // this.CommonListService.getCommonListpriority().subscribe((res) => {
    //   this.priority = res.dataList

    // });


  }

  public getModuleHomeUrl(): string {
    if(this.Internalcase){
      this.url = '/customer-care/casesInternal'
    }else{
      this.url = '/customer-care/cases'
    }
    return this.url;
  }
  backToViewList() {
    if(this.Internalcase){
      this.url = '/customer-care/casesInternal'
    }else{
      this.url = '/customer-care/cases'
    }
    this.router.navigate([this.url]);
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): CasesModel {
    let abc = new CasesModel();
    delete abc.caseReasonId
    return new CasesModel();
  }


  public getFormGroup(): FormGroup {
    //if (!this.isUpdateComponent)
      return this.form;
    // else
    //   return this.formupdate;
  }

  public getId(): number {
    return this.entity.caseId;

  }


  getCircuit() {
    this.form.get('circuit').reset();
    this.CasesService.getcircuitdetails(this.custId).subscribe((res) => {
      ////console.log("circuit",res);
      this.circuit = res.dataList;
      res.dataList.forEach((ls) => {
        this.entity.propertyId = ls.propertyLine;

      });
      //////console.log(this.entity.propertyId);
    });
  }

  get f() {
    //if (!this.isUpdateComponent)
      return this.form.controls;
    // else
    //   return this.formupdate.controls;
  }

  setValidation() {
    // if (this.isUpdateComponent) {
      
    //   this.formupdate = new FormGroup({
    //     case_message_update: new FormControl('', [Validators.required]),
    //     resolution_update: new FormControl(''),
    //     casestatus_update: new FormControl(''),
    //     case_reason_update: new FormControl(''),
    //     case_priority_update: new FormControl(''),
    //     case_nextFollowupDate_update: new FormControl(''),
    //     case_nextFollowupTime_update: new FormControl(''),
    //     case_suspendtilldate_update: new FormControl(''),
    //     bucket_update: new FormControl(null),
    //     title: new FormControl(''),

    //     serviceAreaId:new FormControl(null),
    //     teamType:new FormControl(null),
    //     teamId:new FormControl(null),
    //     staffId:new FormControl(null),
    //     allocationType:new FormControl(null),
    //     algorithmType: new FormControl(null)
    //   });
    // }
    //if (!this.isUpdateComponent) {
      
       this.form = new FormGroup({
        case_title: new FormControl(''),
        altMobile: new FormControl('', Validators.pattern('^[0-9]{10}$')),
        case_for: new FormControl('', [Validators.required]),
        teamId: new FormControl(''),
        currentAssigneeId: new FormControl(''),
        case_for_search: new FormControl(''),
        case_username: new FormControl(''),
        case_name: new FormControl(''),
        case_email: new FormControl(''),
        case_mobile: new FormControl(''),
        case_address: new FormControl(''),
        case_servicearea: new FormControl(''),
        case_olt: new FormControl(''),
        case_slot: new FormControl(''),
        case_port: new FormControl(''),
        case_onuid: new FormControl(''),
        case_type: new FormControl('', [Validators.required]),
        case_casereason: new FormControl('', [Validators.required]),
        case_caseorigin: new FormControl('', [Validators.required]),
        case_priority: new FormControl('', [Validators.required]),
        circuit: new FormControl(''),
        case_assignmentto: new FormControl(''),
        case_remark: new FormControl('', [Validators.required]),
        case_nextFollowupDate: new FormControl(''),
        case_nextFollowupTime: new FormControl(''),
        bucket: new FormControl(null),

        serviceAreaId:new FormControl(''),
        teamType:new FormControl(''),
        typeOfTeam:new FormControl(''),
        staffId:new FormControl(''),
        allocationType:new FormControl(''),
        algorithmType: new FormControl(''),
        machine: new FormControl('', [Validators.required])
        // TeamTypeList
      });
    //}
  }

  calculateDiff(ms, lable: string) {
    ////console.log(ms);


    let currentDate = new Date();
    ms = new Date(ms);
    ////console.log("date::", ms);
    ////console.log("currentDate::", currentDate);
    if (currentDate && ms) {
      ms = currentDate.getTime() - ms.getTime();
      let seconds = Math.floor(ms / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      if (lable === 'caseHours') {
        return hours;
      }
      if (lable === 'caseDay') {
        return days;
      }
      if (lable === 'caseMinutes') {
        return minutes;
      }
      if (lable === 'caseSecond') {
        return seconds;
      }
    }
  }
  numberOnly(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  formSubmit() {
    this.submitted = true;
    let self = this;
    // if (!this.entity.customersId) {
    //   this.autoCompleteRef.check(true);
    // }
    // if (!this.entity.leaseLineId) {
    //   this.autoCompleteRef.check(true);
    // }
    if (this.form.invalid) {
      this.toastrService.error("Please fill all the details!");
    }
    if (this.entity.caseFor == 'Customer') {
      if (!this.entity.customersId) {
        ////console.log("customerId", this.entity.customersId);
        this.error_customer = true;
        this.error_assignto = false;
        return;
      }
    }

    // if (this.entity.currentAssigneeId == null && this.entity.caseFor == 'Lease Line') {
    //   this.error_assignto = true;
    //   return;
    // }
    // else {
    //   this.error_assignto = false;
    // }

    if (this.entity.caseFor == 'Ring') {
      this.entity.customersId = null;
      this.error_customer = false;
      this.error_assignto = false;

    }

    if (this.entity.caseFor == 'Lease Line') {
      if (!this.entity.leaseLineId) {
        ////console.log("customerId", this.entity.leaseLineId);
        this.error_customer = true;
        return;
      }
    }

    if (this.validateForm()) {
      alertify.confirm("Cases", "Are you sure you want to save this record?",
        function () {
          self.removedata();
          self.setcase_nextFollowupDateTime();
          self.onSubmit();
        },
        function () {
        });
    }
  }
  setcase_nextFollowupDateTime() {
    this.entity.nextFollowupDate = this.datePipe.transform(this.datePipeString, 'yyyy-MM-dd');
    this.entity.nextFollowupTime = this.datePipe.transform(this.followTime, 'hh:mm:ss');
  }
  removedata() {
    delete this.entity.cust_email;
    delete this.entity.cust_uname;
    delete this.entity.cust_name;
    delete this.entity.cust_phone;
    delete this.entity.cust_address;
    delete this.entity.cust_port;
    delete this.entity.cust_olt;
    delete this.entity.cust_slot;
    delete this.entity.cust_onuid;
    delete this.entity.cust_serviceArea;
    delete this.entity.mobile;
    delete this.entity.userName;

  }
  onrefreshclick(id) {
    if (this.entity.isSnmpEnable == true) {
      if (id && this.entity.cust_onuid) {
        let data = {
          custid: id,
          serialnumber: this.entity.cust_onuid
        }
        this.CasesService.caseLiveNetworkDetails(data).subscribe((res) => {
          if (res && res.dataList != null) {
            this.entity.serviceAreaCount = res.dataList[0].serviceAreaCount;
            this.entity.oltCount = res.dataList[0].oltCount;
            this.entity.slotCount = res.dataList[0].slotCount;
          }
        });
      } else {
        this.toastrService.error("ONU not found");
      }
    }
  }

  setResolution(objValue) {
    if (objValue === "Resolved") {
      this.formupdate.get('resolution_update').setValue(this.resolution[0].id)
    }
    if (objValue === "Suspend") {
      this.formupdate.get('case_suspendtilldate_update').setValidators(Validators.required);
      this.formupdate.get('case_suspendtilldate_update').updateValueAndValidity();
    }
    else {
      this.formupdate.get('case_suspendtilldate_update').clearValidators()
      this.formupdate.get('case_suspendtilldate_update').updateValueAndValidity();
    }

  }

  onDeleteClickUpdateDetails(id) {
    ////console.log("update id",id);
    this.CasesService.deleteUpdateDetails(id).subscribe((res) => {
      if (res && res.data) {
        this.entity = res.data
        this.getupdateListData()
      }
    });
  }
  updateFormSubmit(entity) {
    this.submitted = true
      this.updateList.resolutionId = this.entity.finalResolutionId;
      this.updateList.filename = "abc"
      this.updateList.nextFollowupDate = this.datePipe.transform(this.entity.nextFollowupDate, 'yyyy-MM-dd');
      if(typeof(this.entity.nextFollowupTime) == 'object'){
        this.updateList.nextFollowupTime = this.datePipe.transform(this.entity.nextFollowupTime,'hh:mm:ss')
      }
      else{
        this.updateList.nextFollowupTime = this.entity.nextFollowupTime
      }
      this.updateList.suspendDate = this.datePipe.transform(this.entity.suspendDate, 'yyyy-MM-dd');
      this.updateList.ticketId = this.entity.caseId;
      this.updateList.reasonId = this.entity.caseReasonId;
      this.updateList.status = this.entity.caseStatus;
      this.updateList.priority = this.entity.priority;
      this.updateList.commentBy = 'Staff';
      this.updateList.bucketType = this.entity.bucketType;
      this.updateList.title = this.entity.caseTitle;
      this.updateList.serviceAreaId = this.entity.serviceAreaId;
      this.updateList.assignee = this.entity.currentAssigneeId;
      this.updateList.teamType = this.entity.teamType;
      this.updateList.allocationType = this.entity.allocationType
      this.updateList.algorithmType = this.entity.algorithmType;
      this.CasesService.updateList(this.updateList).subscribe((res) => {
        if(res.responseCode == 200){
          this.entity = res.data;
          this.getupdateListData();
        }
        else{
          this.toastrService.error(res.responseMessage)
        }

      });
      this.ngbModalService.dismissAll();
  }
  getupdateListData() {
    if (this.entity && this.entity.caseUpdateList && this.entity.caseUpdateList.length > 0) {
      ////console.log("entity::", this.entity)

      this.entity.caseUpdateList.forEach((res) => {
        res.updateDetails.forEach((x) => {
          if (x.entitytype === "Remarks") {
            res.remark = x.newvalue;

          }
          if (x.remarktype === "Public") {
            res.remarkType = x.remarktype;
          }
          if (x.remarktype === "Private") {
            res.remarkType = x.remarktype;
          }
        });

        let newDate = new Date(res.createDateString);
        res.calculatedDay = this.calculateDiff(newDate, 'caseDay');
        if (res.calculatedDay === 0)
          res.calculatedTimeH = this.calculateDiff(newDate, 'caseHours');
        if (res.calculatedTimeH === 0)
          res.calculatedTimeM = this.calculateDiff(newDate, 'caseMinutes');
        if (res.calculatedTimeM === 0) {
          res.calculatedTimeS = this.calculateDiff(newDate, 'caseSecond');
        }
      });
    }
  }
  defaultSelectionAssignment(){
    this.entity.serviceAreaId = -1;
    this.setServiceToTeam(this.entity.serviceAreaId)
    this.entity.teamType = 'Back Office';
    this.entity.allocationType = 'Manual';
    this.getTeamList();
    setTimeout(() => {
      //console.log(this.TeamList);
      if(this.TeamList.length > 0){
        this.entity.teamId = this.TeamList[0].id
        this.selectStaff(this.TeamList[0]);
      }
    }, 2000);
   
  }
  openUpdate(update) {
    this.setValidation();
    this.updateList = new Updatelistmodel();
    ////console.log('Update Form =>', this.formupdate);
    this.defaultSelectionAssignment();
    this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
      res.dataList.forEach((x) => {
        if (x.value === "external") {
          this.updateList.remarkType = x.text;
        }
      });
    });
    this.ngbModalService.open(update, { size: 'lg', backdrop:'static'});
  }
  openUpdateprivate(update) {
    this.setValidation();
    this.updateList = new Updatelistmodel();
    this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
      res.dataList.forEach((x) => {
        if (x.value === "internal") {
          this.updateList.remarkType = x.text;
        }
      });
    });
    this.ngbModalService.open(update, { size: 'lg' , backdrop:'static'});

    
  }
  openCustDetails(custdetails) {
    this.ngbModalService.open(custdetails, { size: 'xl' });
  }
  valuepraparation(data: Array<CasesModel>) {
    if (data) {
      data.forEach((temp) => {
        temp.creationDate = String(temp.createdate)
        temp.finalResolutionDate = this.datePipe.transform(temp.finalResolutionDate, 'yyyy-MM-dd');
        temp.finalResolutionDate = temp.finalResolutionDate ? temp.finalResolutionDate : '-';
        if (temp.caseReasonId) {
          this.caseReasonService.getcaseReasonByID(temp.caseReasonId).subscribe((res) => {
            temp.caseReasonName = res.data.name ? res.data.name : '-';
          });
        }
        if (temp.finalResolvedById) {
          this.staffService.getStaffById(temp.finalResolvedById).subscribe((res) => {
            temp.assigne = res.Staff.firstname ? res.Staff.firstname : '-';
          });
        }

      });
    }


  }


  // cancelmodal() {

  //   this.CasesService.getCaseById(this.entity.caseId).subscribe((res) => {

  //     this.entity.priority = res.data.priority;
  //     this.entity.bucketType = res.data.bucketType;
  //     this.entity.caseReasonId = res.data.caseReasonId;
  //     this.entity.caseStatus = res.data.caseStatus;


  //   });
  //   this.ngbModalService.dismissAll();

  // }

  onEnterRemark() {
    if (this.updateList.remark) {
      this.error_remark = false;
      return;
    }

  }

  getLeaseOrNormalCustomerBasicDetails() {

    ////console.log(this.custId, this.entity.caseFor);

    this.CasesService.getLeaseOrNormalCustomerBasicDetails(this.custId, this.entity.caseFor).subscribe((res) => {
      this.dynamicdata = res.data.dynamicParams;
      ////console.log("new api", this.dynamicdata);
      let data = this.dynamicdata;
            
      this.Data = Object.keys(data).map(field => {
        let value = data[field];
        return { field_label: field, field_value: value }
      })

    })
  }

  onAssigneToMe() {
    //api call
    ////console.log("Assigne To Me");
  }
  onReasonchange(reason) {
    if (this.entity.caseTitle === '') {
      this.entity.caseTitle = reason.name
    }
    // if(reason && reason.bucket){
    //   this.form.get('bucket').setValue(reason.bucket);
    // }else{
    //   this.form.get('bucket').reset();
    // }
  }
  closeDialog() {
    this.dialogRef.close();
  }

  getGenericDataService() {
    return this.genericDataSharingService
  }

  //For Inventory Refresh[Kenil]
  onNgbtabChange(event){
    if(event.nextId == 'ngb-tab-2'){
      this.inventoryRef.getCaseInventoryHistory(this.entity.caseNumber);
    }
  }

  onChangeDocType(event){
    this.doc2 = undefined;
    this.doc = '-'
    if(event){
      this.finalList = this.document2.filter(res => res.documentType == event.documentType)
    }
  }

  onChangeDocL(event){
    this.doc = '-'
    if(event){
      this.doc = event.doc
    }
  }

  onSelect(){
    this.isSelectedAll = !this.isSelectedAll;
      this.finalList.map((ls) => ls.selected = this.isSelectedAll ? true : false)
  }
  getTime(flag: boolean) {
    this.CasesService.getTimeString(flag, this.entity.caseId).subscribe((res) => {
      if (res.responseCode == 200) {
        this.entity.caseStartedDate = res.data.caseStartedDate;
        this.entity.caseEndDate = res.data.caseEndDate;
      } else {
        this.toastrService.error(res.responseMessage)
      }
    });
  }
  
  getActivityByCaseId(id){
    let type='activity';
    this.CasesService.getActivityByCaseId(id,type).subscribe(res => {
      if(res && res.responseCode == 200){
        if(res.dataList)
        this.activityList = res.dataList;
      }else{
        this.toastrService.error(res.responseMessage);
      }
    })

    let type2='consumption';
    this.CasesService.getActivityByCaseId(id,type2).subscribe(res => {
      if(res && res.responseCode == 200){
        if(res.dataList)
        this.consumptionList = res.dataList;
      }else{
        this.toastrService.error(res.responseMessage);
      }
    })
  }

  getImage(item){
    this.CasesService.downloadDocument(item.referencedFileName)
  }

  getAttachmentList(id){
      this.getAttachmets(id,'pre/');
      this.getAttachmets(id,'post/');
      this.getAttachmets(id,'sign/');
  }

  getAttachmets(id,type){
    this.CasesService.getDocument(id, type).subscribe(res => {
      if(res.responseCode == 200){
        this.attachmentList.push(...res.dataList);
      }
    })
  }

}

export class Circuit {
    id:number;
    circuitid:string;
    circuitidentity:string;
    staticidpool:string;
    bandPoliname:string;
    conecttype:string;
    typeofdevice:string;
    othervalue:string;
    propertyLine:number;
    conectinvalue:string;
    qospolicyid:string;
    circuitType:string;

    status:string;
    didNos:string;
    pilotNo:string;
    noOfChannels:string;
    connectionType:string;
    authType:string;
    userName:string;
    password:string;
    ipAddress:number;
    circuitIpAddressDetails:Array<any>;
}